import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import Layout from "../../../../../components/Layout/Layout";
import ErrorText from '../../../../../components/TextField/ErrorText'
import { PrimaryButton, PrimaryOutlineButton } from '../../../../../components/Button'
import ErrorAlert from '../../../../../components/Alert/ErrorAlert';
import OTPVerificationModal from '../../../../../components/Modal/OTPVerificationModal';

// Images
import { logo, smallLogo, authentication } from '../../../../../assets/images'

// Utility Service
import { setUserSession, getToken } from '../../../../../utils/AuthService';

// API Service 
import { loginAFAService, loginService } from '../../../../../service/auth.service';
import { allCountryService } from '../../../../../service/country.service';

// Redux-Functions
import { setToken, setUser } from "../../../../../redux/userSlice"

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [otpVerificationModal, setOTPVerificationModal] = useState(false)

    useEffect(() => {
        // navigate to home page if login
        if (getToken()) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { register, getValues, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibilty
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function handleOTPVerificationModal
        * @params
        * @description set the visibility of otp verification modal
    */
    const handleOTPVerificationModal = () => {
        setOTPVerificationModal(!otpVerificationModal)
    }

    /**
        @function handleAFASignIn
        @params
        @description handle the sign in via AFA
    */
    const handleAFASignIn = async () => {
        try {
            const result = await loginAFAService();
            if (result?.data?.status) {
                window.location.href = result?.data?.data?.url
            }
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
        * @function onSubmit
        * @params formdata
        * @description used to signin the player
    */
    const onSubmit = async (formdata) => {
        try {
            const result = await loginService({
                phone: formdata.phone,
                password: formdata.password
            });
            if (result?.data?.status) {
                if (result.data?.data?.is_verified) {
                    dispatch(setToken(result?.data?.data?.token))
                    dispatch(setUser({
                        ...result.data?.data?.customer,
                        type: 'customer'
                    }))
                    setUserSession(result.data?.data?.token, {
                        ...result.data?.data?.customer,
                        type: 'customer'
                    })
                    navigate('/student/home');
                } else {
                    handleOTPVerificationModal()
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Student Sign In | AFA Coaching</title>
        </Helmet>
        <Container fluid>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start student-login'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" width='106' />
                    </Link>
                    <Row className="d-flex align-items-center justify-content-center flex-grow-1 w-100 mt-5">
                        <Col xs={10} sm={8} className='student-register border p-3'>
                            <h4 className='fw-bold mb-3'>Sign in</h4>
                            {/* <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fw-bold title'>Welcome Back</span>
                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <Form.Select
                                                {...register("country_id")}
                                            >
                                                {countryList.map((item, index) => {
                                                    return <option value={item?.code} key={index}>{item?.code}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control
                                                className='flex-grow-12'
                                                type="tel"
                                                autoComplete='off'
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Please provide the phone number"
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete='nope'
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Please provide the password",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <div className='d-flex justify-content-end'>
                                    <Link to='/forget-password' className='text-decoration-none'>
                                        <span className='fs-14 fw-bold text-primary'>Forgot Password?</span>
                                    </Link>
                                </div>

                                <Form.Group className="mb-3 mt-4">
                                    <Button size='md' variant="primary" className='text-light w-100' type='submit'>Sign In</Button>
                                </Form.Group>
                            </Form> */}

                            <Button size='md' variant="primary" className='text-light w-100 mb-2' onClick={handleAFASignIn}>
                                <img className="img-fluid me-2" src={smallLogo} alt="AFA" />
                                Sign In via AFA
                            </Button>
                            <hr className='mx-5 my-3' />

                            <div className='d-flex flex-column flex-md-row justify-content-md-center px-'>
                                {/* academy-login*/}
                                <Link to='/academy/login' className=' me-md-5'>
                                    {/* <Button size='md' variant="primary" className='text-light w-100' type='submit'>Sign in as Academy</Button> */}
                                    <Button size='lg' color='primary' variant="" className='px-0 px-md-4 mb-2 mb-md-0 w-100 text-primary fs-16 fw-600 buttonBG'>
                                        Sign in as Academy
                                    </Button>
                                </Link>
                                {/* Coach-login*/}
                                <Link to='/coach/login'>
                                    {/* <Button size='md' variant="primary" className='mt-3 text-light w-100' type='submit'>Sign in as Coach</Button> */}
                                    <Button size='lg' color='primary' variant="" className='ms- px-0 px-md-4 w-100 text-primary fs-16 fw-600 buttonBG'>
                                        Sign in as Coach
                                    </Button>
                                </Link>
                            </div>

                            {/* <Link to='/organizer-login' >
                                    <Button size='lg' variant="outline-primary" className='text-primary w-100 mt-3 mb-3'>Sign in as Organiser</Button>
                                </Link> */}

                            {/* <div className='text-center mt-4 mb-5'>
                                <span className='fs-14 fw-500 text-center'>
                                    Don&lsquo;t have an account?
                                    <Link to='/register' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign Up</span>
                                    </Link>
                                </span>
                            </div> */}

                            {/* <div className='student-signIn'>
                                <div className="d-none d-lg-block mt-auto">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="">
                                            <Link to='/academy/login' >
                                                <Button size='lg' color='primary' variant="" className='text-primary fs-16 fw-600 buttonBG'>
                                                    Sign in as Academy
                                                </Button>
                                            </Link>

                                        </div>
                                        <div className="">
                                            <Link to='/coach/login' >
                                                <Button size='lg' color='primary' variant="" className='text-primary fs-16 fw-600 buttonBG ms-2'>
                                                    Sign in as Coach
                                                </Button>
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                            </div> */}
                        </Col>
                        <Col xs={12} className='d-none d-lg-block mt-auto'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <span className='fs-14 fw-400 text-secondary'>
                                    © AFA 2023
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='fs-14 fw-400 text-secondary'>
                                        help@AFA.com
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block p-0'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container >

        {/* OTP Verification Modal */}
        < OTPVerificationModal show={otpVerificationModal} handleClose={handleOTPVerificationModal} phone={getValues("phone")} login={true} />
    </HelmetProvider>
};

export default SignIn;