import { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import CurrentBalance from "../../../../components/Common/CurrentBalance";
import MyTransaction from "../../../../components/Academy/Wallet/MyTransaction";
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { allTransactionHistoryService } from '../../../../service/walletAcademy.service';

const Wallet = () => {
    const [transactionHistoryList, setTransactionHistoryList] = useState([])
    const [searchKeyword, setSearchKeyword] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalEntry, setTotalEntry] = useState(1)

    /**
        * @function fetchTransactionHistoryList
        * @params
        * @description fetch the list of transaction history
    */
    const fetchTransactionHistoryList = async () => {
        try {
            const result = await allTransactionHistoryService({
                paginate: 1,
                page: currentPage,
                perPage: 10,
                keyword: searchKeyword
            });
            if (result?.data?.status) {
                setTransactionHistoryList(result?.data?.data?.wallet_transactions)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Academy Wallet</title>
        </Helmet>
        <CurrentBalance modal={false} fetchTransactionHistoryList={fetchTransactionHistoryList} />
        <MyTransaction fetchTransactionHistoryList={fetchTransactionHistoryList} transactionHistoryList={transactionHistoryList} currentPage={currentPage} setCurrentPage={setCurrentPage} searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} totalEntry={totalEntry} />
    </HelmetProvider>
};

export default Wallet;