import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button, Spinner, Badge } from 'react-bootstrap';

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classnames from "classnames";
import moment from 'moment';
import Flatpickr from 'react-flatpickr'
import { PlusCircle, X, Edit3, Calendar, Download, Link, Mail } from 'react-feather';
import { CSVLink } from "react-csv";

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import DataTable from 'react-data-table-component'
// API Service
import { downloadStudentEarningtService, filterStudentEarningService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"

const defaultValues = {
    startDate: null,
    endDate: null
}

const FilterDateRange = ({ singleData }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [data, setData] = useState(null)
    const [students, setStudents] = useState([])
    const [count, setCount] = useState([])
    const [downloadable, setDownloadable] = useState(null)
    const [csvData, setCSVData] = useState([]);


    let fetchingData = false
    let fileName = ''
    let finalStart = ''
    let finalEnd = ''

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

        },
        mode: "onBlur"
    })

    const setFileName = () => {
        return `order-${startDate}-to-${endDate}`
    }

    const columns = [{
        // {
        //     name: 'Sr. No.',
        //     cell: (row, index) => pageCount > 1 ? ((pageCount - 1) * 10) + index + 1 : index + 1,
        //     width: "100px",
        // },


        name: 'Date & Time',
        selector: (row) => row?.pivot?.created_at ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
        >{moment(row?.pivot?.created_at).format('DD MMM YYYY, dddd, h:mm A')}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '250px'
    },
    {
        name: 'Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Email',
        selector: (row) => row.email ? `${row.email}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Amount',
        selector: (row) => row?.pivot?.amount ? `RM ${row?.pivot?.amount}` : '--',
        minWidth: '120px'
    },
    {
        name: 'Status',
        minWidth: '130px',
        selector: (row) => {
            let showData = ''
            if (row.status === 'pending') {
                showData = <Badge pill bg='danger'>No Payment 1</Badge>
            } else if (row.status === 'success') {
                showData = <Badge pill bg="success">Full Paid 2</Badge>
            }
            showData = <Badge pill bg="success">Full Paid 3</Badge>
            return (showData)
        },
    }]

    const fields = ['Sr. No.',
        'Date & Time',
        'Email',
        'Phone Number',
        'Amount',
        'Status',]

    const processData = (item, index) => {
        return `${index + 1},${item?.pivot?.created_at ? `${moment(item?.pivot?.created_at).format('DD MMM YYYY, dddd, h:mm A')}` : '-'},${item.email ? item.email : '-'},${item.phone ? `+60 ${item?.phone}` : '-'},${item?.pivot?.amount ? `RM ${item?.pivot?.amount}` : '-'},${item.status ? item?.status === 'success' ? 'Full Paid' : 'No Payment' : '-'}`
    }

    const exportToCSV = () => {
        if (students?.length > 0) {
            const tableData = students
            const csvContent = [fields.join(','),
            ...tableData.map((item, index) => processData(item, index))]
                .join('\n')
                .replace(/(^\[)|(\]$)/gm, '')

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const downloadURL = URL.createObjectURL(blob)
            setDownloadable(downloadURL)
            fileName = setFileName()
            fetchingData = false
        } else {
            fetchingData = false
            setCount(0)
        }
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const result = await downloadStudentEarningtService({ id: singleData?.id });
            if (result?.data?.status) {
                setLoading(false)
                const get_all = result?.data?.data.student_lists
                console.log('filter', get_all)
                setStudents(get_all)
                setCount(get_all?.length)
                if (get_all?.length > 0) {
                    setCSVData(get_all?.map(item => {
                        return {
                            date_n_time: moment(item?.pivot?.created_at).format('DD MMM YYYY, dddd, h:mm A') || '-',
                            name: item?.name || '-',
                            email: item?.email || '-',
                            contact: `"${item?.phone}"` || '-',
                            amount: item?.pivot?.amount || '-',
                            status: item?.pivot?.payment_status === 'COMPLETED' ? 'Full Paid' : 'No Payment',
                        }
                    }))
                }
                
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    };

    useEffect(() => {
        if (singleData?.id) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData]);

    useEffect(() => {
        if (students?.length > 0) {
            exportToCSV()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students])

    const csvHeaders = [{ label: 'Date & Time', key: 'date_n_time' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone number', key: 'contact' },
    { label: 'Amount', key: 'amount' },
    { label: 'Status', key: 'status' }];
    
    const onSubmit = data => {
        setData(data)
        if (data.startDate && data.endDate) {
            if (data.startDate !== null && data.endDate !== null) {
                finalStart = moment(data.startDate[0]).format('YYYY-MM-DD')
                finalEnd = moment(data.endDate[0]).format('YYYY-MM-DD')
                // fetchData(data)
            }
        } else {
            ErrorAlert('Please provide start and end date')
        }
    }

    const handleCloseModal = () => {
        setShow(false)
        setStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setEndDate(moment(new Date()).format("YYYY-MM-DD"))
        setData([])
        setStudents([])
        setCount(0)
        setDownloadable(null)
        reset()
    }

    const displayCondition = () => {
        // count > -1 && !fetchingData
        if (count > -1 && !fetchingData) {
            return <div>
                <span className="text-muted">
                    Result:
                </span>
                <span className="text-primary font-weight-bold">
                    {` ${count} `}
                </span>
                <span>
                    {`record${count > 1 ? 's' : ''}`}
                </span>
            </div>
        }
    }
    const displayConditionDownload = () => {
        if (downloadable) {
            return <div className='d-flex align-md-items-center align-items-start justify-content-end'>
                {/* <div className=''>
                    <Input
                        className='dataTable-filter mb-50'
                        type='text'
                        bsSize='sm'
                        disabled
                        id='search-input'
                        defaultValue={fileName}
                    />
                    download={`${fileName}.csv`}
                </div> */}
                <div className='ms-2'>
                    <Button
                        className=''
                        variant='primary'
                        tag="a"
                        size='sm'
                        href={downloadable}
                        download={`earnings.csv`}
                    >
                        <Download size={15} className='text-light'/>
                        <span className='align-middle ms-50 fs-12 text-light'> Download CSV</span>
                    </Button>
                </div>

            </div>
        }
    }


    return <>
        <div className=''>
            <CSVLink className="text-decoration-none" filename={"Earnings.csv"} data={csvData} headers={csvHeaders}>
                <span role='button' className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded d-none d-lg-block'>Export CSV</span>
            </CSVLink>
        </div>
        
    </>

};

export default FilterDateRange;