import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner, Image } from 'react-bootstrap';
import { ArrowRight } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../components/Alert/ErrorAlert';
import JoinStudent from '../../components/Student/JoinStudent';

// Images
import { studentProgrammeDetailBanner, studentSmallProgrammeDetailBanner } from "../../assets/images/index";

// API Service
import { singleProgramService } from '../../service/guestHomeService';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"
import { setPaymentType } from "../../redux/slices/studentProgramme"

const ProgrammeDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const user = useSelector(selectUser);
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(false)
    const [programmeDetail, setProgramList] = useState([])
    const [addStudentModal, setAddStudentModal] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [indexWise, setIndex] = useState('')

    const showMoreProfile = (item, index) => {
        if (item?.id) {
            setShowMore(!showMore)
            setIndex(item?.id)
        }
    }

    const handleAddStudentModal = (item) => {
        if (item?.type) {
            dispatch(setPaymentType(item?.type))
        } else {
            dispatch(setPaymentType('MONTHLY'))
        }
        setAddStudentModal(!addStudentModal)
    }

    const handleCheckUser = () => {
        if (user?.type === 'customer') {
            handleAddStudentModal()
        } else {
            navigate('/login')
        }
    }

    /**
        * @function fetchSingleProgram
        * @params
        * @description fetch the list of customer banner
    */
    const fetchSingleProgram = async () => {
        try {
            console.log('fetchSingleProgram 1')
            const result = await singleProgramService({ id });
            if (result?.data?.status) {
                setLoading(false)
                setProgramList(result?.data?.data)
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleClose = () => {
        setAddStudentModal(false)
    }

    useEffect(() => {
        setLoading(true)
        fetchSingleProgram()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkPlan = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'Weekly Payment'
        } else if (type === 'MONTHLY') {
            showData = 'Monthly Payment'
        } else if (type === 'SESSION') {
            showData = 'Session Payment'
        }
        return (showData)
    }

    const checkPlanDuration = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'per Week'
        } else if (type === 'MONTHLY') {
            showData = 'per Month'
        } else if (type === 'SESSION') {
            showData = 'per Session'
        }
        return (showData)
    }

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }


    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching</title>
        </Helmet>
        <div>
            <div className='DetailSection'>
                <div className='DetailBanner' style={{ background: '#FFEEE6' }}>
                    <Container>
                        <Row>
                            <Col xl={7} className=''>
                                <div>
                                    <div className='programme-name mb-4'>
                                        <h1 className=''>{programmeDetail?.name}</h1>
                                    </div>
                                    <div className='programme-description mb-5'>
                                        <p>{programmeDetail?.description ? programmeDetail?.description.substr(0, 200) : ''}</p>
                                    </div>
                                    <div className='programme-button'>
                                        <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                            <div>
                                                {/* <UpdateProgramModal localData={programmeDetail} /> */}
                                            </div>
                                            <div className=''>
                                                <Button className='text-light fs-18' color='primary' onClick={handleCheckUser}>Enroll now <ArrowRight size='20' /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={5} className=''>
                                <div className='text-center programme-image pt-4'>
                                    <Image fluid src={programmeDetail?.banners && programmeDetail?.banners.length > 0 ? programmeDetail?.banners[0].banner : ''} alt='Banner' width='' />
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="student-learn pt-5 mb-5">
                <Container>
                    <Row className=''>
                        <Col xl={12}><div className='mb-2'>
                            <div>
                                <h1 className='fs-30 fw-bolder'>What you will learn</h1>
                            </div>
                        </div>
                        </Col>
                        <Col xl={5} lg={5} md={6} xs={12} className='student-single-pro'>
                            <div className="banner-programme web-visible-image">
                                <img fluid src={studentProgrammeDetailBanner} alt='program' />
                            </div>
                            <div className="mobile-banner-programme mobile-visible-image">
                                <img fluid src={studentSmallProgrammeDetailBanner} alt='program' />
                            </div>
                        </Col>
                        <Col xl={7} lg={7} md={6} xs={12}>
                            <div className="description-learning pt-5">
                                <h1 className='fs-30 mb-5'>{programmeDetail?.name}</h1>
                                <div className="mb-5">
                                    <p className=''>{programmeDetail?.learn ? parse(programmeDetail?.learn) : ''}</p>

                                </div>
                                <div className='academy-learn-description'>
                                    <Button className='text-light fs-18' color='primary' onClick={handleCheckUser}>Enroll now <ArrowRight size='20' /></Button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className='coach-details mt-4 mb-4'>
                <Container>
                    <Row className='box-coach gx-2 mb-2'>
                        <Col lg='12'>
                            <div className='mb-3'>
                                <div>
                                    <p className='fs-18 text-primary'>{programmeDetail?.name}</p>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div>
                                    <h1 className='fs-30 fw-bold'>Coach details on this program</h1>
                                </div>
                            </div></Col>

                        <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                            {/* /academy/coach/detail/${item?.id} */}
                            <div className='text-decoration-none'>
                                <div>
                                    <div className='coach-profile mb-3'>
                                        <img src={programmeDetail?.main_coach?.profile_image} alt='coach-profile' width='60' />
                                    </div>
                                    <div className='coach-name'>
                                        <h5 className='fw-600 text-dark fs-18'>{programmeDetail?.main_coach?.name}</h5>
                                    </div>
                                    <div className='coach-type'>
                                        {programmeDetail?.main_coach?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                    </div>
                                    <div className='coach-des'>
                                        <p className='mb-0 text-secondary'>{programmeDetail?.main_coach?.description ? programmeDetail?.main_coach?.description.substr(0, 200) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {programmeDetail?.sub_coaches?.length > 0 ? programmeDetail?.sub_coaches.map(item => (
                            <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                                {/* /academy/coach/detail/${item?.id} */}
                                <div className='text-decoration-none'>
                                    <div>
                                        <div className='coach-profile mb-3'>
                                            <img src={item?.profile_image} alt='coach-profile' width='60' />
                                        </div>
                                        <div className='coach-name'>
                                            <h5 className='fw-600 text-dark fs-18'>{item?.name}</h5>
                                        </div>
                                        <div className='coach-type'>
                                            {item?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                        </div>
                                        <div className='coach-des'>
                                            <p className='mb-0 text-secondary'>
                                                {showMore && item?.id === indexWise ? item?.description : item?.description ? item?.description?.substr(0, 200) : ''}
                                            </p>
                                            <p className='mb-0'>
                                                {item?.description?.length > 200 ? showMore && item?.id === indexWise ? <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show less</span> : <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show more</span> : ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )) : ''}

                    </Row>
                </Container>

            </div>

            <div className="student-price overview-pricing">
                <div className='price mt-2'>
                    <div className="price-color">
                        <Container>
                            <Row>
                                <div className=''>
                                    <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                        <div className='text-center'>
                                            <div>
                                                <h1 className='fs-16 mb-2'>Get Started Today 1</h1>
                                                <h1 className='fs-30 fw-bold mb-3'>{programmeDetail?.name}</h1>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <div className='price-list mt-2 mb-5'>
                <div className="">
                    <Container>
                        <div className='overview-pricing-box'>
                            <Row>
                                {programmeDetail?.frequencies?.length > 0 ? programmeDetail?.frequencies.map((item, index) => {
                                    return (
                                        <Col xl={4} md={6} xs={12} className='pt-3 mb-1' key={index}>
                                            <div className='price-box'>
                                                <div className=''>
                                                    <div className='monthly-plan'>
                                                        <p className='mb-3 text-primary'>{checkPlan(item)}</p>
                                                    </div>
                                                    <div className='monthly-price'>
                                                        <h1 className='fs-25 fw-bold mb-3 text-dark'>{`RM ${item.price ? Number(item.price)?.toFixed(2) : ''}`}</h1>
                                                    </div>
                                                    <div className='per-session'>
                                                        <p className=''>{checkPlanDuration(item)}</p>
                                                    </div>
                                                    <div className=''>
                                                        <Button className='text-light fs-18 w-100' color='primary' onClick={handleCheckUser}>Enroll now <ArrowRight size='20' /></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }) : ''}

                            </Row>

                        </div>
                    </Container>
                </div>
            </div>

            <div className='overview-requirement'>
                <Container>
                    <Row>
                        <div className='mt-5'>
                            <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                <div className="mb-4 description">
                                    <div className=''>
                                        <div>
                                            <h1 className='fs-28 fw-bold mb-3'>Requirements</h1>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='programme-list program-requirement'>
                                            {/* <p>{programmeDetail?.requirement}</p> */}
                                            <div className="academy-learn-description">
                                                <p>{programmeDetail?.requirement ? parse(programmeDetail?.requirement) : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className='bottom-enrollment mt-2'>
                <div className="student-bottom-enrollment">
                    <div className='mt-2'>
                        <div className="">
                            <Container>
                                <Row>
                                    <div className=''>
                                        <Col xl={12} md={12} xs={12} className='pt-3 mb-5'>
                                            <div className='text-center'>
                                                <div>
                                                    <h1 className='fs-16 mb-2'>Get Started Today</h1>
                                                    <h1 className='fs-30 fw-bold mb-3 text-primary'>{programmeDetail?.name}</h1>
                                                </div>
                                                <div className='custom-white-button'>
                                                    <Button className='text-light fs-18' color='light' onClick={handleCheckUser}>Enroll now</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                <JoinStudent show={addStudentModal} handleClose={handleCheckUser} size='md' id={id} />
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
            <div className='clearfix'></div>
        </div>
    </HelmetProvider>

};

export default ProgrammeDetail;