import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Tab, Tabs, Image, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

// Custom Component ../Alert/ErrorAlert
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { ArrowRight, Edit3 } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser, paidImage, studentProgrammeDetailBanner, studentSmallProgrammeDetailBanner } from "../../assets/images/index";

// API Service
import { singleProgramService, joinStudentSession, getStudentSession, singleMyProgramService } from '../../service/studentProgram';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"
import { setMySession, selectMySession } from "../../redux/slices/studentProgramme"

import ReactStars from 'react-rating-star-with-type'
import NoBank from "../../components/Common/NoBank";
import moment from 'moment'

import { useParams, Link } from 'react-router-dom';
import JoinStudent from './JoinStudent'
import RatingReviewModal from './ReviewRating'
import classNames from 'classnames';

const ProgrammeDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [programmeDetail, setProgramList] = useState([])
    const [star, setStar] = useState(5);
    const user = useSelector(selectUser);
    const all_session = useSelector(selectMySession);
    const [sessionObject, setSessionObject] = useState([])
    const [addStudentModal, setAddStudentModal] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [indexWise, setIndex] = useState('')

    const handleCheckSession = (subItem, sIndex) => {
        const temp = [...sessionObject]
        if (!sessionObject?.some(item => item?.session_id === subItem?.session_id)) {
            temp?.push(subItem)
            setSessionObject(temp)
        } else if (sessionObject?.some(item => item?.id === subItem?.id)) {
            setSessionObject(sessionObject?.filter(item => item?.id !== subItem?.id))
        } else {
            ErrorAlert('You can not select multiple timeslot of same session.')
        }
    }

    const showMoreProfile = (item, index) => {
        if (item?.id) {
            setShowMore(!showMore)
            setIndex(item?.id)
        }
    }

    /**
         * @function handleAddStudentModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleAddStudentModal = () => {
        setAddStudentModal(!addStudentModal)
    }

    /**
         * @function fetchSingleProgram
         * @params
         * @description fetch the list of customer banner
         */
    const fetchSingleProgram = async () => {
        try {
            const result = await singleMyProgramService({ id });
            if (result?.data?.status) {
                setLoading(false)
                setProgramList(result?.data?.data)
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }
    const handleClose = () => {
        setAddStudentModal(false)
    }

    // const statusUpdate = async () => {
    //     try {
    //         const result = await programmeStatusChange({ id });
    //         if (result?.data?.status) {
    //             fetchSingleProgram()
    //         } else {
    //             ErrorAlert(result?.response?.data?.message)
    //         }
    //     } catch (error) {
    //         ErrorAlert(error)
    //     }
    // }

    /**
     * @function fetchCustomerSessionList
     * @params
     * @description fetch the list of customer session
    */
    const fetchCustomerSessionList = async () => {
        try {
            const result = await getStudentSession({ id: id });
            if (result?.data?.status) {
                dispatch(setMySession(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    };

    useEffect(() => {
        if (user?.type === 'customer') {
            setLoading(true)
            fetchSingleProgram()
        }

        fetchCustomerSessionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkPlan = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'Weekly Payment'
        } else if (type === 'MONTHLY') {
            showData = 'Monthly Payment'
        } else if (type === 'SESSION') {
            showData = 'Session Payment'
        }
        return (showData)
    }

    const checkPlanDuration = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'per Week'
        } else if (type === 'MONTHLY') {
            showData = 'per Month'
        } else if (type === 'SESSION') {
            showData = 'per Session'
        }
        return (showData)
    }

    /**
         * @function handleJoinSession
         * @params 
         * @description used to join session
         */
    const handleJoinSession = async (objIndex) => {
        const selectedSession = sessionObject?.filter((item) => all_session[objIndex]?.timeslots?.find((itemS) => itemS?.id === item?.id))
        if (selectedSession?.length > 0) {
            try {
                if (programmeDetail?.id) {
                    const data = new FormData()
                    data.append('session_id', selectedSession[0]?.session_id)
                    data.append('timeslot_id', selectedSession[0]?.id)
                    // data.append('students[0]', user?.id)
                    if (programmeDetail?.students?.length > 0) {
                        programmeDetail?.students.map((element, index) => (
                            data.append(`students[${index}]`, element?.id)
                        ))
                    }
                    const result = await joinStudentSession({ data });
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        setSessionObject([])
                        fetchCustomerSessionList()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('You have to select time slot first.')
        }
    }

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }

    return <HelmetProvider>
        <Helmet>
            <title>Student My Programs || AFA Coaching</title>
        </Helmet>
        <div>
            <div className='DetailSection'>
                <div className='DetailBanner' style={{ background: '#FFEEE6' }}>
                    <Container>
                        <Row>
                            <Col xl={7} className=''>
                                <div>
                                    <div className='programme-name mb-4'>
                                        <h1 className=''>{programmeDetail?.name}</h1>
                                    </div>
                                    <div className='programme-description mb-5'>
                                        <p>{programmeDetail?.description ? programmeDetail?.description.substr(0, 200) : ''}</p>
                                    </div>
                                    <div className='programme-button'>
                                        <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                            <div>
                                                {/* <UpdateProgramModal localData={programmeDetail} /> */}
                                            </div>
                                            {/* <div className=''>
                                                    <Button className='text-light fs-18' color='primary' onClick={handleAddStudentModal}>Enroll now <ArrowRight size='20' /></Button>
                                                </div> */}
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            <Col xl={5} className=''>
                                <div className='text-center programme-image pt-4'>
                                    <Image fluid src={programmeDetail?.banners && programmeDetail?.banners.length > 0 ? programmeDetail?.banners[0].banner : ''} alt='Banner' width='' />
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="student-session-listing">
                <div className='single-session'>
                    <Container>
                        <Row>
                            <Col xl={12} md={12} xs={12} className='pt-5 mb-1'>
                                <div className="student-heading">
                                    <div>
                                        <h1 className='fs-30 fw-bold'>Available Sessions</h1>
                                        <p className='text-primary fw-600 fs-20'> 4 Sessions per month</p>
                                    </div>
                                </div>
                            </Col>
                            {all_session?.length > 0 ? all_session?.map((item, index) => {
                                return (
                                    // <>
                                    //     <Col xl={12} md={12} xs={12} className='pt-1 mb-1' key={index}>
                                    //         <div className='session-show mb-2'>
                                    //             <div className=''>
                                    //                 <div className="session-count"><p className='fs-20 fw-600'>Session - {index + 1}</p></div>
                                    //                 <div className='session-date'>
                                    //                     <p className='fs-16 fw-600'>{moment(item?.date).format('MM/DD/YYYY, dddd')}</p>
                                    //                 </div>

                                    //                 <div className="time-slots">
                                    //                     <div className="d-flex justify-content-between align-content-center">
                                    //                         <div className="div">
                                    //                             <div className="list d-flex justify-content-start align-content-center">
                                    //                                 {item?.timeslots?.length > 0 ? item?.timeslots.map((subItem, sIndex) => {
                                    //                                     return (
                                    //                                         <div className="" key={sIndex} >
                                    //                                             {/* <Button variant='dark' className='time-slot-button me-2'>{moment(i?.start_time).format('MM/DD/YYYY, h:mm A')} - {moment(i?.end_time).format('h:mm A')}</Button> */}
                                    //                                             {/* <Button variant='dark' className='time-slot-button me-2 pe-none' onClick={() => handleJoinSession(item, subItem)}>{subItem?.start_time} - {subItem?.end_time}</Button> */}

                                    //                                             <Button variant='' className={classNames('border me-3', { 'bg-light pe-none opacity-50': !subItem?.is_open, "bg-primary opacity-50 text-light pe-none": subItem?.is_joined, 'pe-none opacity-50': item?.timeslots?.some((itemTime) => itemTime?.is_joined) })}> {`${subItem?.start_time}`} {`- ${subItem?.end_time}`} </Button>
                                    //                                         </div>
                                    //                                     )
                                    //                                 }) : ''}
                                    //                             </div>
                                    //                         </div>
                                    //                         {/* <div className="div">
                                    //                             {item?.is_joined ? <div className="joined-session float-end"><Button variant='success' size='lg' className='me-2 text-light join-button'>Joined</Button></div> : <div className="joined-session float-end"><Button variant='primary' className='me-2 text-light join-button'>Join</Button></div>}
                                    //                         </div> */}
                                    //                         {item?.timeslots?.some((itemTime) => itemTime?.is_joined) ? <div className="joined-session float-end"><Button variant='success' size='lg' className='me-2 text-light join-button'>Joined</Button></div> : <div className="float-end"><Button variant='primary' className='me-2 text-light join-button px-5'>Join</Button></div>}
                                    //                     </div>
                                    //                 </div>

                                    //             </div>
                                    //         </div>
                                    //         <hr className='custom-hr' />
                                    //     </Col>

                                    // </>

                                    <div className='parent-session pl-3' key={`ul-${index}`}>
                                        <div className="date-session">
                                            <p className='fw-600'> Session {index + 1}</p>
                                            <p className='fw-600'>{item?.date ? moment(item?.date).format('DD/MM/YYYY, dddd') : ''} </p>
                                        </div>

                                        <div className="d-flex flex-wrap align-items-center align-items-start">
                                            {item?.timeslots.map((subItem, sIndex) => {
                                                return <>
                                                    <div className='parent-child-session ms-3 mb-2' key={`li-${sIndex}`}>
                                                        <Button variant='' className={classNames('border', { 'bg-primary text-light': sessionObject?.some(sessionItem => sessionItem?.id === subItem?.id), 'bg-primary pe-none opacity-50': !subItem?.is_open, "bg-primary opacity-50 text-light pe-none": subItem?.is_joined, 'pe-none opacity-50': item?.timeslots?.some((itemTime) => itemTime?.is_joined) })} onClick={() => handleCheckSession(subItem, sIndex)}> {`${subItem?.start_time}`} {`- ${subItem?.end_time}`} </Button>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                        <div className="horizental-row">
                                            <div className="joined mt-3 mb-1"> {item?.timeslots?.some((itemTime) => itemTime?.is_joined) ? <Button size='sm' variant="success" className='px-5 text-light join-button pe-none'> Joined</Button> : <Button variant='primary' size='sm' className='text-light join-button px-4' onClick={() => handleJoinSession(index)}> Join session </Button>} </div>
                                            <div className="show-row">
                                                {item === item?.timeslots.length - 1 ? '' : <hr className='program-custom-hr' />}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''}
                        </Row>
                    </Container>
                </div>

            </div>

            <div className="student-learn pt-3 mb-5">
                <Container>
                    <Row className=''>
                        <Col xl={12}><div className='mb-2'>
                            <div>
                                <h1 className='fs-30 fw-bolder'>What you will learn</h1>
                            </div>
                        </div>
                        </Col>
                        <Col xl={5} lg={5} md={6} xs={12} className='student-single-pro'>
                            <div className="banner-programme web-visible-image">
                                <Image fluid src={studentProgrammeDetailBanner} alt='program-image' />
                            </div>
                            <div className="mobile-banner-programme mobile-visible-image">
                                <Image fluid src={studentSmallProgrammeDetailBanner} alt='program-image' />
                            </div>
                        </Col>
                        <Col xl={7} lg={7} md={6} xs={12}>
                            <div className="description-learning pt-5">
                                <h1 className='fs-30 mb-5'>{programmeDetail?.name}</h1>
                                <div className="mb-5">
                                    {/* <p className=''>{programmeDetail?.learn}</p> */}
                                    <p className='academy-learn-description'>{programmeDetail?.learn ? parse(programmeDetail?.learn) : ''}</p>
                                </div>
                                {/* <div className=''>
                                        <Button className='text-light fs-18' color='primary' onClick={handleAddStudentModal}>Enroll now <ArrowRight size='20' /></Button>
                                    </div> */}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className='coach-details mt-4 mb-4'>
                <Container>
                    <Row className='box-coach gx-2 mb-2'>
                        <Col lg='12'>
                            <div className='mb-3'>
                                <div>
                                    <p className='fs-18 text-primary'>{programmeDetail?.name}</p>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div>
                                    <h1 className='fs-30 fw-bold'>Coach details on this program</h1>
                                </div>
                            </div></Col>

                        <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                            {/* /academy/coach/detail/${item?.id} */}
                            <div className='text-decoration-none'>
                                <div>
                                    <div className='coach-profile mb-3'>
                                        <img src={programmeDetail?.main_coach?.profile_image} alt='coach-profile' width='60' />
                                    </div>
                                    <div className='coach-name'>
                                        <h5 className='fw-600 text-dark fs-18'>{programmeDetail?.main_coach?.name}</h5>
                                    </div>
                                    <div className='coach-type'>
                                        {programmeDetail?.main_coach?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                    </div>
                                    <div className='coach-des'>
                                        <p className='mb-0 text-secondary'>{programmeDetail?.main_coach?.description ? programmeDetail?.main_coach?.description.substr(0, 200) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {programmeDetail?.sub_coaches?.length > 0 ? programmeDetail?.sub_coaches.map(item => (
                            <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                                {/* /academy/coach/detail/${item?.id} */}
                                <div className='text-decoration-none'>
                                    <div>
                                        <div className='coach-profile mb-3'>
                                            <img src={item?.profile_image} alt='coach-profile' width='60' />
                                        </div>
                                        <div className='coach-name'>
                                            <h5 className='fw-600 text-dark fs-18'>{item?.name}</h5>
                                        </div>
                                        <div className='coach-type'>
                                            {item?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                        </div>
                                        <div className='coach-des'>
                                            <p className='mb-0 text-secondary'>
                                                {showMore && item?.id === indexWise ? item?.description : item?.description ? item?.description?.substr(0, 200) : ''}
                                            </p>
                                            <p className='mb-0'>
                                                {item?.description?.length > 200 ? showMore && item?.id === indexWise ? <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show less</span> : <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show more</span> : ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )) : ''}

                    </Row>
                    <Row>
                        <div className=''>
                            <Col xl={12} md={12} xs={12} className='pt-3 mb-5'>
                                <div className='text-center student-bottom-enrollment'>
                                    {programmeDetail?.id ? <RatingReviewModal rowData={programmeDetail} /> : ''}
                                    {/* <div className='custom-white-button'>
                                            <Button className='give-rating-buttonn' color='light' >Give Ratings to your Coach</Button>
                                        </div> */}
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>

            </div>

            <div className="student-price overview-pricing">
                <div className='price mt-2'>
                    <div className="price-color">
                        <Container>
                            <Row>
                                <div className=''>
                                    <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                        <div className='text-center'>
                                            <div>
                                                <h1 className='fs-16 mb-2'>Get Started Today</h1>
                                                <h1 className='fs-30 fw-bold mb-3'>{programmeDetail?.name}</h1>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <div className='price-list mt-2 mb-5'>
                <div className="">
                    <Container>
                        <div className='overview-pricing-box'>
                            <Row className='gx-4'>
                                {programmeDetail?.frequencies?.length > 0 ? programmeDetail?.frequencies.map((item, index) => {
                                    return (
                                        <Col xl={4} md={6} xs={12} className='pt-3 mb-1' key={index}>
                                            <div className='price-box'>
                                                <div className=''>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="div">
                                                            <div className='monthly-plan'>
                                                                <p className='mb-3 text-primary'>{checkPlan(item)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="div">
                                                            <div className="paid-image">
                                                                <Image src={paidImage} alt='paid-logo' />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='monthly-price'>
                                                        <p className='fw-bold mb-3 text-dark'>{`RM ${item.price ? Number(item.price)?.toFixed(2) : ''}`}</p>
                                                    </div>
                                                    <div className='per-session'>
                                                        <p className=''>{checkPlanDuration(item)}</p>
                                                    </div>
                                                    <div className=''>
                                                        {/* <ArrowRight size='20' /> */}
                                                        <Button className='text-light fs-18 w-100' color='primary'>Change Plan </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }) : ''}

                            </Row>

                        </div>
                    </Container>
                </div>
            </div>

            <div className='overview-requirement'>
                <Container>
                    <Row>
                        <div className='mt-5'>
                            <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                <div className=''>
                                    <div>
                                        <h1 className='fs-28 fw-bold mb-3'>Requirements</h1>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='programme-list program-requirement'>
                                        {/* <p>{programmeDetail?.requirement}</p> */}
                                        <p>{programmeDetail?.requirement ? parse(programmeDetail?.requirement) : ''}</p>
                                        {/* <ul className='list'>
                                        {frequencies?.length > 0 ? requirements.map(item => (
                                            <li>{item?.name}</li>
                                        )) : <NoBank />}
                                    </ul> */}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Row>
                    <Row className='pt-4 mb-4'>

                        <Col xl={12} lg={12} xs={12}>

                        </Col>
                        <Col xl={12} lg={12} xs={12}>

                        </Col>
                    </Row>
                </Container>

            </div>
            <div className='bottom-enrollment mt-2'>
                <div className="student-bottom-enrollment">
                    <div className='mt-2'>
                        <div className="">
                            <Container>
                                <Row>
                                    <div className=''>
                                        <Col xl={12} md={12} xs={12} className='pt-3 mb-5'>
                                            <div className='text-left'>
                                                <div>
                                                    <h1 className='fs-30 fw-bold mb-3' style={{ color: '#101828' }}>Your Rating and Review this program</h1>
                                                    <h1 className='fs-16 mb-2' style={{ color: '#667085' }}>Your valuable ratings and reviews matter to us.</h1>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12} md={12} xs={12} className='pt-3 mb-5'>
                                            <div className='text-center'>
                                                {programmeDetail?.id ? <RatingReviewModal rowData={programmeDetail} /> : ''}
                                                {/* <div className='custom-white-button'>
                                                        <Button className='text-light fs-16' color='light' onClick={handleAddStudentModal}>Give Ratings and Reviews</Button>
                                                    </div> */}
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                <JoinStudent show={addStudentModal} handleClose={handleAddStudentModal} size='md' id={id} />
                            </Container>
                        </div>
                    </div>
                </div>

            </div>
            <div className='clearfix'></div>
        </div>
    </HelmetProvider>

};

export default ProgrammeDetail;