import axios from "axios"

/**
    * @function allClientService
    * @param
    * @description This function is used to get all bank account
*/
export const allClientService = async () => {
    try {
        const result = await axios.get(`/customer/sports/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCountService
    * @param
    * @description This function is used to get all count
*/
export const allCountService = async () => {
    try {
        const result = await axios.get(`/customer/home`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allBannerService
    * @param
    * @description This function is used to get all banner data
*/
export const allBannerService = async () => {
    try {
        const result = await axios.get(`/customer/home`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allProgramsService
    * @param
    * @description This function is used to get all bank
*/
export const allProgramsService = async () => {
    try {
        const result = await axios.get(`/customer/programs/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramService
    * @param
    * @description This function is used to get all bank
*/
export const singleProgramService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/programs/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCategoryService
    * @param
    * @description This function is used to get all bank account
*/
export const allCategoryService = async () => {
    try {
        const result = await axios.get(`/customer/sports/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function allTopCoachService
    * @param
    * @description This function is used to get all bank account
*/
export const allTopCoachService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/home/top-coaches/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}/**
    * @function allFaqService
    * @param
    * @description This function is used to get all bank account
*/
export const allFaqService = async () => {
    try {
        const result = await axios.get(`/customer/faq/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getStudentRatingService
    * @param
    * @description This function is used to get all bank account
*/
export const getStudentRatingService = async () => {
    try {
        const result = await axios.get(`/customer/review/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getFeedbackService
    * @param
    * @description This function is used to get feedback
*/
export const getFeedbackService = async () => {
    try {
        const result = await axios.get(`/customer/feedback/list`)
        return result;
    } catch (error) {
        return error;
    }
}