import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    coachList: [],
    exportList: [],
    singleDetail: {},
    isFreshData: false,
    totalEntry: 0,
}

export const coachSlice = createSlice({
    name: "coachSlice",
    initialState,
    reducers: {
        setCoachList: (state, action) => {
            state.coachList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const { 
    setCoachList, 
    setFreshList, 
    setSingleData,
    setTotalEntry
} = coachSlice.actions

export const selectCoachList = (state) => state.academyCoach.coachList 
export const selectFreshData = (state) => state.academyCoach.isFreshData
export const selectTotalEntry = (state) => state.academyCoach.totalEntry
export const selectSingleDetail = (state) => state.academyCoach.singleDetail

export default coachSlice.reducer