import axios from "axios"

/**
    * @function currentBalanceService
    * @param
    * @description This function is used to get current balance for academy
*/
export const currentBalanceService = async() =>{
    try {
        const result = await axios.get(`/academy/wallet/current-balance`)
        return result;
    } catch (error) {
        return error;
    }
}
 
/**
    * @function withdrawWalletService
    * @param data
    * @description This function is used to withdraw wallet for academy
*/
export const withdrawWalletService = async(data) =>{
    try {
        const result = await axios.post(`/academy/wallet/withdraw`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allTransactionHistoryService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all transaction history for academy paginate=${paginate}&
*/
export const allTransactionHistoryService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/academy/wallet/transaction-history?page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}