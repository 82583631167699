import { useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from 'react-redux';
import classNames from "classnames";

// Custom Component
import Sidebar from "../Sidebar";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Custom Hook
import useWindowSize from "../Hook/windowSize";

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const Layout = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const windowSize = useWindowSize();

    const user = useSelector(selectUser);
    
    const authenticatedRoute = ['/login', '/organizer-login', '/academy/login', '/coach/login', '/register', '/forget-password', '/reset-password', '/about-us', '/contact-us', '/privacy-policy', '/terms-and-condition']

    /**
        * @function toggle
        * @params
        * @description set the visibility of sidebar menu
    */
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return <div className="App">
        {user && (user?.type === 'academy' || user?.type === 'coach') ? <>
            <div className="d-flex flex-grow-1">
                {windowSize?.width >= 992 ? <Sidebar toggle={toggle} isOpen={true} authenticatedRoute={authenticatedRoute} /> : <Sidebar toggle={toggle} isOpen={isOpen} authenticatedRoute={authenticatedRoute} />}
                <Container fluid className={classNames("content overflow-hidden", {
                    "is-open": isOpen
                })}
                >
                    <Navbar toggle={toggle} authenticatedRoute={authenticatedRoute} />
                    {props.children}
                </Container>
            </div>
            <div className={classNames("d-block", {
                "d-none d-lg-block": isOpen
            })}>
                <Footer authenticatedRoute={authenticatedRoute} />
            </div>
        </> : <>
            <div>
                <Header authenticatedRoute={authenticatedRoute} />
            </div>
            <main className="flex-grow-1">{props.children}</main>
            <div>
                <Footer authenticatedRoute={authenticatedRoute} />
            </div>
        </>
        }
    </div>
};

export default Layout;