import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// Custom Component
import ErrorText from '../../../../../components/TextField/ErrorText'
import ErrorAlert from '../../../../../components/Alert/ErrorAlert';
import OTPVerificationModal from '../../../../../components/Modal/OTPVerificationModal';

// Images
import { logo, authentication } from '../../../../../assets/images'

// API Service
import { forgetPasswordService } from '../../../../../service/auth.service';
import { allCountryService } from '../../../../../service/country.service';

const ForgotPassword = () => {
    const [countryList, setCountryList] = useState([])
    const [otpVerificationModal, setOTPVerificationModal] = useState(false)
    
    const { register, getValues, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function handleOTPVerificationModal
        * @params
        * @description set the visibility of otp verification modal
    */
    const handleOTPVerificationModal = () => {
        setOTPVerificationModal(!otpVerificationModal)
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to send otp to phone number for reset password
    */
    const onSubmit = async (formdata) => {
        try {
            const result = await forgetPasswordService({
                phone: formdata.phone
            });
            if (result?.data?.status) {
                handleOTPVerificationModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Forgot Password | AFA Coaching</title>
        </Helmet>
        <Container fluid>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" width='120' />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5 student-register">
                        <Col xs={10} sm={8}>
                            <p className='fs-30 fw-bold title'>Forgot Password</p>
                            <span className='fw-400 text-secondary'>Enter registered Phone number to get verification code</span>

                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Select
                                            {...register("country_id")}
                                        >
                                            {countryList.map((item, index) => {
                                                return <option value={item?.code} key={index}>{item?.code}</option>
                                            })}
                                        </Form.Select>
                                        <Form.Control
                                            className='flex-grow-5'
                                            type="tel"
                                            autoComplete='off'
                                            placeholder="Enter Phone number"
                                            {...register("phone", {
                                                required: "Please provide the phone number"
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                </Form.Group>
                       
                                <Form.Group className="mt-5 mb-3">
                                    <Button size='lg' color='primary' variant="" className='text-primary fs-16 fw-600 buttonBG' type="submit">
                                        Get Verification code
                                    </Button>
                                </Form.Group>
                            </Form>

                            <div className='text-center mt-5 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Remember Password?
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign In</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <Col xs={12} className='d-none d-lg-block mt-auto'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <span className='fs-14 fw-400 text-secondary'>
                                    © AFA 2023
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='fs-14 fw-400 text-secondary'>
                                        help@AFA.com
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block p-0'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>

        {/* OTP Verification Modal */}
        <OTPVerificationModal show={otpVerificationModal} handleClose={handleOTPVerificationModal} size='sm' phone={getValues("phone")} forgotPassword={true} />
    </HelmetProvider>
};

export default ForgotPassword;