import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { ArrowRight, X } from 'react-feather'
import { useSelector } from 'react-redux';

// Custom Component
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import PaymentConfirmModal from '../Modal/PaymentConfirmModal'

// Images
import { CreditCard } from "../../assets/images/index";

// API Service
import { getCheckoutStudents, studentCheckoutService, getJustAddedStudents, studentDeleteFromProgramService } from '../../service/studentProgram';

// Redux-Functions
import { selectPaymentType } from "../../redux/slices/studentProgramme"
import { selectUser } from "../../redux/userSlice"

import { useNavigate, useParams, Link } from 'react-router-dom';

const ProgrammeDetail = () => {
    const { id } = useParams()
    const [isLoading, setLoading] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [programmeDetail, setProgramList] = useState([])
    const [justAddedStudentList, setJustAddedStudentList] = useState([])
    const paymentType = useSelector(selectPaymentType)
    const [moodValue, setModeValue] = useState(paymentType);
    const [summaryDetail, setSummaryDetail] = useState();
    const [methodValue, setMethodValue] = useState('ONLINE');
    const user = useSelector(selectUser);
    const navigate = useNavigate()
    const payment_methods = [{ name: 'Credit Card', value: 'ONLINE' },
    { name: 'Pay Pay', value: 'ONLINE' },
    { name: 'Grab Pay', value: 'ONLINE' }]

    const makePaymentNow = async () => {
        console.log('makePaymentNow')
        const final_price = (summaryDetail?.price) * (justAddedStudentList?.length)
        if (justAddedStudentList?.length > 0) {
            if (summaryDetail?.price && id) {
                try {
                    const data = new FormData()
                    data.append('amount', final_price)
                    data.append('program_id', id)
                    data.append('payment_method', methodValue)
                    data.append('frequency_type', moodValue)
                    if (justAddedStudentList?.length > 0) {
                        justAddedStudentList.map((element, index) => (
                            data.append(`student_ids[${index}]`, element.id)
                        ))
                    }
                    const result = await studentCheckoutService({ data });
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        window.open(result?.data?.data)
                        navigate('/student/home')
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } catch (error) {
                    ErrorAlert(error)
                }
            }
        }
    }

    useEffect(() => {
        if (confirmation) {
            makePaymentNow()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmation]);
    /**
        * @function handleConfirmModalShow
        * @params (rowData)
        * @description use To show modal
    */
    const handleConfirmModalShow = (rowData) => {
        if (moodValue) {
            setConfirmModalShow(!confirmModalShow);
            setRowData(rowData)
        } else if (!moodValue) {
            ErrorAlert('Select Payment Mood.')
        }

        // else if (!methodValue) {
        //     ErrorAlert('Select Payment Method.')
        // }

    };

    /**
        * @function checkoutSingleProgram
        * @params
        * @description fetch the list of customer banner
    */
    const checkoutSingleProgram = async () => {
        try {
            const result = await getCheckoutStudents({ id });
            if (result?.data?.status) {
                setLoading(false)
                setProgramList(result?.data?.data)
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchStudentAddedProgram
         * @params
         * @description fetch the list of customer banner
         */
    const fetchStudentAddedProgram = async () => {
        try {
            const result = await getJustAddedStudents({ id });
            if (result?.data?.status) {
                setLoading(false)
                if (result?.data?.data?.length === 0) {
                    navigate(`/student/program/detail/${id}`)
                }
                setJustAddedStudentList(result?.data?.data)
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        checkoutSingleProgram()
        fetchStudentAddedProgram()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (programmeDetail?.frequencies?.length > 0) { autoSelectMood() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programmeDetail])

    const autoSelectMood = () => {
        if (programmeDetail?.frequencies?.length > 0) {
            const value = moodValue
            if (value) {
                setModeValue(value)
                setSummaryDetail(programmeDetail?.frequencies[0])
                programmeDetail?.frequencies?.forEach((item) => {
                    if (item?.type === moodValue) {
                        setSummaryDetail(item)
                    }
                })
            }

        }
    }

    const selectMood = (item, e) => {
        const value = e.target.value
        if (value) {
            setModeValue(value)
            setSummaryDetail(item)
        }
    }

    const checkTypes = (item) => {
        let textValue = ''
        if (item.type === 'MONTHLY') {
            textValue = 'Monthly Payment'
        } else if (item.type === 'SESSION') {
            textValue = 'Session Payment'
        } else if (item.type === 'WEEKLY') {
            textValue = 'Weekly Payment'
        }
        return textValue
    }

    const handleRemoveStudent = async (item) => {
        if (item?.id) {
            try {
                const result = await studentDeleteFromProgramService({ id: item?.id });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    checkoutSingleProgram()
                    fetchStudentAddedProgram()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        }
    }

    if (isLoading) {
        return <div className='parent'>
            <div className='myHeight text-center'>
                <Spinner variant='primary' />
            </div>
        </div>
    }

    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching Student Checkout</title>
        </Helmet>
        {programmeDetail &&
            <div>
                {/* className='DetailBanner' style={{ background: '#FFEEE6' }} */}
                <div className='checkout mt-2'>
                    <div>
                        <Container>
                            <Row>
                                <Col xl={12} className=''>
                                    <div className='checkout-title pt-4 mb-5'>
                                        <h1 className='fw-600'>Checkout</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xl={12} className=''>
                                    <div className="academy-name"><p>{programmeDetail?.academy?.name}</p></div>
                                </Col>
                                <Col xl={12} className=''>
                                    <div className="programme-name">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p>{programmeDetail?.name}</p>
                                            <p className='fs-24 fw-500'>4 Session</p>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="joined-programmes">
                        <Container>
                            <Row>
                                <Col xl={6} md={6} sm={12} className=''>
                                    <div className='checkout-programmes pt-4 mb-5'>
                                        <div className="list">
                                            <ul>
                                                <spa><h4>Students</h4></spa>
                                                {programmeDetail && justAddedStudentList?.length > 0 ? justAddedStudentList.map((item, index) => {
                                                    return (

                                                        <li key={`li-${index}`}>
                                                            <p ><span className='fw-600'>Student {index + 1}</span> <span> <div className="cancel float-end text-primary cursor-pointer"><X size={22} className='custom-hover' onClick={(e) => handleRemoveStudent(item)} /> </div></span></p>
                                                            <p><span className='name fw-600'>{item?.name}</span> </p>
                                                            <p><span>{item?.gender}</span> .  <span>{item?.email}</span> . <span>{item?.phone ? `+60 ${item?.phone}` : ''}</span>  </p>
                                                        </li>
                                                    )
                                                }

                                                ) : ''}
                                            </ul>
                                        </div>

                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12} className=''>

                                    <div className="pt-4">
                                        <div className='checkout-payment mb-5'>
                                            <h4 className='mb-4'>Summary</h4>
                                            <div className='d-flex align-md-items-center align-items-start justify-content-between'>
                                                <div><p><span>Original Price: </span></p></div>
                                                <div className=''>
                                                    <p> <span>{summaryDetail?.price ? summaryDetail?.price : '0.00'}</span></p>
                                                </div>
                                            </div>

                                            <div className='d-flex align-md-items-center align-items-start justify-content-between'>
                                                <div><p><span>No of students:</span></p> </div>
                                                <div className=''>
                                                    <p><span>{programmeDetail && justAddedStudentList?.length > 0 ? justAddedStudentList.length : 0}</span></p>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className='d-flex align-md-items-center align-items-start justify-content-between'>
                                                <div><p><span>Total: </span></p></div>
                                                <div className=''>
                                                    <p> <span>{summaryDetail?.price ? (summaryDetail?.price) * (justAddedStudentList?.length) : '0.00'}</span></p>
                                                </div>
                                            </div>
                                            <div className=''>
                                                {/* <Button className='text-light fs-18 w-100' color='primary' onClick={makePaymentNow}>Complete Checkout <ArrowRight size='20' /></Button> */}
                                                <Button className='text-light fs-18 w-100' color='primary' onClick={() => handleConfirmModalShow(programmeDetail)}>Complete Checkout <ArrowRight size='20' /></Button>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="payment-moods">
                        <Container>
                            <Row>
                                <Col xl={6} md={6} sm={12} className=''>
                                    <div className='payment-list pt-4 mb-5'>
                                        <div className="list">
                                            <ul>
                                                <span className='fw-600'><h4 className='mb-3'>Payment Mode</h4></span>
                                                {programmeDetail?.frequencies?.length > 0 ? programmeDetail?.frequencies.map((item, index) => {
                                                    return (
                                                        <li key={`li-${index}`}>
                                                            <div className='mb-0' style={{ marginTop: '-16px' }}>
                                                                <Form.Label className='w-100'>
                                                                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                                                        <div className="div">
                                                                            <input key={index}
                                                                                id={`radio-${index}`}
                                                                                type="radio"
                                                                                name="radio"
                                                                                value={item?.type}
                                                                                // checked={item.type}
                                                                                checked={moodValue === item.type}
                                                                                onChange={(e) => selectMood(item, e)} />
                                                                            <span class="checkmark"></span>
                                                                        </div>
                                                                        <div className="w-100">
                                                                            <span className='radio-label w-100'>
                                                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                                                    <div className="div" style={{ paddingLeft: '45px' }}>{checkTypes(item)} </div>
                                                                                    <div className="div">{`RM ${item.price}`}</div>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Form.Label> </div>
                                                        </li>
                                                    )
                                                }

                                                ) : ''}
                                            </ul>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div className="payment-moods">
                        <Container>
                            <Row>
                                <Col xl={6} md={6} sm={12} className=''>
                                    <div className='payment-list pt-4 mb-5'>
                                        <div className="list">
                                            <ul>
                                                <span className='fw-600'><h4 className='mb-3'>Payment Method</h4></span>
                                                {payment_methods.map((item, index) => {
                                                    return (
                                                        <li key={`li-${index}`}>
                                                            <p className='mb-0'>
                                                                <Form.Label className='mb-0'>
                                                                    <input key={index}
                                                                        id={`radio-${index}`}
                                                                        type="radio"
                                                                        name="method"
                                                                        value={item.value}
                                                                        checked={methodValue === item.value}
                                                                        onChange={(e) => setMethodValue(e.currentTarget.value)} />
                                                                    <span class="checkmark"></span>
                                                                    <span className='radio-label'>
                                                                        <span><img src={CreditCard} alt='' width='30' /></span> {item?.name}</span>
                                                                </Form.Label> </p>
                                                        </li>
                                                    )
                                                }

                                                )}
                                            </ul>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                </div>

                <div className='clearfix'></div>
            </div>
        }
        <PaymentConfirmModal show={confirmModalShow} handleClose={handleConfirmModalShow} setConfirmation={setConfirmation} confirmation={confirmation} />
    </HelmetProvider>

};

export default ProgrammeDetail;