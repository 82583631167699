import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Custom Component
import { PrimaryButton } from "../Button";
import ErrorAlert from "../Alert/ErrorAlert";
import WithdrawWalletModal from '../Modal/WithdrawWalletModal';
import AddBankAccountModal from '../Modal/AddBankAccountModal';

// API Service
import { currentBalanceService } from '../../service/walletAcademy.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const CurrentBalance = (props) => {
    const [currentBalance, setCurrentBalance] = useState(0)
    const [withdrawWalletModal, setWithdrawWalletModal] = useState(false)
    const [addBankAccountModal, setAddBankAccountModal] = useState(false)

    const user = useSelector(selectUser);
    
    /**
         * @function fetchCurrentBalance
         * @params
         * @description fetch current balance for organizer
         */
    const fetchCurrentBalance = async () => {
        try {
            const result = await currentBalanceService();
            if (result?.data?.status) {
                setCurrentBalance(result?.data?.data?.current_balance || 0)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'academy') {
            fetchCurrentBalance();
        }
    }, [user])

    /**
         * @function handleWithdrawWalletModal
         * @params
         * @description set the visibility of withdraw wallet modal
         */
    const handleWithdrawWalletModal = () => {
        setWithdrawWalletModal(!withdrawWalletModal)
    }

    /**
         * @function handleAddBankAccountModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleAddBankAccountModal = () => {
        handleWithdrawWalletModal()
        setAddBankAccountModal(!addBankAccountModal)
    }
    
    return <div className='d-flex flex-column bg-light p-3'>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>Wallet</p>
            {!props?.modal &&
                <div>
                    <PrimaryButton className='fs-14 fw-500 px-5' onClick={handleWithdrawWalletModal}>Withdraw</PrimaryButton>
                </div>
            }
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <span className='fs-30 fw-600 mb-2 text-primary'>RM{currentBalance}</span>
            <span className='fs-18 fw-400 mb-4'>Available balance in your Wallet</span>
        </div>

        {/* Withdraw Wallet Modal */}
        <WithdrawWalletModal show={withdrawWalletModal} handleClose={handleWithdrawWalletModal} handleAddBankAccountModal={handleAddBankAccountModal} fetchTransactionHistoryList={props?.fetchTransactionHistoryList} size='lg' />

        {/* Add Bank Modal */}
        <AddBankAccountModal show={addBankAccountModal} handleClose={handleAddBankAccountModal} size='lg' />
    </div>
};

export default CurrentBalance;