import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import DataListing from '../../../../components/Academy/AccessManager/DataListing';

const AccessManagerListComponent = () => {
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Access Manager List</title>
        </Helmet>
        <DataListing />
    </HelmetProvider>
};

export default AccessManagerListComponent;