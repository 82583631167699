import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    programmeList: [],
    mainCoachList: [],
    subCoachList: [],
    categoryList: [],
    studentList: [],
    sessionList: [],
    studentEarningList: [],
    sessionDropList: [],
    totalEntry: 0,
    reFresh: false
}

export const programmeSlice = createSlice({
    name: "programmeSlice",
    initialState,
    reducers: {
        setProgrammeList: (state, action) => {
            state.programmeList = action.payload
        },
        setMainCoachList: (state, action) => {
            state.mainCoachList = action.payload
        },
        setSubCoachList: (state, action) => {
            state.subCoachList = action.payload
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setStudentList: (state, action) => {
            state.studentList = action.payload
        },
        setSessionList: (state, action) => {
            state.sessionList = action.payload
        },
        setSessionDropList: (state, action) => {
            state.sessionDropList = action.payload
        },
        setStudentEarningList: (state, action) => {
            state.studentEarningList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setFreshData: (state, action) => {
            state.reFresh = action.payload
        },
    }
})

export const { setFreshData, setStudentEarningList, setProgrammeList, setSessionDropList, setTotalEntry, setMainCoachList, setSubCoachList, setCategoryList, setStudentList, setSessionList } = programmeSlice.actions

export const selectProgrammeList = (state) => state.academyProgramme.programmeList
export const selectMainCoachList = (state) => state.academyProgramme.mainCoachList
export const selectSubCoachList = (state) => state.academyProgramme.subCoachList
export const selectCategoryList = (state) => state.academyProgramme.categoryList
export const selectStudentList = (state) => state.academyProgramme.studentList
export const selectSessionList = (state) => state.academyProgramme.sessionList
export const selectSessionDropList = (state) => state.academyProgramme.sessionDropList
export const selectStudentEarningList = (state) => state.academyProgramme.studentEarningList
export const selectTotalEntry = (state) => state.academyProgramme.totalEntry
export const selectFreshData = (state) => state.academyProgramme.reFresh

export default programmeSlice.reducer