// Custom Component
import { PrimaryOutlineButton } from '../Button';

// Images
import { noCoach } from "../../assets/images";

const NoCoach = (props) => {
    return <>
        <div className="text-center">
            <div className='d-flex flex-column justify-content-center align-items-center py-4'>
                <img className='ímg-fluid' src={noCoach} alt='no-bank' width={300} maxWidth={300} />
                <span className='fs-18 fw-600 text-secondary text-center my-3'>You haven’t created coach yet!</span>
                <div>
                    <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={props?.handleAddCoachModal}>Add Coach</PrimaryOutlineButton>
                </div>
            </div>
        </div>
    </>
};

export default NoCoach;