import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { studentSession, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allCountService } from '../../service/guestHomeService';

// Redux-Functions
import { selectMySession, setMySession } from "../../redux/slices/guestHomeSlice"

import ReactStars from 'react-rating-star-with-type'

import { Link } from 'react-router-dom';

const StudentSession = ({ rowData }) => {
    const dispatch = useDispatch()
    const myOnSession = useSelector(selectMySession);

    /**
         * @function fetchCustomerSession
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerSession = async () => {
        try {
            const result = await allCountService({ id: rowData?.id ? rowData?.id : '' });
            if (result?.data?.status) {
                dispatch(setMySession(result?.data?.data?.ongoing_program))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerSession()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        {myOnSession?.length > 0 ? <div className='session-section mt-5'>
            <Container>
                <Row className='session-list'>
                    <Col xl={12} className='pt-2 mb-5'><h1 className='fw-bold'>Your going session</h1></Col>
                </Row>

                <Row className='session-background'>
                    <Col xs={6} md={4}>
                        <div className="section-start-image  p-2 text-center">
                            <div>
                                <img src={studentSession} alt='student-session' />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className="section-start-text p-2">
                            <div className='title'><p>Lorem ipsum dolor sit amet consectetur. At tincidunt sit sed ornare ut.</p></div>
                            <div className="description"><p>Lorem ipsum dolor sit amet consectetur. Ut cras sed nunc odio nibh. Turpis enim leo viverra tellus elit mollis faucibus in iaculis.</p></div>
                            <div className="rating mb-5">
                                <p>By Stephen Robert</p>
                                <p className=''>
                                    <ReactStars
                                        disabled
                                        value={4.5}
                                        edit={true}
                                        activeColors={["#FDB022"]}
                                    />
                                </p>
                            </div>
                            <div className="session-column">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="parent-session">
                                        <div className="child">
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div><p className='mb-0 fs-14'> <img src={sessionBook} alt='' width='17' /> 03 <span className='fw-bold'>Sessions</span></p></div>
                                                <div><p className='mb-0 fs-14'> <img src={sessionUser} alt='' width='17' /> 20 <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="parent-session student-session">
                                        <span>Your Ongoing session</span> <br />
                                        <Button className='text-light fs-16' size='md' color='primary' variant='primary' >09:20AM - 03: 30PM</Button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div> : ''}

        <div className='clearfix'></div>

    </div>

};

export default StudentSession;