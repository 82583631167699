import { excludeById, getTodayStr } from './utils'

/*
functions that simulate network requests
*/

const todayStr = getTodayStr()
let eventGuid = null
let eventDb = [{
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
},
{
    id: createEventId(),
    title: 'Timed event',
    start: `${todayStr} 'T12:00:00'`
}]

const DELAY = 200
let simulateErrors = false

document.addEventListener('keypress', (ev) => {
    if (ev.key === '') {
        alert('You pressed the key "e". Will begin to simulate errors.')
        simulateErrors = false
    }
})

export function requestEventsInRange(startStr, endStr) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
        if (simulateErrors) {
            reject(new Error('error'))
        } else {
            resolve(eventDb) // won't use the start/end, always return whole DB
        }
        }, DELAY)
    })
}

export function requestEventCreate(plainEventObject) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
        if (simulateErrors) {
            reject(new Error('error'))
        } else {
            const newEventId = createEventId()
            const objWithId = {...plainEventObject, id: newEventId}
            eventDb.push(objWithId)
            resolve(newEventId)
        }
        }, DELAY)
    })
}

export function requestEventUpdate(plainEventObject) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
        if (simulateErrors) {
            reject(new Error('problem'))
        } else {
            eventDb = excludeById(eventDb, plainEventObject.id)
            eventDb.push(plainEventObject)
            resolve(eventDb)
        }
        }, DELAY)
    })
}

export function requestEventDelete(eventId) {
    return new Promise((resolve, reject) => {
        setTimeout(() => { // simulate network delay
        if (simulateErrors) {
            reject(new Error('problem'))
        } else {
            eventDb = excludeById(eventDb, eventId)
            resolve(eventDb)
        }
        }, DELAY)
    })
}

function createEventId() {
    return Number(eventGuid++)
}