import axios from "axios"

/**
    * @function submitCustomerQueryService
    * @param data
    * @description This function is used to add bank account
*/
export const submitCustomerQueryService = async(data) =>{
    try {
        const result = await axios.post(`/customer/contact-us`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCountryService
    * @param
    * @description This function is used to get all country
*/
export const allCountryService = async() =>{
    try {
        const result = await axios.get(`/customer/country/list`)
        return result;
    } catch (error) {
        return error;
    }

}

/**
    * @function getOrganizerTermsAndConditionsService
    * @param
    * @description This function is used to get terms and conditions for organizer
*/
export const getOrganizerTermsAndConditionsService = async() =>{
    try {
        const result = await axios.get(`/organizer/additional/variables/terms_and_conditions`)
        return result;
    } catch (error) {
        return error;
    } 
}

/**
    * @function getOrganizerPrivacyPolicyService
    * @param
    * @description This function is used to get privacy policy for organizer
*/
export const getOrganizerPrivacyPolicyService = async() =>{
    try {
        const result = await axios.get(`/organizer/additional/variables/privacy_policy`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerContactUsService
    * @param
    * @description This function is used to get contact us for organizer
*/
export const getOrganizerContactUsService = async() =>{
    try {
        const result = await axios.get(`/organizer/additional/variables/contact_us`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerAboutUsService
    * @param
    * @description This function is used to get about us for organizer
*/
export const getOrganizerAboutUsService = async() =>{
    try {
        const result = await axios.get(`/organizer/additional/variables/about_us`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getCustomerTermsAndConditionsService
    * @param
    * @description This function is used to get terms and conditions for customer
*/
export const getCustomerTermsAndConditionsService = async() =>{
    try {
        const result = await axios.get(`/customer/additional/variables/terms_and_conditions`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerPrivacyPolicyService
    * @param
    * @description This function is used to get privacy policy for customer
*/
export const getCustomerPrivacyPolicyService = async() =>{
    try {
        const result = await axios.get(`/customer/additional/variables/privacy_policy`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerContactUsService
    * @param
    * @description This function is used to get contact us for customer
*/
export const getCustomerContactUsService = async() =>{
    try {
        const result = await axios.get(`/customer/additional/variables/contact_us`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerAboutUsService
    * @param
    * @description This function is used to get about us for customer
*/
export const getCustomerAboutUsService = async() =>{
    try {
        const result = await axios.get(`/customer/additional/variables/about_us`)
        return result;
    } catch (error) {
        return error;
    }
}