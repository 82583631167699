import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Form, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import Flatpickr from 'react-flatpickr'

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { searchImage, searchArrowImage, banner } from "../../assets/images/index";

// API Service
import { allBannerService } from '../../service/guestHomeService';

// Redux-Functions
import { selectBannerList, setBanner } from "../../redux/slices/guestHomeSlice"

const BannerSection = () => {
    const dispatch = useDispatch()
    const bannerData = useSelector(selectBannerList);

    /**
         * @function fetchCustomerBannerList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerBannerList = async () => {
        try {
            const result = await allBannerService();
            console.log('fetchCustomerBannerList', result)

            if (result?.data?.status) {
                dispatch(setBanner(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerBannerList()
        // eslint-disable-next-line
    }, [])

    return <div>
        <div className='bannerSection'>
            <Container>
                <Row>
                    <Col lg={7} md={12} sm={12}>
                        <div className='header-title'>
                            <p className='text-primary mb-2 fs-20 fs-sm-18'>Lorem ipsum dolor sit amet cons ectetur.</p>
                        </div>
                        <div className='header-slag mb-5'>
                            <h1 className='fw-bold'>Set up your game with us</h1>
                        </div>
                        <Row>
                            <Col lg={10} md={12} sm={12}>

                                <div className='header-search mb-3'>
                                    <div className='search-input d-flex flex-row justify-content-between align-items-center'>
                                        <div>
                                            <img src={searchImage} alt='search' width={27} />
                                        </div>
                                        <div className='w-100'>
                                            <Form>
                                                <Form.Group className="my-4">
                                                    <div className="d-flex align-items-center">
                                                        <Form.Control
                                                            type="text"
                                                            autoComplete='nope'
                                                            name='name'
                                                            placeholder='Search program'
                                                        // onChange={(e) => handleName(e, index1)}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button">
                                                                <ArrowRight size={15} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                {/* <div className="input-group">
                                                    <input className="form-control py-2" type="text" value="" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button">
                                                            <ArrowRight size={15} />
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className='clear'></div>
                                <div className='trusted'>
                                    <p>Trusted by over 15K Users worldwide <br /> since 2022</p>
                                </div>
                                <div className='count d-flex flex-row align-items-center'>
                                    <div className='plus'>
                                        <h2 className='fw-bold mb-0'>{bannerData?.review_counts} + </h2>
                                    </div>
                                    <p className='ms-2 fs-18 mb-0 fw-bold'>Reviews</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} md={12} sm={12}>
                        <div className='banner'>
                            <a href={banner} rel="noreferrer" target='_blank'>
                                <img className='bannerImage' src={banner} alt='banner' />
                            </a>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>
    // <Carousel className='home-carousel'>
    //     {bannerList?.length > 0 && bannerList?.map((item, index) => {
    //         return <Carousel.Item key={index}>
    //             <img
    //                 className="d-block w-100 carousel-image rounded-5"
    //                 src={item?.image}
    //                 alt={item?.title}
    //             />
    //             <Carousel.Caption className='w-100 px-4'>
    //                 <div className='d-flex flex-wrap justify-content-between align-items-center'>
    //                     <div className='d-flex align-items-center mt-3'>
    //                         <Live className='me-2' />
    //                         <span>{item?.title}</span>
    //                     </div>
    //                     <div className='d-flex align-items-center text-primary bg-light px-4 py-1 rounded  mt-3'>
    //                         <span className='me-2'>View</span>
    //                         <BsArrowRight size={20} />
    //                     </div>
    //                 </div>
    //             </Carousel.Caption>
    //         </Carousel.Item>
    //     })}
    // </Carousel>

};

export default BannerSection;