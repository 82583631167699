import { Modal, Row, Col, Form } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

// Custom Component
import { PrimaryButton } from '../Button';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import SuccessAlert from '../../components/Alert/SuccessAlert';
// third party
import DataTable from 'react-data-table-component'
import moment from 'moment';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { Edit3 } from 'react-feather';
import { checkStudentAttendance } from '../../service/coachProgram';
// redux
import { selectFreshData, setFreshData } from '../../redux/coachProgrammeSlice';
import { useDispatch, useSelector } from 'react-redux';

const CoachSessionAttandenceModal = (props) => {
    const dispatch = useDispatch()
    const [listing, setList] = useState()
    const [checked, setChecked] = useState(0)
    const freshData = useSelector(selectFreshData);

    console.log('CoachSessionAttandenceModal', props)

    useEffect(() => {
        if (props?.rowData) {
            setList(props?.rowData)
        }
    }, [props]);
    
    /**
        * @function updateStudentAttendance
        * @params (value, item)
        * @description used for attendance api
    */
    const updateStudentAttendance = async (value, item) => {
        if (props?.singleData?.id) {
            const date = moment(item?.session?.date).format('YYYY-MM-DD')
            const time = moment(item?.start_time, 'hh:mm A').format('HH:mm')

            try {
                const data = new FormData()
                data.append('program_id', props?.singleData.id)
                data.append('session_id', props?.singleData?.sessions?.length > 0 ? props?.singleData?.sessions[0].id : '')
                data.append('student_id', props?.tableRecord?.id)
                data.append('timeslot_id', item?.id)

                data.append('date', date)
                data.append('time', time)
                const result = await checkStudentAttendance(data);
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    dispatch(setFreshData(!freshData))
                    props.handleClose()
                    // fetchSingleProgramProgress(pageCount)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        }
    };

    /**
        * @function handleCheck
        * @params e, item
        * @description used for attendance
    */
    const handleCheck = (e, item) => {
        const value = e.currentTarget.checked
        updateStudentAttendance(value, item)
        setChecked(value)
    };

    const columns = [{
        name: 'Id',
        selector: (row) => row.id ? row?.id : '--',
        width: '50px'
    },
    {
        name: 'Selected Session',
        selector: (row) => row?.start_time ? <>
            <div className="d-flex justify-content-center align-content-center">
                <span className='text-primary'>{moment(row?.session?.date).format('DD-MM-YYYY')}, {row?.start_time} - {row?.end_time}</span>
                <span className='ms-2'>
                    {/* <ChangeSession tableRecord={row} rowData={props?.singleData} /> */}
                    <Edit3 className='custom-hover' size={17} onClick={props?.handleChangeSession} />

                </span>
            </div>
        </> : '--',
        width: '250px'
    },
    
    {
        name: 'Attendance',
        selector: (row) => <Form.Check
            type='checkbox'
            id={`recurring-payment`}
            checked={props?.rowData?.attendances?.some(item => item?.timeslot_id === row?.id)}
            onChange={(e) => handleCheck(e, row)}
        />,
    }];
    
    
    return <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 px-0 py-3 position-relative'>
            <IoCloseOutline role='button' className='text-danger position-absolute top-0 end-0 mt-2 me-2' size={20} onClick={() => props.handleClose()} />
        </Modal.Header>

        <Modal.Body className='pt-0'>
            <h5 className='fw-bold'>{`Session - ${props?.rowData?.name}`}</h5>
            <div>
                {props?.rowData && props?.rowData?.timeslots?.length > 0 && <>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            // noHeader
                            columns={columns}
                            // customStyles={students && students.length <= 2 ? customStyles : ''}
                            className='react-dataTable'
                            // sortIcon={<ChevronDown size={10} />}
                            data={props?.rowData?.timeslots}
                        />
                    </div>
                </>}
            </div>
        </Modal.Body>
    </Modal>
};

export default CoachSessionAttandenceModal