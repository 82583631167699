import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { getCustomerAboutUsService, getOrganizerAboutUsService } from '../../../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";

const AboutUs = () => {
    const [aboutUs, setAboutUs] = useState()
    const user = useSelector(selectUser)

    /**
        * @function fetchCustomerAboutUs
        * @params
        * @description fetch the content of about us for customer
    */
    const fetchCustomerAboutUs = async () => {
        try {
            const result = await getCustomerAboutUsService();
            if (result?.data?.status) {
                setAboutUs(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerAboutUs
        * @params
        * @description fetch the content of about us for organizer
    */
    const fetchOrganizerAboutUs = async () => {
        try {
            const result = await getOrganizerAboutUsService();
            if (result?.data?.status) {
                setAboutUs(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerAboutUs()
        } else {
            fetchCustomerAboutUs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
    return <HelmetProvider>
        <Helmet>
            <title>About Us | AFA Coaching</title>
        </Helmet>
        {aboutUs && parse(aboutUs)}
    </HelmetProvider>
};

export default AboutUs;