import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import BannerSection from "../../../../components/Homepage/BannerSection";
import BoxSection from '../../../..//components/Homepage/Box'
import Programmes from '../../../../components/Homepage/Programmes'
import TopCoach from '../../../../components/Homepage/TopCoaches';
import Faq from '../../../../components/Homepage/Faq'
import Clients from '../../../../components/Homepage/Clients'
import Session from '../../../../components/Homepage/Session';

const Homepage = () => {
    return <HelmetProvider>
        <Helmet>
            <title>Home || AFA Coaching</title>
        </Helmet>
        <BannerSection />
        <BoxSection />
        <Session />
        <Programmes />
        <TopCoach />
        <Faq />
        <Clients />
    </HelmetProvider>
}; 

export default Homepage;