import { Nav, Button } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";

// Images
import { logo } from "../../assets/images";
import { Home, Support } from "../../assets/images/svg";
import { UserImage, UserCheck, Wallet, OnGoing, Calendar } from "../../assets/images/index";

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const SideBar = (props) => {
    const location = useLocation();

    const user = useSelector(selectUser);

    const navItemList = [{
        logo: <Home className="me-2" color={location.pathname === '/academy/home' && '#1D2939'} />,
        text: 'Home',
        link: '/academy/home',
        visibility: user?.type === 'academy'
    },
    {
        logo: <img src={UserImage} alt='menu' width={22} className="me-2" color={location.pathname === '/academy/coach/list' && '#1D2939'} />,
        text: 'Coach',
        link: '/academy/coach/list',
        visibility: user?.type === 'academy'
    },
    {
        logo: <img src={UserCheck} alt='menu' width={22} className="me-2" color={location.pathname === '/academy/coach/list' && '#1D2939'} />,
        text: 'Bank',
        link: '/academy/bank',
        visibility: user?.type === 'academy'
    },
    {
        logo: <img src={Calendar} alt='menu' width={22} className="me-2" color={location.pathname === '/academy/calendar/list' && '#1D2939'} />,
        text: 'Calendar',
        link: '/academy/calendar',
        visibility: user?.type === 'academy'
    },
    {
        logo: <img src={Wallet} alt='menu' width={22} className="me-2" color={location.pathname === '/academy/coach/list' && '#1D2939'} />,
        text: 'Wallet',
        link: '/academy/wallet/list',
        visibility: user?.type === 'academy'
    },
    {
        logo: <img src={UserCheck} alt='menu' width={22} className="me-2" color={location.pathname === '/academy/coach/list' && '#1D2939'} />,
        text: 'Access Manager',
        link: '/academy/access/manager',
        visibility: user?.type === 'academy'
    },
    // coach menu
    {
        logo: <Home width={20} className="me-2" color={location.pathname === '/coach/home' && '#1D2939'} />,
        text: 'Home',
        link: '/coach/home',
        visibility: user?.type === 'coach'
    },
    {
        logo: <img src={OnGoing} alt='menu' width={20} className="me-2" color={location.pathname === '/coach/ongoing/programs' && '#1D2939'} />,
        text: 'Ongoing Programs',
        link: '/coach/ongoing/programs',
        visibility: user?.type === 'coach'
    }]

    return <div className={classNames("sidebar px-3 d-flex flex-column", {
        "is-open": props.isOpen,
        "d-none": props.authenticatedRoute.includes(location.pathname)
    })}>
        <div className="mt-3">
            <Button
                variant="link"
                onClick={props.toggle}
                style={{ color: "#fff" }}
                className="mt-2 me-3 p-0 float-end d-lg-none"
            >
                <IoMdClose size={25} />
            </Button>
            <img className="img-fluid" src={logo} alt="AFA" width='120' />
        </div>

        <Nav className="flex-column flex-grow-1 pt-2 mt-3">
            {navItemList?.filter(item => item?.visibility)?.map((item, index) => {
                return <Nav.Item className={classNames("rounded", { active: location.pathname === item.link })} key={index}>
                    <NavLink to={item.link} className={classNames("nav-link", { "text-dark": location.pathname === item.link, "text-secondary": location.pathname !== item.link, })}>
                        {item.logo}
                        {item.text}
                    </NavLink>
                </Nav.Item>
            })}

            <Nav.Item className="mt-auto mb-3 rounded">
                <Nav.Link href="/" className="text-secondary">
                    <Support className="me-2" />
                    Support
                </Nav.Link>
            </Nav.Item>
        </Nav>
    </div>
}

export default SideBar;