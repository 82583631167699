import axios from "axios"

/**
    * @function copyLinkGenerateService
    * @param data
    * @description This function is used to generate copy link
*/
export const copyLinkGenerateService = async(data) =>{
    try {
        const result = await axios.post(`/academy/payment/create-payment-link`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function sendLinkViaEmailService
    * @param data
    * @description This function is used to send link via email
*/
export const sendLinkViaEmailService = async(data) =>{
    try {
        const result = await axios.post(`/academy/payment/create-payment-mail`, data)
        return result;
    } catch (error) {
        return error;
    }
}