import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

import { Form, Row, Col, Container, InputGroup, Image } from 'react-bootstrap';
import { PrimaryButton } from '../../../../components/Button';
import ErrorText from '../../../../components/TextField/ErrorText'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import toast from 'react-hot-toast'
// Custom Component
import Layout from "../../../../components/Layout/Layout";
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';

// API Service
import { submitCustomerQueryService, allCountryService } from '../../../../service/variable.service';

import { contact, logo } from '../../../../assets/images/index'

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";

const ContactUs = () => {
    const [countryList, setCountryList] = useState([])
    const [checkBox, setCheckBox] = useState(false)
    const user = useSelector(selectUser)
    const navigate = useNavigate()

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    })

    /**
         * @function onSubmit
         * @params formdata
         * @description used to submit a query
         */
    const onSubmit = async (formdata) => {
        try {
            const data = new FormData()
            data.append('first_name', formdata?.first_name)
            data.append('last_name', formdata?.last_name)
            data.append('email', formdata?.email)
            data.append('country', formdata?.country)
            data.append('phone', formdata?.phone)
            data.append('message', formdata?.message)

            const result = await submitCustomerQueryService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                reset()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }
    /**
         * @function fetchCountryList
         * @params
         * @description fetch the list of country
         */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    const handleCheckBox = () => {
        const check = !checkBox
        setCheckBox(check)
    }
    const handlePrivacy = () => {
        navigate('/privacy-policy')
    }

    // return <HelmetProvider>
    //     <Helmet>
    //         <title>Contact Us | AFA Coaching</title>
    //     </Helmet>
    //     <Container>
    //         <Row className='my-5'>
    //             <Col xs={12} md={6} lg={6}>
    //                 <div className="contact-page text-center only-desktop-visible">
    //                     <img src={contact} alt='contact' height='' />
    //                 </div>
    //             </Col>
    //             <Col xs={12} md={6} lg={6}>
    //                 <div className="contact-form pt-2">
    //                     <h1>Contact Us</h1>
    //                     <p className='mb-4'>Our friendly team would love to hear from you.</p>

    //                     <div className="query">
    //                         <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
    //                             <Row>
    //                                 <Form.Group className="my-2 col-md-6 col-sm-12">
    //                                     <Form.Label className='fs-14 fw-500'>First Name</Form.Label>
    //                                     <Form.Control
    //                                         type="text"
    //                                         autoComplete='nope'
    //                                         placeholder="Enter first name here"
    //                                         {...register("first_name", {
    //                                             required: "Please provide the first name",
    //                                         })}
    //                                     />
    //                                     {errors.first_name && <ErrorText>{errors.first_name.message}</ErrorText>}
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2 col-md-6 col-sm-12">
    //                                     <Form.Label className='fs-14 fw-500'>Last Name</Form.Label>
    //                                     <Form.Control
    //                                         type="text"
    //                                         autoComplete='nope'
    //                                         placeholder="Enter first name here"
    //                                         {...register("last_name", {
    //                                             required: "Please provide the last name",
    //                                         })}
    //                                     />
    //                                     {errors.last_name && <ErrorText>{errors.last_name.message}</ErrorText>}
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2 col-12">
    //                                     <Form.Label className='fs-14 fw-500'>Email Address</Form.Label>
    //                                     <Form.Control
    //                                         type="email"
    //                                         autoComplete='nope'
    //                                         placeholder="Enter email here"
    //                                         {...register("email", {
    //                                             required: "Please provide the email address",
    //                                         })}
    //                                     />
    //                                     {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
    //                                 </Form.Group>

    //                                 {countryList.length > 0 &&
    //                                     <Form.Group className="my-2 contact-page">
    //                                         <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
    //                                         <InputGroup className='rounded'>
    //                                             <Form.Select
    //                                                 {...register("country_id")}
    //                                             >
    //                                                 {countryList.map((item, index) => {
    //                                                     return <option value={item?.code} key={index}>{item?.code}</option>
    //                                                 })}
    //                                             </Form.Select>
    //                                             <Form.Control
    //                                                 className='flex-grow-19'
    //                                                 type="tel"
    //                                                 autoComplete='off'
    //                                                 placeholder="Enter Phone number"
    //                                                 {...register("phone", {
    //                                                     required: "Please provide the phone"
    //                                                 })}
    //                                             />
    //                                         </InputGroup>
    //                                         {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
    //                                     </Form.Group>
    //                                 }

    //                                 <Form.Group className="my-2 12">
    //                                     <Form.Label className='fs-14 fw-500'>Message</Form.Label>
    //                                     <Form.Control
    //                                         as="textarea"
    //                                         rows={8}
    //                                         placeholder="Leave a message"
    //                                         {...register("description", {
    //                                             required: "Please provide the message",
    //                                         })}
    //                                     />
    //                                     {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
    //                                 </Form.Group>
    //                                 <Form.Group className="col-12" controlId="formBasicCheckbox">
    //                                     <Form.Label>You agree to our friendly <a href='/privacy-policy' target='_blank'><span className='text-primary fs-16'>Privacy Policy</span></a></Form.Label>
    //                                     {/* <Form.Check size='md' height='20' width='20' type="checkbox" label={`${`You agree to our friendly ${<a href='/privacy-policy' target='_blank'><span className='text-primary'>Privacy Policy</span></a>}`}`} value={checkBox} onClick={() => handleCheckBox()} /> */}
    //                                 </Form.Group>
    //                             </Row>

    //                             <div className='d-flex justify-content-end align-items-end my-4'>
    //                                 <div className='col-12'>
    //                                     <PrimaryButton className='fs-16 fw-600 px-5 p-2' size='md' type="submit">Send Message</PrimaryButton>
    //                                 </div>
    //                             </div>
    //                         </Form>
    //                     </div>
    //                 </div>
    //             </Col>
    //         </Row>
    //     </Container>
    // </HelmetProvider>

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Contact Us | AFA Coaching</title>
        </Helmet>
        <Container className='my-4'>
            <Row>
                <Col lg={2} md={12}>
                    <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center'>
                        <Link to="/" className='d-contents'>
                            <img className='img-fluid' src={logo} alt='logo' width='120' />
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col lg={6} className='d-none d-lg-block'>
                    <img className='img-fluid' src={contact} alt='Contact Us' />
                </Col>
                <Col lg={6} className='ps-lg-5'>
                    <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                        <p className='fs-30 fw-bold'>Contact us</p>
                        <span className='fs-20 fw-400 text-secondary'>Our friendly team would love to hear from you.</span>

                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mt-4">
                                    <Form.Label className='fs-14 fw-500'>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        {...register("first_name", {
                                            required: "First name is required"
                                        })}
                                    />
                                    {errors.first_name && <ErrorText>{errors.first_name.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mt-4">
                                    <Form.Label className='fs-14 fw-500'>Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        {...register("last_name", {
                                            required: "Last name is required"
                                        })}
                                    />
                                    {errors.last_name && <ErrorText>{errors.last_name.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="you@team.com"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                            />
                            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                        </Form.Group>

                        {countryList.length > 0 &&
                            <Form.Group className="my-4">
                                <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                <InputGroup className='rounded'>
                                    <InputGroup.Text className='p-0 border-0'>
                                        <Form.Select
                                            className='border-end-0'
                                            {...register("country")}
                                        >
                                            {countryList.map((item, index) => {
                                                return <option value={item?.id} key={index}>{item?.code}</option>
                                            })}
                                        </Form.Select>
                                    </InputGroup.Text>
                                    <Form.Control
                                        className='border-start-0 ps-0'
                                        type="tel"
                                        placeholder="Enter Phone number"
                                        {...register("phone", {
                                            required: "Phone is required"
                                        })}
                                    />
                                </InputGroup>
                                {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                            </Form.Group>
                        }

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Leave us a message..."
                                {...register("message", {
                                    required: "Message is required",
                                })}
                            />
                            {errors.message && <ErrorText>{errors.message.message}</ErrorText>}
                        </Form.Group>

                        <Form.Check
                            className='d-flex align-items-center'
                            type='checkbox'
                            label={<p role='button' className='ms-2 fw-500 fs-14 mb-0 mt-1 text-secondary'>
                                You agree to our friendly <Link to='/privacy-policy' className='text-decoration-none'>privacy policy</Link>.
                            </p>}
                            id={`agree`}
                            required
                        />

                        <Form.Group className="mb-3 mt-4">
                            <PrimaryButton type="submit">Send message</PrimaryButton>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default ContactUs;