import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button, Image } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import { PlusCircle, X, Edit, Calendar } from 'react-feather';

import { selectThemeColors } from '../../../..//utils/Utils'

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker'
import DatePickerWithYearAndMonth from '../../../../components/DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { allStudentServiceDrop, updateStudentService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { setStudentList, selectStudentList } from "../../../../redux/academyProgrammeSlice"
import { BiCategoryAlt } from 'react-icons/bi';

const UpdateStudent = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const user = useSelector(selectUser);
    const sessionList = useSelector(selectStudentList);
    const [startDate, setStartDate] = useState(new Date())

    const [just_add, setJustAdd] = useState(false);
    const [studentList, setStudent] = useState([]);
    const [selectedStudents, setSelectedStudent] = useState();
    const [selectedGender, setSelectedGender] = useState('MALE');
    const allGender = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
    const [selectedSession, setSelectedSession] = useState();
    const [allSession, setAllSession] = useState([]);

    console.log('singleData', singleData)

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

        },
        mode: "onBlur"
    })

    useEffect(() => {
        if (singleData?.id) {
            setJustAdd(true)
            const defaultValues = {};
            // defaultValues.username = singleData?.name
            defaultValues.username = singleData?.customer?.username
            defaultValues.phone = singleData?.customer?.phone
            defaultValues.email = singleData?.customer?.email
            defaultValues.studentName = singleData?.name
            defaultValues.studentPhone = singleData?.phone
            defaultValues.studentEmail = singleData?.email

            setStartDate(singleData?.dob)
            if (singleData?.gender === 'MALE') {
                setSelectedGender({ label: 'Male', value: 'MALE' })
            } else {
                setSelectedGender({ label: 'Female', value: 'FEMALE' })
            }

            if (singleData?.programs[0]?.pivot?.frequency_type) {
                setSelectedSession({
                    value: singleData?.programs[0]?.pivot?.amount,
                    label: singleData?.programs[0]?.pivot?.frequency_type
                })
            }
            reset({ ...defaultValues })

        } else {
            reset()
        }
        // eslint-disable-next-line
    }, [singleData, show]);

    const handleSelect = (e) => {
        const object = e
        if (object?.value) {
            setSelectedStudent(object?.value)
            setJustAdd(false)
        } else {
            setJustAdd(true)
        }
    }
    /**
        * @function fetchAcademyStudentList
        * @params
        * @description fetch the list of organizer banner
        */
    const fetchAcademyStudentList = async () => {
        try {
            const result = await allStudentServiceDrop({ id: singleData?.id });
            if (result?.data?.status) {
                setLoading(false)
                const resultStudent = result?.data?.data.student_list?.map((item) => ({ value: item.customer_id, label: <div><Image src={item?.customer?.profile_pic} alt='' roundedCircle={true} width='18' /><span className='ms-2'>{item?.name}</span></div>, image: item?.profile_image }))
                const allOptions = [{ value: '', label: <span className='text-primary fs-12 custom-hover' onClick={() => setJustAdd()}> <PlusCircle size='16' className='text-primary' /> Add new student</span> }]
                const finalDrop = allOptions.concat(resultStudent)
                setStudent(finalDrop)

            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setAllSession(props?.frequency?.map((item) => {
            return {
                label: item?.type,
                value: item?.type,
            }
        }))
        if (singleData?.id && show && user?.type === 'academy') {
            fetchAcademyStudentList()
        }
        // eslint-disable-next-line
    }, [singleData, user])

    const directAddStudent = async () => {
        try {
            if (selectedStudents?.value) {
                const data = new FormData()
                data.append('customer_id', selectedStudents)
                data.append('_method', 'PUT')
                const result = await updateStudentService({ id: singleData?.id, data });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    props?.fetchAcademyStudentList(1)
                    setJustAdd(false)
                    handleCloseModal()
                    // props?.fetchAcademyStudentList(1)
                    // reset()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                ErrorAlert('Please select student')
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to add student in program
         */
    const onSubmit = async (formdata) => {

        const object = {
            name: formdata?.studentName,
            email: formdata?.studentEmail,
            phone: formdata?.studentPhone,
            gender: selectedGender?.value,
            dob: moment(startDate[0]).format('YYYY-MM-DD')
        }
        const students = [object]
        if (selectedStudents?.value) {
            try {

                const data = new FormData()
                data.append('customer_id', selectedStudents)
                data.append('_method', 'PUT')
                const result = await updateStudentService({ id: singleData?.id, data });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    props?.fetchAcademyStudentList(1)
                    setJustAdd(false)
                    handleCloseModal()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            if (selectedGender?.value) {
                try {
                    const data = new FormData()
                    data.append('name', formdata?.studentName)
                    data.append('email', formdata?.studentEmail)
                    data.append('phone', formdata?.studentPhone)
                    data.append('gender', selectedGender?.value)
                    data.append('dob', moment(startDate[0]).format('YYYY-MM-DD'))
                    data.append('username', formdata?.username)
                    data.append('user_phone', formdata?.phone)
                    data.append('user_email', formdata?.email)
                    data.append('_method', 'PUT')
                    // if (students?.length > 0) {
                    //     students.forEach((element, index) => {
                    //         data.append(`students[${index}][name]`, element.name)
                    //         data.append(`students[${index}][email]`, element.email)
                    //         data.append(`students[${index}][phone]`, element.phone)
                    //         data.append(`students[${index}][gender]`, element.gender)
                    //         data.append(`students[${index}][dob]`, element.dob)
                    //     })
                    // }

                    const result = await updateStudentService({ id: singleData?.id, data });
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        props?.fetchAcademyStudentList(1)
                        setJustAdd(false)
                        reset()
                        handleCloseModal()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } catch (error) {
                    ErrorAlert(error)
                }
            } else {
                ErrorAlert('Please select gender')
            }

        }

    }
    const handleCloseModal = () => {
        setShow(false)
        // setJustAdd(false)
        setSelectedStudent('')
        reset()
    }

    return <><div className="update-student">
        <Edit className='custom-hover text-dark' size='17' onClick={() => setShow(true)} />
    </div>
        <Modal show={show} onHide={handleCloseModal} size='md' centered>
            <Modal.Header className='fs-22 fw-bold border-0' closeButton >
                Update Student
            </Modal.Header>
            <Modal.Body className=''>
                <div className='text-left'>
                    {/* <div>
                    <h5>Search <span><Button size='sm' className='text-light' variant="primary" onClick={() => setJustAdd(true)}>Add Student</Button></span></h5>
                </div> */}
                    {!just_add && <div className=''>
                        <Form.Label className='fs-14 fw-600'>Search / Add Student</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedStudents}
                            name='clear'
                            options={studentList}
                            placeholder='Students'
                            onChange={(e) => handleSelect(e)}
                        />
                    </div>}

                </div>
                {just_add ? <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <h5 className='pt-3 fw-600'>User Detail</h5>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>User Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username here"
                            {...register("username", {
                                required: "Please provide the username",
                            })}
                        />
                        {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                    </Form.Group>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter phone number here"
                            {...register("phone", {
                                required: "Please provide the phone number",
                            })}
                        />
                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                    </Form.Group>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email here"
                            {...register("email", {
                                required: "Please provide the email address",
                            })}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                    </Form.Group>

                    <h5 className='pt-3 fw-600'>Student Detail</h5>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Student Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name here"
                            {...register("studentName", {
                                required: "Please provide the name ",
                            })}
                        />
                        {errors.studentName && <ErrorText>{errors.studentName.message}</ErrorText>}
                    </Form.Group>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter phone here"
                            {...register("studentPhone", {
                                required: "Please provide the phone number",
                            })}
                        />
                        {errors.studentPhone && <ErrorText>{errors.studentPhone.message}</ErrorText>}
                    </Form.Group>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email here"
                            {...register("studentEmail", {
                                required: "Please provide the email address",
                            })}
                        />
                        {errors.studentEmail && <ErrorText>{errors.studentEmail.message}</ErrorText>}
                    </Form.Group>

                    <Row>
                        <Form.Group className="col-6">
                            <Form.Label className='fs-14 fw-600'>Gender</Form.Label>
                            <Select
                                // closeMenuOnSelect={false}
                                theme={selectThemeColors}
                                className='react-select'
                                classNamePrefix='select'
                                defaultValue={selectedGender}
                                name='clear'
                                options={allGender}
                                placeholder='Gender'
                                onChange={setSelectedGender}
                            />
                        </Form.Group>
                        <Form.Group className="col-6 update-student">
                            <Form.Label className='fs-14 fw-600'>Date of Birth</Form.Label>
                            <InputGroup>
                                <Flatpickr
                                    className='form-control'
                                    value={startDate}
                                    required
                                    id='timepicker'
                                    options={{
                                        static: true,
                                        altInput: true,
                                        allowInput: true,
                                        enableTime: false,
                                        noCalendar: false,
                                        dateFormat: "Y-m-d",
                                        disable: ["2025-01-30", "2025-02-21", "2025-03-08", new Date(2025, 4, 9)],
                                    }}
                                    onChange={date => setStartDate(date)}
                                />
                                <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className='mt-3'>
                        <Form.Group className="">
                            <Form.Label className='fs-14 fw-600'>Frequency</Form.Label>
                            <Select
                                // closeMenuOnSelect={false}
                                isDisabled
                                theme={selectThemeColors}
                                className='react-select'
                                classNamePrefix='select'
                                defaultValue={selectedSession}
                                name='frequency'
                                options={allSession}
                                placeholder='Select frequency'
                                onChange={setSelectedSession}
                            />
                        </Form.Group>
                    </Row>
                    {singleData?.timeslots?.length > 0 ? <div className='mt-3'>
                        <p className='mb-1 fs-14 fw-600'>Joined Timeslot</p>
                        <Button variant='' disabled className={classNames('border bg-light pe-none')}> {`${singleData?.timeslots[0]?.start_time}`} {`- ${singleData?.timeslots[0]?.end_time}`} </Button>
                    </div> : <p className='mt-2'>---</p>}

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Save</PrimaryButton>
                        </div>
                    </div>
                </Form> : <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5' onClick={() => directAddStudent()}>Add</PrimaryButton>
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    </>


};

export default UpdateStudent;