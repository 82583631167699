import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import VTlist from "./Tabs";
import VTcontent from "./Contents";

import "./two.css";

function VerticalTab(props) {
  const [activeTabId, setActiveTabId] = useState(0);

  function btnClick(id) {
    setActiveTabId(id);
  }

  return (
    <Container className="section__Jobs-container">
      <Row>
        <Col lg={3} sm="6">

          <div className="section__Jobs-styledTab">
          <h1 className="fw-600 mb-3">Account</h1>
            <ul className="section__Jobs-styledTabList">
              {props.data.map((item, index) => (
                <VTlist
                  key={index}
                  onClick={btnClick}
                  data={item}
                  index={index}
                  activeTabId={activeTabId}
                />
              ))}
            </ul>
          </div>
        </Col>
        <Col lg={8} sm="6">
          {props.data.map((item, index) => (
            <VTcontent
              data={item}
              key={index}
              index={index}
              activeTabId={activeTabId}
            />
          ))}
        </Col>
      </Row>
      <span
        className={ activeTabId === 0 ? "index1-chosen" : activeTabId === 1 ? "index2-chosen" : "index3-chosen"
        }
      >
        &nbsp;
      </span>
    </Container>
  );
}

export default VerticalTab;
