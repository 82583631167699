import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row } from "react-bootstrap";

import 'react-tabs/style/react-tabs.css';

// Custom Component
import VerticalTab from "./components/AllTabs";
import tabData from "./resumeData.js";

const ProfilePage = () => {
    return <HelmetProvider>
        <Helmet>
            <title> User Profile || AFA Coaching</title>
        </Helmet>
        <div>
            <hr className='m-0' />
            <Container className='mt-4'>
                <Row>
                    <VerticalTab data={tabData.data} />
                </Row>
            </Container>
        </div>
    </HelmetProvider>
};

export default ProfilePage;