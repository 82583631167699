import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../../../../components/TextField/ErrorText'
import ErrorAlert from '../../../../../components/Alert/ErrorAlert';

// Images 
import { logo, authentication } from '../../../../../assets/images'

// Utility Service
import { setUserSession } from '../../../../../utils/AuthService';

// API Service
import { academyLoginService } from '../../../../../service/auth.service';
import { allCountryService } from '../../../../../service/country.service';

// Redux-Functions
import { setToken, setUser } from "../../../../../redux/userSlice"

const OrganizerSignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    
    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibilty
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function onSubmit
        * @params formdata
        * @description used to signin the organization
    */
    const onSubmit = async (formdata) => {
        try {
            const result = await academyLoginService({
                phone: formdata.phone,
                password: formdata.password
            });
            if (result?.data?.status) {
                if (result.data?.data?.is_verified) {
                    dispatch(setToken(result?.data?.data?.token))
                    dispatch(setUser({
                        ...result.data?.data?.academy,
                        type: 'academy'
                    }))
                    setUserSession(result.data?.data?.token, {
                        ...result.data?.data?.academy,
                        type: 'academy'
                    })
                    navigate('/academy/home');
                } else {
                    ErrorAlert('This Organizer is not verified')
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Academy Sign In | AFA Coaching</title>
        </Helmet>
        <Container fluid>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" width='120' />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5 student-register academy-login">
                        <Col xs={10} sm={8}>
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <p className='fs-30 fw-bold title'>Sign In as Academy</p>
                                <p className='fs-16 fw-400 text-secondary'>Welcome back! Please enter your details.</p>
                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <Form.Select
                                                {...register("country_id")}
                                            >
                                                {countryList.map((item, index) => {
                                                    return <option value={item?.code} key={index}>{item?.code}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control
                                                className='flex-grow-17'
                                                type="tel"
                                                autoComplete='off'
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Please provide the phone"
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            autoComplete='nope'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Please provide the password",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4">
                                    <Button size='md' color='' variant="primary" className='text-light fs-16 fw-600 w-100' type='submit'>
                                        Sign In
                                    </Button>
                                </Form.Group>
                            </Form>
                            <hr className='mx-5 my-3' />
                            <div className='text-center mt-4 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Sign in As
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Student</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <Col xs={12} className='d-none d-lg-block mt-auto'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <span className='fs-14 fw-400 text-secondary'>
                                    © AFA 2023
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='fs-14 fw-400 text-secondary'>
                                        help@AFA.com
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block p-0'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default OrganizerSignIn;