import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import CoachList from "../../../../components/Homepage/CoachList";

const CoachPage = () => {
    return <HelmetProvider>
        <Helmet>
            <title>Coach List || AFA Coaching</title>
        </Helmet>
        <CoachList />
    </HelmetProvider>
}; 

export default CoachPage;