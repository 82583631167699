import { useState, useEffect, Fragment } from 'react';
import { Carousel, Container, Row, Col, Card, Image, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Switch from "react-switch";
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
    Input,
    Button,
    Label,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'

import toast from 'react-hot-toast'

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../Alert/ErrorAlert';
import SuccessAlert from '../../Alert/SuccessAlert';
import { ArrowDown, ArrowRight, Calendar, Search, } from 'react-feather'
// Images
import { coachProfile, uploadImage } from '../../../assets/images/index'

// Utility-Service
import { getToken } from '../../../utils/AuthService'

// API Service
import { accessListService, accessSearchListService, accessStatusChange } from '../../../service/accessAcademy.service';

// Redux-Functions
import { selectUser } from "../../../redux/userSlice"
import { setDataList, setFreshList, setTotalEntry, selectDataList, selectFreshData, selectTotalEntry, } from "../../../redux/accessAcademySlice"

import AddAccessManager from './AddAccessManager'
import NoManager from "../../../components/Common/NoAccess";
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import DeleteUpdate from './EditDelete';
import Filter from './Filter'

const AccessManagerListPage = (props) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const token = getToken()
    const [bannerList, setBannerList] = useState([])
    const [star, setStar] = useState(5);
    const user = useSelector(selectUser);
    const coachList = useSelector(selectDataList);
    const [coachCount, setCoachCount] = useState(null);
    const totalEntry = useSelector(selectTotalEntry)
    const [isSearch, setIsSearch] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const countPerPage = 10
    const [addManagerModal, setAddManagerModal] = useState(false)
    
    /**
        * @function handleAddCoachModal
        * @params
        * @description set the visibility of add bank account modal
    */
    const handleAddCoachModal = () => {
        setAddManagerModal(true)
    }

    const handleCloseModal = () => {
        setAddManagerModal(false)
    }

    /**
        * @function fetchAcademyCoachList
        * @params
        * @description fetch the list of organizer banner
    */
    const fetchAcademyCoachList = async (page) => {
        try {
            const result = await accessListService({ page, countPerPage });
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setDataList(result?.data?.data.access_manager_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                setCoachCount(result?.data?.data?.pagination.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'academy') {
            setLoading(true)
            fetchAcademyCoachList(currentPage + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, refetch, currentPage])

    const statusUpdate = async (rowRecord) => {
        const getStatus = rowRecord.status === '0' ? '1' : '0';
        const formData = new FormData();
        formData.append('is_active', getStatus)
        const statusChanged = await accessStatusChange({ token, id: rowRecord.id }, formData)
        if (statusChanged?.data?.status) {
            toast.success('Status changed successfully.')
            fetchAcademyCoachList(currentPage + 1)
        }
    }

    const columns = [{
        name: 'Profile',
        selector: (row) => row.profile_image ? <Image src={row?.profile_image} alt='profile' width='35' roundedCircle={true} /> : <Image src={uploadImage} alt='profile' width='35' roundedCircle={true} />,
        width: '100px'
    },
    {
        name: 'Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
    },
    {
        name: 'Email',
        selector: (row) => row?.email ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
        >{row.email}</span> : '--',
        className: 'onlyCapitalized',
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
    },
    {
        name: 'Status',
        selector: (row) => (
            <div className='form-switchh form-check-primary pl-0'>
                <Switch className='pl-0' size='sm' onChange={() => statusUpdate(row)} checked={row.status === 1} />
            </div>
        ),
    },
    {
        name: 'Actions',
        allowOverflow: true,
        selector: (row) => <DeleteUpdate tableRecord={row} fetchAcademyCoachList={fetchAcademyCoachList} setRefetch={setRefetch} refetch={refetch} setCoachCount={setCoachCount} />,
        width: '150px',

    }]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
        />
    )

    const searchData = async (searchKey, page) => {
        const result = await accessSearchListService({ searchValue: searchKey, page, countPerPage, token });
        if (result?.data?.status) {
            setLoading(false)
            dispatch(setDataList(result?.data?.data?.access_manager_list))
            dispatch(setTotalEntry(result?.data?.data?.pagination?.total_pages))
            setCoachCount(result?.data?.data?.pagination.total)
        } else {
            setLoading(false)
            toast.success(result)
        }
    }

    const handleSearch = (e) => {
        const value = e.target.value
        if (value) {
            setIsSearch(true)
            searchData(value, currentPage + 1);
        } else {
            setIsSearch(false)
            fetchAcademyCoachList(currentPage + 1)
        }
    }

    const checkDisplayCondition = () => {
        if (isSearch === false && coachList?.length === 0) {
            return (
                <> {addManagerModal ? <AddAccessManager show={addManagerModal} handleClose={handleCloseModal} fetchAcademyCoachList={fetchAcademyCoachList} size='md' /> : <NoManager handleAddCoachModal={handleAddCoachModal} />}</>
            )
        } else if (isSearch === true && coachList?.length === 0) {
            return (<>
                <div>
                    <div className='boxSection mt-2'>
                        <div>
                            <>
                                <Row className='mb-4'>
                                    <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                        <div className='d-flex justify-content-between align-items-center flex-lg-row'>
                                            <div><p className='fs-18 fw-bold mb-0'>Access Manager</p> </div>
                                            <div className=''>
                                                <Button size="sm" color='primary' outline className='px-5' onClick={handleAddCoachModal}>Add Access Manager</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>

                            <div className="box-cover">
                                <>
                                    <Row className='mb-4'>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                                <div>
                                                    {coachCount > 0 ? <span className='student-count'>{`${coachCount} `} Access</span> : <span className='student-count'>{`${coachCount} `} Access</span>}

                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-lg-end justify-content-between align-items-center flex-lg-row'>
                                                <div className='w-50'>
                                                    <Input
                                                        className='dataTable-filter mb-50'
                                                        type='text'
                                                        autoComplete='off'
                                                        bsSize='sm'
                                                        placeholder='Search Access Manager'
                                                        id='search-input'
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                                <div className='ms-4 mx-2'>
                                                    <div>
                                                        <Filter />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>

                                <div className="div" id='box-coach-web-only'>
                                    <Row className='box-programme gx-2'>

                                        <Fragment>
                                            <Col lg={12} md={12} sm={12} className=''>
                                                <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                                    <DataTable
                                                        columns={columns}
                                                        className='react-dataTable'
                                                        data={coachList}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={10}
                                                        paginationPerPage={10}
                                                        paginationComponentOptions={{ noRowsPerPage: true }}
                                                        paginationComponent={CustomPagination}
                                                        paginationDefaultPage={currentPage + 1}
                                                    />
                                                </div>

                                            </Col>
                                        </Fragment>
                                    </Row>
                                    <Row>
                                        <Col className='' lg={12}>
                                            <div className='pt-3 pb-3 student-pagination'>
                                                <ReactPaginate
                                                    nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                        <span className='fs-14 fw-600 me-2'>Next</span>
                                                        <AiOutlineArrowRight className='text-secondary' size={20} />
                                                    </div>}
                                                    breakLabel='...'
                                                    previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                        <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                        <span className='fs-14 fw-600'>Previous</span>
                                                    </div>}
                                                    pageRangeDisplayed={3}
                                                    forcePage={currentPage}
                                                    marginPagesDisplayed={3}
                                                    activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                                    pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                                    breakClassName='page-item me-3 d-flex align-items-center'
                                                    nextClassName='page-item next-item flex-grow-1 text-end'
                                                    previousClassName='page-item prev-item flex-grow-1 me-3'
                                                    disabledLinkClassName='btn disabled p-0 border-0'
                                                    pageCount={totalEntry || 1}
                                                    onPageChange={page => handlePagination(page)}
                                                    containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </div>

                            <AddAccessManager show={addManagerModal} handleClose={handleCloseModal} fetchAcademyCoachList={fetchAcademyCoachList} size='md' />
                        </div>
                    </div>

                    <div className="div" id='box-coach-mobile-only'>
                        <div className='boxSection mt-2'>
                            <div>
                                <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                                    {coachList?.map((item, index) => (
                                        <Col sm={12} className=''>
                                            <>
                                                <div className='coach-view-box'>
                                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                                        <div className=''>
                                                            {item?.type === 'MAIN_COACH' ? <span className='text-success fw-600'>Main Coach</span> : <span className='text-secondary fw-600'>Sub Coach</span>}
                                                        </div>
                                                        <div className=''>
                                                            <DeleteUpdate tableRecord={item} />
                                                        </div>
                                                    </div>
                                                    <div className='detail my-2'>
                                                        <div className="profile">
                                                            <Image src={item?.profile_image} alt='profile' width='70' roundedCircle={true} />
                                                        </div>
                                                        <div className="my-2">
                                                            <p className='fs-14 fw-600'>{item?.name}</p>
                                                        </div>
                                                        <div className="my-2">
                                                            <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{`+60 ${item?.phone}`}</span></p>
                                                        </div>
                                                        <div className="my-2">
                                                            <p className='mb-0'><span>{moment(item.dob).format("DD/MM/YYYY")}</span> &nbsp; <span>{item?.gender === 'MALE' ? 'Male' : 'Female'}</span></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className='clearfix'></div>
                </div>
            </>)
        } else {
            return (
                <>
                    <div>
                        <div className='boxSection mt-2'>
                            <div>
                                <>
                                    <Row className='mb-4'>
                                        <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-between align-items-center flex-lg-row'>
                                                <div><p className='fs-18 fw-bold mb-0'>Access Manager</p> </div>
                                                <div className=''>
                                                    <Button size="sm" color='primary' outline className='px-5' onClick={handleAddCoachModal}>Add Access Manager</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>

                                <div className="box-cover">
                                    <>
                                        <Row className='mb-4'>
                                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                                <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                                    <div>
                                                        {coachCount > 0 ? <span className='student-count'>{`${coachCount} `} Access</span> : <span className='student-count'>{`${coachCount} `} Access</span>}

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                                <div className='d-flex justify-content-lg-end justify-content-between align-items-center flex-lg-row'>
                                                    <div className='w-50'>
                                                        <Input
                                                            className='dataTable-filter mb-50'
                                                            type='text'
                                                            autoComplete='off'
                                                            bsSize='sm'
                                                            placeholder='Search Access Manager'
                                                            id='search-input'
                                                            onChange={handleSearch}
                                                        />
                                                    </div>
                                                    <div className='ms-4 mx-2'>
                                                        <div>
                                                            <Filter />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>

                                    <div className="div" id='box-coach-web-only'>
                                        <Row className='box-programme gx-2'>

                                            <Fragment>
                                                <Col lg={12} md={12} sm={12} className=''>
                                                    <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                                        <DataTable
                                                            columns={columns}
                                                            className='react-dataTable'
                                                            data={coachList}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={10}
                                                            paginationPerPage={10}
                                                            paginationComponentOptions={{ noRowsPerPage: true }}
                                                            paginationComponent={CustomPagination}
                                                            paginationDefaultPage={currentPage + 1}
                                                        />
                                                    </div>

                                                </Col>
                                            </Fragment>
                                        </Row>
                                        <Row>
                                            <Col className='' lg={12}>
                                                <div className='pt-3 pb-3 student-pagination'>
                                                    <ReactPaginate
                                                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                            <span className='fs-14 fw-600 me-2'>Next</span>
                                                            <AiOutlineArrowRight className='text-secondary' size={20} />
                                                        </div>}
                                                        breakLabel='...'
                                                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                            <span className='fs-14 fw-600'>Previous</span>
                                                        </div>}
                                                        pageRangeDisplayed={3}
                                                        forcePage={currentPage}
                                                        marginPagesDisplayed={3}
                                                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                                        breakClassName='page-item me-3 d-flex align-items-center'
                                                        nextClassName='page-item next-item flex-grow-1 text-end'
                                                        previousClassName='page-item prev-item flex-grow-1 me-3'
                                                        disabledLinkClassName='btn disabled p-0 border-0'
                                                        pageCount={totalEntry || 1}
                                                        onPageChange={page => handlePagination(page)}
                                                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>

                                <AddAccessManager show={addManagerModal} handleClose={handleCloseModal} fetchAcademyCoachList={fetchAcademyCoachList} size='md' />
                            </div>
                        </div>

                        <div className="div" id='box-coach-mobile-only'>
                            <div className='boxSection mt-2'>
                                <div>
                                    <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                                        {coachList?.map((item, index) => (
                                            <Col sm={12} className=''>
                                                <>
                                                    <div className='coach-view-box'>
                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                            <div className=''>
                                                                {item?.type === 'MAIN_COACH' ? <span className='text-success fw-600'>Main Coach</span> : <span className='text-secondary fw-600'>Sub Coach</span>}
                                                            </div>
                                                            <div className=''>
                                                                <DeleteUpdate tableRecord={item} />
                                                            </div>
                                                        </div>
                                                        <div className='detail my-2'>
                                                            <div className="profile">
                                                                <Image src={item?.profile_image} alt='profile' width='70' roundedCircle={true} />
                                                            </div>
                                                            <div className="my-2">
                                                                <p className='fs-14 fw-600'>{item?.name}</p>
                                                            </div>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{`+60 ${item?.phone}`}</span></p>
                                                            </div>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{moment(item.dob).format("DD/MM/YYYY")}</span> &nbsp; <span>{item?.gender === 'MALE' ? 'Male' : 'Female'}</span></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </>
            )
        }
    }

    if (isLoading) {
        return (
            <>
                <Fragment>
                    <>
                        <div className='parent'>
                            <div className='myHeight text-center'>
                                <Spinner variant='primary' />
                            </div>
                        </div>
                    </>
                </Fragment>
            </>
        )
    }

    return <>
        {checkDisplayCondition()}
    </>
};

export default AccessManagerListPage;