import { Route, Routes } from 'react-router-dom';

// Custom Component
import Layout from './Layout';

// Authentication Page
import SignUp from '../../pages/Student/Public/Authentication/SignUp/SignUp';
import SignIn from '../../pages/Student/Public/Authentication/SignIn/SignIn';
import ForgotPassword from '../../pages/Student/Public/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/Student/Private/ResetPassword/ResetPassword';

// Public Page
import SSO from '../../pages/SSO';
import Homepage from '../../pages/Student/Public/Home/Index';
import CoachLists from '../../pages/Student/Public/Home/CoachList';
import ProgramList from '../../pages/Student/Public/Home/ProgramList';
import GuestProgramDetail from '../Homepage/ProgramDetail';
import AboutUs from '../../pages/Common/Contents/AboutUs/AboutUs';
import ContactUs from '../../pages/Common/Contents/ContactUs/ContactUs';
import PrivacyPolicy from '../../pages/Common/Contents/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from '../../pages/Common/Contents/TermsAndCondition/TermsAndCondition';
import NotFoundPage from '../../pages/404';

// Private Page Student
import StudentHome from '../../pages/Student/Private/Homepage/Homepage';
import StudentProgramDetail from '../Student/SingleProgamme';
import StudentMyProgramDetail from '../Student/MyProgram';
import Profile from '../../pages/Student/Private/Profile/index';
import ChangePassword from '../../pages/Student/Private/ChangePassword/ChangePassword';
import CheckoutPage from '../../pages/Student/Private/checkout/index';

// Academy Urls
import AcademySignIn from '../../pages/Academy/Public/Authentication/AcademySignIn/AcademySignIn';
import AcademyHomepage from '../../pages/Academy/Private/Homepage/Homepage';
import AcademyProgrammeDetails from '../../pages/Academy/Private/Homepage/SingleProgramme';
import CoachList from '../../pages/Academy/Private/coaches/index';
import AccessManger from '../../pages/Academy/Private/AccessManager/index';
import AcademyProfile from '../../pages/Academy/Private/Profile/Profile';
import AcademyWallet from '../../pages/Academy/Private/Wallet/index';
import AcademyBank from '../../pages/Academy/Private/BankAccount/index';
import AcademyCalendar from '../../pages/Academy/Private/Calendar/index';

// Coach Urls 
import CoachSignIn from '../../pages/Coach/Public/Authentication/CoachSignIn/CoachSignIn';
import CoachHomepage from '../../pages/Coach/Private/Homepage/Homepage';
import CoachProgrammeDetails from '../../pages/Coach/Private/Homepage/SingleProgramme';
import CoachProfile from '../../pages/Coach/Private/Profile/Profile';
import CoachWallet from '../../pages/Coach/Private/Wallet/index';
import CoachOnGoingProgrammes from '../../pages/Coach/Private/OnGoing/index';

// Auth-Service
import PrivateRoute from '../../utils/PrivateRoute';
// Payment status
import PaymentSuccess from '../../pages/Student/Private/PaymentStatus/PaymentSuccess';
import PaymentFailure from '../../pages/Student/Private/PaymentStatus/PaymentFailure';

const DefaultLayout = () => {
    return <Layout>
        <Routes>
            {/* SSO */}
            <Route path="/sso" element={<SSO />} />

            {/* Authentication Route */}
            <Route path="/register" element={<SignUp />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/academy/login" element={<AcademySignIn />} />
            <Route path="/coach/login" element={<CoachSignIn />} />
            <Route path="/forget-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/payment/success/:receiptId" element={<PaymentSuccess />} />
            <Route path="/payment/failure/:programId" element={<PaymentFailure />} />


            {/* Home Page Route */}
            <Route path="/" element={<Homepage />} />
            <Route path="/all/programs" element={<ProgramList />} />
            <Route path="/all/coach" element={<CoachLists />} />
            <Route path="/*" element={<NotFoundPage />} />
            {/* About Us Page Route */}
            <Route path="/about-us" element={<AboutUs />} />
            {/* Contact Us Page Route */}
            <Route path="/contact-us" element={<ContactUs />} />
            {/* Privacy Policy Page Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* Terms And Condition Page Route */}
            <Route path="/terms-and-condition" element={<TermsAndCondition />} />
            <Route path="/program/detail/:id" element={<GuestProgramDetail />} />

            {/* Private Route */}
            <Route element={<PrivateRoute />}>
                {/* Password Change Page Route */}
                <Route path="/change-password" element={<ChangePassword />} />
                {/* Profile Page Route */}
                <Route path="/user/profile" element={<Profile />} />

                {/* Student Private Pages */}
                <Route path="/student/home" element={<StudentHome />} />
                <Route path="/student/program/detail/:id" element={<StudentProgramDetail />} />
                <Route path="/student/my/program/detail/:id" element={<StudentMyProgramDetail />} />
                <Route path="/student/checkout/:id" element={<CheckoutPage />} />

                {/* Coach Routes */}
                <Route path="/coach/profile" element={<CoachProfile />} />
                <Route path="/coach/home" element={<CoachHomepage />} />
                <Route path="/coach/index" element={<CoachHomepage />} />
                <Route path="/coach/wallet/list" element={<CoachWallet />} />
                <Route path="/coach/ongoing/programs" element={<CoachOnGoingProgrammes />} />
                <Route path="/coach/program/detail/:id" element={<CoachProgrammeDetails />} />

                {/* Academy routes */}
                <Route path="/academy/profile" element={<AcademyProfile />} />
                <Route path="/academy/home" element={<AcademyHomepage />} />
                <Route path="/academy/programme/detail/:id" element={<AcademyProgrammeDetails />} />
                <Route path="/academy/coach/list" element={<CoachList />} />
                <Route path="/academy/wallet/list" element={<AcademyWallet />} />
                <Route path="/academy/access/manager" element={<AccessManger />} />
                <Route path="/academy/bank" element={<AcademyBank />} />
                <Route path="/academy/calendar" element={<AcademyCalendar />} />
            </Route>
        </Routes>
    </Layout>
}

export default DefaultLayout