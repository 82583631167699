import axios from "axios"

/**
    * @function allProgramsService
    * @param
    * @description This function is used to get all programs
*/
export const allProgramsService = async() =>{
    try {
        const result = await axios.get(`/academy/program/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramService
    * @param
    * @description This function is used to get single progam
*/
export const singleProgramService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/program/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCategoryService
    * @param
    * @description This function is used to get all sports
*/
export const allCategoryService = async() =>{
    try {
        const result = await axios.get(`/academy/sport/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function allCoachService
    * @param
    * @description This function is used to get all coach list
*/
export const allCoachService = async() =>{
    try {
        const result = await axios.get(`/academy/coach/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addProgramService
    * @param data
    * @description This function is used to add program
*/
export const addProgramService = async(data) =>{
    try {
        // const result = await axios.post(`/academy/program/store`, data)
        const result = await axios.post(`/academy/program/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProgramService
    * @param { id, data }
    * @description This function is used to update program
*/
export const updateProgramService = async({ id, data }) =>{
    try {
        // const result = await axios.post(`/academy/program/update/${id}`, data)
        const result = await axios.post(`/academy/program/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteProgramService
    * @param id
    * @description This function is used to delete program
*/
export const deleteProgramService = async(id) =>{
    try {
        const result = await axios.delete(`/academy/program/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

export const programmeStatusChange = async({ id, data }) =>{
    try {
        const result = await axios.post(`/academy/program/status/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

// Academy Students services

/**
    * @function allStudentSessionService
    * @param
    * @description This function is used to get all session academy/session/program/
*/
export const allStudentSessionService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/session/program/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allStudentSessionDropService
    * @param
    * @description This function is used to get all session academy/session/program/
*/
export const allStudentSessionDropService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/session/program/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allStudentServiceDrop
    * @param
    * @description This function is used to get all student dropdown
*/
export const allStudentServiceDrop = async({id}) =>{
    try {
        const result = await axios.get(`/academy/customer/list`)
        // const result = await axios.get(`/academy/program/student/list/${id}?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allStudentService
    * @param
    * @description This function is used to get all students
*/
export const allStudentService = async({id, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/program/student/list/${id}?paginate=${1}&page=${page}&perPage=${countPerPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterStudentService
    * @param
    * @description This function is used to get filter students
*/
export const filterStudentService = async({id, start_date, end_date}) =>{
    try {
        const result = await axios.get(`/academy/program/student/list/${id}?paginate=${false}&start_date=${start_date}&end_date=${end_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function downloadStudentListService
    * @param
    * @description This function is used to get data for students
*/
export const downloadStudentListService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/program/student/list/${id}?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramFilterStudentService
    * @param
    * @description This function is used to get filter
*/
export const singleProgramFilterStudentService = async({id, filter, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/program/student/list/${id}?paginate=${true}&page=${page}&limit=${countPerPage}&session_id=${filter}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addStudentService
    * @param data
    * @description This function is used to add student
*/
export const addStudentService = async({id, data}) =>{
    try {
        // const result = await axios.post(`/academy/program/store`, data)
        const result = await axios.post(`/academy/program/student/add/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateStudentService
    * @param data
    * @description This function is used to update student
*/
export const updateStudentService = async({id, data}) =>{
    try {
        const result = await axios.post(`/academy/program/student/edit/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteStudentService
    * @param data
    * @description This function is used to update profile data for player
*/
export const deleteStudentService = async ({id}) => {
    try {
        const result = await axios.delete(`/academy/program/student/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

// . Academy Earning Apis

/**
    * @function allStudentEarningService
    * @param
    * @description This function is used to get all earnings
*/
export const allStudentEarningService = async({id, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/program/single/earnings/${id}?paginate=${1}&page=${page}&perPage=${countPerPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramFilterStudentEarningService
    * @param
    * @description This function is used to get filter earning
*/
export const singleProgramFilterStudentEarningService = async({id, page, countPerPage, filter}) =>{
    try {
        const result = await axios.get(`/academy/program/single/earnings/${id}?paginate=${true}&page=${page}&perPage=${countPerPage}&session_id=${filter}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramFilterStudentEarningwithMonthService
    * @param
    * @description This function is used to get filter earning with only month
*/
export const singleProgramFilterStudentEarningwithMonthService = async({id, page, countPerPage, start_date}) =>{
    try {
        const result = await axios.get(`/academy/program/single/earnings/${id}?paginate=${1}&page=${page}&perPage=${countPerPage}&start_date=${start_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterStudentEarningService
    * @param
    * @description This function is used to get filter earning
*/
export const filterStudentEarningService = async({id, start_date, end_date}) =>{
    try {
        const result = await axios.get(`/academy/program/single/earnings/${id}?paginate=${false}&start_date=${start_date}&end_date=${end_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function downloadStudentEarningtService
    * @param
    * @description This function is used to get filter earning
*/
export const downloadStudentEarningtService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/program/single/earnings/${id}?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleUserDetailsService
    * @param
    * @description This function is used to get single user data
*/
export const singleUserDetailsService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/customer/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}
