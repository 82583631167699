import { useState, useEffect, Fragment } from 'react';
import {
    Carousel, Form, Container, Row, Col, Card, Button, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { selectThemeColors } from '../../../../utils/Utils'

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import { ArrowDown, ArrowRight, Calendar, Search, XSquare, Delete, Edit, Trash } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../../../assets/images/index";

// API Service
import { deleteStudentService, allStudentSessionDropService, allStudentService, singleProgramFilterStudentService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { setStudentList, setSessionDropList, selectSessionDropList, selectStudentList, setTotalEntry, selectTotalEntry } from "../../../../redux/academyProgrammeSlice"

import AddStudent from './AddStudent'
import NoStudent from "../../../../components/Common/NoStudent";
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import FilterStudent from './FilterStudent'
import UpdateStudent from './UpdateStudent'

const Students = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [star, setStar] = useState(5);
    const user = useSelector(selectUser);
    const studentsList = useSelector(selectStudentList);
    const sessionList = useSelector(selectSessionDropList);
    const [studentCount, setStudentCount] = useState(0);
    const [checked, setChecked] = useState(0)
    const [isSearch, setIsSearch] = useState(false)

    const totalEntry = useSelector(selectTotalEntry)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const countPerPage = 10

    const [addStudentAccountModal, setAddStudentModal] = useState(false)
    const [editBankAccountModal, setEditBankAccountModal] = useState(false)
    const [selectedSession, setSelectedSession] = useState();
    const [delete_id, setDelete_id] = useState('');

    /**
         * @function fetchAcademySessionList
         * @params
         * @description fetch the list of session
         */
    const fetchAcademySessionList = async () => {
        try {
            if (singleData?.id) {
                const result = await allStudentSessionDropService({ id: singleData?.id });
                if (result?.data?.status) {
                    setLoading(false)
                    const resultSession = result?.data?.data?.map((item) => ({ value: item.id, label: item.date }))
                    const allOptions = [{ value: '', label: 'All' }]
                    const finalDrop = allOptions.concat(resultSession)
                    dispatch(setSessionDropList(finalDrop))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchAcademyStudentList
         * @params
         * @description fetch the list of student
         */
    const fetchAcademyStudentList = async (page) => {
        try {
            if (singleData?.id) {
                const result = await allStudentService({ id: singleData?.id, page, countPerPage, });
                    console.log('student 1', result)

                if (result?.data?.status) {
                    console.log('student 2', result)
                    setLoading(false)
                    dispatch(setStudentList(result?.data?.data.student_list))
                    dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                    setStudentCount(result?.data?.data?.pagination.total)
                    fetchAcademySessionList()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
     * @function filterSingleProgramProgress
     * @params 
     * @description fetch the list of customer banner
     */
    const filterSingleProgramProgress = async (page, filter) => {
        try {
            const result = await singleProgramFilterStudentService({ id: singleData.id, page, filter: filter ? filter : selectedSession, countPerPage });
                    console.log('student 3', result)

            if (result?.data?.status) {
                    console.log('student 4', result)

                setLoading(false)
                dispatch(setStudentList(result?.data?.data?.student_list))
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
                setStudentCount(result?.data?.data?.pagination?.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleStudentAccountModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleStudentAccountModal = () => {
        setAddStudentModal(!addStudentAccountModal)
    }
    const handleStudentAccountModalByComponent = () => {
        setAddStudentModal(!addStudentAccountModal)
    }

    /**
         * @function handleEditBankAccountModal
         * @params
         * @description set the visibility of edit bank account modal
         */

    const handleEditBankAccountModal = () => {
        setEditBankAccountModal(!editBankAccountModal)
    }

    useEffect(() => {
        setLoading(true)
        fetchAcademySessionList()
        // fetchAcademyStudentList(pageCount)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedSession?.value) {
            filterSingleProgramProgress(currentPage + 1, selectedSession?.value)
        } else {
            fetchAcademyStudentList(currentPage + 1)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData, currentPage, selectedSession]);

    const handleCheck = (e) => {
        const value = e.currentTarget.checked
        setChecked(value)

    }

    const handleCancel = () => {
        setShow(false)
    }

    const handleDeleteModal = (item) => {
        setShow(true)
        if (item?.id) {
            setDelete_id(item?.id)
        }
    }

    const handleDelete = async () => {
        const result = await deleteStudentService({ id: delete_id, })
        if (result?.data?.status) {
            // toast.success(result.data.message)
            SuccessAlert('Student deleted successfully')
            fetchAcademyStudentList(pageCount)
            setShow(false)

        }
    }

    const columns = [{
        // {
        //     name: 'Sr. No.',
        //     cell: (row, index) => pageCount > 1 ? ((pageCount - 1) * 10) + index + 1 : index + 1,
        //     width: "100px",
        // },


        name: 'Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Email',
        selector: (row) => row.email ? `${row.email}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
        minWidth: '150px'
    },
    {
        name: 'Gender',
        selector: (row) => row.gender ? `${row.gender}` : '--',
        minWidth: '100px'
        },
    {
        name: 'Time Slots of Session',
        selector: (row) => row.phone ? <Fragment>
            <p>
                <div className="d-flex justify-content-center align-content-center">
                    {row?.timeslots?.length > 0 ? <span className='text-primary'>{moment(row?.timeslots[0]?.session?.date).format('DD-MM-YYYY')}, {row?.timeslots[0]?.start_time} - {row?.timeslots[0]?.end_time}</span> : ''}
                </div>
            </p>
        </Fragment> : '--',
        minWidth: '280px'
    },
    // {
    //     name: 'Session',
    //     selector: (row) => row.timeslots?.length > 0 ? 'yes' : '--',
    //     // selector: (row) => row.timeslots?.length > 0 ? `${moment(row.timeslots?.session?.date)}` : '--',
    //     minWidth: '100px'
    // },
    {
        name: 'Student Age',
        selector: (row) => row.dob ? `${moment().diff(row?.dob, 'years')}` : '--',
        minWidth: '80px'
    },
    // {
    //     name: 'Attendance',
    //     selector: (row) => row.attendance ? <Form.Check
    //         type='checkbox'
    //         id={`recurring-payment`}
    //         checked={checked ? checked : row.attendance}
    //         value="1"
    //         onChange={(e) => handleCheck(e)}

    //     /> : <Form.Check
    //         type='checkbox'
    //         id={`recurring-payment`}
    //         checked={checked ? checked : row.attendance}
    //         value="1"
    //         onChange={(e) => handleCheck(e)}

    //     />,
    //     minWidth: '50px'
    // },
    {
        name: 'Actions',
        allowOverflow: true,
        selector: (row) => <p className='d-flex justify-content-center align-content-center'><span className=''><UpdateStudent fetchAcademyStudentList={fetchAcademyStudentList} singleData={row} frequency={singleData?.frequencies} /></span> <span className='ms-2'><Trash size='17' className='custom-hover text-primary' onClick={() => handleDeleteModal(row)} /></span></p>,
    },]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
        */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    const handleFilterSession = (e) => {
        const value = e?.value
        if (value) {
            setSelectedSession(e)
            setCurrentPage(0)
        } else {
            setSelectedSession('')
            setCurrentPage(0)
        }
    }

    /**
         * @function CustomPagination
         * @params
         * @description used for Custom Pagination
         */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
        />
    )

    const checkDisplayCondition = () => {
        if (isSearch === false && studentsList?.length === 0) {
            return (
                <>  {addStudentAccountModal ? <AddStudent show={addStudentAccountModal} handleClose={handleStudentAccountModal} singleData={singleData} size='lg' fetchAcademyStudentList={fetchAcademyStudentList} /> : <NoStudent show={addStudentAccountModal} handleClose={handleStudentAccountModalByComponent} />}</>
            )
        } else if (isSearch === true && studentsList?.length === 0) {
            return (<>
                <div>
                    <div>
                        <div className='boxSection mt-2'>
                            <div>
                                <div className="box-cover">
                                    <Row className='mb-4'>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                                <div className='mx-2'>
                                                    <p className='fs-18 fw-bold mb-0'>Students</p>
                                                </div>
                                                <div>
                                                    {studentsList?.length > 0 ? <span className='student-count'>{`${studentsList?.length} `}
                                                    </span> : <span className='student-count'>{`${studentsList?.length} `} </span>}
                                                </div>
                                                <div className='ms-5'>
                                                    {/* <p>June 2023<span>
                                    <Calendar size={20} />
                                </span></p> */}
                                                    <Button size="md" variant="primary" className='text-light fw-bold'
                                                        onClick={handleStudentAccountModal}>Add Student</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-lg-end align-items-center flex-lg-row'>
                                                <div className='w-50'>
                                                    <Select // closeMenuOnSelect={false} theme={selectThemeColors} className='react-select'
                                                        classNamePrefix='select' defaultValue={selectedSession} name='clear'
                                                        options={sessionList} placeholder='Session' onChange={(e) => handleFilterSession(e)}
                                                    />
                                                </div>
                                                <div className='ms-4 mx-2'>
                                                    <div>
                                                        <FilterStudent singleData={singleData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>

                                    <div id='box-coach-web-only' className="div">
                                        <Row className='box-programme gx-2'>
                                            <Fragment>
                                                <Col lg={12} md={12} sm={12} className=''>
                                                    <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                                        <DataTable // noHeader 
                                                            columns={columns}
                                                            // customStyles={studentsList && studentsList.length <= 2 ? customStyles : ''} 
                                                            className='react-dataTable'
                                                            // sortIcon={<ChevronDownsize={10} />}
                                                            data={studentsList}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={10}
                                                            paginationPerPage={10}
                                                            paginationComponentOptions={{ noRowsPerPage: true }}
                                                            paginationComponent={CustomPagination}
                                                            paginationDefaultPage={currentPage + 1}
                                                        />
                                                    </div>
                                                </Col>
                                            </Fragment>
                                        </Row>
                                        <Row>
                                            <Col className='' lg={12}>
                                                <div className='pt-3 pb-3 student-pagination'>
                                                    <ReactPaginate nextLabel={<div
                                                        className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                        <span className='fs-14 fw-600 me-2'>Next</span>
                                                        <AiOutlineArrowRight className='text-secondary' size={20} />
                                                    </div>}
                                                        breakLabel='...'
                                                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                            <span className='fs-14 fw-600'>Previous</span>
                                                        </div>}
                                                        pageRangeDisplayed={3}
                                                        forcePage={currentPage}
                                                        marginPagesDisplayed={3}
                                                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                                        breakClassName='page-item me-3 d-flex align-items-center'
                                                        nextClassName='page-item next-item flex-grow-1 text-end'
                                                        previousClassName='page-item prev-item flex-grow-1 me-3'
                                                        disabledLinkClassName='btn disabled p-0 border-0'
                                                        pageCount={totalEntry || 1}
                                                        onPageChange={page => handlePagination(page)}
                                                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                                <AddStudent show={addStudentAccountModal} handleClose={handleStudentAccountModal} singleData={singleData} size='lg'
                                    fetchAcademyStudentList={fetchAcademyStudentList} />
                            </div>
                        </div>

                        <div className="div" id='box-coach-mobile-only'>
                            <div className='boxSection mt-2'>
                                <div>
                                    <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                                        {studentsList.map(item => (
                                            <Col sm={12} className=''>
                                                <>
                                                    <div className='coach-view-box'>
                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                            <div className="">
                                                                <p className='fs-14 fw-600 mb-0'>{item?.name}</p>
                                                            </div>
                                                            <div className=''>
                                                                <XSquare size={15} onClick={() => handleDeleteModal(item)} />
                                                            </div>
                                                        </div>
                                                        <div className='detail my-2'>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{item?.gender}</span> &nbsp; <span>Age:
                                                                    {`${moment().diff(item?.dob, 'years')}`} </span></p>
                                                            </div>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{item?.phone}</span></p>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </>
                                            </Col>

                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>


                        <Modal show={show} onHide={handleCancel} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Student</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancel}>
                                    No
                                </Button>
                                <Button variant="primary" className='text-light' onClick={() => handleDelete()}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <div className='clearfix'></div>
                    </div>
                </div>

            </>)
        } else {
            return (<>
                <div>
                    <div>
                        <div className='boxSection mt-2'>
                            <div>
                                <div className="box-cover">
                                    <Row className='mb-4'>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                                <div className='mx-2'>
                                                    <p className='fs-18 fw-bold mb-0'>Students</p>
                                                </div>
                                                <div>
                                                    {studentsList?.length > 0 ? <span className='student-count'>{`${studentsList?.length} `}
                                                    </span> : <span className='student-count'>{`${studentsList?.length} `} </span>}
                                                </div>
                                                <div className='ms-5'>
                                                    {/* <p>June 2023<span>
                                    <Calendar size={20} />
                                </span></p> */}
                                                    <Button size="md" variant="primary" className='text-light fw-bold'
                                                        onClick={handleStudentAccountModal}>Add Student</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                            <div className='d-flex justify-content-lg-end align-items-center flex-lg-row'>
                                                <div className='w-50'>
                                                    <Select // closeMenuOnSelect={false} theme={selectThemeColors} className='react-select'
                                                        classNamePrefix='select' defaultValue={selectedSession} name='clear'
                                                        options={sessionList} placeholder='Session' onChange={(e) => handleFilterSession(e)}
                                                    />
                                                </div>
                                                <div className='ms-4 mx-2'>
                                                    <div>
                                                        <FilterStudent singleData={singleData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>

                                    <div id='box-coach-web-only' className="div">
                                        <Row className='box-programme gx-2'>
                                            <Fragment>
                                                <Col lg={12} md={12} sm={12} className=''>
                                                    <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                                        <DataTable // noHeader 
                                                            columns={columns}
                                                            // customStyles={students && students.length <= 2 ? customStyles : ''} 
                                                            className='react-dataTable'
                                                            // sortIcon={<ChevronDownsize={10} />}
                                                            data={studentsList}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={10}
                                                            paginationPerPage={10}
                                                            paginationComponentOptions={{ noRowsPerPage: true }}
                                                            paginationComponent={CustomPagination}
                                                            paginationDefaultPage={currentPage + 1}
                                                        />
                                                    </div>
                                                </Col>
                                            </Fragment>
                                        </Row>
                                        <Row>
                                            <Col className='' lg={12}>
                                                <div className='pt-3 pb-3 student-pagination'>
                                                    <ReactPaginate nextLabel={<div
                                                        className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                        <span className='fs-14 fw-600 me-2'>Next</span>
                                                        <AiOutlineArrowRight className='text-secondary' size={20} />
                                                    </div>}
                                                        breakLabel='...'
                                                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                            <span className='fs-14 fw-600'>Previous</span>
                                                        </div>}
                                                        pageRangeDisplayed={3}
                                                        forcePage={currentPage}
                                                        marginPagesDisplayed={3}
                                                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                                        breakClassName='page-item me-3 d-flex align-items-center'
                                                        nextClassName='page-item next-item flex-grow-1 text-end'
                                                        previousClassName='page-item prev-item flex-grow-1 me-3'
                                                        disabledLinkClassName='btn disabled p-0 border-0'
                                                        pageCount={totalEntry || 1}
                                                        onPageChange={page => handlePagination(page)}
                                                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                                <AddStudent show={addStudentAccountModal} handleClose={handleStudentAccountModal} singleData={singleData} size='lg'
                                    fetchAcademyStudentList={fetchAcademyStudentList} />
                            </div>
                        </div>

                        <div className="div" id='box-coach-mobile-only'>
                            <div className='boxSection mt-2'>
                                <div>
                                    <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                                        {studentsList.map(item => (
                                            <Col sm={12} className=''>
                                                <>
                                                    <div className='coach-view-box'>
                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                            <div className="">
                                                                <p className='fs-14 fw-600 mb-0'>{item?.name}</p>
                                                            </div>
                                                            <div className=''>
                                                                <XSquare size={15} onClick={() => handleDeleteModal(item)} />
                                                            </div>
                                                        </div>
                                                        <div className='detail my-2'>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{item?.gender}</span> &nbsp; <span>Age:
                                                                    {`${moment().diff(item?.dob, 'years')}`} </span></p>
                                                            </div>
                                                            <div className="my-2">
                                                                <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{item?.phone}</span></p>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </>
                                            </Col>

                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>


                        <Modal show={show} onHide={handleCancel} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Student</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancel}>
                                    No
                                </Button>
                                <Button variant="primary" className='text-light' onClick={() => handleDelete()}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <div className='clearfix'></div>
                    </div>
                </div>
            </>)
        }
    }

    return <>
        {/* {checkDisplayCondition()} */}
        <div>
            <div className='boxSection mt-2'>
                <div>
                    <div className="box-cover">
                        <Row className='mb-4'>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                    <div className='mx-2'>
                                        <p className='fs-18 fw-bold mb-0'>Students</p>
                                    </div>
                                    <div>
                                        <span className='student-count'>{`${studentCount} `} </span>
                                    </div>
                                    <div className='ms-5'>
                                        {/* <p>June 2023<span>
                                    <Calendar size={20} />
                                </span></p> */}
                                        <Button size="md" variant="primary" className='text-light fw-bold'
                                            onClick={handleStudentAccountModal}>Add Student</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-lg-end align-items-center flex-lg-row'>
                                    <div className='w-50'>
                                        <Select // closeMenuOnSelect={false} theme={selectThemeColors} className='react-select'
                                            classNamePrefix='select' defaultValue={selectedSession} name='clear'
                                            options={sessionList} placeholder='Session' onChange={(e) => handleFilterSession(e)}
                                        />
                                    </div>
                                    <div className='ms-4 mx-2'>
                                        <div>
                                            <FilterStudent singleData={singleData} />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                        <div id='box-coach-web-only' className="div">
                            <Row className='box-programme gx-2'>
                                <Fragment>
                                    <Col lg={12} md={12} sm={12} className=''>
                                        <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                            <DataTable // noHeader 
                                                columns={columns}
                                                // customStyles={studentsList && studentsList.length <= 2 ? customStyles : ''} 
                                                className='react-dataTable'
                                                // sortIcon={<ChevronDownsize={10} />}
                                                data={studentsList}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={10}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                paginationComponent={CustomPagination}
                                                paginationDefaultPage={currentPage + 1}
                                            />
                                        </div>
                                    </Col>
                                </Fragment>
                            </Row>
                            <Row>
                                <Col className='' lg={12}>
                                    <div className='pt-3 pb-3 student-pagination'>
                                        <ReactPaginate nextLabel={<div
                                            className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                            <span className='fs-14 fw-600 me-2'>Next</span>
                                            <AiOutlineArrowRight className='text-secondary' size={20} />
                                        </div>}
                                            breakLabel='...'
                                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                <span className='fs-14 fw-600'>Previous</span>
                                            </div>}
                                            pageRangeDisplayed={3}
                                            forcePage={currentPage}
                                            marginPagesDisplayed={3}
                                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                            breakClassName='page-item me-3 d-flex align-items-center'
                                            nextClassName='page-item next-item flex-grow-1 text-end'
                                            previousClassName='page-item prev-item flex-grow-1 me-3'
                                            disabledLinkClassName='btn disabled p-0 border-0'
                                            pageCount={totalEntry || 1}
                                            onPageChange={page => handlePagination(page)}
                                            containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm p-3 mt-3'
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>
                    <AddStudent show={addStudentAccountModal} handleClose={handleStudentAccountModal} singleData={singleData} size='lg'
                        fetchAcademyStudentList={fetchAcademyStudentList} />
                </div>
            </div>

            <div className="div" id='box-coach-mobile-only'>
                <div className='boxSection mt-2'>
                    <div>
                        <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                            {studentsList.map(item => (
                                <Col sm={12} className=''>
                                    <>
                                        <div className='coach-view-box'>
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div className="">
                                                    <p className='fs-14 fw-600 mb-0'>{item?.name}</p>
                                                </div>
                                                <div className=''>
                                                    <XSquare size={15} onClick={() => handleDeleteModal(item)} />
                                                </div>
                                            </div>
                                            <div className='detail my-2'>
                                                <div className="my-2">
                                                    <p className='mb-0'><span>{item?.gender}</span> &nbsp; <span>Age:
                                                        {`${moment().diff(item?.dob, 'years')}`} </span></p>
                                                </div>
                                                <div className="my-2">
                                                    <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{item?.phone}</span></p>
                                                </div>

                                            </div>


                                        </div>
                                    </>
                                </Col>

                            ))}
                        </Row>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Student</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        No
                    </Button>
                    <Button variant="primary" className='text-light' onClick={() => handleDelete()}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='clearfix'></div>
        </div>
    </>
};

export default Students;