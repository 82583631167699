import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { programme, sessionImage, students, coachImage } from "../../assets/images/index";

// API Service
import { allCountService } from '../../service/guestHomeService';

// Redux-Functions
import { selectCountList, setCount } from "../../redux/slices/guestHomeSlice"

const BoxSection = () => {
    const dispatch = useDispatch()
    const allCount = useSelector(selectCountList);

    /**
         * @function fetchCustomerCountList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerCountList = async () => {
        try {
            const result = await allCountService();
            if (result?.data?.status) {
                dispatch(setCount(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerCountList()
        // eslint-disable-next-line
    }, [])

    /**
        @function convertNumber
        @params n
        @description convert the number into short form
    */
    const convertNumber = (n) => {
        if (n < 1e3) {
            return n;
        }
        if (n >= 1e3 && n < 1e6) {
            // return +(n / 1e3).toFixed(1) + "K";
            return `${(n / 1e3).toFixed(1)} K`;
        }
        if (n >= 1e6 && n < 1e9) {
            return `${(n / 1e6).toFixed(1)} M`;
        }
        if (n >= 1e9 && n < 1e12) {
            return `${(n / 1e9).toFixed(1)} B`;
        }
        if (n >= 1e12) {
            return `${(n / 1e12).toFixed(1)} T`;
        }
    };
    
    const boxContents = [{
        image: programme,
        name: 'Programs',
        count: `${convertNumber(allCount?.program_count)}`
    },
    {
        image: sessionImage,
        name: 'Sessions',
        count: `${convertNumber(allCount?.session_count)}`
    },
    {
        image: coachImage,
        name: 'Coaches',
        count: `${convertNumber(allCount?.coaches_count)}`
    },
    {
        image: students,
        name: 'Students',
        count: `${convertNumber(allCount?.student_count)}`
    },]

    return <div>
        <div className='boxSection'>
            <Container>
                <Row className='boxTop gx-5'>
                    {boxContents?.length > 0 ? boxContents.map(item => (
                        <Col lg={3} md={6} sm={6}>
                            <div className="mobileBoxx">
                                <div className='box d-flex flex-row justify-content-center align-items-center mb-xs-3'>
                                    <div>
                                        <div className='image'>
                                            <img src={item?.image} alt='' />
                                        </div>
                                    </div>
                                    <div className='box-text'>
                                        <div className="box-title">
                                            <p className='fw-500'>{item?.name}</p>
                                            <h4 className='fw-600'>{item?.count}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )) : ''}

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default BoxSection;