import { requestEventsInRange, requestEventCreate, requestEventUpdate, requestEventDelete } from "./requests";

const toggleWeekends = () => {
    return {
        type: "TOGGLE_WEEKENDS",
    };
}

const requestEvents = (startStr, endStr) => {
    return (dispatch) => {
        return requestEventsInRange(startStr, endStr).then((plainEventObjects) => {
            dispatch({
                type: "RECEIVE_EVENTS",
                plainEventObjects,
            })
        })
    }
}

const createEvent = (plainEventObject) => {
    return (dispatch) => {
        return requestEventCreate(plainEventObject).then((newEventId) => {
            dispatch({
                type: "CREATE_EVENT",
                plainEventObject:
                {
                    id: newEventId,
                    ...plainEventObject,
                }
            })
        })
    }
}

const updateEvent = (plainEventObject) => {
    return (dispatch) => {
        return requestEventUpdate(plainEventObject).then(() => {
            dispatch({
                type: "UPDATE_EVENT",
                plainEventObject,
            });
        });
    };
}

const deleteEvent = (eventId) => {
    return (dispatch) => {
        return requestEventDelete(eventId).then(() => {
            dispatch({
                type: "DELETE_EVENT",
                eventId,
            });
        });
    };
}

const exportedObject = {
    toggleWeekends,
    requestEvents,
    createEvent,
    updateEvent,
    deleteEvent
}

export default exportedObject;
