import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    programmeList: [],
    myProgrammes: [],
    mySession: [],
    mainCoachList: [],
    subCoachList: [],
    categoryList: [],
    studentList: [],
    sessionList: [],
    paymentType: ''
}

export const programmeSlice = createSlice({
    name: "programmeSlice",
    initialState,
    reducers: {
        setPaymentType: (state, action) => {
            state.paymentType = action.payload
        },
        setProgrammeList: (state, action) => {
            state.programmeList = action.payload
        },
        setMyProgrammeList: (state, action) => {
            state.myProgrammes = action.payload
        },
        setMySession: (state, action) => {
            state.mySession = action.payload
        },
        setMainCoachList: (state, action) => {
            state.mainCoachList = action.payload
        },
        setSubCoachList: (state, action) => {
            state.subCoachList = action.payload
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setStudentList: (state, action) => {
            state.studentList = action.payload
        },
        setSessionList: (state, action) => {
            state.sessionList = action.payload
        },
    }
})
 
export const { setPaymentType, setProgrammeList, setMySession, setMyProgrammeList, setMainCoachList, setSubCoachList, setCategoryList, setStudentList, setSessionList } = programmeSlice.actions

export const selectProgrammeList = (state) => state.studentProgramme.programmeList
export const selectPaymentType = (state) => state.studentProgramme.paymentType
export const selectMyProgrammeList = (state) => state.studentProgramme.myProgrammes
export const selectMySession = (state) => state.studentProgramme.mySession
export const selectMainCoachList = (state) => state.studentProgramme.mainCoachList
export const selectSubCoachList = (state) => state.studentProgramme.subCoachList
export const selectCategoryList = (state) => state.studentProgramme.categoryList
export const selectStudentList = (state) => state.studentProgramme.studentList
export const selectSessionList = (state) => state.studentProgramme.sessionList

export default programmeSlice.reducer