import React, { useEffect, useState } from 'react'
import PaymentSuccessModal from '../../../../components/Modal/PaymentSuccessModal'
import { getSuccessDetailsStudent, myProgramsService } from '../../../../service/studentProgram';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
  const [paymentSuccessModalShow, setPaymentSuccessModalShow] = useState(true);
  const [successData, setSuccessData] = useState();
  const [studentCount, setStudentCount] = useState();
  const { receiptId } = useParams()

  console.log('receiptId', receiptId)

  /**
      * @function handleSuccessModalShow
      * @params ()
      * @description use To show modal
  */
  const handleSuccessModalShow = () => {
    setPaymentSuccessModalShow(!paymentSuccessModalShow)
  };

  /**
   * @function fetchCustomerProgramList
   * @params
   * @description fetch the list of customer banner
  */
  const fetchSuccessDetailsStudentData = async () => {
    try {
      const result = await getSuccessDetailsStudent({ id: receiptId });
      console.log('receiptId 1', result)

      if (result?.data?.status) {
        setSuccessData(result?.data?.data[0])
        setStudentCount(result?.data?.data?.length)
      } else {
        ErrorAlert(result?.response?.data?.message)
      }
    } catch (error) {
      ErrorAlert(error)
    }
  }

  useEffect(() => {
    if (receiptId) {
      fetchSuccessDetailsStudentData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptId]);

  return (
    <>
      {successData && <PaymentSuccessModal show={paymentSuccessModalShow} handleClose={handleSuccessModalShow} successData={successData} studentCount={studentCount} />}
    </>
  )
};

export default PaymentSuccess