import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom'
import classNames from "classnames";

import moment from 'moment';
import Select, { components, MultiValueRemoveProps } from 'react-select'
import Flatpickr from 'react-flatpickr'

// Custom Component
import CalendarCard from "./Card";
import GanttChartCalendar from "./calendarWithGanttChart";

const CalendarCardPage = (props) => {

    return <>
        <GanttChartCalendar />
        {/* <CalendarCard /> */}
    </>

};

export default CalendarCardPage;