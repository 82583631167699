import { Modal, Row, Col } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

// Custom Component
import { PrimaryButton } from '../Button';

// Images
import { Success } from '../../assets/images/svg';

const PaymentSuccessModal = (props) => {
    console.log('successData', props)
    /**
         * @function calculateSum
         * @params array, property
         * @description used to calculate sum of specific property ib array
         */
    const calculateSum = (array, property) => {
        const total = array.reduce((accumulator, object) => {
            return accumulator + object[property];
        }, 0);

        return total;
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 bg-success text-light px-0 py-5 position-relative'>
            <IoCloseOutline role='button' className='text-light position-absolute top-0 end-0 mt-2 me-2' size={20} onClick={props.handleClose} />
            <Success />
            <span className='fs-30 fw-600 mt-3 mb-2 text-light'>Awesome!</span>
            <span className='text-center text-light'>You have Successfully entered the Tournament AFA Badminton</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column'>
            <p className='text-primary text-start'>{props?.successData?.program?.academy?.name}</p>
            <div className='d-flex justify-content-between'>
                <p className='fs-18 fw-bold'>{props?.successData?.program?.name}</p>
                <p className='fs-18 fw-bold'>{`${props?.successData?.program?.sessions?.length} Sessions`}</p>
            </div>

            <div className='p-3 FooterBg'>
                <div className='d-flex justify-content-between'>
                    <p className='fs-14'>Original Price</p>
                    <p className='fs-14 fw-bold'>{`RM ${parseFloat(props?.successData?.amount / props?.studentCount).toFixed(2)}`}</p>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='fs-14 mb-0'>No. of Students</p>
                    <p className='fs-14 mb-0 fw-bold'>{`${props?.studentCount}`}</p>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <p className='fs-18 fw-bold'>Total Price</p>
                    <p className='fs-14 fw-bold'>{`RM ${props?.successData?.amount}`}</p>
                </div>
            </div>


            <Row className='mb-4 w-100 mt-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <Link to='/user/profile'>
                        <PrimaryButton className='fs-14 fw-500' onClick={props.handleClose}>History</PrimaryButton>
                    </Link>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
};

export default PaymentSuccessModal;