// Custom Component
import { PrimaryOutlineButton } from '../Button';

// Images
import { noProgramme } from "../../assets/images";

const NoProgram = (props) => {
    return <div className='d-flex flex-column justify-content-center align-items-center py-4'>
        <img className='ímg-fluid' src={noProgramme} width={150} alt='no-bank' />
        <span className='fs-18 fw-600 text-secondary text-center my-3'>You haven’t assigned programs yet!</span>
        <div>
            {/* <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={props?.handleAddBankAccountModal}>Home</PrimaryOutlineButton> */}
        </div>
    </div>
};

export default NoProgram;