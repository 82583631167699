// Custom Component
import { PrimaryOutlineButton } from '../Button';

// Images
import { noCoach } from "../../assets/images";

const NoCoach = (props) => {
    return <div className='d-flex flex-column justify-content-center align-items-center py-4'>
        <img className='ímg-fluid' src={noCoach} alt='no-bank' width='210' />
        <span className='fs-18 fw-600 text-secondary text-center my-3'>You haven’t created Access Manager yet!</span>
        <div>
            <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={props?.handleAddCoachModal}>Add Access Manager</PrimaryOutlineButton>
        </div>
    </div>
};

export default NoCoach;