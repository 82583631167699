import { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { allBankService, allBankAccountService, addBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList } from "../../redux/bankAccountSlice"

const AddBankAccountModal = (props) => {
    const dispatch = useDispatch();

    const [bankList, setBankList] = useState([])
    const {
        // register, reset, handleSubmit, formState: { errors }
        register, control, watch, setValue, reset, handleSubmit, formState: { errors }
    } = useForm({
        defaultValues: {
            name: ""
        },
        mode: "onBlur"
    });

    /**
         * @function fetchBankList
         * @params
         * @description fetch the list of bank
         */
    const fetchBankList = async () => {
        try {
            const result = await allBankService();
            if (result?.data?.status) {
                setBankList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchBankAccountList
         * @params
         * @description fetch the list of bank account
         */
    const fetchBankAccountList = async () => {
        try {
            const result = await allBankAccountService();
            if (result?.data?.status) {
                dispatch(setBankAccountList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankList();
    }, [])

    /**
         * @function onSubmit
         * @params formdata
         * @description used to add bank account for organizer
         */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('bank_id', formdata?.bank_id)
        data.append('holder_name', formdata?.holder_name)
        data.append('account_number', formdata?.account_number)
        try {
            const result = await addBankAccountService(data);
            if (result?.data?.status) {
                reset()
                fetchBankAccountList()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            Add Bank Account
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <div className='w-100'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {bankList?.length > 0 &&
                        <Form.Group>
                            <Form.Label className='fs-14 fw-500'>Bank Name</Form.Label>
                            <Form.Select
                                {...register("bank_id", {
                                    required: "Please provide the bank name"
                                })}
                            >
                                {bankList.map((item, index) => {
                                    return <option value={item?.id} key={index}>{item?.name}</option>
                                })}
                            </Form.Select>
                            {errors.bank_id && <ErrorText>{errors.bank_id.message}</ErrorText>}
                        </Form.Group>
                    }

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Bank Account number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter bank account number here"
                            {...register("account_number", {
                                required: "Please provide the account number"
                            })}
                        />
                        {errors.account_number && <ErrorText>{errors.account_number.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Bank account holder name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter bank account holder name here"
                            {...register("holder_name", {
                                required: "Please provide the bank account holder name"
                            })}
                        />
                        {errors.holder_name && <ErrorText>{errors.holder_name.message}</ErrorText>}
                    </Form.Group>

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5' type="submit">Add Account</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
};

export default AddBankAccountModal;