import { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { allBankService, allBankAccountService, updateBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList, selectBankAccountList } from "../../redux/bankAccountSlice"
import { sendLinkViaEmailService } from '../../service/academyCopyShareLink';
import SuccessAlert from '../Alert/SuccessAlert';

const EmailConfirmationModal = (props) => {
    const dispatch = useDispatch();
    const bankAccountList = useSelector(selectBankAccountList)
    
    const [bankList, setBankList] = useState([])
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    console.log('formdata', props);

    const onSubmit = async (formdata) => {
        console.log('formdata', formdata)
        if (true) {
            try {
                const data = new FormData()

                data.append('amount', props?.rowData?.pivot?.amount)
                data.append('program_id', props?.rowData?.programs[0]?.id)
                data.append('payment_method', props?.rowData?.pivot?.payment_type)
                data.append('student_ids[0]', props?.rowData?.id)
                data.append('frequency_type', props?.rowData?.pivot?.frequency_type)
                data.append('customer_id', props?.rowData?.customer_id)
              
                for (const value of data.values()) {
                    console.log('rowData data', value);
                };

                const result = await sendLinkViaEmailService(data);
                if (result?.data?.status) {
                    SuccessAlert('Link sent successfully')
                    props.handleClose()
                } else {
                    console.log('result?.data', result?.response?.data?.message)
                    ErrorAlert(result?.response?.data?.message)
                }
            
            } catch (error) {
            
            }
        } else {
            ErrorAlert('Payment is not completed.')
        }
    };
    
    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            Share Payment Link
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <div className='w-100'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='text-center'>
                        {/* <img src={props?.rowData?.profile_image} alt='profile-image' width='60' /> */}
                        <p className='fw-bold mb-0'>{props?.rowData?.customer?.name}</p>
                        <p className='fs-14 mb-0'>{props?.rowData?.customer?.email}</p>
                        <p className='fs-14 mb-0'>{props?.rowData?.customer?.phone}</p>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center mt-2'>
                        <p className='fw-bold'>Are you sure you want to proceed?</p>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5' type="submit">Proceed</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
};

export default EmailConfirmationModal;