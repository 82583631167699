import { useState, useEffect, Fragment } from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allTopCoachService, allCategoryService } from '../../service/guestHomeService';

// Redux-Functions
import { selectTopCoachList, selectSportList, setSportList, setTopCoachList } from "../../redux/slices/guestHomeSlice"
import ReactStars from 'react-rating-star-with-type'
import { Link } from 'react-router-dom';

const TopCoach = () => {
    const dispatch = useDispatch()
    const topCoachList = useSelector(selectTopCoachList);
    const sportList = useSelector(selectSportList);
    const [active, setActive] = useState('')
    const [indexWise, setIndex] = useState('')

    /**
       * @function fetchCustomerSportList
       * @params
       * @description fetch the list of customer banner
       */
    const fetchCustomerSportList = async () => {
        try {
            const result = await allCategoryService();
            if (result?.data?.status) {
                if (result?.data?.data.length > 0) {
                    dispatch(setSportList(result?.data?.data))
                    setActive(result?.data?.data[2]?.id)
                    setIndex(result?.data?.data[2]?.id)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const filterSport = (item, index) => {
        if (item?.id) {
            setActive(item?.id)
            setIndex(item?.id)
            fetchCustomerCoachList(item)
        }

    }

    /**
         * @function fetchCustomerCoachList
         * @params item
         * @description fetch the list of customer banner
         */
    const fetchCustomerCoachList = async (item) => {
        try {
            if (item?.id === 1) {
                const result = await allTopCoachService({ id: item?.id ? sportList[2].id : sportList[2].id });
                if (result?.data?.status) {
                    dispatch(setTopCoachList(result?.data?.data))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                const result = await allTopCoachService({ id: item?.id ? item?.id : sportList[2].id });
                if (result?.data?.status) {
                    dispatch(setTopCoachList(result?.data?.data))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            ErrorAlert(error)
        }
    }

    const fetchAutoCustomerCoachList = async (sportList) => {
        try {
            if (sportList) {
                const result = await allTopCoachService({ id: sportList[2].id });
                if (result?.data?.status) {
                    dispatch(setTopCoachList(result?.data?.data))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (sportList.length > 0) {
            fetchAutoCustomerCoachList(sportList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sportList])

    useEffect(() => {
        fetchCustomerSportList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const boxContents = [{
        image: post1Image,
        contents: 'Lorem ipsum dolor sit amet consectetur. Suspendisse.',
        session: 4,
        name: 'Stephen Robert',
        certificate: 'Professional Certificate',
        iconBook: sessionBook,
        iconUser: sessionUser,
    },
    {
        image: post2Image,
        contents: 'Lorem ipsum dolor sit amet consectetur. Suspendisse.',
        session: 4,
        name: 'Stephen Robert',
        certificate: 'Professional Certificate',
        iconBook: sessionBook,
        iconUser: sessionUser,
    },
    {
        image: post3Image,
        contents: 'Lorem ipsum dolor sit amet consectetur. Suspendisse.',
        session: 4,
        name: 'Stephen Robert',
        certificate: 'Professional Certificate',
        iconBook: sessionBook,
        iconUser: sessionUser,
    },
    {
        image: post4Image,
        contents: 'Lorem ipsum dolor sit amet consectetur. Suspendisse.',
        session: 4,
        name: 'Stephen Robert',
        certificate: 'Professional Certificate',
        iconBook: sessionBook,
        iconUser: sessionUser,
    }]

    console.log('topCoachList', topCoachList)

    return <div>
        <div className='boxSection boxSectionCoach mt-5 pt-4'>
            <Container>
                <Row className='box-programme gx-4'>

                    <Col xl={12} className='pt-2 mb-4 d-flex align-items-center justify-content-between'>
                        <div className=''><h1 className='fw-bold'>Explore top Coaches</h1></div>
                        <div className=''><Link to='/all/coach'><Button size='sm' variant='primary' className='text-light'>View All</Button></Link></div>
                    </Col>

                    <Col xl={12} className='mb-3'>
                        <div className=''>
                            <div className='filter-sport'>
                                <ul className={sportList?.length > 10 ? "filter-custom-scroll" : ''}>
                                    {sportList?.length > 0 ? sportList?.map((item, index) => (
                                        <li className={active && item?.id === indexWise ? 'custom-active' : ''} key={index} onClick={() => filterSport(item, index)}>{item?.name}</li>
                                    )) : ''}
                                </ul>
                            </div>
                        </div>
                    </Col>

                    {topCoachList.length > 0 ? topCoachList?.filter(item => item?.sub_coaches?.some(subItem => subItem?.is_popular))?.slice(0, 4)?.map((item, index) => {
                        return (
                            <Col lg={3} md={4} sm={12} className='no-use' key={index}>
                                {item?.sub_coaches?.filter(item => item?.is_popular)?.slice(0, 1)?.map((subItems, sIndex) => {
                                    return <>
                                        <div className='customBox h-100' key={sIndex}>
                                            <Card style={{}} className='h-100 session-box border-2 border-light'>
                                                <div className="program-card-image">
                                                    <Card.Img variant="top" src={subItems?.profile_image} />
                                                </div>
                                                <Card.Body className='d-flex flex-column'>
                                                    <Card.Text>
                                                        <div className="top-coaches-common-height fw-600">
                                                            {subItems?.description ? subItems?.description.substr(0, 60) : ''}
                                                        </div>
                                                    </Card.Text>
                                                    <Card.Title className='mb-4'>
                                                        <p className='fw-400' style={{ color: '#667085' }}>{subItems?.name}</p>
                                                    </Card.Title>
                                                    <Card.Text className='mt-auto'><div className='certificate'>Professional Certificate</div></Card.Text>

                                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                                        <div><p className='mb-0 fs-14 fw-bold'> {item?.sessions?.length} <span className='fw-bold'>Classes</span></p></div>
                                                        {/* <div><p className='mb-0 fs-14'> <img src={subItems?.iconUser} alt='coach' width='17' /> 20 <span>Students</span></p></div> */}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </>
                                })}
                            </Col>
                        );
                    }) : ''}

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default TopCoach;