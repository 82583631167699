import { useState, useEffect, Fragment } from 'react';
import { Modal, Form, Row, Col, Button, InputGroup, Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useForm } from "react-hook-form";
import Flatpickr from 'react-flatpickr';
import { PlusCircle, X, Edit3, Calendar } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Custom Component
import { PrimaryButton } from '../../../../components/Button';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { updateSessionService, singleProgramService } from '../../../../service/coachProgram';
import { selectFreshData, setFreshData } from "../../../../redux/coachProgrammeSlice";

const UpdateSessionModal = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const freshData = useSelector(selectFreshData);
    const [startDate, setStartDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [sessionList, setSession] = useState([]);
    // const [sessionList, setSession] = useState([{ time_slots: [], date: '', location: '', latitude: '', longitude: '', }]);
    const [address, setAddress] = useState();

    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: {
            time_slots: [], date: '', location: '', latitude: '', longitude: '',
        },
    })

    console.log('first', singleData, ',', sessionList, ',', new Date(singleData?.date), ',', new Date())
    console.log('first 1', sessionList)
    console.log('first 2', new Date(singleData?.date))
    console.log('first 3', new Date())
    useEffect(() => {
        if (singleData?.program_id) {
            const defaultValues = {};
            // const date = moment(singleData?.date).format('Y/m/d')
            const date = new Date(singleData?.date)
            setStartDate(date)
            setAddress(singleData?.location)
            const time_slots = []
            singleData?.timeslots.forEach((item, index) => {
                if (item.id) {
                    const obj = {
                        id: item?.id,
                        max: item?.max_pax,
                        startTime: moment(item?.start_time, 'hh:mm A').format('HH:mm'),
                        endTime: moment(item?.end_time, 'hh:mm A').format('HH:mm')
                    }
                    time_slots.push(obj)
                }
            })
            const temp = [...sessionList]
            setSession([{
                date: new Date(singleData?.date),
                location: singleData?.location,
                latitude: singleData?.latitude,
                longitude: singleData?.longitude,
                time_slots
            }])

            reset({ ...defaultValues })

        } else {
            reset()
        }
        // eslint-disable-next-line
    }, [singleData, props?.show])

    /**
        * @function fetchSingleProgram
        * @params
        * @description fetch the list of customer banner
    */
    const fetchSingleProgram = async () => {
        try {
            console.log('fetchSingleProgram 7')

            const result = await singleProgramService({ id: singleData?.program_id });
            if (result?.data?.status) {
                // setLoading(false)
                // setProgramList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (singleData?.program_id) {
            fetchSingleProgram()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to create session
    */
    const onSubmit = async (formdata) => {
        console.log('sessionListing for', singleData, formdata, sessionList)
        try {
            const data = new FormData()
            data.append('program_id', singleData.program_id)
            data.append('sessions[0][id]', singleData.id)
            data.append('_method', 'PUT')
            if (sessionList?.length > 0) {
                sessionList.forEach((element, index) => {
                    data.append(`sessions[${index}][date]`, moment(element.date).format('YYYY-MM-DD'))
                    data.append(`sessions[${index}][location]`, element.location)
                    data.append(`sessions[${index}][latitude]`, element.latitude)
                    data.append(`sessions[${index}][longitude]`, element.longitude)
                    element.time_slots.forEach((element2, index2) => {
                        if (element2.id) {
                            data.append(`sessions[${index}][timeslots][${index2}][id]`, element2.id)
                        }
                        data.append(`sessions[${index}][timeslots][${index2}][max_pax]`, element2.max)
                        data.append(`sessions[${index}][timeslots][${index2}][start_time]`, element2.startTime)
                        data.append(`sessions[${index}][timeslots][${index2}][end_time]`, element2.endTime)
                    })
                })
            }
            for (const value of data.values()) {
                console.log('data', value);
            };

            const result = await updateSessionService({ id: props?.programID, data });
            if (result?.data?.status) {
                SuccessAlert('Session updated successfully')
                dispatch(setFreshData(!freshData))
                setSession([])
                reset()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }


    const handleRemoveSession = (index1) => {
        const temp = [...sessionList]
        const deleteVal = [...temp]
        deleteVal.splice(index1, 1)
        setSession(deleteVal)
    }

    const handleAddItem = (index1) => {
        const temp = [...sessionList]
        if (temp[index1].time_slots?.length > 0) {
            temp[index1].time_slots = [...temp[index1].time_slots, { max: '', startTime: '', endTime: '' }]
        } else {
            temp[index1].time_slots = [{ max: '', startTime: '', endTime: '' }]
        }
        setSession(temp)
    }

    const handleRemoveItem = (index1, index2) => {
        const temp = [...sessionList]
        if (temp[index1].time_slots?.length > 0) {
            const deleteVal = [...temp[index1].time_slots]
            deleteVal.splice(index2, 1)
            temp[index1].time_slots = deleteVal
            setSession(temp)
        } else {
            setSession(temp)
        }
    }

    const handleStartDateChange = (date, index1) => {
        const startDate = date.length > 0 ? moment(date[0]).format('YYYY-MM-DD') : ''
        const temp = [...sessionList]
        const name = 'date'
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = startDate
            setSession(temp)
        } else {

        }
    }

    const handleSelectLocation = async (index1, address) => {
        setAddress(address)
        const temp = [...sessionList]
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            const name = 'location'
            const latitude = 'latitude'
            const longitude = 'longitude'
            if (temp?.length > 0 && temp[index1]) {
                temp[index1][name] = address
                temp[index1][latitude] = latAndLang?.lat
                temp[index1][longitude] = latAndLang?.lng
                setSession(temp)
            } else {
                setSession(temp)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddressChange = (index1, address) => {
        setAddress(address)
        const temp = [...sessionList]
        try {
            const name = 'location'
            if (temp?.length > 0 && temp[index1]) {
                temp[index1][name] = address
                setSession(temp)
            } else {
                setSession(temp)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleMaxChange = (e, index1, index2) => {
        const temp = [...sessionList]
        const { name, value } = e?.target
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = value
            setSession(temp)
        } else {
            setSession(temp)
        }
    }

    const handleStartTimeChange = (date, index1, index2) => {
        const startTime = date.length > 0 ? moment(date[0]).format('HH:mm') : ''
        const temp = [...sessionList]
        const name = 'startTime'
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = startTime
            setSession(temp)
        } else {
            setSession(temp)
        }
    }

    const handleEndTimeChange = (date, index1, index2) => {
        const endTime = date.length > 0 ? moment(date[0]).format('HH:mm') : ''
        const temp = [...sessionList]
        const name = 'endTime'
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = endTime
            setSession(temp)
        }
    }


    return <>

        <Modal show={props?.show} onHide={props?.handleClose} size='lg' centered>
            <Modal.Header className='fs-20 fw-bold border-0 pt-4 px-5' closeButton >
                Update Program Session
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center px-5'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xl={12} className='mb-2'>
                            <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                                <div><h1 className='fs-18 text-secondary'>Frequency</h1></div>
                                <div><h1 className='fs-18 text-secondary'>Monthly 4 Sessions</h1></div>
                            </div>
                        </Col>
                        <Col xl={12} className='pt-3 mb-2'>
                            <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-row add-session-button'>
                                <div><h1 className='fs-18 fw-600'>Sessions</h1></div>
                            </div>
                        </Col>
                    </Row>

                    <div className="frequency-list mb-2">

                        {/* {sessionList.map((item, index) => {
                        return (
                            <ul key={`ul-${index}`}>
                                {item?.frequency.map((subItems, sIndex) => {
                                    return <li key={`li-${sIndex}`}> {subItems} </li>;
                                })}
                            </ul>
                        );
                    })} */}

                        {sessionList?.length > 0 && sessionList?.map((item, index1) => {
                            return (
                                <Fragment>
                                    <div className="frequency-list mb-3" key={`${index1}`}>
                                        <div className="first">
                                            <div className='d-flex justify-content-between align-items-center align-items-start flex-row'>
                                                <h1 className='fs-16 fw-600'>{`Session ${index1 + 1}`}</h1>
                                                <div className="cancel float-end text-primary cursor-pointer"><X size={22} onClick={(e) => handleRemoveSession(index1)} /> </div>
                                            </div>
                                            <div className="session">
                                                <Form.Group className="my-2">
                                                    <Form.Label className='fs-14 fw-600'>Date</Form.Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className='form-control'
                                                            required
                                                            value={item?.date}
                                                            id='timepicker'
                                                            name='date'
                                                            options={{
                                                                static: true,
                                                                dateFormat: 'Y-m-d',
                                                                allowInput: true,
                                                                enableTime: false,
                                                                // minDate: "today"
                                                            }}
                                                            onChange={(date) => handleStartDateChange(date, index1)}
                                                        />
                                                        <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group className="my-2">
                                                    <Form.Label className='fs-14 fw-600'>Location</Form.Label>
                                                    <div className='mb-1'>
                                                        <PlacesAutocomplete
                                                            // value={address}
                                                            value={item?.location}
                                                            // defaultValue={item?.location}
                                                            onChange={(address) => handleAddressChange(index1, address)}
                                                            onSelect={(address) => handleSelectLocation(index1, address)}
                                                        // onChange={ () => handleAddressChange()}
                                                        // onSelect={ () => handleSelectLocation(index1)}
                                                        // debounce={300}
                                                        >
                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                <div>

                                                                    <InputGroup className='input-group-merge search-address1'>
                                                                        <Form.Control
                                                                            id={props.id}
                                                                            required
                                                                            name='location'
                                                                            {...getInputProps({
                                                                                placeholder: 'Search location',
                                                                                className: 'location-search-input',
                                                                            })}
                                                                        />
                                                                    </InputGroup>
                                                                    <div className="autocomplete-dropdown-container">
                                                                        {loading && <div className='w-100 text-center'>
                                                                            <Spinner animation="border" />
                                                                        </div>
                                                                        }
                                                                        {suggestions.map(suggestion => {
                                                                            return <div className='mt-1' {...getSuggestionItemProps(suggestion)}>
                                                                                <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PlacesAutocomplete>

                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="child">
                                            <div className="frequency-content pt-4">
                                                <Row className='mb-2'>
                                                    <Col md={12}>
                                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                                            <div className="add-title text-dark fs-14 fw-600"> <span></span> <span>Time Slot</span></div>
                                                            <div className="add-content text-primary fs-14 fw-600 cursor-pointer">
                                                            </div>
                                                        </div>

                                                    </Col>
                                                </Row>
                                                {/* copy and paste here original script */}
                                                {item?.time_slots?.map((object, index2) => <Fragment>
                                                    <div className="inputs-fields" key={`child${index1}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="cancel-mobile">
                                                                    <div className="cancel float-end text-primary cursor-pointer">
                                                                        <X size={22} onClick={(e) => handleRemoveItem(index1, index2)} />
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col md={3} sm={12}>
                                                                <div className="custom-number">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Max Pax</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Enter number here"
                                                                            required="number is required"
                                                                            value={object?.max}
                                                                            name='max'
                                                                            onChange={(e) => handleMaxChange(e, index1, index2)}

                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>

                                                            <Col md={4} sm={12}>
                                                                <div className="custom-number time-selection">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Starting time</Form.Label>

                                                                        <Flatpickr
                                                                            className='form-control'
                                                                            value={object?.startTime ? object?.startTime : startTime}
                                                                            defaultValue={object?.startTime}
                                                                            id='timepicker'
                                                                            required="start time is required"
                                                                            name='startTime'
                                                                            options={{
                                                                                static: true,
                                                                                altInput: true,
                                                                                allowInput: true,
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat: "H:i",
                                                                                maxTime: sessionList[index1]?.time_slots[index2]?.endTime ? sessionList[index1]?.time_slots[index2]?.endTime : null,
                                                                            }}
                                                                            // onChange={date => setStartDate(date)}
                                                                            onChange={(date) => handleStartTimeChange(date, index1, index2)}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <div className="custom-price time-selection">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Ending time</Form.Label>
                                                                        <Flatpickr
                                                                            className='form-control'
                                                                            value={object?.endTime ? object?.endTime : endTime}
                                                                            defaultValue={object?.endTime}
                                                                            id='timepicker'
                                                                            required="end time is required"
                                                                            name='endTime'
                                                                            options={{
                                                                                static: true,
                                                                                altInput: true,
                                                                                allowInput: true,
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat: "H:i",
                                                                                minTime: sessionList[index1]?.time_slots[index2]?.startTime ? sessionList[index1]?.time_slots[index2]?.startTime : null,
                                                                            }}
                                                                            // onChange={date => setStartDate(date)}
                                                                            onChange={(date) => handleEndTimeChange(date, index1, index2)}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={1}>
                                                                <div className="cancel-web">
                                                                    <div className="cancel float-end text-primary cursor-pointer">
                                                                        <X size={22} onClick={(e) => handleRemoveItem(index1, index2)} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Fragment>
                                                )}
                                            </div>

                                        </div>
                                        <Row className='pt-2 mb-2'>
                                            <Col md={12}>
                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                    <div className="add-title text-dark fs-14 fw-600"></div>
                                                    <div className="add-content text-primary fs-14 fw-600 cursor-pointer">
                                                        <p className={`${index1}`} onClick={() => handleAddItem(index1)}><span><PlusCircle className='text-primary' size={15} /></span> <span className='fw-600 fs-12 text-primary'> Add more time slot</span></p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </Fragment>
                            )

                        })}

                    </div>

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-600 px-5 custom-hover' type="submit">Save</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>
};

export default UpdateSessionModal;