import axios from "axios"

/**
    * @function ganttChartCalendarService
    * @param
    * @description This function is used to get all dat for gantt chart
*/
export const ganttChartCalendarService = async (date) => {
    try {
        const result = await axios.get(`/academy/calender?date=${date}`)
        return result;
    } catch (error) {
        return error;
    }
}
