import { Navbar, Nav, OverlayTrigger, Popover, Row, Col } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineUser } from "react-icons/hi";
import { AiOutlineMenu, AiOutlineHeart } from "react-icons/ai";
import { FiSearch, FiLock } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import classNames from "classnames";
import Select from 'react-select'

// Custom Component
import { PrimaryButton } from "../Button";
import SearchInput from "../SearchInput/SearchInput";
import ErrorAlert from "../Alert/ErrorAlert";

// Images
import { logo } from "../../assets/images";
import { Avatar } from '../../assets/images/svg'

// Utility Service
import { removeUserSession } from '../../utils/AuthService';

// API Service
import { logoutService, academyLogoutService, coachLogoutService } from '../../service/auth.service';

// Redux-Functions
import { setUser, setToken, selectUser } from "../../redux/userSlice"
import { Fragment, useState } from "react";
// utils
import { selectThemeColors } from "../../utils/Utils";
import NotificationIcon from "../../assets/images/svg/notificationicon";

const Header = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedLanguage, setLanguage] = useState()
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const languages = [{ label: 'English', value: 'en' }, { label: 'Malay', value: 'ml' }]

    /**
        * @function handleLanguage
        * @params(e)
        * @description used to change language
    */
    const handleLanguage = (e) => {
        const myLang = e?.value
        setLanguage(myLang)
    }

    /**
        * @function logout
        * @params
        * @description used to logout the user
    */
    const logout = async () => {
        try {
            let result;
            if (user?.type === 'customer') {
                result = await logoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else if (user?.type === 'coach') {
                result = await coachLogoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                result = await academyLogoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <Navbar bg="light" className={classNames("navbar shadow-sm p-3 bg-white", { "d-none": props.authenticatedRoute.includes(location.pathname) })} expand>
        <AiOutlineMenu role='button' className="d-lg-none" onClick={props.toggle} />
        <img className="img-fluid d-lg-none ms-2" src={logo} alt="AFA" width={105} />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-none d-lg-block flex-grow-1 mx-4 mx-lg-0">
                <SearchInput className="py-point-1_5" placeholder='Search Program...' />
            </div>
            <Nav className="ms-auto">
                <div className="d-flex d-lg-none align-items-center">
                    <FiSearch className="text-secondary" size={28} />
                </div>
                <Select
                    theme={selectThemeColors}
                    className='react-select text-start headerButton ms-2'
                    classNamePrefix='select'
                    defaultValue={selectedLanguage}
                    name='clear'
                    options={languages}
                    placeholder='English'
                    onChange={handleLanguage}
                />
                <NavLink to="#" className="d-flex align-items-center mx-2 border rounded px-2">
                    {/* <AiOutlineHeart className="text-secondary" size={28} /> */}
                    <NotificationIcon/>
                </NavLink>
                {user ? <OverlayTrigger trigger="click" placement='bottom' rootClose overlay={<Popover className='profile-popover'> <Popover.Body>
                    {/* My account */}
                    {user?.type === 'academy' ? <Fragment>
                        <Row className='mb-3' onClick={() => {
                            navigate('/academy/profile')
                            document.body.click()
                        }}>
                            <Col xs={3} className='text-center pe-0'>
                                <HiOutlineUser className="text-secondary" size={20} />
                            </Col>
                            <Col xs={9} className='px-0'>
                                <span role='button' className='fs-14 fw-400'>View profile</span>
                            </Col>
                        </Row>
                    </Fragment> : ''}

                    {user?.type === 'customer' ? <Fragment>
                        <Row className='mb-3' onClick={() => {
                            navigate('/user/profile')
                            document.body.click()
                        }}>
                            <Col xs={3} className='text-center pe-0'>
                                <HiOutlineUser className="text-secondary" size={20} />
                            </Col>
                            <Col xs={9} className='px-0'>
                                <span role='button' className='fs-14 fw-400'>View profile</span>
                            </Col>
                        </Row>
                    </Fragment> : ''}
                    {user?.type === 'coach' ? <Fragment>
                        <Row className='mb-3' onClick={() => {
                            navigate('/coach/profile')
                            document.body.click()
                        }}>
                            <Col xs={3} className='text-center pe-0'>
                                <HiOutlineUser className="text-secondary" size={20} />
                            </Col>
                            <Col xs={9} className='px-0'>
                                <span role='button' className='fs-14 fw-400'>View profile</span>
                            </Col>
                        </Row>
                    </Fragment> : ''}

                    {/* Favorite */}
                    {/* <Row className='mb-3' onClick={() => navigate('#')}>
                        <Col xs={3} className='text-center pe-0'>
                            <AiOutlineHeart className="text-secondary" size={20} />
                        </Col>
                        <Col xs={9} className='px-0'>
                            <span role='button' className='fs-14 fw-400'>Favorite</span>
                        </Col>
                    </Row> */}

                    {/* Change Password */}
                    {user?.type === 'customer' &&
                        <Row className='mb-3' onClick={() => navigate('/change-password')}>
                            <Col xs={3} className='text-center pe-0'>
                                <FiLock className="text-secondary" size={20} />
                            </Col>
                            <Col xs={9} className='px-0'>
                                <span role='button' className='fs-14 fw-400'>Change Password</span>
                            </Col>
                        </Row>
                    }
                    <hr />
                    {/* Log Out */}
                    <Row className='mb-2'>
                        <Col xs={3} className='text-center pe-0'>
                            <IoMdLogOut className="text-danger" size={20} />
                        </Col>
                        <Col xs={9} className='px-0'>
                            <span role='button' className='fs-14 fw-400 text-danger' onClick={logout}>Logout</span>
                        </Col>
                    </Row>
                </Popover.Body>
                </Popover>} >
                    <div role='button' className="d-flex justify-content-center align-items-center">
                        {user?.type === 'academy' && user?.profile_image ? <img className='profile-small-photo rounded-circle me-2' src={user?.profile_image} alt={user?.name} /> : user?.type === 'coach' && user?.profile_image ? <img className='profile-small-photo rounded-circle me-2' src={user?.profile_image} alt={user?.name} /> : <Avatar className="me-2" size={30} />}
                        {/* <span className="fw-600 text-secondary">{user?.type === 'customer' ? user?.username : user?.name}</span> */}
                    </div>
                </OverlayTrigger> : <NavLink to="/login"> <PrimaryButton>Sign In</PrimaryButton></NavLink>}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
}

export default Header;