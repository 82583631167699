import axios from "axios"

/**
    * @function addStudentService
    * @param {id, data}
    * @description This function is used to add student in programme
*/
export const addStudentService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/customer/student/programs/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allBannerService
    * @param
    * @description This function is used to get all bank
*/
export const allBannerService = async () => {
    try {
        const result = await axios.get(`/customer/home`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allProgramsService
    * @param
    * @description This function is used to get all bank
*/
export const allProgramsService = async () => {
    try {
        const result = await axios.get(`/customer/programs/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function myProgramsService
    * @param
    * @description This function is used to get all bank
*/
export const myProgramsService = async () => {
    try {
        const result = await axios.get(`/customer/my-programs/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSuccessDetailsStudent
    * @param
    * @description This function is used to get Success Details for Student
*/
export const getSuccessDetailsStudent = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/student/details/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getStudentSession
    * @param {data}
    * @description This function is used to add student in programme
*/
export const joinStudentSession = async ({ data }) => {
    try {
        const result = await axios.post(`/customer/session/join`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getStudentSession
    * @param
    * @description This function is used to get all bank
*/
export const getStudentSession = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/session/details/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function studentCheckoutService
    * @param {data}
    * @description This function is used to add student in programme
*/
export const studentCheckoutService = async ({ data }) => {
    try {
        const result = await axios.post(`/customer/payment/create-payment`, data)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function singleProgramService
    * @param
    * @description This function is used to get all bank
*/
export const singleProgramService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/programs/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleMyProgramService
    * @param
    * @description This function is used to get single My Program Service
*/
export const singleMyProgramService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/my-programs/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCategoryService
    * @param
    * @description This function is used to get all bank account
*/
export const allCategoryService = async () => {
    try {
        const result = await axios.get(`/customer/sports/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function allTopCoachService
    * @param
    * @description This function is used to get all bank account
*/
export const allTopCoachService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/home/top-coaches/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getCheckoutStudents
    * @param
    * @description This function is used to get all bank
*/
export const getCheckoutStudents = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/my-programs/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getJustAddedStudents
    * @param
    * @description This function is used to get all bank
*/
export const getJustAddedStudents = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/student/checkout-list/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function studentDeleteFromProgramService
    * @param
    * @description This function is used to get delete
*/
export const studentDeleteFromProgramService = async ({ id }) => {
    try {
        const result = await axios.delete(`/customer/student/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function studentRatingService
    * @param {id, data}
    * @description This function is used to add student in programme
*/
export const studentRatingService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/customer/review/add`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getStudentRatingService
    * @param
    * @description This function is used to get all bank account
*/
export const getStudentRatingService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/review`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteAccountSelf
    * @param
    * @description This function is used to delete account
*/
export const deleteAccountSelf = async ({ id }) => {
    try {
        const result = await axios.delete(`/customer/auth/delete-account`)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function getRelatedPrograms
    * @param
    * @description This function is used to get all bank
*/
export const getRelatedPrograms = async () => {
    try {
        const result = await axios.get(`/customer/my-programs/related`)
        return result;
    } catch (error) {
        return error;
    }
}