import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Image } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
// third party component
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import toast from 'react-hot-toast'
import { LuEye, LuEyeOff } from "react-icons/lu";
// Custom Component
import ErrorText from '../../TextField/ErrorText'
import { PrimaryButton } from '../../Button';
 
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker'
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';

// Images
import { uploadImage } from '../../../assets/images/index'

// API Service
import { addAccessService, accessListService } from '../../../service/accessAcademy.service';
import { selectUser } from "../../../redux/userSlice"
import { setDataList, setFreshList, setTotalEntry, selectDataList, selectFreshData, selectTotalEntry, } from "../../../redux/accessAcademySlice"

const CreateAccessManager = (props) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const user = useSelector(selectUser);
    const [profile_Image, setFile] = useState(null);

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            password: '',
            password_confirmation: ''
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    const selectImages = async (event) => {
        const checkSize = event.target.files[0]
        const myData = event.target.files[0]
        // Check if any file is selected.
        if (checkSize?.size) {
            const fileSize = checkSize.size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                toast.danger('File too Big, please select a file less than 2mb')
                // $(file).val(''); //for clearing with Jquery
            } else {
                // Proceed further
                setFile(myData)
            }

        }
    }

    const handleCloseModal = () => {
        setFile()
        reset()
        props.handleClose()
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to create access manager
         */
    const onSubmit = async (formdata) => {
        try {
            // if (!profile_Image) {
            //     ErrorAlert('You need to upload a profile image')
            //     return
            // }
            const data = new FormData()
            if (profile_Image) {
                data.append('profile_pic', profile_Image)
            }
            data.append('name', formdata?.name)
            data.append('email', formdata?.email)
            data.append('phone', formdata?.phone)
            data.append('academy_id', user?.id)
            data.append('password', formdata?.password)
            data.append('password_confirmation', formdata?.password_confirmation)
            data.append('description', formdata?.description)

            const result = await addAccessService(data);
            if (result?.data?.status) {
                SuccessAlert('Access manger added successfully')
                dispatch(setFreshList(true))
                props?.fetchAcademyCoachList(1)
                reset()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return <Modal show={props.show} onHide={() => handleCloseModal()} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0 px-5 pt-3' closeButton >
            Add New Access Manager
        </Modal.Header>
        <Modal.Body className='px-5'>
            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="col-12">
                    {/* <Form.Label className='fs-14 fw-500'>Coach Type</Form.Label> */}
                    <div className='mb-2 product'>
                        <label htmlFor="upload-button" class="drop-container" >
                            {profile_Image ? <Image className="img mb-3" src={URL.createObjectURL(profile_Image)} roundedCircle={true} width='100' height='100' alt='profile' /> : <img className="img mb-3" src={uploadImage} width='100' alt='profile' />}

                            <br />
                            <span class="drop-title text-primary fs-14">Add Profile Image</span>
                            
                        </label>
                        <input id="upload-button" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={selectImages}
                            style={{ display: 'none' }} />
                    </div>
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Access Manager Name</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete='nope'
                        placeholder="Enter name here"
                        {...register("name", {
                            required: "Please provide the name",
                        })}
                    />
                    {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="number"
                        autoComplete='nope'
                        placeholder="Enter phone number here"
                        {...register("phone", {
                            required: "Please provide the phone number",
                        })}
                    />
                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        autoComplete='nope'
                        placeholder="Enter email here"
                        {...register("email", {
                            required: "Please provide the email address",
                        })}
                    />
                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password', {
                                required: "Please provide the password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                </Form.Group>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Confirm Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password_confirmation', {
                                required: "Please provide the confirm password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                </Form.Group>

                <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Add</PrimaryButton>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
};

export default CreateAccessManager;