import { useState, useEffect } from 'react';
import ProfileUpdate from './Profile'
import ChangePassword from '../ChangePassword/ChangePassword'
import MyProgrammes from "../../../../components/Student/MyProgrammes";
import PrivacyPolicy from '../../../Common/Contents/PrivacyPolicy/PrivacyPolicy'
// import MyProgrammes from './MyProgrammes'
import Logout from './logout'
import DeleteAccount from './deletdAccount'

const tabData = {
    data: [{
        expData: {
            company: "Personal Information",
            content: <ProfileUpdate />,
        }
    },
    {
        expData: {
            company: "My Programs",
            content: <MyProgrammes />,
        }
    },
    {
        expData: {
            company: "Notification",
            // content: <ProfileUpdate />,
            content: <p className='text-center mt-5'>No Notification Available Now</p>,
        }
    },
    // {
    //     expData: {
    //         company: "Change Password",
    //         content: <ChangePassword />,
    //     }
    // },
    // {
    //     expData: {
    //         company: "Privacy",
    //         content: <a href='/privacy-policy' target='_blank'>Privacy</a>,
    //         // <PrivacyPolicy />
    //         // <a href='/privacy-policy' target='_blank'>Privacy</a>,
    //     }
    // },
    {
        expData: {
            company: "Close Account",
            content: <p className='text-center mt-5'>Cooming Soon . . .</p>,
        }
    }]
    // {
    //     expData: {
    //         company: "Logout",
    //         content: <Logout check={true} />,
    //     }
    // }]
}
// content: <Logout check={true} onClick={() => setShow(true)} />,
// const LogoutModal = () => {
//     const [show, setShow] = useState(true) 


//     return tabData
// };

export default tabData;