import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Edit3 } from 'react-feather';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from "../../../../components/Button";
import ErrorText from '../../../../components/TextField/ErrorText';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { Avatar } from '../../../../assets/images/svg';

// API Service
import { allCountryService } from '../../../../service/country.service';
import { updateProfileImageService, getProfileDetails, updateProfileService } from '../../../../service/profile.service';

// Redux-Functions
import { setUser, selectUser } from "../../../../redux/userSlice";

const Profile = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const user = useSelector(selectUser);
    const [studentProfile, setProfile] = useState('')

    const [countryList, setCountryList] = useState([])
    const [profileImage, setProfileImage] = useState(null);

    const { register, control, setValue, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchProfile
        * @params
        * @description fetch profile data
    */
    const fetchProfile = async () => {
        try {
            const result = await getProfileDetails();
            if (result?.data?.status) {
                setLoading(false)
                setProfile(result?.data?.data)
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }
    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchCountryList();
        fetchProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // for setting default value while editing address
    useEffect(() => {
        if (user && studentProfile && countryList) {
            const defaultValues = {};
            if (user?.type === 'customer') {
                defaultValues.username = studentProfile?.username
                setProfileImage(studentProfile?.profile_pic)
            }
            defaultValues.country_id = studentProfile?.country_id
            defaultValues.phone = studentProfile?.phone
            defaultValues.email = studentProfile?.email
            defaultValues.gender = studentProfile?.gender
            reset({ ...defaultValues })
        } else {
            reset()
            setProfileImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, studentProfile, countryList])

    /**
        * @function removePhoto
        * @params
        * @description used for remove profile photo
    */
    const removePhoto = async () => {
        setProfileImage()
    }

    /**
        * @function handleSelect
        * @params address
        * @description used for selecting the address
    */
    const handleSelect = async (address) => {
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            setValue('address', address);
            setValue('latitude', latAndLang.lat);
            setValue('longitude', latAndLang.lng);
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
        * @function handleEdit
        * @params address
        * @description used for show edit button
    */
    const handleEdit = () => {
        setEdit(!isEdit)
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to update profile
    */
    const onSubmit = async (formdata) => {
        if (isEdit) {
            try {
                let result;
                if (user?.type === 'customer') {
                    const data = new FormData()
                    data.append('username', formdata?.username)
                    data.append('email', formdata?.email)
                    data.append('phone', formdata?.phone)
                    data.append('country_id', formdata?.country_id)
                    data.append('gender', formdata?.gender)
                    if (profileImage instanceof File) {
                        const imageData = new FormData()
                        imageData.append('profile_pic', profileImage)
                        await updateProfileImageService(imageData)
                    } else if (!profileImage) {
                        await updateProfileImageService({})
                    }
                    result = await updateProfileService(data);
                    if (result?.data?.status) {
                        setEdit(false)
                        SuccessAlert(result?.data?.message)
                        fetchProfile()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('Please click on edit account before submit')
        }

    }

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }

    return <HelmetProvider>
        <Helmet>
            <title>Student Profile | AFA Coaching</title>
        </Helmet>

        <Row className='mb-2'>
            <p className='fs-25 fw-600 mt-4 p-0'>Personal Information</p>
            <Col className='profile-background' lg={8}>
                <Form className='mt-4 px-3' onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <span className='fs-18 fw-600'>&nbsp;</span>
                        <div>
                            {isEdit ? <PrimaryButton type='submit'>Save Changes</PrimaryButton> : <span onClick={handleEdit} className='fs-14 custom-hover'><Edit3 size={17} /> Edit Account</span>}
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        {profileImage instanceof File ? <img className='profile-photo rounded-circle' src={URL.createObjectURL(profileImage)} alt={user?.username || user?.name} /> : profileImage ? <img className='profile-photo rounded-circle' src={profileImage} alt={user?.username || user?.name} /> : <Avatar />}
                        {isEdit ? <div className='d-flex align-items-center mt-3'>
                            <input className='d-none' type='file' id="profilePhotoUpload" accept="image/*" onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setProfileImage(e.target.files[0])
                                }
                            }} onClick={(e) => e.target.value} />
                            <label htmlFor="profilePhotoUpload">
                                <span role='button' className='fs-14 fw-500 text-light bg-primary py-2 px-3 rounded' disabled={isEdit === false}>Change Photo</span>
                            </label>
                            <div className='ms-3'>
                                <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={removePhoto} disabled={isEdit === false}>Remove Photo</PrimaryOutlineButton>
                            </div>
                        </div> : ''}
                    </div>
                    {user?.type === 'customer' &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Full Name*</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={isEdit === false}
                                placeholder="Enter Full Name"
                                {...register("username", {
                                    required: "Please provide the full name",
                                    message: "Invalid username"
                                })}
                            />
                            {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                        </Form.Group>
                    }
                    {user?.type === 'organizer' &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Full Name*</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={isEdit === false}
                                placeholder="Enter Username"
                                {...register("name", {
                                    required: "Please provide the full name"
                                })}
                            />
                            {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                        </Form.Group>
                    }
                    {countryList.length > 0 &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                            <InputGroup className='rounded'>
                                <Form.Select
                                    disabled
                                    {...register("country_id")}
                                >
                                    {countryList.map((item, index) => {
                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                    })}
                                </Form.Select>
                                <Form.Control
                                    className='user-profile-edit-phone'
                                    type="tel"
                                    disabled
                                    placeholder="Enter Phone number"
                                    {...register("phone", {
                                        required: "Please provide the phone"
                                    })}
                                />
                            </InputGroup>
                            {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                        </Form.Group>
                    }
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                        <Form.Control
                            type="email"
                            disabled
                            placeholder="Enter email"
                            {...register("email", {
                                required: "Please provide the email",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                    </Form.Group>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                        <Form.Select
                            {...register("gender")}
                            disabled={isEdit === false}
                        >
                            <option value='MALE'>Male</option>
                            <option value='FEMALE'>Female</option>
                        </Form.Select>
                    </Form.Group>
                    {user?.type === 'customer1' &&
                        <Controller
                            control={control}
                            name={`address`}
                            disabled={isEdit === false}
                            render={({ field }) => (
                                <PlacesAutocomplete
                                    value={field.value}
                                    onChange={field.onChange}
                                    onSelect={handleSelect}
                                    debounce={300}
                                    className='form-control border-1'
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <Form.Group className="my-4">
                                            <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                                            <Form.Control
                                                {...getInputProps({
                                                    placeholder: 'Enter location here',
                                                    className: 'rounded-3'
                                                })}
                                                disabled={isEdit === false}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div className='w-100 text-center mt-3'>
                                                    <Spinner animation="border" />
                                                </div>
                                                }
                                                {suggestions.map(suggestion => {
                                                    return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                                        <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                    </div>
                                                })}
                                            </div>
                                        </Form.Group>
                                    )}
                                </PlacesAutocomplete>
                            )}
                        />
                    }
                </Form>
            </Col>
        </Row>
    </HelmetProvider>
};

export default Profile;