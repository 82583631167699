import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allProgramsService } from '../../service/guestHomeService';

// Redux-Functions
import { selectProgrammeList, setProgrammeList } from "../../redux/slices/guestHomeSlice"

import ReactStars from 'react-rating-star-with-type'

const Programmes = () => {
    const dataPerRow = 2;
    const dispatch = useDispatch()
    const programmeList = useSelector(selectProgrammeList);
    const [next, setNext] = useState(dataPerRow);

    /**
         * @function fetchCustomerProgramList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerProgramList = async () => {
        try {
            const result = await allProgramsService();
            if (result?.data?.status) {
                dispatch(setProgrammeList(result?.data?.data?.program_list))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerProgramList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkPrice = (item) => {
        const priceList = []
        if (item?.frequencies?.length > 0) {
            item?.frequencies.forEach((item) => {
                if (item.price) {
                    // priceList.push(Number(item.price?.toFixed()))
                    priceList.push(Number(item.price))
                }
            })
        }
        const maxPrice = Math.max(...priceList)
        let showData = ''
        showData = maxPrice
        return (showData)
    }
    const handleMoreImage = () => {
        setNext(next + dataPerRow);
    };

    return <div>
        <div className='boxSection mt-2'>
            <Container>
                <Row className='guest box-programme gx-5'>
                    {/* className='text-sm-center text-xs-center' */}
                    <Col xl={12} className='pt-2 mb-4 d-flex flex-row align-items-center justify-content-between'>
                        <div className=''><h1 className='fw-bold'>All Programs</h1></div>
                        {/* <div className=''><Link><Button siz='sm' variant='primary' className='text-light fs-600'>View All</Button></Link></div> */}
                    </Col>
                    {/* {programmeList?.length > 0 ? programmeList?.slice(0, next)?.map(item => ( */}
                    {programmeList?.length > 0 ? programmeList.map(item => (
                        <Col lg={3} md={6} sm={12} className='customBox'>
                            <Link to={`/program/detail/${item?.id}`} className='text-decoration-none'>
                                <Card style={{ width: '280px' }} className='mb-5 session-box border-2 border-light'>
                                    <div className="program-card-image">
                                        <Card.Img variant="top" src={item?.banners[0].banner} />
                                    </div>
                                    <Card.Body >
                                        {/* style={{height: '175px'}} <Card.Title>Card Title</Card.Title> */}
                                        {/* <div className="main-price">
                                            <div className="price-cirle-color course-price">
                                                <p className='mb-0'><span className='fs-14'>{`RM`}1</span></p>
                                                <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                            </div>
                                        </div> */}
                                        <div className="main-price">
                                            <div className="course-price d-flex flex-column justify-content-center">
                                                <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                        <Card.Text className='mb-0'>
                                            <div className='description-common-height pt-3 text-truncate'>
                                                {item?.name ? item?.name?.substr(0, 60) : ''}
                                            </div>
                                        </Card.Text>
                                        <Card.Text className='mb-4'>
                                            <p className='d-flex align-items-center'>
                                                <div className="div">
                                                    <ReactStars
                                                        disabled
                                                        value={item?.average_rating}
                                                        edit={true}
                                                        activeColors={["#FDB022"]}
                                                    />
                                                </div>
                                                <div className="div"><span className='fs-12'>{Number(item?.average_rating)}</span></div>
                                            </p>
                                        </Card.Text>

                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-800 fw-bold'>Classes</span></p></div>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-800 fw-bold'>Students</span></p></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    )) : ''}
                </Row>
                {/* <Row>
                    <Col lg='12' className='text-center'>
                        {next < programmeList?.length && (
                            <Button
                                className="mt-4 text-light"
                                variant='primary'
                                onClick={handleMoreImage}
                            >
                                Load more
                            </Button>
                        )}
                    </Col>
                </Row> */}
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;