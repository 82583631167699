import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import CoachList from './CoachList'

const CoachListComponent = () => {
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Coach List</title>
        </Helmet>
        <CoachList />
    </HelmetProvider>
};

export default CoachListComponent;