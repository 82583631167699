import { useState, useEffect, useRef } from 'react';
import { Modal, Image, Card, CardBody, CardTitle, CardText, Row, Col, InputGroup } from 'react-bootstrap';
import { MoreVertical, Edit, Eye, Trash, Edit2, Edit3, Star } from 'react-feather'
import { LuAsterisk } from "react-icons/lu";

// Images
import { coachProfile, uploadImage } from '../../../../assets/images/index'
import moment from 'moment';
import { Input } from 'reactstrap';
const ViewDetail = ({ tableRecord, ...props }) => {
    return <Modal show={props.show} onHide={props.handleClose} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0' closeButton >&nbsp;</Modal.Header>
        <Modal.Body className='pt-0'>
            <div className='coach-detail text-center'>
                <div className='profile mb-2'>
                    <div>
                        {tableRecord?.profile_image ? <Image src={tableRecord?.profile_image} roundedCircle={true} width='110px' /> : <Image src={uploadImage} roundedCircle={true} width='110px' />}
                    </div>
                </div>
                <div className=''> {tableRecord?.type === 'MAIN_COACH' ? <p className='text-success fw-600'>Main Coach</p> : <p className='text-danger fw-600'>Sub Coach</p>}
                </div>
                <div className='coach-name'>
                    <p>{tableRecord?.name}</p>
                </div>
                <div className='profile-contact'>
                    <p className='mb-1'>{`+60 ${tableRecord?.phone}`}</p>
                    <p className='mb-1'>{tableRecord?.email}</p>
                    <p className='mb-4'>{tableRecord?.gender === 'MALE' ? 'Male' : 'Female'}, {moment(tableRecord.dob).format("DD/MM/YYYY")}</p>
                </div>
                <div className='profile-description'>
                    <p>{tableRecord?.description}</p>
                </div>
                <div className='d-flex password justify-content-center align-items-center'>
                    <span className='fw-bolder fs-18'>Password :</span>&nbsp;<span className='fw-bolder fs-18'>{tableRecord?.password ? tableRecord?.password : Array.from(Array(8).keys())?.map((item) => (
                        <LuAsterisk/>
                     ))}</span>
                </div>
            </div>
        </Modal.Body>
    </Modal>
};

export default ViewDetail;