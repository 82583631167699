// import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { Container, Row, Col } from 'reactstrap';
// Images
import logo from '../../assets/images/header/logo.png'

const Footer = (props) => {
    const location = useLocation();

    return <div className="FooterBg">
        <footer className={classNames('', { "d-none": props.authenticatedRoute.includes(location.pathname) })}>
            <Container className='py-4' id='footerWebOnly'>
                <Row className='text-center text-md-start'>
                    <Col lg={2}>
                        <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center'>
                            <Link to="/" className='d-contents'>
                                <img className='img-fluid' src={logo} alt='logo' width='120' />
                            </Link>
                        </Row>
                    </Col>
                    <Col lg={7}>
                        <Row className='h-100'>
                            <Col xs={6} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-end align-items-start align-items-lg-center text-center pe-0'>
                                <Link to='/about-us' className='text-decoration-none mt-2 me-lg-4'>
                                    <span className='fw-bold footer-text'>About Us</span>
                                </Link>
                                <Link to='/contact-us' className='text-decoration-none mt-2 me-lg-4'>
                                    <span className='fw-bold footer-text'>Contact Us</span>
                                </Link>
                            </Col>
                            <Col xs={6} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-start align-items-start align-items-lg-center text-center ps-0'>
                                <Link to='/privacy-policy' className='text-decoration-none mt-2 me-lg-4'>
                                    <span className='fw-bold footer-text'>Privacy policy</span>
                                </Link>
                                <Link to='/terms-and-condition' className='text-decoration-none mt-2 me-lg-4'>
                                    <span className='fw-bold footer-text'>Terms & Conditions</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <div className='d-flex flex-column'>
                            <span className='fw-400 text-secondary text-start text-lg-center mt-2'>© 2023 AFA Community</span>
                        </div>
                    </Col>
                </Row>
            </Container>

        </footer>
        <footer className='p-4' id='footerMobileOnly'>
            <Row>
                <Col xs={12}>
                    <Link to="/" className='d-contents'>
                        <img className='img-fluid' src={logo} alt='logo' width='106' />
                    </Link></Col></Row>
            <Row className="pt-5 pb-5">
                <Col xs={6} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-end align-items-start align-items-lg-center text-center pe-0'>
                    <Link to='/about-us' className='text-decoration-none mt-2'>
                        <span className='fw-bold footer-text'>About Us</span>
                    </Link>
                    <Link to='/contact-us' className='text-decoration-none mt-2'>
                        <span className='fw-bold footer-text'>Contact Us</span>
                    </Link>
                </Col>
                <Col xs={6} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-start align-items-start align-items-lg-center text-center ps-0'>
                    <Link to='/privacy-policy' className='text-decoration-none mt-2'>
                        <span className='fw-bold footer-text'>Privacy policy</span>
                    </Link>
                    <Link to='/terms-and-condition' className='text-decoration-none mt-2'>
                        <span className='fw-bold footer-text'>Terms & Conditions</span>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='d-flex flex-column'>
                        <span className='fw-400 text-secondary text-start text-lg-center mt-2'>© 2023 AFA Community</span>
                    </div></Col>
            </Row>
        </footer>
    </div>
};

export default Footer;