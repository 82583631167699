import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Button, Row, Col } from 'react-bootstrap'
// Custom Component
import Layout from "../../../../components/Layout/Layout";
import CheckoutPage from "../../../../components/Student/Checkout";

const Index = () => {
    return <>
    <CheckoutPage />
    </>
};

export default Index;