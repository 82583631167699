import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

import ReactStars from 'react-rating-star-with-type'

const Programmes = ({relatedPrograms}) => {
    const dispatch = useDispatch()
    // const relatedPrograms = useSelector(selectProgrammeList);
    const [isLoading, setLoading] = useState(false)
    const checkPrice = (item) => {
        const priceList = []
        if (item?.frequencies?.length > 0) {
            item?.frequencies.forEach((item) => {
                if (item.price) {
                    // priceList.push(Number(item.price?.toFixed()))
                    priceList.push(Number(item.price))
                }
            })
        }
        const maxPrice = Math.max(...priceList)
        let showData = ''
        showData = maxPrice
        return (showData)
    }
   
    return <div className='session-box '>
        <div className='boxSection mt-5'>
            <Container>
                <Row className='box-programme'>
                    {/* <Col xl={12} className='pt-2 mb-5'><h1 className='fs-45 fw-bold'>Popular Programmes</h1></Col> */}
                    <Col xl={12} className='p-0 pt-2 mb-4 d-flex flex-row align-items-center justify-content-between'>
                        <div><h1 className='fw-bold'>Related Programs</h1></div>
                        <div><Link><Button variant='primary' className='text-light'>View All</Button></Link></div>
                    </Col>
                </Row>

                <Row className='box-programme gx-5'>
                    <div className="d-flex justify-content-start align-content-center related-pro">
                    {relatedPrograms?.length > 0 ? relatedPrograms?.slice(0, 4)?.map(item => (
                        <Col lg={3} md={6} sm={12} className='customBox p-0 pb-5'>
                            <a href={`/student/program/detail/${item?.id}`} target='_self'>
                                <Card style={{}} className='me-3 session-box border-2 border-light'>
                                    <div className="program-card-image">
                                        <Card.Img variant="top" src={item?.banners[0].banner} />
                                    </div>
                                    <Card.Body>
                                        {/*  style={{ height: '175px' }} <Card.Title>Card Title</Card.Title> */}
                                        {/* <div className="main-price">
                                            <div className="price-cirle-color course-price">
                                                <p className='mb-0'><span className='fs-14 text-light'>{`RM`}4</span></p>
                                                <p className='mb-0'><span className='fs-14 text-light'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                            </div>
                                        </div> */}
                                        <div className="main-price">
                                            <div className="course-price d-flex flex-column justify-content-center">
                                                <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                        <Card.Text className='mb-0'>
                                            <div className='description-common-height pt-3'>
                                                {item?.name ? item?.name.substr(0, 60) : ''}
                                            </div>
                                        </Card.Text>
                                        <Card.Text className='mb-4'>
                                            <div className="d-flex align-content-center">
                                                <div className="">
                                                    <div className=''>
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="fs-12 ms-2">{item?.average_rating}</div>
                                            </div>
                                        </Card.Text>

                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </a>

                        </Col>
                    )) : ''}
                    </div>

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;