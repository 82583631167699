import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import classNames from "classnames";
import moment from 'moment';
// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { setMySession, selectMySession } from "../../redux/slices/studentProgramme"
import { allStudentSessionService } from '../../service/academyProgramme';

const JoinSessionNewStudentModal = ({ rowData, ...props }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [sessionObject, setSessionObject] = useState([])
    const all_session = useSelector(selectMySession);

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            all_session: [],
            startDate: new Date()
        },
        mode: "onBlur"
    })

    console.log('rowData', rowData)
    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    const showModal = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    console.log('sessionObject', sessionObject)

    const handleCheckSession = (subItem, sIndex) => {
        console.log('handleCheckSession', subItem, sIndex)
        const temp = [...sessionObject]
        // if (!sessionObject?.some(item => item?.session_id === subItem?.session_id)) {
        if (!sessionObject?.length > 0) {
            temp?.push(subItem)
            setSessionObject(temp)
        } else if (sessionObject?.some(item => item?.id === subItem?.id)) {
            setSessionObject(sessionObject?.filter(item => item?.id !== subItem?.id))
        } else {
            // ErrorAlert('You can not select multiple timeslot of same session.')
            ErrorAlert('You can not select multiple timeslot.')

        }

        // if (sessionObject?.some(item => item?.id === subItem?.id)) {
        //     setSessionObject(sessionObject?.filter(item => item?.id !== subItem?.id))
        // } else {
        //     temp?.push(subItem)
        //     setSessionObject(temp)
        // }
    };

    /**
         * @function fetchSessionList
         * @params
         * @description fetch the list of customer session
         */
    const fetchSessionList = async () => {
        try {
            const result = await allStudentSessionService({ id: rowData });
            if (result?.data?.status) {
                dispatch(setMySession(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleJoinSession
         * @params 
         * @description used to join session
         */
    const handleJoinSession = async () => {
        if (sessionObject?.length > 0) {
            props?.setNewStudentSessionData(sessionObject)
            handleClose()
        } else {
            ErrorAlert('No timeslot is selected')
        }
    }

    useEffect(() => {
        if (show) {
            fetchSessionList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return <>
        <div className="div">
            <div className="join-modal">
                <Button variant='primary' className='text-light' onClick={showModal}>{props?.newStudentSessionData?.length > 0 ? 'Joined' : 'Join the session'}</Button>
            </div>
        </div>
        <div className="modal">
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header className='fs-20 fw-bold border-0' closeButton >
                </Modal.Header>
                <Modal.Body className='pt-0'>

                    <div className="session-list">
                        <div className="div">
                            <div className="ms-3 mb-3">
                                <p className='fs-24 fw-bold mb-0'>Join the Session</p>
                                <p className='text-primary modal-session'> 4 session per month</p>
                            </div>

                            {all_session.length > 0 ? all_session.map((item, index) => {
                                return (
                                    <>
                                        <div className='parent-session pl-3' key={`ul-${index}`}>
                                            <div className="date-session">
                                                <p className='fw-600'> Session {index + 1}</p>
                                                <p className='mb-0 fw-600'>{item?.date ? moment(item?.date).format('DD/MM/YYYY, dddd') : ''} </p>
                                            </div>
                                            
                                            <div className="d-flex flex-wrap align-items-center align-items-start">
                                                {item?.timeslots.map((subItem, sIndex) => {
                                                    return <>
                                                        <div className='parent-child-session ms-3 mb-2' key={`li-${sIndex}`}>
                                                            {/* <Button variant='secondary' className='modal-join-session-button' onClick={() => handleCheckSession(subItem, sIndex)}> {`${subItem?.start_time}`} {`- ${subItem?.end_time}`} </Button> */}
                                                            <Button variant='' className={classNames('border', {'bg-primary text-light': sessionObject?.some(sessionItem => sessionItem?.id === subItem?.id), 'bg-light pe-none' : !subItem?.is_open})} onClick={() => handleCheckSession(subItem, sIndex)}> {`${subItem?.start_time}`} {`- ${subItem?.end_time}`} </Button>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                            <hr />
                                        </div>
                                    </>
                                );
                            }) : <p className='text-center'> No session available</p>}
                            
                            {all_session.length > 0 && <>
                                <div className="joined mt-3 mb-1">  <Button variant='primary' size='sm' className='text-light join-button' onClick={() => handleJoinSession()}> Join session </Button> </div>
                            </>
                            }
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    </>
};

export default JoinSessionNewStudentModal;