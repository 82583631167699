import { Link } from "react-router-dom";

// Custom Component
import { PrimaryOutlineButton } from "../../Button";

// Images
import wallet  from "../../../assets/images/wallet/wallet.png";

const NoTransaction = () => {
    return <div className='my-5 text-center'>
        <img className='img-fluid mt-5 mb-3' src={wallet} alt='wallet' />
        <p className='fs-24 fw-500 text-secondary'>You haven’t done any transaction yet!</p>
        <div className='d-inline-block mt-3'>
            <Link to='/'>
                <PrimaryOutlineButton className='fs-14 fw-500 px-5'>Home</PrimaryOutlineButton>
            </Link>
        </div>
    </div>
};

export default NoTransaction;