import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { myProgramsService } from '../../service/studentProgram';

// Redux-Functions
import { selectMyProgrammeList, setMyProgrammeList } from "../../redux/slices/studentProgramme"

import ReactStars from 'react-rating-star-with-type'
import JoinSession from './JoinSession'

const Programmes = () => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const programmeList = useSelector(selectMyProgrammeList);

    /**
         * @function fetchCustomerProgramList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerProgramList = async () => {
        try {
            const result = await myProgramsService();
            console.log('myProgramsService', result)
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setMyProgrammeList(result?.data?.data?.program_list))
            } else {
                ErrorAlert(result?.response?.data?.message)
                setLoading(false)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchCustomerProgramList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }

    return <div>
        <div className='boxSection mt-5'>
            <Container>
                <Row className='box-my-programme gx-2'>

                    {/* <Col xl={12} className='pt-2 mb-5'><h1 className='fs-45 fw-bold'>Popular Programmes</h1></Col> */}
                    <Col xl={12} className='pt-2 mb-4 d-flex flex-row align-items-center justify-content-between'>
                        <div><h1 className='fs-20 fw-bold'>My Programs</h1></div>
                        {/* <div><Link><Button variant='primary' className='text-light'>View All</Button></Link></div> */}
                    </Col>

                    {programmeList?.length > 0 ? programmeList.map(item => (
                        <Col lg={12} md={12} sm={12} className='customBox'>

                            <Card style={{}} className='mb-5 session-box border-2 border-light'>
                                <div className="">
                                    <Row>

                                        <Col lg={3}>
                                            <a href={`/student/my/program/detail/${item?.id}`} target='_self'>
                                                <Card.Img variant="left" src={item?.banners[0].banner} />
                                            </a>
                                        </Col>

                                        <Col lg={9}>

                                            <Card.Body>
                                                {/* <Card.Title>Card Title</Card.Title> */}

                                                <div className="d-flex justify-content-around align-content-end flex-column" style={{ height: '9rem' }}>
                                                    <div className="div">
                                                        <Card.Text className='mb-1'>
                                                            <a href={`/student/my/program/detail/${item?.id}`} target='_self'>
                                                                <div className='student-my-programs'>
                                                                    {item?.name ? item?.name.substr(0, 60) : ''}
                                                                </div>
                                                            </a>
                                                        </Card.Text>
                                                        <Card.Text className=''>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <div className="">
                                                                    <div className=''>
                                                                        <ReactStars
                                                                            disabled
                                                                            value={item?.average_rating}
                                                                            edit={true}
                                                                            activeColors={["#FDB022"]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="fs-12 ms-2">{item?.average_rating}</div>
                                                                <div className="ms-2"><div className='fs-12 text-primary text-decoration-underline fw-600'>Rate & Review</div></div>
                                                            </div>
                                                        </Card.Text>
                                                    </div>
                                                    <div className="div">
                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                            <div style={{ minWidth: '190px' }} className="d-flex flex-row justify-content-between align-items-center">
                                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'> Classes</span></p></div>
                                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'> Students</span></p></div>
                                                            </div>
                                                            <div className="join-session-button">
                                                                <div className="joined">
                                                                    <JoinSession rowData={item} />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    )) : ''}

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;