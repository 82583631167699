import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import BankAccount from './BankAccount';

const CoachListComponent = () => {
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Academy Bank</title>
        </Helmet>
        <BankAccount />
    </HelmetProvider>
};

export default CoachListComponent;