
import { Navbar, Nav, OverlayTrigger, Popover, Container, Row, Col, Button, nav, navbar, Dropdown, DropdownButton, NavDropdown, Spinner } from "react-bootstrap";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineUser } from "react-icons/hi";
import { AiOutlineMenu, AiOutlineHeart } from "react-icons/ai";
import { FiSearch, FiLock } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import classNames from "classnames";
import React, { Fragment, useState, forwardRef, useEffect } from 'react'
// Images
import logo from '../../assets/images/header/logo.png'
import { userPic } from "../../assets/images/index";
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
// Custom Component
import { PrimaryButton } from "../Button";
import { Avatar } from '../../assets/images/svg'
import { Bell, ArrowDown } from 'react-feather'
// import { Button } from 'reactstrap';
// ** Third Party Components
import Select from 'react-select'

// ** Utils
import { selectThemeColors } from '../../utils/Utils'
import { getCustomerProfileDetails, getCoachProfileDetails, getAcademyProfileDetails, logoutService, academyLogoutService, coachLogoutService } from '../../service/auth.service';

// Redux-Functions
import { selectToken, selectUser, setUser, setToken } from "../../redux/userSlice"
// Utility Service
import { setUserSession, removeUserSession, getToken } from '../../utils/AuthService';
import ConfirmationModal from "../Modal/ConfirmationModal";

const Header = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedLanguage, setLanguage] = useState()
    const isToken = useSelector(selectToken)
    const token = getToken();
    const userDetail = useSelector(selectUser)
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmation, setConfirmation] = useState(false)

    const languages = [{ label: 'English', value: 'en' }, { label: 'Malay', value: 'ml' }]

    const handleLanguage = (e) => {
        const myLang = e?.value
        setLanguage(myLang)
    }

    /**
         * @function fetchCustomerProfile
         * @params
         * @description fetch the list of country
         */
    const fetchCustomerProfile = async () => {
        try {
            const result = await getCustomerProfileDetails();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))

            } else {
                ErrorAlert(result?.response?.data?.message)
                if (result?.response?.data?.message === 'Unauthorized or Token expired') {
                    removeUserSession()
                    dispatch(setUser(''))
                    navigate('/login')

                }
            }
        } catch (error) {
            ErrorAlert(error, 'BB')

        }
    }

    /**
         * @function fetchAcademyProfile
         * @params
         * @description fetch the list of country
         */
    const fetchAcademyProfile = async () => {
        try {
            const result = await getAcademyProfileDetails();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'academy'
                }))

            } else {
                ErrorAlert(result?.response?.data?.message)
                if (result?.response?.data?.message === 'Unauthorized or Token expired') {
                    removeUserSession()
                    dispatch(setUser(''))
                    navigate('/login')

                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchCoachProfile
         * @params
         * @description fetch the list of country
         */
    const fetchCoachProfile = async () => {
        try {
            const result = await getCoachProfileDetails();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'coach'
                }))

            } else {
                ErrorAlert(result?.response?.data?.message)
                if (result?.response?.data?.message === 'Unauthorized or Token expired') {
                    removeUserSession()
                    dispatch(setUser(''))
                    navigate('/login')
                }
            }
        } catch (error) {
            ErrorAlert(error, 'BB')

        }
    }

    /**
      * @function logout
      * @params
      * @description used to logout the user
      */
    const logout = async () => {
        try {
            let result;
            if (userDetail?.type === 'academy') {
                result = await academyLogoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else if (userDetail?.type === 'coach') {
                result = await coachLogoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                result = await logoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (isToken) {
            if (userDetail?.type === 'customer') {
                fetchCustomerProfile()
            } else if (userDetail?.type === 'coach') {
                fetchCoachProfile()
            } else if (userDetail?.type === 'academy') {
                fetchAcademyProfile()
            } else {
                removeUserSession()
                dispatch(setUser())
                dispatch(setToken())
                navigate('/login')
            }
        } else {
            // logout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleConfirmModalShow
        * @params ()
        * @description use To show modal
    */
    const handleConfirmModalShow = () => {
        setConfirmModalShow(!confirmModalShow);

    };

    useEffect(() => {
        if (confirmation) {
            logout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmation])

    return <div>
        <header className={classNames({ "d-none": props.authenticatedRoute.includes(location.pathname) })}>
            <Container className='py-3' id='headerWebOnly'>
                <Row className='align-items-center text-center text-md-start'>
                    <Col lg={2} md={12}>
                        <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center'>
                            <Link to="/" className='d-contents'>
                                <img className='img-fluid' src={logo} alt='logo' width='120' />
                            </Link>
                        </Row>
                    </Col>
                    <Col lg={6} md={12} sm={12} className=''>
                        <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center coachingHeader'>
                            <Navbar expand="lg" className='p-0 sticky-top'>
                                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav"> */}
                                <Nav className="me-auto d-flex flex-row justify-content-around">
                                    <NavDropdown title="Programs" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/academy/home">Academy</NavDropdown.Item>
                                        <NavDropdown.Item href="/coach/home">Coach</NavDropdown.Item>
                                        <NavDropdown.Item href="/all/programs">Program</NavDropdown.Item>
                                        {/* <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                            Separated link
                                        </NavDropdown.Item> */}
                                    </NavDropdown>
                                    <NavDropdown title="Coaches" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Coaches</NavDropdown.Item>
                                        <NavDropdown.Item href="/all/coaches">Coaches</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link className='price' href="#home">Pricing</Nav.Link>
                                </Nav>
                                {/* </Navbar.Collapse> */}
                            </Navbar>
                        </Row>
                    </Col>
                    <Col lg={4} md={12} sm={12} className='d-flex flex-row justify-content-end align-items-start align-items-lg-center text-center pe-0 coachingHeaderRight'>
                        <Select
                            theme={selectThemeColors}
                            className='react-select text-start headerButton'
                            classNamePrefix='select'
                            defaultValue={selectedLanguage}
                            name='clear'
                            options={languages}
                            placeholder='English'
                            onChange={handleLanguage}
                        />
                        {isToken ? <Fragment>
                            <div className='d-flex flex-row justify-content-end align-items-center'>
                                <div>
                                    <div className="bellIcon ms-3">
                                        <Bell size={20} />
                                    </div>
                                </div>
                                <div>
                                    {/* <div className='profile_pic ms-3'>
                                        {userDetail?.type === 'customer' ? userDetail?.profile_pic ? <Fragment><a href='/user/profile' rel="noopener"><img src={userDetail?.profile_pic} alt="Profile-Pic" width='30' /></a></Fragment> : <Fragment><a href='/user/profile' rel="noopener"><img src={userPic} alt="Profile-Pic" width='30' /></a></Fragment> : ''}
                                        {userDetail?.type === 'coach' ? userDetail?.profile_pic ? <Fragment><Link><img src={userDetail?.profile_pic} alt="Profile-Pic" width='30' /></Link></Fragment> : <Fragment><Link><img src={userPic} alt="Profile-Pic" width='30' /></Link></Fragment> : ''}
                                        {userDetail?.type === 'academy' ? userDetail?.profile_pic ? <Fragment><Link><img src={userDetail?.profile_pic} alt="Profile-Pic" width='30' /></Link></Fragment> : <Fragment><Link><img src={userPic} alt="Profile-Pic" width='30' /></Link></Fragment> : ''}
                                    </div> */}

                                    <Navbar>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                        <Navbar.Collapse id="responsive-navbar-nav">
                                            <Nav className="ms-auto">
                                                {userDetail ? <OverlayTrigger trigger="click" placement='bottom' rootClose overlay={<Popover className='profile-popover'> <Popover.Body>
                                                    {/* My account */}

                                                    {userDetail?.type === 'customer' ? <Fragment>
                                                        <Row className='mb-3' onClick={() => {
                                                            navigate('/user/profile')
                                                            document.body.click()
                                                        }}>
                                                            <Col xs={3} className='text-center pe-0'>
                                                                <HiOutlineUser className="text-secondary" size={20} />
                                                            </Col>
                                                            <Col xs={9} className='px-0'>
                                                                <span role='button' className='fs-14 fw-400'>View profile</span>
                                                            </Col>
                                                        </Row>
                                                    </Fragment> : ''}

                                                    <hr />
                                                    {/* Log Out */}
                                                    <Row className='mb-2'>
                                                        <Col xs={3} className='text-center pe-0'>
                                                            <IoMdLogOut className="text-danger" size={20} />
                                                        </Col>
                                                        <Col xs={9} className='px-0'>
                                                            <span role='button' className='fs-14 fw-400 text-danger' onClick={() => handleConfirmModalShow()}>Logout</span>
                                                        </Col>
                                                    </Row>
                                                </Popover.Body>
                                                </Popover>} >
                                                    <div role='button' className="d-flex justify-content-center align-items-center ms-3">
                                                        {userDetail?.type === 'customer' && userDetail?.profile_pic ? <img className='rounded-circle me-2' src={userDetail?.profile_pic} alt={userDetail?.name} width='40' /> : userDetail?.type === 'coach' && userDetail?.profile_pic ? <img className='rounded-circle me-2' src={userDetail?.profile_image} alt={userDetail?.name} width='40' /> : userDetail?.type === 'academy' && userDetail?.profile_image ? <img className='rounded-circle me-2' src={userDetail?.profile_image} alt={userDetail?.name} width='40' /> : <Avatar className="me-2" size={40} />}
                                                        {/* <span className="fw-600 text-secondary">{user?.type === 'customer' ? user?.username : user?.name}</span> */}
                                                    </div>
                                                </OverlayTrigger> : <NavLink to="/login"> <PrimaryButton>Sign In</PrimaryButton></NavLink>}
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </div>
                            </div>
                        </Fragment> : <Fragment>
                            <NavLink to="/login" className='text-decoration-none'>
                                <Button size='md' variant="primary" className='text-light ms-2'>Sign In</Button>
                            </NavLink>
                            {/* <NavLink to="/login" className='text-decoration-none'>
                                <Button size='md' variant="primary" className='text-coaching headerButton ms-2 common-sign-in'>Sign In</Button>
                            </NavLink> */}
                            {/* <NavLink to="/register" className='text-decoration-none'>
                                <Button size='md' variant="primary" className='text-light headerButton ms-2'>Sign Up</Button>
                            </NavLink> */}
                        </Fragment>}

                    </Col>
                </Row>
            </Container>
        </header>
        <header className={classNames({ "d-none": props.authenticatedRoute.includes(location.pathname) })}>
            <div className="p-4" id='mobileMenuOnly'>
                <Row className='text-start mb-4 align-items-center'>
                    <Col sm={6} xs={6}>
                        <Link to="/" className='d-contents'>
                            <img className='img-fluid' src={logo} alt='logo' width='106' />
                        </Link>
                    </Col>
                    <Col sm={6} xs={6}>
                        {isToken ? <Fragment>
                            <div className='d-flex flex-row justify-content-end align-items-center'>
                                <div>
                                    <div className="bellIcon">
                                        <Bell size={20} />
                                    </div>
                                </div>
                                <div>
                                    {/* <div className='profile_pic ms-3'>
                                        {userDetail?.profile_pic ? <Fragment><Link to='/user/profile'><img src={userDetail?.profile_pic} alt="Profile-Pic" width='40' /></Link></Fragment> : <Link to='/user/profile'><img src={userPic} alt="Profile-Pic" width='40' /></Link>}
                                    </div> */}
                                    <Navbar>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                        <Navbar.Collapse id="responsive-navbar-nav">
                                            <Nav className="ms-auto">
                                                {userDetail ? <OverlayTrigger trigger="click" placement='bottom' rootClose overlay={<Popover className='profile-popover'> <Popover.Body>
                                                    {/* My account */}

                                                    {userDetail?.type === 'customer' ? <Fragment>
                                                        <Row className='mb-3' onClick={() => {
                                                            navigate('/user/profile')
                                                            document.body.click()
                                                        }}>
                                                            <Col xs={3} className='text-center pe-0'>
                                                                <HiOutlineUser className="text-secondary" size={20} />
                                                            </Col>
                                                            <Col xs={9} className='px-0'>
                                                                <span role='button' className='fs-14 fw-400'>View profile</span>
                                                            </Col>
                                                        </Row>
                                                    </Fragment> : ''}

                                                    <hr />
                                                    {/* Log Out */}
                                                    <Row className='mb-2'>
                                                        <Col xs={3} className='text-center pe-0'>
                                                            <IoMdLogOut className="text-danger" size={20} />
                                                        </Col>
                                                        <Col xs={9} className='px-0'>
                                                            <span role='button' className='fs-14 fw-400 text-danger' onClick={() => handleConfirmModalShow()}>Logout</span>
                                                        </Col>
                                                    </Row>
                                                </Popover.Body>
                                                </Popover>} >
                                                    <div role='button' className="d-flex justify-content-center align-items-center ms-3">
                                                        {userDetail?.type === 'customer' && userDetail?.profile_pic ? <img className='rounded-circle me-2' src={userDetail?.profile_pic} alt={userDetail?.name} width='40' /> : userDetail?.type === 'coach' && userDetail?.profile_pic ? <img className='rounded-circle me-2' src={userDetail?.profile_pic} alt={userDetail?.name} width='40' /> : <Avatar className="me-2" size={40} />}
                                                        {/* <span className="fw-600 text-secondary">{user?.type === 'customer' ? user?.username : user?.name}</span> */}
                                                    </div>
                                                </OverlayTrigger> : <NavLink to="/login"> <PrimaryButton>Sign In</PrimaryButton></NavLink>}
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </div>
                            </div>

                        </Fragment> : <Fragment>
                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                <NavLink to="/login" className='text-decoration-none'>
                                    <Button size='sm' variant="outline-secondary" className='text-coaching headerButton fw-600'>Sign In</Button>
                                </NavLink>
                                <NavLink to="/login" className='text-decoration-none'>
                                    <Button size='sm' variant="primary" className='text-light headerButton fw-600 ms-2'>Sign Up</Button>
                                </NavLink>
                            </div>
                        </Fragment>}
                    </Col>
                </Row>

                <Row className='mt-2 text-start mobileMenu' >
                    <Col xs={12}>
                        <Navbar expand="xs" className='p-0'>
                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                            <Nav className="me-auto d-flex flex-row">
                                <NavDropdown title="Programs" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#">Programs</NavDropdown.Item>
                                    <NavDropdown.Item href="#"> Programs 1</NavDropdown.Item>
                                    <NavDropdown.Item href="#">Program 2</NavDropdown.Item>
                                    {/* <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                            Separated link
                                        </NavDropdown.Item> */}
                                </NavDropdown>
                                <NavDropdown title="Coaches" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Coaches</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Coach 1</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Coach 2</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Coach 3</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Coach 4</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link className='price' href="#home">Pricing</Nav.Link>
                            </Nav>
                            {/* </Navbar.Collapse> */}
                        </Navbar>
                    </Col>
                </Row>
            </div>
        </header>
        <ConfirmationModal show={confirmModalShow} handleClose={handleConfirmModalShow} setConfirmation={setConfirmation} />
    </div>

};

export default Header;