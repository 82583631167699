// Custom Component
import { PrimaryOutlineButton } from '../Button';

// Images
import { noStudent } from "../../assets/images";
 
const NoBank = (props) => {
    return <div className='d-flex flex-column justify-content-center align-items-center py-4'>
        <img className='ímg-fluid' src={noStudent} alt='no-bank' width='120' />
        <span className='fs-18 fw-600 text-secondary text-center my-3'>Students are not added yet!</span>
        <div>
            {/* <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={props?.handleAddBankAccountModal}>Add Bank Account</PrimaryOutlineButton> */}
        </div>
    </div>
};

export default NoBank;