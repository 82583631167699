import { useState, useEffect, Fragment } from 'react';
import { Carousel, Form, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom'
import Select from 'react-select'

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import { ArrowDown, ArrowRight, Calendar, Eye, Search, XSquare, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../../../assets/images/index";
 
// API Service
import { singleProgramStudentService, ProgramSessionStudentService, singleProgramFilterStudentService, checkStudentAttendance } from '../../../../service/coachProgram';

// Redux-Functions selectTotalEntry, setTotalEntry
import { setStudentList, selectStudentList, selectTotalEntry, setTotalEntry, selectSessionDropList, setSessionDropList, selectFreshData } from "../../../../redux/coachProgrammeSlice"
import { selectUser } from "../../../../redux/userSlice"

import UpdateProgress from './UpdateProgress'
import ChangeSession from './ChangeSession';
import NoStudent from "../../../../components/Common/NoStudents";
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Input } from 'reactstrap';
import { selectThemeColors } from '../../../../utils/Utils'
import FilterStudent from './FilterStudent'
import CoachSessionAttandenceModal from '../../../../components/Modal/CoachSessionAttandenceModal';

const Students = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const [bannerList, setBannerList] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [sessionAttandenceModalShow, setSessionAttandenceModalShow] = useState(false)
    const [rowData, setRowData] = useState();
    const [star, setStar] = useState(5)
    const user = useSelector(selectUser);
    const sessionList = useSelector(selectSessionDropList)
    const freshData = useSelector(selectFreshData)
    const [selectedSession, setSelectedSession] = useState('')
    const students = useSelector(selectStudentList)
    const [checked, setChecked] = useState(0)
    const [studentCount, setStudentCount] = useState(null)
    const totalEntry = useSelector(selectTotalEntry)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const countPerPage = 10

    const [updateProgressModal, setUpdateProgressModal] = useState(false)
    const [editBankAccountModal, setEditBankAccountModal] = useState(false)
    const [selectedDays, setSelectedDays] = useState([]);
    const [tableRecordData, setTableRecordData] = useState();
    const [show, setShow] = useState(false)

    const handleChangeSession = () => {
        setShow(!show)
    };

    /**
        * @function handleEmailSendModalShow
        * @params ()
        * @description used to show Modal
    */
    const handleSessionAttandenceModalShow = (item) => {
        setRowData(item)
        setTableRecordData(item)
        setSessionAttandenceModalShow(!sessionAttandenceModalShow)
    };

    /**
        * @function fetchSingleProgramProgress
        * @params 
        * @description fetch the list of customer banner
    */
    const fetchSingleProgramProgress = async (page) => {
        try {
            const result = await singleProgramStudentService({ id: singleData.id, page, countPerPage });
            console.log('fetchSingleProgramProgress', result)
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setStudentList(result?.data?.data?.student_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total_pages))
                setStudentCount(result?.data?.data?.pagination?.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchSessionProgram
        * @params 
        * @description fetch the list of customer banner
        */
    const fetchSessionProgram = async (page) => {
        try {
            const result = await ProgramSessionStudentService({ id: singleData.id });
            if (result?.data?.status) {
                setLoading(false)
                const resultSession = result?.data?.data?.map((item) => ({ value: item.id, label: <span>{item?.date}</span> }))
                const allOptions = [{ value: '', label: 'All' }]
                const finalDrop = allOptions.concat(resultSession)
                dispatch(setSessionDropList(finalDrop))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function filterSingleProgramProgress
        * @params 
        * @description fetch the list of customer banner
        */
    const filterSingleProgramProgress = async (page, filter) => {
        try {
            const result = await singleProgramFilterStudentService({ id: singleData.id, page, filter: filter ? filter : selectedSession, countPerPage });
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setStudentList(result?.data?.data?.student_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total_pages))
                setStudentCount(result?.data?.data?.pagination?.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (singleData.id && user?.type === 'coach') {
            setLoading(true)
            fetchSingleProgramProgress(currentPage + 1)
            fetchSessionProgram()
        }
        // eslint-disable-next-line
    }, [user, currentPage, freshData])

    /**
         * @function handleUpdateProgressModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleUpdateProgressModal = () => {
        setUpdateProgressModal(!updateProgressModal)
    }

    /**
         * @function handleEditBankAccountModal
         * @params
         * @description set the visibility of edit bank account modal
         */

    const handleEditBankAccountModal = () => {
        setEditBankAccountModal(!editBankAccountModal)
    }

    const updateStudentAttendance = async (value, item) => {
        if (value && singleData?.id) {
            const date = item.timeslots?.length > 0 ? moment(item?.timeslots[0]?.session?.date).format('YYYY-MM-DD') : ''
            const time = item.timeslots?.length > 0 ? moment(item?.timeslots[0]?.start_time, 'hh:mm A').format('HH:mm') : ''

            try {
                const data = new FormData()
                data.append('program_id', singleData.id)
                data.append('session_id', singleData?.sessions?.length > 0 ? singleData?.sessions[0].id : '')
                data.append('student_id', item?.customer_id)
                data.append('date', date)
                data.append('time', time)
                const result = await checkStudentAttendance(data);
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    fetchSingleProgramProgress(currentPage + 1)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        }
    }

    const handleCheck = (e, item) => {
        const value = e.currentTarget.checked
        updateStudentAttendance(value, item)
        setChecked(value)

    }

    useEffect(() => {
        if (user?.type === 'academy') {
            handleCheck()
        }
        // eslint-disable-next-line
    }, [])

    const columns = [{
        name: 'id',
        selector: (row) => row.id ? `${row.id}` : '--',
        minWidth: '200px'
    },
    {
        name: 'Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Email',
        selector: (row) => row.email ? `${row.email}` : '--',
        minWidth: '200px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
        minWidth: '150px'
    },
    {
        name: 'Session Selected',
        selector: (row) => row?.timeslots?.length > 0 ? <div><span className='text-primary'>{row?.timeslots?.length}&nbsp;</span> <Eye role='button' size={15} onClick={() => handleSessionAttandenceModalShow(row)} /></div> : '--',
        minWidth: '280px'
    },
    {
        name: 'Gender',
        selector: (row) => row.gender ? `${row.gender}` : '--',
        minWidth: '50px'
    },
    {
        name: 'Student Age',
        selector: (row) => row.dob ? `${moment().diff(row?.dob, 'years')}` : '--',
        minWidth: '120px'
    },
    {
        name: 'Progress',
        selector: (row) => row?.timeslots?.length > 0 ? <UpdateProgress tableRecord={row} /> : '--',
        minWidth: '130px'
    },
    
    {
        name: 'Attendance',
        selector: (row) => row?.attendances ? `${row?.attendances?.length}/${row?.timeslots?.length}` : '--',
        width: '130px'
    }]
    

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
        */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
         * @function CustomPagination
         * @params
         * @description used for Custom Pagination
         */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    const handleFilterSession = (e) => {
        const value = e?.value
        if (value) {
            setSelectedSession(e)
            filterSingleProgramProgress(pageCount, value)
        } else {
            fetchSingleProgramProgress(currentPage + 1)
            setSelectedSession('')
        }

    }

    return <>
        {students?.length > 0 ? <div>
            <div className='boxSection mt-2'>
                <div className='m-0'>
                    <div className="box-cover">
                        <Row className='mb-4'>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                    <div className='ms-2'><p className='fs-18 fw-bold mb-0'>Students</p> </div>
                                    <div><span className='student-count'>{studentCount}</span></div>
                                    <div className='ms-5'>
                                        {/* <p>June 2023<span><Calendar size={20} /></span></p> */}
                                        {/* <Button size="md" variant="primary" className='text-light' onClick={handleAddBankAccountModal}>Add Student</Button> */}
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-lg-end justify-content-sm-between align-items-center flex-lg-row'>
                                    <div className='w-50'>
                                        <Select
                                            // closeMenuOnSelect={false}
                                            theme={selectThemeColors}
                                            className='react-select'
                                            classNamePrefix='select'
                                            defaultValue={selectedSession}
                                            name='clear'
                                            options={sessionList}
                                            placeholder='Session'
                                            onChange={(e) => handleFilterSession(e)}
                                        />
                                    </div>
                                    <div className='ms-4 mx-2'>
                                        <div><FilterStudent singleData={singleData} /></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div id='box-coach-web-only' className="div">
                            <Row className='box-programme gx-2'>
                                {students && students?.length > 0 ? <Fragment>
                                    <Col lg={12} md={12} sm={12} className=''>
                                        <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                            <DataTable
                                                // noHeader
                                                columns={columns}
                                                // customStyles={students && students.length <= 2 ? customStyles : ''}
                                                className='react-dataTable'
                                                // sortIcon={<ChevronDown size={10} />}
                                                data={students}
                                                key={students?.id}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={10}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                paginationComponent={CustomPagination}
                                                paginationDefaultPage={currentPage + 1}
                                            />
                                        </div>

                                    </Col>
                                </Fragment> : ''}
                            </Row>
                            <Row>
                                <Col className='' lg={12}>
                                    <div className='pt-3 pb-3 student-pagination'>
                                        <ReactPaginate
                                            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                <span className='fs-14 fw-600 me-2'>Next</span>
                                                <AiOutlineArrowRight className='text-secondary' size={20} />
                                            </div>}
                                            breakLabel='...'
                                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                <span className='fs-14 fw-600'>Previous</span>
                                            </div>}
                                            pageRangeDisplayed={3}
                                            forcePage={currentPage}
                                            marginPagesDisplayed={3}
                                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                            breakClassName='page-item me-3 d-flex align-items-center'
                                            nextClassName='page-item next-item flex-grow-1 text-end'
                                            previousClassName='page-item prev-item flex-grow-1 me-3'
                                            disabledLinkClassName='btn disabled p-0 border-0'
                                            pageCount={totalEntry || 1}
                                            onPageChange={page => handlePagination(page)}
                                            containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>

            <div className="div" id='box-coach-mobile-only'>
                <div className='boxSection mt-2'>
                    <div>
                        <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                            {students && students?.length > 0 ? students.map(item => (
                                <Col sm={12} className=''>
                                    <>
                                        <div className='coach-view-box'>
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div className="">
                                                    <p className='fs-14 fw-600 mb-0'>{item?.name}</p>
                                                </div>
                                                <div className=''>
                                                    <XSquare size={15} tableRecord={item} />
                                                </div>
                                            </div>
                                            <div className='detail my-2'>
                                                <div className="my-2">
                                                    <p className='mb-0'><span>{item?.gender}</span> &nbsp; <span>Age: {item?.age}</span></p>
                                                </div>
                                                <div className="my-2">
                                                    <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{item?.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Col>
                            )) : ''}
                        </Row>
                    </div>
                </div>
            </div>
            <div className='clearfix'></div>
        </div> : <NoStudent handleUpdateProgressModal={handleUpdateProgressModal} />}
        <CoachSessionAttandenceModal show={sessionAttandenceModalShow} handleClose={handleSessionAttandenceModalShow} singleData={singleData} rowData={rowData} handleChangeSession={handleChangeSession} tableRecord={tableRecordData} />
        <ChangeSession show={show} tableRecord={tableRecordData} rowData={singleData} handleClose={handleChangeSession} handleUpdateProgressClose={handleSessionAttandenceModalShow} />
    </>
};

export default Students;