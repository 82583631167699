import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    walletList: [],
}
 
export const walletSlice = createSlice({
    name: "walletSlice",
    initialState,
    reducers: {
        setWalletList: (state, action) => {
            state.walletList = action.payload
        },
    }
})

export const { setWalletList } = walletSlice.actions

export const selectWalletList = (state) => state.coachWallet.walletList

export default walletSlice.reducer