import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    tournamentList: [],
    totalEntry: 0
}

export const tournamentSlice = createSlice({
    name: "tournament",
    initialState,
    reducers: {
        setTournamentList: (state, action) => {
            state.tournamentList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        }
    }
})

export const {
    setTournamentList,
    setTotalEntry,
} = tournamentSlice.actions

export const selectTournamentList = (state) => state.tournament.tournamentList 
export const selectTotalEntry = (state) => state.tournament.totalEntry 

export default tournamentSlice.reducer