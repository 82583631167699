import { configureStore } from "@reduxjs/toolkit";

import bankAccountReducer from "./bankAccountSlice";
import programmeReducer from "./academyProgrammeSlice";
import academyCoachReducer from './coachSlice';
import academyAccessReducer from './accessAcademySlice';
import tournamentReducer from "./tournamentSlice";
import userReducer from "./userSlice";
import coachProgrammeReducer from "./coachProgrammeSlice";
import guestHomeSliceReducer from "./slices/guestHomeSlice";
import studentSliceReducer from "./slices/studentProgramme";
import coachWalletSliceReducer from "./slices/coachWallet";

export default configureStore({
    reducer: {
        user: userReducer,
        academyProgramme: programmeReducer,
        academyCoach: academyCoachReducer,
        access: academyAccessReducer,
        bankAccount: bankAccountReducer,
        tournament: tournamentReducer,
        coachProgram: coachProgrammeReducer,
        guestHome: guestHomeSliceReducer,
        studentProgramme: studentSliceReducer,
        coachWallet: coachWalletSliceReducer
        
    }
});