import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import CustomCalendar from '../../../../components/Academy/Calendar/index'

const CalendarComponent = () => {
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Academy Calendar</title>
        </Helmet>
        <CustomCalendar />
    </HelmetProvider>
};

export default CalendarComponent;