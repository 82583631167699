import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allProgramsService } from '../../service/studentProgram';

// Redux-Functions
import { selectProgrammeList, setProgrammeList } from "../../redux/slices/studentProgramme"

import ReactStars from 'react-rating-star-with-type'

const Programmes = () => {
    const dispatch = useDispatch()
    const programmeList = useSelector(selectProgrammeList);
    const [isLoading, setLoading] = useState(false)

    /**
         * @function fetchCustomerProgramList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerProgramList = async () => {
        try {
            const result = await allProgramsService();
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setProgrammeList(result?.data?.data?.program_list))
            } else {
                setLoading(false)
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchCustomerProgramList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkPrice = (item) => {
        const priceList = []
        if (item?.frequencies?.length > 0) {
            item?.frequencies.forEach((item) => {
                if (item.price) {
                    // priceList.push(Number(item.price?.toFixed()))
                    priceList.push(Number(item.price))
                }
            })
        }
        const maxPrice = Math.max(...priceList)
        let showData = ''
        showData = maxPrice
        return (showData)
    }


    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }
    return <div>
        <div className='boxSection mt-5'>
            <Container>
                <Row className='box-programme gx-4'>

                    {/* <Col xl={12} className='pt-2 mb-5'><h1 className='fs-45 fw-bold'>Popular Programmes</h1></Col> */}
                    <Col xl={12} className='pt-2 mb-4 d-flex flex-row align-items-center justify-content-between'>
                        <div><h1 className='fw-bold'>Popular Programs</h1></div>
                        <div><Link to='/all/programs'><Button variant='primary' size='sm' className='text-light'>View All</Button></Link></div>
                    </Col>

                    {programmeList?.length > 0 ? programmeList?.filter(item => item?.is_popular)?.slice(0, 8)?.map(item => (
                        <Col lg={3} md={6} sm={12} className='customBox'>
                            <a href={`/student/program/detail/${item?.id}`} target='_self'>
                                <Card style={{}} className='mb-5 session-box border-2 border-light'>
                                    <div className="program-card-image">
                                        <Card.Img variant="top" src={item?.banners[0].banner} />
                                    </div>
                                    <Card.Body>
                                        {/*  style={{ height: '175px' }} <Card.Title>Card Title</Card.Title> */}
                                        {/* <div className="main-price">
                                            <div className="price-cirle-color course-price">
                                                <p className='mb-0'><span className='fs-14'>{`RM`}3</span></p>
                                                <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                            </div>
                                        </div>  */}
                                        <div className="main-price">
                                            <div className="course-price d-flex flex-column justify-content-center">
                                                <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                        <Card.Text className='mb-0'>
                                            <div className='description-common-height pt-3'>
                                                {item?.name ? item?.name.substr(0, 60) : ''}
                                            </div>
                                        </Card.Text>
                                        <Card.Text className='mb-4'>
                                            <div className="d-flex align-content-center">
                                                <div className="">
                                                    <div className=''>
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="fs-12 ms-2 pt-1">{Number(item?.average_rating)} 3</div>
                                                {/* <div className="ms-2"><span className='fs-12 text-primary text-decoration-underline'>Rate & Review</span></div> */}
                                            </div>
                                        </Card.Text>

                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </a>

                        </Col>
                    )) : ''}

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;