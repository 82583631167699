import { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import OnGoingProgrammes from "../../../../components/Coach/OnGoing/OnGoingProgrammes";

const OnGoingProgrammesPage = () => {
    
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Coach On Going Programmes</title>
        </Helmet>
        <OnGoingProgrammes />
    </HelmetProvider>
};

export default OnGoingProgrammesPage;