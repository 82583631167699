import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Image } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import classNames from "classnames";
import moment from 'moment';
import Select, { components, MultiValueRemoveProps } from 'react-select'
import Flatpickr from 'react-flatpickr'

// quill editor 
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { ImageDrop } from 'quill-image-drop-module'

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
// Images
import { programIcon, uploadImage } from "../../../../assets/images/index";
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { selectThemeColors } from '../../../..//utils/Utils'
// API Service
import { addProgramService, allCategoryService, allCoachService } from '../../../../service/academyProgramme';
import { setCategoryList, setMainCoachList, setSubCoachList, selectCategoryList, selectMainCoachList, selectSubCoachList } from "../../../../redux/academyProgrammeSlice"
import { PlusCircle, X, Calendar } from 'react-feather';

const CreateProgrammeModal = (props) => {
    const dispatch = useDispatch()
    const [programme_images, setFile] = useState([]);
    const [startDate, setStartDate] = useState([])
    const sportList = useSelector(selectCategoryList);
    const mainCoachList = useSelector(selectMainCoachList);
    const subCoachList = useSelector(selectSubCoachList);
    const [selectedCategory, setCategory] = useState(null);
    const [selectedMainCoach, setMainCoach] = useState(null);
    const [selectedSubCoach, setSubCoach] = useState([]);
    const [selectedType, setType] = useState('')
    const [checked, setChecked] = useState(false);
    const [frequency, setFrequency] = useState([]);
    const [selectedContentLearn, setContentLearn] = useState('');
    const [selectedContentRequirement, setContentRequirement] = useState('');

    const listTypes = [{ label: 'Session', value: 'SESSION', name: 'type' }, { label: 'Weekly', value: 'WEEKLY', name: 'type' }, { label: 'Monthly', value: 'MONTHLY', name: 'type' }]

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            description: '',
            selectedCategory: '',
            selectedMainCoach: '',
            selectedSubCoach: [],
            selectedType: '',
            checked: false,
            frequency: [],
            startDate: new Date()
        },
        mode: "onBlur"
    })

    const fontSizeArr = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '40px']
    const Font = ReactQuill.Quill.import('formats/font')
    const alignClass = Quill.import('attributors/style/align')
    const backgroundClass = ReactQuill.Quill.import('attributors/style/background')
    const colorClass = ReactQuill.Quill.import('attributors/style/color')
    const directionClass = ReactQuill.Quill.import('attributors/style/direction')
    const fontClass = ReactQuill.Quill.import('attributors/style/font')
    const sizeClass = ReactQuill.Quill.import('attributors/style/size')

    ReactQuill.Quill.register(alignClass, true)
    ReactQuill.Quill.register(backgroundClass, true)
    ReactQuill.Quill.register(colorClass, true)
    ReactQuill.Quill.register(directionClass, true)
    ReactQuill.Quill.register(fontClass, true)
    ReactQuill.Quill.register(sizeClass, true)
    ReactQuill.Quill.register(Font, true)
    ReactQuill.Quill.register('modules/imageResize', ImageResize)
    ReactQuill.Quill.register('modules/imageDrop', ImageDrop)

    sizeClass.whitelist = fontSizeArr
    alignClass.whitelist = ["right", "center", "justify"]
    fontClass.whitelist = ['poppins', 'latin']

    const modules = {
        toolbar: [['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ color: [] }, { background: [] }],
        // [{ size: sizeClass.whitelist }],
        [{ font: fontClass.whitelist }],
        [{ align: alignClass.whitelist }],
        ["link", "image", "video"],
        ["clean"]],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            // parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize', 'Toolbar']
        }
    };

    const formats = ["header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "indent",
        'link',
        'image',
        'color',
        'size',
        'video',
        'align',
        'background',
        'direction',
        'code-block',
        'code'];

    const handleAboutChange = (content, delta, source, editor) => {
        setContentLearn(content);
    };
    const handleRequirementChange = (content, delta, source, editor) => {
        setContentRequirement(content);
    };

    /**
         * @function fetchCategoryList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchCategoryList = async () => {
        try {
            const result = await allCategoryService();
            if (result?.data?.status) {
                const resultCategory = result?.data?.data?.map((item) => ({ value: item.id, label: item.name }))
                // const allOptions = [{ value: '', label: 'All' }]
                const allOptions = []
                const finalDrop = allOptions.concat(resultCategory)
                dispatch(setCategoryList(finalDrop))

            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchCoachList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchCoachList = async () => {
        try {
            const result = await allCoachService();
            if (result?.data?.status) {
                const resData = result?.data?.data?.coach_list
                const subCoach = resData.filter(itemInArray => itemInArray.type === 'SUB_COACH')
                const mainCoach = resData.filter(itemInArray => itemInArray.type === 'MAIN_COACH')
                if (mainCoach.length > 0) {
                    const resultCoach = mainCoach?.map((item) => ({ value: item.id, label: <div><Image src={item?.profile_image || uploadImage} alt='' roundedCircle={true} width='18' /><span className='ms-2'>{item?.name}</span></div>, image: item?.profile_image }))
                    // const allOptions = [{ value: '', label: 'All' }]
                    // const finalDrop = allOptions.concat(resultCoach)
                    dispatch(setMainCoachList(resultCoach))
                }

                if (subCoach.length > 0) {
                    const resultCoach = subCoach?.map((item) => ({ value: item.id, label: <div><Image src={item?.profile_image || uploadImage} alt='' roundedCircle={true} width='18' /><span className='ms-2'>{item?.name}</span></div>, image: item?.profile_image }))
                    // const allOptions = [{ value: '', label: 'All' }]
                    // const finalDrop = allOptions.concat(resultCoach)
                    dispatch(setSubCoachList(resultCoach))
                }

            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to create programme
         */
    const onSubmit = async (formdata) => {
        const removeTagLearn = selectedContentLearn.replace(/(<([^>]+)>)/ig, '');
        const removeTagReq = selectedContentRequirement.replace(/(<([^>]+)>)/ig, '');
        try {
            if (programme_images?.length > 0) {
                const data = new FormData()
                const recurringDate = startDate.length > 0 ? moment(startDate[0]).format('YYYY-MM-DD') : ''
                data.append('name', formdata?.name)
                data.append('description', formdata?.description)
                data.append('is_recurring', checked === true ? '1' : '0')
                data.append('sport_id', selectedCategory?.value)
                data.append('coach_id', selectedMainCoach?.value)
                // if (!frequency?.length > 0) {
                //     ErrorAlert('Please add frequencey')
                // }
                data.append('learn', formdata?.learn)
                data.append('requirement', formdata?.requirements)

                if (checked === true) {
                    data.append('recurring_day', '12')
                }
                if (selectedSubCoach && selectedSubCoach?.length > 0) {
                    selectedSubCoach.map((element, index) => (
                        data.append(`sub_coaches[${index}]`, element.value)
                    ))
                }
                if (frequency && frequency?.length > 0) {
                    frequency.forEach((element, index) => {
                        data.append(`frequency_details[${index}][type]`, element.type)
                        data.append(`frequency_details[${index}][number_of_section]`, element.number)
                        data.append(`frequency_details[${index}][price]`, element.price)
                    })
                }

                if (programme_images?.length > 0) {
                    data.append(`images[${0}]`, programme_images[0])
                }
                const result = await addProgramService(data);
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    reset()
                    if (props) {
                        props.handleClose()
                        props?.fetchAcademyProgramList(1)
                    }
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                ErrorAlert('Please select program images')
            }

        } catch (error) {
            ErrorAlert(error)
        }
    };

    const handleAddItem = () => {
        setFrequency([...frequency, { type: '', number: '', price: null }])
    }

    const handleRemoveItem = (i) => {
        const result = frequency.filter((item, index) => index === i)
        const deleteVal = [...frequency]
        deleteVal.splice(i, 1)
        setFrequency(deleteVal)
    }

    const handleTypeChange = (e, i) => {
        const { name, value } = e
        const onChangeVal = [...frequency]
        onChangeVal[i][name] = value
        setFrequency(onChangeVal)
    }
    const handleNumberChange = (e, i) => {
        const { name, value } = e?.target
        const onChangeVal = [...frequency]
        onChangeVal[i][name] = value
        setFrequency(onChangeVal)
    }
    const handlePriceChange = (e, i) => {
        const { name, value } = e?.target
        const onChangeVal = [...frequency]
        onChangeVal[i][name] = value
        setFrequency(onChangeVal)
    }

    useEffect(() => {
        fetchCategoryList()
        fetchCoachList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <Modal show={props.show} onHide={props.handleClose} size={props.size} centered>
            <Modal.Header className='fs-22 fw-bold border-0 px-5 pt-4' closeButton >
                Create Program
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center px-5 pb-4'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <input className='d-none' type='file' id="programmePhotoUpload" accept="image/*" multiple onChange={(e) => {
                        if (e.target.files.length > 0) {
                            const file = e.target.files;
                            const showImage = e.target.files[0];
                            const img = document.createElement("img");
                            img.onload = function () {
                                if (img.width <= 1200) {
                                    setFile(file)
                                } else {
                                    ErrorAlert("Please upload the correct ratio program image")
                                }
                            };
                            img.src = URL.createObjectURL(showImage);
                        }
                    }} onClick={(e) => e.target.value} />
                    <label className='w-100' htmlFor="programmePhotoUpload">

                        {programme_images?.length > 0 ? <img role='button' className='w-100 img-fluid' src={URL.createObjectURL(programme_images.length > 0 ? programme_images[0] : '')} alt={'programmeImage'} /> : <div role='button' className='d-flex flex-column justify-content-center align-items-center py-5' style={{ background: '#FFF8F5' }}>
                            <Image className='mb-3' />
                            <img src={programIcon} width='40' alt='program-icon' />
                            <span className='fs-18 fw-600 text-primary mb-3'>Add Banners here</span>
                            <span className='fw-400 text-secondary'>Maximum width 480 pixels, 16:9 recommended</span>
                        </div>
                        }
                    </label>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-600'>Program name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name here"
                            {...register("name", {
                                required: "Please provide the program name",
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-600'>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Description"
                            {...register("description", {
                                required: "Please provide the description",
                            })}
                        />
                        {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-600'>What students will learn</Form.Label>
                        {/* <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder='Brief Here'
                            required
                            value={selectedContentLearn}
                            onChange={handleAboutChange}
                        /> */}

                        <Controller
                            id='learn'
                            name='learn'
                            defaultValue=''
                            control={control}
                            render={({ field }) => <ReactQuill
                                theme="snow"
                                placeholder='Brief Here'
                                value={field.value}
                                modules={modules}
                                formats={formats}
                                onChange={field.onChange}
                            />}
                            rules={{
                                required: "Please provide the Learning topics",
                                pattern: {
                                    value: /(?<=>)([\w\s]+)(?=<\/)/gm,
                                    message: "Please provide the Learning topics"
                                }
                            }}
                        />
                        {errors?.learn && <ErrorText>{errors?.learn?.message}</ErrorText>}

                        {/* <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Brief Here"
                            {...register("topic", {
                                required: "Please provide the topics",
                            })}
                        />
                        {errors.topic && <ErrorText>{errors.topic.message}</ErrorText>} */}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-600'>Requirements</Form.Label>
                        {/* <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder='Brief Here'
                            required
                            value={selectedContentRequirement}
                            onChange={handleRequirementChange}
                        /> */}
                        <Controller
                            id='requirements'
                            name='requirements'
                            defaultValue=''
                            control={control}
                            render={({ field }) => <ReactQuill
                                theme="snow"
                                placeholder='Brief Here'
                                value={field.value}
                                modules={modules}
                                formats={formats}
                                onChange={field.onChange}
                            />}
                            rules={{
                                required: "Please provide the requirements",
                                pattern: {
                                    value: /(?<=>)([\w\s]+)(?=<\/)/gm,
                                    message: "Please provide the requirements"
                                }
                            }}
                        />
                        {errors?.requirements && <ErrorText>{errors?.requirements?.message}</ErrorText>}

                        {/* <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Brief Here"
                            {...register("requirement", {
                                required: "Please provide the requirements",
                            })}
                        />
                        {errors.requirement && <ErrorText>{errors.requirement.message}</ErrorText>} */}
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label className='fs-14 fw-600'>Sports Category</Form.Label>

                        <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedCategory}
                            name='clear'
                            options={sportList}
                            placeholder='Sport Type'
                            onChange={setCategory}
                        />

                    </Form.Group>
                    <Form.Group className="mb-4">
                        <h4 className='fw-600'>Assign Coach</h4>
                        <Form.Label className='fs-14 fw-600'>Select Main Coach</Form.Label>
                        <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            required
                            defaultValue={selectedMainCoach}
                            name='clear'
                            options={mainCoachList}
                            placeholder='Main Coach'
                            onChange={setMainCoach}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-600'>Select Sub Coach</Form.Label>
                        <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedSubCoach}
                            required
                            name='clear'
                            isMulti={true}
                            options={subCoachList}
                            placeholder='Sub Coach'
                            onChange={setSubCoach}
                        />
                    </Form.Group>

                    <div className="frequency-list mb-4">
                        <div className="frequency-content">
                            <Row className='mb-2'>
                                <Col md={12}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        {/* <div className="add-title text-dark fs-18 fw-600">  <span className="add-title text-dark fs-18 fw-600">Frequency 1</span></div> */}
                                        <span className="add-title text-dark fs-18 fw-600">Frequency</span>
                                        <div className="add-content text-primary fs-14 fw-600 cursor-pointer">
                                            {frequency?.length === 0 ? <p onClick={() => handleAddItem()} className='fs-600 mb-0'><span><PlusCircle className='text-primary' size={24} /></span> <span className="text-primary fs-14 fw-bold rounded-2 p-1">Add Frequency</span></p> : frequency?.length >= 3 ? '' : <p onClick={() => handleAddItem()} className='fs-600 mb-0'><span><PlusCircle className='text-primary' size={24} /></span> <span className="text-primary fs-14 fw-bold rounded-2 p-1">Add More</span></p>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {frequency?.map((item, i) => <Fragment>
                                <div className="inputs-fields">
                                    <Row className=''>
                                        <Col md={12}>
                                            <div className="cancel-mobile">
                                                <div className="cancel float-end text-primary cursor-pointer">
                                                    <X size={22} onClick={(e) => handleRemoveItem(i)} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={12} sm={12} className="">
                                            <div className="frequency-adjust">
                                                <div className="custom-dropdown w-50-freq">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className='fs-14 fw-600'>Select type of frequency</Form.Label>
                                                        <Select
                                                            theme={selectThemeColors}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            defaultValue={selectedType}
                                                            isMulti={false}
                                                            options={listTypes}
                                                            required
                                                            placeholder='Type'
                                                            name="type"
                                                            onChange={(e) => handleTypeChange(e, i)}
                                                        // onChange={(e) => handleTypeChange(e, i)}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="custom-number w-50-freq mx-1">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className='fs-14 fw-600'>Number of Session</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            className='py-2'
                                                            placeholder="Enter number here"
                                                            required="Please provide the number"
                                                            name='number'
                                                            onChange={(e) => handleNumberChange(e, i)}

                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="custom-price w-50-freq pt-2">
                                                    <Form.Label className='fs-14 fw-600 mb-1'>Price</Form.Label>
                                                    <InputGroup className="mt-1">
                                                        <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Enter price here"
                                                            step='any'
                                                            // step="0.25"
                                                            required="Please provide the price"
                                                            name='price'
                                                            onChange={(e) => handlePriceChange(e, i)}
                                                        />
                                                        {/* {errors.name && <ErrorText>{errors.name.message}</ErrorText>} */}
                                                    </InputGroup>
                                                </div>
                                                <div className="cancel-web">
                                                    <div className="cancel float-end text-primary cursor-pointer">
                                                        <X size={22} onClick={(e) => handleRemoveItem(i)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        {i === frequency.length - 1 ? '' : <hr className='program-custom-hr' />}
                                    </Row>
                                </div>
                            </Fragment>
                            )}
                        </div>

                    </div>

                    <Form.Check
                        type='checkbox'
                        // role='button'
                        className='d-flex align-items-center'
                        label={<p className='ms-1 fw-bold mb-0 fs-14 mt-1'>
                            Recurring Payment?
                        </p>}
                        id={`recurring-payment`}
                        checked={checked}
                        value="1"
                        onChange={(e) => setChecked(e.currentTarget.checked)}

                    />

                    {checked ? <Fragment>
                        <div className="recurring-date pt-3">
                            <p className='recurring -label'>Set a day of month to indicate when to send the payment link email</p>
                            <Form.Group className="">
                                <Form.Label className='fs-14 fw-600'>Day</Form.Label>
                                <InputGroup>
                                    <Flatpickr
                                        className='form-control'
                                        value={startDate}
                                        id='timepicker'
                                        options={{
                                            static: true,
                                            altInput: true,
                                            allowInput: true,
                                            enableTime: false,
                                            noCalendar: false,
                                            dateFormat: "Y-m-d",
                                        }}
                                        onChange={date => setStartDate(date)}
                                    />
                                    <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </Fragment> : ''}

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Create</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>
};

export default CreateProgrammeModal;