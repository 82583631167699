import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    programmeList: [],
    sessionDropList: [],
    sessionListing: [],
    mainCoachList: [],
    subCoachList: [],
    categoryList: [],
    studentList: [],
    totalEntry: 0,
    onGoingList: [],
    onGoingTotalEntry: 0,
    isFresh: false
}

export const programmeSlice = createSlice({
    name: "programmeSlice",
    initialState,
    reducers: {
        setProgrammeList: (state, action) => {
            state.programmeList = action.payload
        },
        setSessionDropList: (state, action) => {
            state.sessionDropList = action.payload
        },
        setMainCoachList: (state, action) => {
            state.mainCoachList = action.payload
        },
        setSubCoachList: (state, action) => {
            state.subCoachList = action.payload
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setStudentList: (state, action) => {
            state.studentList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setOnGoingList: (state, action) => {
            state.onGoingList = action.payload
        },
        setOnGoingTotalEntry: (state, action) => {
            state.onGoingTotalEntry = action.payload
        },
        setSessionListing: (state, action) => {
            state.sessionListing = action.payload
        },
        setFreshData: (state, action) => {
            state.isFresh = action.payload
        },

    }
})

export const { setOnGoingList, setFreshData, setSessionListing, setOnGoingTotalEntry, setProgrammeList, setTotalEntry, setSessionDropList, setStudentList, setMainCoachList, setSubCoachList, setCategoryList } = programmeSlice.actions

export const selectProgrammeList = (state) => state.coachProgram.programmeList
export const selectSessionDropList = (state) => state.coachProgram.sessionDropList
export const selectSessionListing = (state) => state.coachProgram.sessionListing
export const selectMainCoachList = (state) => state.coachProgram.mainCoachList
export const selectSubCoachList = (state) => state.coachProgram.subCoachList
export const selectCategoryList = (state) => state.coachProgram.categoryList
export const selectStudentList = (state) => state.coachProgram.studentList
export const selectTotalEntry = (state) => state.coachProgram.totalEntry
export const selectOnGoingTotalEntry = (state) => state.coachProgram.onGoingTotalEntry
export const selectOnGoingList = (state) => state.coachProgram.onGoingList
export const selectFreshData = (state) => state.coachProgram.isFresh

export default programmeSlice.reducer