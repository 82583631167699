import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser, uploadImage } from "../../../../assets/images/index";

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import AddProgramme from './AddProgramme'
import NoProgram from "../../../../components/Common/NoProCoach";
import { setFreshData, selectFreshData, setStudentList, setTotalEntry, selectTotalEntry } from "../../../../redux/academyProgrammeSlice"

const Overview = ({ singleData }) => {
    const user = useSelector(selectUser);
    const freshData = useSelector(selectFreshData);
    const [showMore, setShowMore] = useState(false)
    const [indexWise, setIndex] = useState('')

    const showMoreProfile = (item, index) => {
        if (item?.id) {
            setShowMore(!showMore)
            setIndex(item?.id)
        }
    }

    useEffect(() => {
        if (user?.type === 'academy') {

        }
    }, [user])

    const checkPlan = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'Weekly Payment'
        } else if (type === 'MONTHLY') {
            showData = 'Monthly Payment'
        } else if (type === 'SESSION') {
            showData = 'Session Payment'
        }
        return (showData)
    }
    const checkPlanDuration = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'per Week'
        } else if (type === 'MONTHLY') {
            showData = 'per Month'
        } else if (type === 'SESSION') {
            showData = 'per Session'
        }
        return (showData)
    }

    const checkPrice = (item) => {
        const checkPlan = (item) => {
            const priceList = Math.max(item?.frequencies)
            let showData = ''
            showData = priceList
            return (showData)
        }
    }

    return <div>
        <div className='overview-section m-3'>
            <div>
                <Row className='mb-4'>
                    <Col xl={12} md={12} xs={12} className='pt-3 mb-2'>
                        <div className=''>
                            <div>
                                <h1 className='fs-25 fw-bold mb-4'>What Students will learn</h1>
                            </div>
                        </div>
                        <div className=''>
                            <div className='programme-title mb-3'>{singleData?.name}</div>
                            <div className='programme-list'>
                                <p className='academy-learn-description'>{singleData?.learn ? parse(singleData?.learn) : ''}</p>
                                {/* <p className='academy-learn-description'>{singleData?.learn}</p> */}
                                {/* <ul className='list'>
                                    {list?.length > 0 ? list.map(item => (
                                        <li>{item?.name}</li>
                                    )) : <NoProgram />}
                                </ul> */}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='box-coach gx-5 mb-4'>
                    <div className='mb-3'>
                        <div>
                            <h1 className='fs-25 fw-bold'>Assigned Coach Details</h1>
                        </div>
                    </div>
                    {singleData?.main_coach?.type ? <>
                        <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                            {/* /academy/coach/detail/${item?.id} */}
                            <div className='text-decoration-none'>
                                <div>
                                    <div className='coach-profile mb-3'>
                                        {singleData?.main_coach?.profile_image ? <img src={singleData?.main_coach?.profile_image} alt='coach-profile 1' width='60' /> : <img src={uploadImage} alt='coach-profile 2' width='60' />}
                                    </div>
                                    <div className='coach-name'>
                                        <h5 className='fw-600 text-dark fs-18'>{singleData?.main_coach?.name}</h5>
                                    </div>
                                    <div className='coach-type'>
                                        {singleData?.main_coach?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                    </div>
                                    <div className='coach-des'>
                                        <p className='mb-0 text-secondary'>
                                            {singleData?.main_coach?.description ? singleData?.main_coach?.description?.substr(0, 250) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </Col> </> : ''}
                    {singleData?.sub_coaches?.length > 0 ? singleData?.sub_coaches.map((item, index) => (
                        <Col lg={4} md={6} sm={6} xs={6} className='mb-4' key={index}>
                            {/* /academy/coach/detail/${item?.id} */}
                            <div className='text-decoration-none'>
                                <div>
                                    <div className='coach-profile mb-3'>
                                        <img src={item?.profile_image || uploadImage} alt='coach-profile' width='60' />
                                    </div>
                                    <div className='coach-name'>
                                        <h5 className='fw-600 text-dark fs-18'>{item?.name}</h5>
                                    </div>
                                    <div className='coach-type'>
                                        {item?.type === 'SUB_COACH' ? <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p> : ''}
                                    </div>
                                    <div className='coach-des'>
                                        <p className='mb-0 text-secondary'>
                                            {showMore && item?.id === indexWise ? item?.description : item?.description ? item?.description?.substr(0, 200) : ''}
                                        </p>
                                        <p className='mb-0'>
                                            {item?.description?.length > 200 ? showMore && item?.id === indexWise ? <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show less</span> : <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show more</span> : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )) : ''}

                </Row>

                <Row>
                    <div className='overview-pricing'>
                        <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                            <div className='text-center'>
                                <div>
                                    <h1 className='fs-30 fw-bold mb-3'>Pricing Details</h1>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
                <div className='overview-pricing-box'>
                    <Row>
                        {singleData?.frequencies?.length > 0 ? singleData?.frequencies.map((item, index) => {
                            return (
                                <Col xl={4} md={6} xs={12} className='pt-3 mb-1' key={index}>
                                    <div className='price-box'>
                                        <div className=''>
                                            <div className='monthly-plan'>
                                                <p className='fs-16 mb-3 text-primary'>{checkPlan(item)}</p>
                                            </div>
                                            <div className='monthly-price'>
                                                <h1 className='fs-25 fw-bold mb-3 text-dark'>{`RM ${item.price ? Number(item.price)?.toFixed(2) : ''}`}</h1>
                                            </div>
                                            <div className='per-session'>
                                                <p className=''>{checkPlanDuration(item)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) : ''}


                        {/* <Col xl={4} md={6} xs={12} className='pt-3 mb-1'>
                            <div className='price-box'>
                                <div className=''>
                                    <div className='monthly-plan'>
                                        <p className='fs-24 mb-3 text-primary'>Session Payment</p>
                                    </div>
                                    <div className='monthly-price'>
                                        <h1 className='fs-30 fw-bold mb-3 text-dark'>RM 80.50</h1>
                                    </div>
                                    <div className='per-session'>
                                        <p className=''>per session</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} md={6} xs={12} className='pt-3 mb-1'>
                            <div className='price-box'>
                                <div className=''>
                                    <div className='monthly-plan'>
                                        <p className='fs-24 mb-3 text-primary'>Weekly Plan</p>
                                    </div>
                                    <div className='monthly-price'>
                                        <h1 className='fs-30 fw-bold mb-3 text-dark'>RM 80.50</h1>
                                    </div>
                                    <div className='per-session'>
                                        <p className=''>per week</p>
                                    </div>
                                </div>
                            </div>
                        </Col> */}


                    </Row>

                </div>

                <Row>
                    <div className='overview-requirement mt-5'>
                        <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                            <div className=''>
                                <div>
                                    <h1 className='fs-28 fw-bold mb-3'>Requirements</h1>
                                </div>
                            </div>
                            <div className=''>
                                <div className='programme-list'>
                                    <div className="academy-learn-description">
                                        <p>{singleData?.requirement ? parse(singleData?.requirement) : ''}</p>
                                    </div>

                                    {/* <ul className='list'>
                                        {frequencies?.length > 0 ? requirements.map(item => (
                                            <li>{item?.name}</li>
                                        )) : <NoProgram />}
                                    </ul> */}
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </div>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Overview;