import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { getCustomerPrivacyPolicyService, getOrganizerPrivacyPolicyService } from '../../../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";

const PrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState()
    const user = useSelector(selectUser)

    /**
        * @function fetchCustomerPrivacyPolicy
        * @params
        * @description fetch the content of privacy policy for customer
    */
    const fetchCustomerPrivacyPolicy = async () => {
        try {
            const result = await getCustomerPrivacyPolicyService();
            if (result?.data?.status) {
                setPrivacyPolicy(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerPrivacyPolicy
        * @params
        * @description fetch the content of privacy policy for organizer
    */
    const fetchOrganizerPrivacyPolicy = async () => {
        try {
            const result = await getOrganizerPrivacyPolicyService();
            if (result?.data?.status) {
                setPrivacyPolicy(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerPrivacyPolicy()
        } else {
            fetchCustomerPrivacyPolicy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
    return <HelmetProvider>
        <Helmet>
            <title>Privacy Policy | AFA Coaching</title>
        </Helmet>
        {privacyPolicy && parse(privacyPolicy)}
    </HelmetProvider>
};

export default PrivacyPolicy;