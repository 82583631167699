import { useState, useEffect, Fragment } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom'

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../../../assets/images/index";

// API Service
import { allProgramsService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { setProgrammeList, selectProgrammeList } from "../../../../redux/academyProgrammeSlice"
import ReactStars from 'react-rating-star-with-type'
import AddProgramme from './AddProgramme'
import NoBank from "../../../../components/Common/NoProgram";

const Programmes = () => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const programsList = useSelector(selectProgrammeList);
    const [addBankAccountModal, setAddBankAccountModal] = useState(false)
    const [editBankAccountModal, setEditBankAccountModal] = useState(false)
    /**
         * @function fetchAcademyProgramList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchAcademyProgramList = async () => {
        try {
            const result = await allProgramsService();
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setProgrammeList(result?.data?.data?.program_list))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
         * @function handleAddBankAccountModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleAddBankAccountModal = () => {
        setAddBankAccountModal(!addBankAccountModal)
    }

    /**
         * @function handleEditBankAccountModal
         * @params
         * @description set the visibility of edit bank account modal
         */

    const handleEditBankAccountModal = () => {
        setEditBankAccountModal(!editBankAccountModal)
    }

    useEffect(() => {
        if (user?.type === 'academy') {
            fetchAcademyProgramList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const checkPrice = (item) => {
        const priceList = []
        if (item?.frequencies?.length > 0) {
            item?.frequencies.forEach((item) => {
                if (item.price) {
                    priceList.push(Number(item.price))
                }
            })
        }
        const maxPrice = Math.max(...priceList)
        let showData = ''
        showData = maxPrice
        return (showData)
    }

    if (isLoading) {
        return (
            <>
                <Fragment>
                    <>
                        <div className='parent'>
                            <div className='myHeight text-center'>
                                <Spinner variant='primary' />
                            </div>
                        </div>
                    </>
                </Fragment>
            </>
        )
    }

    return <div>
        <div className='boxSection m-3'>
            <div>
                <Row>
                    <Col xl={12} className='pt-3 mb-4'>
                        <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                            <div><h1 className='fs-24 fw-bold'>My Programs</h1></div>
                            <div><Button size="md" variant="outline-primary" className='px-5 fw-600' onClick={handleAddBankAccountModal}>Create Program</Button></div>
                        </div>
                    </Col>
                </Row>

                <Row className='box-programme gx-4'>

                    {programsList?.length > 0 ? programsList?.map(item => (
                        <Col xl={3} lg={6} md={6} sm={12} p-3 col-lg-3 col-md-4 col-sm-6 col-12 className='customBox mb-5 '>
                            <Link to={`/academy/programme/detail/${item?.id}`} className='text-decoration-none'>
                                <Card style={{}} className='h-100 session-box border-2 border-light'>
                                    <div className="program-card-image">
                                        {item?.banners?.length > 0 ? <Card.Img variant="top" src={item?.banners[0].banner} /> : <Card.Img variant="top" src={post1Image} />}
                                    </div>

                                    <Card.Body className='d-flex flex-column'>
                                        <div className="custom-chip-box">
                                            {item?.status ? <div className="custom-chip-success">Open</div> : <div className="custom-chip-danger">Closed</div>}
                                        </div>
                                        <div className="main-price">
                                            <div className="course-price d-flex flex-column justify-content-center">
                                                <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                        <Card.Text className='pt-4 mb-0'>
                                            <div className='description-common-height wrap_after_two_line'>
                                                {item?.name ? item?.name?.substr(0, 60) : ''}
                                            </div>
                                        </Card.Text>
                                        <Card.Text className='mb-4'>
                                            <p className='d-flex align-items-center'>
                                                <div className="div">
                                                    <ReactStars
                                                        disabled
                                                        value={item?.average_rating}
                                                        edit={true}
                                                        activeColors={["#FDB022"]}
                                                    />
                                                </div>
                                                <div className="mt-1"><span className='fs-12'>{item?.average_rating}</span></div>
                                            </p>
                                        </Card.Text>

                                        <div className='d-flex flex-row justify-content-between align-items-center fw-500 mt-auto'>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                            <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    )) : <NoBank handleAddBankAccountModal={handleAddBankAccountModal} />}

                    {addBankAccountModal ? <AddProgramme show={addBankAccountModal} handleClose={handleAddBankAccountModal} size='lg' fetchAcademyProgramList={fetchAcademyProgramList} /> : ''}
                </Row>
            </div>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;