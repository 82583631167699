import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import ProgList from "../../../../components/Homepage/ProgramList";

const ProgramList = () => {
    return <HelmetProvider>
        <Helmet>
            <title>Program List || AFA Coaching</title>
        </Helmet>
        <div className="bg-grey">
            <ProgList />
        </div>
    </HelmetProvider>
};

export default ProgramList;