import axios from "axios"

/**
    * @function accessListService
    * @param
    * @description This function is used to get all country
*/
export const accessListService = async({page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/access-manager/list?paginate=${true}&page=${page}&perPage=${countPerPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterManagerService
    * @param
    * @description This function is used to get filter earning
*/
export const filterManagerService = async({start_date, end_date}) =>{
    try {
        const result = await axios.get(`/academy/access-manager/list?paginate=${false}&start_date=${start_date}&end_date=${end_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function downloadCSVManagerService
    * @param
    * @description This function is used to get access manager listing
*/
export const downloadCSVManagerService = async() =>{
    try {
        const result = await axios.get(`/academy/access-manager/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function accessSearchListService
    * @param
    * @description This function is used to get all country
*/
export const accessSearchListService = async({searchValue, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/access-manager/list?paginate=${true}&page=${page}&perPage=${countPerPage}&keyword=${searchValue}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function accessStatusChange
    * @param
    * @description This function is used to get all country
*/
export const accessStatusChange = async({id}) =>{
    try {
        const result = await axios.post(`/academy/access-manager/status/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function accessSingleService
    * @param
    * @description This function is used to get all country
*/
export const accessSingleService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/access-manager/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addAccessService
    * @param data
    * @description This function is used to update profile data for player
*/
export const addAccessService = async (data) => {
    try {
        const result = await axios.post(`/academy/access-manager/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateAccessService
    * @param data
    * @description This function is used to update profile data for player
*/
export const updateAccessService = async ({id}, data) => {
    try {
        const result = await axios.post(`/academy/access-manager/edit/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteAccessService
    * @param data
    * @description This function is used to update profile data for player
*/
export const deleteAccessService = async ({id}) => {
    try {
        const result = await axios.delete(`/academy/access-manager/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function changePasswordService
    * @param data
    * @description This function is used to change password for player
*/
export const changePasswordService = async (data) => {
    try {
        const result = await axios.post('/customer/auth/change-password', data)
        return result;
    } catch (error) {
        return error;
    }
}
