import React, { useEffect, useState } from 'react'
// thrid party
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import moment from 'moment/moment';
import { ganttChartCalendarService } from '../../../service/ganttChartCalendarService';
import ErrorAlert from '../../Alert/ErrorAlert';

const GanttChart = (props) => {
    const [tasks, setTasks] = useState([])
    const [chartDate, setChartDate] = useState()

    console.log('first', moment(props?.date).format("YYYY-MM-DD"))

    /**
         * @function fetchGanttChartCalendarData
         * @params
         * @description fetch the list of gantt chart
         */
    const fetchGanttChartCalendarData = async () => {
        try {
            const result = await ganttChartCalendarService(moment(props?.date).format("YYYY-MM-DD"));
            if (result?.data?.status) {
                const tempTask = []

                result?.data?.data?.forEach((item, index) => {
                    item?.programs?.forEach((itemProgram, indexProgram) => {
                        itemProgram?.sessions?.forEach((itemSession, indexSession) => {
                            tempTask?.push({
                                start: moment(`${itemSession?.session_date} ${itemSession?.timeslots[0]?.start_time}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                                end: moment(`${itemSession?.session_date} ${itemSession?.timeslots[0]?.end_time}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                                name: itemProgram?.program_name,
                                coachName: item?.coach_name,
                                id: indexSession,
                                type: 'task',
                                progress: 100,
                                isDisabled: true,
                                styles: { progressColor: 'red' },
                            })
                        })
                    })
                })
                console.log('tempTask', tempTask)
                setTasks(tempTask)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchGanttChartCalendarData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.date])

    // const tasks = [{
    //     start: new Date('2024-04-22T18:00:00'),
    //     end: new Date('2024-04-22T19:00:00'),
    //     name: 'Idea',
    //     coachName: 'Tom',
    //     id: 'Task 0',
    //     type: 'task',
    //     progress: 100,
    //     isDisabled: true,
    //     styles: { progressColor: 'red' },
    // }];


    // console.log('moment', moment.tz("2013-11-18 11:55", "America/Toronto"))
    const TaskListTable = ({
        tasks,
        rowWidth,
        rowHeight,
        onExpanderClick,
    }) => {
        return (
            <div style={{ border: "1px solid #dfe1e5", width: rowWidth }}>
                {tasks?.length > 0 && tasks?.map((item, i) => {
                    const isProject = item.type === "project";
                    return (
                        <div
                            key={item.id}
                            style={{
                                height: rowHeight,
                                width: rowWidth,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                cursor: isProject ? "pointer" : "auto",
                                fontFamily: "sans-serif",
                                background: i % 2 === 0 ? "#ffffff" : "#f4f5f7",
                                padding: 10,
                                paddingLeft: isProject ? 10 : 40,
                            }}
                        >
                            <p
                                onClick={() => onExpanderClick(item)}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 0
                                }}
                            >
                                {isProject ? "> " : ""}
                                {item.coachName}
                            </p>
                        </div>
                    );
                })}
            </div>
        );
    };

    // ganttChartFlatpickr

    return (
        <>
            {tasks?.length > 0 ? <Gantt
                tasks={tasks}
                preStepsCount={11}
                viewMode='Hour'
                viewDate={new Date()}
                TaskListHeader={({ headerHeight, rowWidth }) => (
                    <div className='border border-end-0 d-flex align-items-center'
                        style={{
                            height: headerHeight,
                            width: rowWidth
                        }}
                    >
                        <div className='ms-3 mb-0 mt-2 w-100 fs-12'>Coach Name</div>
                    </div>
                )}
                TaskListTable={(props) => (
                    <TaskListTable {...props} />
                )} /> : <>
                <p className='text-center pt-5'>No time slot is here</p>
            </>}
        </>
    )
}

export default GanttChart