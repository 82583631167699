import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom"
import axios from "axios"

// moment js
// import moment from 'moment'

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
// import "./@core/scss/core.scss"  // extra over
import '././assets/css/calendar.css'
import '././assets/css/quill.css'
import '././assets/css/style.css'
import '././assets/css/responsive.css'
// flatpick css
import '././assets/css/flatpickr.min.css'
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/flatpickr.css";
import 'flatpickr/dist/plugins/monthSelect/style.css'
// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
// image light box CSS
import 'react-18-image-lightbox/style.css';
// datepicker css
import "react-datepicker/dist/react-datepicker.css";
// gantt chart css
import "gantt-task-react/dist/index.css";
// schedular css
import 'react-big-scheduler/lib/css/style.css'
// timepicker css
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// SCSS
import './assets/scss/index.scss';

// Redux Store
import store from "./redux/store"

axios.interceptors.request.use((request) => {
    if (request.url) {
        request.url = process.env.REACT_APP_APP_API_BACKEND_SERVER + request.url;
    }
    if (localStorage.getItem("token")) {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    }
    return request;
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
