import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom'
import classNames from "classnames";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import Select, { components, MultiValueRemoveProps } from 'react-select'
import Flatpickr from 'react-flatpickr'
// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button';

import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { joinStudentSession, getStudentSession } from '../../service/studentProgram';
import { setMySession, selectMySession } from "../../redux/slices/studentProgramme"
import { PlusCircle, X, Search } from 'react-feather';

const JoinSessionModal = ({ rowData, ...props }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [checkSession, setCheckSession] = useState(false)
    const [sessionObject, setSessionObject] = useState([])
    const [color, setColor] = useState(false)
    const all_session = useSelector(selectMySession);
    const [send_session, setSendSession] = useState(false)

    const navigate = useNavigate()
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            all_session: [],
            startDate: new Date()
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    const showModal = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setSessionObject([])
    }

    const handleCheckSession = (subItem, sIndex) => {
        const temp = [...sessionObject]
        if (!sessionObject?.some(item => item?.session_id === subItem?.session_id)) {
            // if (!sessionObject?.length > 0) {
            temp?.push(subItem)
            setSessionObject(temp)
        } else if (sessionObject?.some(item => item?.id === subItem?.id)) {
            setSessionObject(sessionObject?.filter(item => item?.id !== subItem?.id))
        } else {
            ErrorAlert('You can not select multiple timeslot of same session.')
            // ErrorAlert('You can not select multiple timeslot.')

        }
    }

    /**
         * @function fetchCustomerSessionList
         * @params
         * @description fetch the list of customer session
         */
    const fetchCustomerSessionList = async () => {
        try {
            const result = await getStudentSession({ id: rowData?.id });
            if (result?.data?.status) {
                dispatch(setMySession(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleJoinSession
         * @params 
         * @description used to join session
         */
    const handleJoinSession = async (objIndex) => {
        try {
            console.log('sessionObject', rowData, sessionObject, all_session, objIndex, all_session[objIndex]?.timeslots, sessionObject?.filter((item) => all_session[objIndex]?.timeslots?.find((itemS) => itemS?.id === item?.id)))

            const selectedSession = sessionObject?.filter((item) => all_session[objIndex]?.timeslots?.find((itemS) => itemS?.id === item?.id))

            if (rowData?.id) {
                const data = new FormData()
                data.append('session_id', selectedSession[0]?.session_id)
                data.append('timeslot_id', selectedSession[0]?.id)
                if (rowData?.students?.length > 0) {
                    rowData?.students.map((element, index) => (
                        data.append(`students[${index}]`, element?.id)
                    ))
                }
                const result = await joinStudentSession({ data, id: rowData?.id });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    setCheckSession(false)
                    setSessionObject([])
                    setColor(false)
                    reset()
                    // handleClose()
                    fetchCustomerSessionList()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (show) {
            fetchCustomerSessionList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return <>
        <div className="div">
            <div className="join-modal">
                <Button variant='primary' className='text-light' onClick={showModal}>Join the session</Button>
            </div>
        </div>
        <div className="modal">
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header className='fs-20 fw-bold border-0' closeButton >
                </Modal.Header>
                <Modal.Body className='pt-0'>

                    <div className="session-list">
                        <div className="div">
                            <div className="ms-3 mb-3">
                                <p className='fs-24 fw-bold mb-0'>Join the Session</p>
                                <p className='text-primary modal-session'> 4 session per month</p>
                            </div>

                            {all_session.length > 0 ? all_session.map((item, index) => {
                                return (
                                    <div className='parent-session pl-3' key={`ul-${index}`}>
                                        <div className="date-session">
                                            <p className='fw-600'> Session {index + 1}</p>
                                            <p className='mb-0 fw-600'>{item?.date ? moment(item?.date).format('DD/MM/YYYY, dddd') : ''} </p>
                                        </div>

                                        <div className="d-flex flex-wrap align-items-center align-items-start">
                                            {item?.timeslots.map((subItem, sIndex) => {
                                                return <>
                                                    <div className='parent-child-session ms-3 mb-2' key={`li-${sIndex}`}>
                                                        <Button variant='' className={classNames('border', { 'bg-primary text-light': sessionObject?.some(sessionItem => sessionItem?.id === subItem?.id), 'bg-light text-dark pe-none opacity-50': !subItem?.is_open, "bg-primary opacity-50 text-light pe-none": subItem?.is_joined, 'pe-none opacity-50': item?.timeslots?.some((itemTime) => itemTime?.is_joined) })} onClick={() => handleCheckSession(subItem, sIndex)}> {`${subItem?.start_time}`} {`- ${subItem?.end_time}`} </Button>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                        <div className="horizental-row">
                                            <div className="joined mt-3 mb-1"> {item?.timeslots?.some((itemTime) => itemTime?.is_joined) ? <Button size='sm' variant="success" className='px-5 text-light join-button pe-none'> Joined</Button> : <Button variant='primary' size='sm' className='text-light join-button px-4' onClick={() => handleJoinSession(index)}> Join session </Button>} </div>
                                            <div className="show-row">
                                                {item === item?.timeslots.length - 1 ? '' : <hr className='program-custom-hr' />}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : <p className='text-center'> No session available</p>}
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    </>
};

export default JoinSessionModal;