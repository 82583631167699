import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, ModalHeader, ModalFooter, ModalBody, Image } from 'react-bootstrap';
import { Button } from 'reactstrap'
import { useForm, useFieldArray, Controller } from "react-hook-form";
// third party component
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import toast from 'react-hot-toast'
import { selectThemeColors } from '../../../..//utils/Utils'
// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Calendar } from 'react-feather';
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker'
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { uploadImage } from '../../../../assets/images/index'

// API Service
import { updateCoachService, deleteCoachService, coachListService, coachSingleService } from '../../../../service/coach.service';

import { setCoachList, setFreshList, setTotalEntry, selectCoachList, selectFreshData, selectTotalEntry, selectSingleDetail } from "../../../../redux/coachSlice"
import { selectUser } from "../../../../redux/userSlice"

const UpdateCoach = ({ localData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const user = useSelector(selectUser);
    const [startDate, setStartDate] = useState(new Date())
    const [coachImage, setFile] = useState(null);
    const [showImage, setProfileImage] = useState(null);
    const [coachType, setCoachType] = useState('');
    const [selectedGender, setSelectedGender] = useState('');

    const allCoach = [{ label: 'Main Coach', value: 'MAIN_COACH' }, { label: 'Sub Coach', value: 'SUB_COACH' }]
    const allGender = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
    const countPerPage = 10
    const page = 1

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            description: '',
            password: '',
            password_confirmation: ''
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });


    // for setting default value while editing address
    useEffect(() => {
        if (localData?.id && props.show) {
            const defaultValues = {};
            defaultValues.name = localData?.name
            defaultValues.phone = localData?.phone
            defaultValues.email = localData?.email
            // defaultValues.gender = localData?.gender
            defaultValues.description = localData?.description
            setProfileImage(localData?.profile_image)
            setStartDate(moment(localData?.dob).format('YYYY-MM-DD'))
            if (localData?.type === 'SUB_COACH') {
                setCoachType({ label: "Sub Coach", value: 'SUB_COACH' })
            } else {
                setCoachType({ label: "Main Coach", value: 'MAIN_COACH' })
            }
            if (localData?.gender === 'MALE') {
                setSelectedGender({ label: "Male", value: 'MALE' })
            } else {
                setSelectedGender({ label: "Female", value: 'FEMALE' })
            }
            reset({ ...defaultValues })

        } else {
            reset()
            setProfileImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localData])

    const selectImage = async (event) => {
        const checkSize = event.target.files[0]
        const myData = event.target.files[0]
        // Check if any file is selected.
        if (checkSize?.size) {
            const fileSize = checkSize.size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                toast.danger('File too Big, please select a file less than 2mb')
                // $(file).val(''); //for clearing with Jquery
            } else {
                // Proceed further
                setFile(myData)
            }

        }
    }

    /**
        * @function fetchAcademyCoachList
        * @params
        * @description fetch the list of organizer banner
        */
    const fetchAcademyCoachList = async () => {
        try {
            const result = await coachListService({ page, countPerPage });
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setCoachList(result?.data?.data.coach_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                // setCoachCount(result?.data?.data?.pagination.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to update coach
         */
    const onSubmit = async (formdata) => {
        console.log('startDate', startDate)
        try {
            if (selectedGender?.value) {
                if (coachType?.value) {
                    // if (!coachImage) {
                    //     ErrorAlert('Please upload the profile image')
                    //     return
                    // }

                    const data = new FormData()
                    if (coachImage) {
                        data.append('profile_pic', coachImage)
                    }
                    data.append('name', formdata?.name)
                    data.append('email', formdata?.email)
                    data.append('phone', formdata?.phone)
                    data.append('academy_id', user?.id)
                    data.append('type', coachType?.value)
                    data.append('gender', selectedGender?.value)
                    data.append('password', formdata?.password)
                    data.append('password_confirmation', formdata?.password_confirmation)
                    data.append('description', formdata?.description)
                    data.append('dob', moment(startDate).format('YYYY-MM-DD'))
                    data.append('_method', 'PUT')

                    const result = await updateCoachService({ id: localData?.id }, data);
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        fetchAcademyCoachList(1)
                        reset()
                        props.handleEditCoachModal()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } else {
                    ErrorAlert('Please select the coach type')
                }
            } else {
                ErrorAlert('Please select the gender')
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleDelete
        * @params formdata
        * @description used to update coach
        */
    const handleDelete = async () => {
        try {
            // if (!coachImage) {
            //     ErrorAlert('You need to upload a coach profile image')
            //     return
            // }

            const result = await deleteCoachService({ id: localData?.id });
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                fetchAcademyCoachList(1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleDeleteModal = () => {
        setIsDeleteModal(true)
    }

    const handleCancel = () => {
        setIsDeleteModal(false)
    }

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    return <Modal show={props.show} onHide={props.handleEditCoachModal} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0 px-5 pt-3' closeButton >
            Edit Coach
        </Modal.Header>
        <Modal.Body className='px-5'>

            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="col-12">
                    <div className='mb-4 product'>
                        <label htmlFor="upload-button" className="drop-container" >
                            {coachImage ? <Image className="img mb-3" src={URL.createObjectURL(coachImage)} roundedCircle={true} width='100' height='100' alt='profile' /> : <Image className="img mb-3" src={uploadImage} roundedCircle={true} width='100' alt='profile' />}
                            <br />
                            <span className="drop-title text-primary fs-14">Change Profile Image</span>
                        </label>
                        <input id="upload-button" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={selectImage}
                            style={{ display: 'none' }} />
                    </div>
                </Form.Group>
                {coachType?.value ? <Form.Group className="col-12">
                    <Form.Label className='fs-14 fw-600'>Coach Type</Form.Label>
                    <Select
                        // closeMenuOnSelect={false}
                        theme={selectThemeColors}
                        className='react-select'
                        classNamePrefix='select'
                        defaultValue={coachType}
                        name='clear'
                        options={allCoach}
                        placeholder='Coach Type'
                        onChange={setCoachType}
                    />
                </Form.Group> : <Form.Group className="col-12">
                    <Form.Label className='fs-14 fw-600'>Coach Type</Form.Label>
                    <Select
                        // closeMenuOnSelect={false}
                        theme={selectThemeColors}
                        className='react-select'
                        classNamePrefix='select'
                        defaultValue={coachType}
                        value={coachType}
                        name='clear'
                        options={allCoach}
                        placeholder='Coach Type'
                        onChange={setCoachType}
                    />
                </Form.Group>}

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Coach Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name here"
                        autoComplete='nope'
                        {...register("name", {
                            required: "Please provide the coach name",
                        })}
                    />
                    {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter phone number here"
                        autoComplete='nope'
                        {...register("phone", {
                            required: "Please provide the phone number",
                        })}
                    />
                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email here"
                        {...register("email", {
                            required: "Please provide the email address",
                        })}
                    />
                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                </Form.Group>
                <Row>
                    <Form.Group className="col-6">
                        <Form.Label className='fs-14 fw-600'>Gender</Form.Label>
                        {selectedGender?.value ? <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedGender}
                            value={selectedGender}
                            name='clear'
                            options={allGender}
                            placeholder='Gender'
                            onChange={setSelectedGender}
                        /> : <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedGender}
                            value={selectedGender}
                            name='clear'
                            options={allGender}
                            placeholder='Gender'
                            onChange={setSelectedGender}
                        />}
                    </Form.Group>
                    <Form.Group className="col-6 academy-date">
                        <Form.Label className='fs-14 fw-600'>Date Of Birth</Form.Label>
                        <InputGroup>
                            <Flatpickr
                                className='form-control'
                                value={startDate}
                                required
                                id='timepicker'
                                options={{
                                    static: true,
                                    enableTime: false,
                                    noCalendar: false,
                                    dateFormat: "Y-m-d",
                                }}
                                onChange={date => setStartDate(date[0])}
                            />
                            <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                </Row>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        {...register("description", {
                            required: "Description is required",
                        })}
                    />
                    {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password', {
                                required: "Please provide the password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                </Form.Group>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Confirm Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password_confirmation', {
                                required: "Please provide the confirm password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                </Form.Group>

                <div className="div" id="coach-button-web">
                    <div className='d-flex justify-content-end align-items-center pt-4'>
                        <div>
                            {/* <Button className='fs-14 fw-600 px-5' color='primary' onClick={handleDeleteModal}>Remove1 this coach</Button>
                            <span className='text-primary' onClick={handleDeleteModal}>Remove1 this coach</span> */}
                        </div>
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5' color='secondary' >Cancel</Button>
                        </div>
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5 text-light' color='primary' type="submit">Save</Button>
                        </div>
                    </div>
                </div>
                <div id="coach-button-mobile" className='pt-4'>
                    {/* <div className='text-center mb-2'>
                        <span className='text-primary my-2' onClick={() => handleDeleteModal}>Remove2 this coach</span>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5' color='secondary' >Cancel</Button>
                        </div>
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5 text-light' color='primary' type="submit">Save</Button>
                        </div>
                    </div>
                </div>
            </Form>
            <Modal isOpen={isDeleteModal} toggle={() => setIsDeleteModal(!isDeleteModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setIsDeleteModal(!isDeleteModal)}>Delete Banner</ModalHeader>
                <ModalBody>
                    <h5>Are you sure, you want to delete this?</h5>
                </ModalBody>
                <ModalFooter>
                    <Button className='ms-2' color='primary' onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button color='danger' onClick={() => handleDelete(!isDeleteModal)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </Modal.Body>
    </Modal>
};

export default UpdateCoach;