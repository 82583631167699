import { Modal, Row, Col } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";

// Custom Component
import { PrimaryButton } from '../Button';

// Images
import { Error } from '../../assets/images/svg';
import { Link } from 'react-router-dom';

const PaymentErrorModal = (props) => {
    /**
         * @function calculateSum
         * @params array, property
         * @description used to calculate sum of specific property ib array
         */
    const calculateSum = (array, property) => {
        const total = array.reduce((accumulator, object) => {
            return accumulator + object[property];
        }, 0);

        return total;
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 bg-danger text-light px-0 py-5 position-relative'>
            <IoCloseOutline role='button' className='text-light position-absolute top-0 end-0 mt-2 me-2' size={20} onClick={props.handleClose} />
            <Error />
            <span className='fs-30 fw-600 mt-3 mb-2 text-light'>Oops!</span>
            <span className='text-center text-light'>Something went Wrong, Please try again later</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>

            {/* <Row className='w-100 my-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <PrimaryButton className='fs-14 fw-500' onClick={props.handleClose}>Try again</PrimaryButton>
                </Col>
            </Row> */}
            {/* <span role='button' className='fs-16 fw-bold text-secondary mb-4' onClick={props.handleClose}>Go back</span> */}
            <Link to='/'>
                <PrimaryButton className='fs-14 fw-500' onClick={props.handleClose}>Go Back</PrimaryButton>
            </Link>
        </Modal.Body>
    </Modal>
};

export default PaymentErrorModal;