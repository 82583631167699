import { useState, useEffect } from 'react';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import NoProCoach from "../../../../components/Common/NoProCoach";

// Images
import { post1Image,  sessionBook, sessionUser } from "../../../../assets/images/index";

// API Service
import { allProgramsService } from '../../../../service/coachProgram';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";
import { setProgrammeList, selectProgrammeList } from "../../../../redux/coachProgrammeSlice";

const Programmes = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const programsList = useSelector(selectProgrammeList);

    const [isLoading, setLoading] = useState(false)

    console.log('programsList', programsList)
    /**
        * @function fetchCoachProgramList
        * @params
        * @description fetch the list of organizer banner
    */
    const fetchCoachProgramList = async () => {
        try {
            const result = await allProgramsService();
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setProgrammeList(result?.data?.data?.program_list))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'coach') {
            setLoading(true)
            fetchCoachProgramList()
        }
        // eslint-disable-next-line
    }, [user])

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }

    return <div>
        <div className='boxSection m-3'>
            <div>
                <Row>
                    <Col xl={12} className='pt-3 mb-4'>
                        <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                            <div><h1 className='fs-24 fw-bold'>Assigned Programs</h1></div>
                        </div>
                    </Col>
                </Row>

                <Row className='box-programme gx-5'>
                    {programsList?.length > 0 ? programsList.map(item => (
                        <Col xl={3} lg={6} md={6} sm={12} className='customBox'>
                            <Link to={`/coach/program/detail/${item?.id}`} className='text-decoration-none'>
                                <Card style={{}} className='mb-5 session-box border-2 border-light'>
                                    <div className="program-card-image">
                                        {item?.banners?.length > 0 ? <Card.Img variant="top" src={item?.banners[0].banner} /> : <Card.Img variant="top" src={post1Image} />}
                                    </div>

                                    <Card.Body style={{ height: '' }}>
                                        <div className="div" style={{ minHeight: '', maxHeight: '' }}>
                                            <Card.Text className='pt-1'>
                                                <div className="description-common-height-coach">
                                                    {item?.name.substr(0, 40)}
                                                </div>
                                            </Card.Text>
                                            <Card.Text className='mb-3'>
                                                <p className='fs-12'>Assigned by: <span className='text-primary'>{item?.academy?.name}</span></p>
                                            </Card.Text>

                                            <div className='d-flex flex-row justify-content-between align-items-center fw-500 fs-12'>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    )) : <NoProCoach />}
                </Row>
            </div>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;