import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, Edit3 } from 'react-feather'
// Images
import { coachImage, uploadImage, post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../../../assets/images/index";
import moment from 'moment'
// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import AddSession from './AddSession'
import UpdateSession from './UpdateSession'

import NoCoach from "../../../../components/Common/NoCoach";
import { singleSessionService, singleProgramSessionService } from '../../../../service/coachProgram';
import { setSessionListing, selectSessionListing, selectFreshData } from "../../../../redux/coachProgrammeSlice"

const Overview = ({ singleData }) => {
    const dispatch = useDispatch()
    const [bannerList, setBannerList] = useState([])
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const freshData = useSelector(selectFreshData);
    const sessionListing = useSelector(selectSessionListing);
    const [addSessionModal, setAddSessionModal] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [indexWise, setIndex] = useState('')
    const [show, setShow] = useState(false)
    const [singleItem, setSingleItem] = useState()

    console.log('singleData', singleData)
    /**
         * @function fetchProgramSession
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchProgramSession = async () => {
        if (singleData?.id) {
            try {
                const result = await singleSessionService({ id: singleData?.id });
                console.log('sessionList', result)
                if (result?.data?.status) {
                    // const resultSession = result?.data?.data?.map((item) => ({ value: item.id, label: item.name }))
                    dispatch(setSessionListing(result?.data?.data))

                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        }

    }

    const showMoreProfile = (item, index) => {
        if (item?.id) {
            setShowMore(!showMore)
            setIndex(item?.id)
        }
    }

    useEffect(() => {
        if (user?.type === 'coach') {
            fetchProgramSession()
        }
        // eslint-disable-next-line
    }, [user, freshData])

    /**
         * @function handleAddSessionModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleAddSessionModal = () => {
        setAddSessionModal(!addSessionModal)
    }
    const checkPlan = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'Weekly Payment'
        } else if (type === 'MONTHLY') {
            showData = 'Monthly Payment'
        } else if (type === 'SESSION') {
            showData = 'Session Payment'
        }
        return (showData)
    }
    const checkPlanDuration = (item) => {
        const type = item?.type
        let showData = ''
        if (type === 'WEEKLY') {
            showData = 'per Week'
        } else if (type === 'MONTHLY') {
            showData = 'per Month'
        } else if (type === 'SESSION') {
            showData = 'per Session'
        }
        return (showData)
    }

    if (isLoading) {
        return (
            <>
                <div className='parent'>
                    <div className='myHeight text-center'>
                        <Spinner variant='primary' />
                    </div>
                </div>
            </>
        )
    }

    console.log('sessionListing', sessionListing)

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = (item) => {
        setSingleItem(item)
        setShow(true);
    }

    return <div>
        <div className='overview-section m-4'>
            <div>
                <Row className='mb-4'>
                    <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                        <div className=''>
                            <div>
                                <h1 className='fs-28 fw-bold mb-5'>What Students will learn</h1>
                            </div>
                        </div>
                        <div className=''>
                            <div className='programme-title mb-3'>{singleData?.name}</div>
                            <div className='programme-list'>
                                <p>{singleData?.learn ? parse(singleData?.learn) : ''}</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='box-coach gx-5 mb-4'>
                    <div className='mb-3'>
                        <div>
                            <h1 className='fs-28 fw-bold'>Assigned Coach Details</h1>
                        </div>
                    </div>

                    <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                        <div className='text-decoration-none'>
                            <div>
                                <div className='coach-profile mb-3'>
                                    <img src={singleData?.main_coach?.profile_image || uploadImage} alt='coach-profile 1' width='60' />
                                </div>
                                <div className='coach-name'>
                                    <h5 className='fw-600 text-dark fs-18'>{singleData?.main_coach?.name}</h5>
                                </div>
                                <div className='coach-type'>
                                    {singleData?.main_coach?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                </div>
                                <div className='coach-des'>
                                    <p className='mb-0 text-secondary'>{singleData?.main_coach?.description ? singleData?.main_coach?.description.substr(0, 200) : ''}</p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {singleData?.sub_coaches?.length > 0 ? singleData?.sub_coaches.map(item => (
                        <Col lg={4} md={6} sm={6} xs={6} className='mb-4'>
                            <div className='text-decoration-none'>
                                <div>
                                    <div className='coach-profile mb-3'>
                                        <img src={item?.profile_image || uploadImage} alt='coach-profile 2' width='60' />
                                    </div>
                                    <div className='coach-name'>
                                        <h5 className='fw-600 text-dark fs-18'>{item?.name}</h5>
                                    </div>
                                    <div className='coach-type'>
                                        {item?.type === 'MAIN_COACH' ? <p className='text-success mb-0 fw-600 fs-16'>Main Coach</p> : <p className='text-primary mb-0 fw-600 fs-16'>Sub Coach</p>}
                                    </div>
                                    <div className='coach-des'>
                                        <p className='mb-0 text-secondary'>
                                            {showMore && item?.id === indexWise ? item?.description : item?.description ? item?.description?.substr(0, 200) : ''}
                                        </p>
                                        <p className='mb-0'>
                                            {item?.description?.length > 200 ? showMore && item?.id === indexWise ? <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show less</span> : <span className='tex-primary custom-hover fs-14' onClick={() => showMoreProfile(item)}>Show more</span> : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )) : ''}
                </Row>
                <Row>
                    <div className='overview-requirement mt-5'>
                        <Col xl={12} md={12} xs={12} className='pt-3 mb-1'>
                            <div className=''>
                                <div>
                                    <h1 className='fs-28 fw-bold mb-3'>Requirements</h1>
                                </div>
                            </div>
                            <div className=''>
                                <div className='programme-list'>
                                    <div className="academy-learn-description">
                                        <p>{singleData?.requirement ? parse(singleData?.requirement) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>

                <Row>
                    <div className='overview-frequency mt-2'>
                        <Col xl={12} className='pt-3 mb-4'>
                            <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                                <div><h1 className='fs-22 fw-bold'>Sessions</h1></div>
                                <div>
                                    {sessionListing?.length < 4 ? <Button size="sm" variant="primary" className='fw-600 fs-16 text-light px-5' onClick={handleAddSessionModal}>Create Session</Button> : ''}
                                </div>
                            </div>
                        </Col>
                        <Col xl={12} className='pt-3 mb-4'>
                            <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                                <div><h1 className='fs-20 fw-bold'>Frequency</h1></div>
                                <div><h1 className='fs-20 fw-bold'>Monthly 4 Session</h1></div>
                            </div>
                        </Col>
                    </div>
                </Row>
                {sessionListing?.length < 4 && <AddSession show={addSessionModal} handleClose={handleAddSessionModal} size='lg' singleData={singleData} currentSessionListing={sessionListing} />}

                <div className="coach-session-listing mb-3">
                    <div className='single-session'>
                        <Row>
                            {sessionListing?.length > 0 ? sessionListing.map((item, index) => {
                                return (
                                    <>
                                        <Col xl={12} md={12} xs={12} className='pt-3 mb-1' key={index}>
                                            <div className='session-show mb-2'>
                                                <div className=''>
                                                    <div className="session-count">
                                                        <p className='d-flex justify-content-start align-content-center fs-14 fw-600'>
                                                            <span>Session - {index + 1} </span>
                                                            <span className='ms-4'>
                                                                <Edit3 className='fw-600 custom-hover ' size='20' onClick={() => handleShow(item)} />

                                                                {/* <UpdateSession singleData={item} /> */}
                                                            </span>
                                                        </p></div>
                                                    <div className='session-date'>
                                                        <p className=''>{moment(item?.date).format('MM/DD/YYYY, dddd')}</p>
                                                    </div>

                                                    <div className="time-slots">
                                                        <div className="list d-flex justify-content-start align-content-center">
                                                            {item?.timeslots?.length > 0 ? item?.timeslots.map((i, index1) => {
                                                                return (
                                                                    <div className="" key={index1} >
                                                                        <Button variant='dark' className='time-slot-button me-2'>{i?.start_time} - {i?.end_time}</Button>
                                                                    </div>
                                                                )
                                                            }) : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {item === item?.timeslots?.length - 1 ? '' : <hr className='custom-hr' />}

                                        </Col>

                                    </>
                                )
                            }) : ''}
                        </Row>
                    </div>

                </div>
            </div>
        </div >
        <div className='clearfix'></div>
        <UpdateSession handleClose={handleClose} singleData={singleItem} show={show} programID={singleData?.id} />
    </div >

};

export default Overview;