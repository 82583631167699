import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    dataList: [],
    exportList: [],
    singleDetail: {},
    isFreshData: false,
    totalEntry: 0,
}

export const accessSlice = createSlice({
    name: "accessSlice",
    initialState,
    reducers: {
        setDataList: (state, action) => {
            state.dataList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const { 
    setDataList, 
    setFreshList, 
    setSingleData,
    setTotalEntry
} = accessSlice.actions

export const selectDataList = (state) => state.access.dataList 
export const selectFreshData = (state) => state.access.isFreshData
export const selectTotalEntry = (state) => state.access.totalEntry
export const selectSingleDetail = (state) => state.access.singleDetail

export default accessSlice.reducer