import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import Programmes from './Programmes'

const Homepage = () => {
    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching</title>
        </Helmet>
        <Programmes />
    </HelmetProvider>
};

export default Homepage;