import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../../../../components/TextField/ErrorText'
import ErrorAlert from '../../../../../components/Alert/ErrorAlert';
import OTPVerificationModal from '../../../../../components/Modal/OTPVerificationModal';

// Images
import { logo, authentication } from '../../../../../assets/images'

// API Service
import { signUpService } from '../../../../../service/auth.service';
import { allCountryService } from '../../../../../service/country.service';

const SignUp = () => {
    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [otpVerificationModal, setOTPVerificationModal] = useState(false)

    const { register, getValues, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibilty
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function handleShowConfirmPassword
        * @params
        * @description toggle the confirm password input field visibilty
    */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
        * @function handleOTPVerificationModal
        * @params
        * @description set the visibility of otp verification modal
    */
    const handleOTPVerificationModal = () => {
        setOTPVerificationModal(!otpVerificationModal)
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to signup the player
    */
    const onSubmit = async (formdata) => {
        if (formdata.password !== formdata.password_confirmation) {
            setError('password', {
                type: "manual",
                message: 'Please make sure your passwords match.',
            })
            setError('password_confirmation', {
                type: "manual",
                message: 'Please make sure your passwords match.',
            })
            return;
        }
        const data = new FormData()
        data.append('username', formdata?.username)
        data.append('email', formdata?.email)
        data.append('phone', formdata?.phone)
        data.append('country_id', formdata?.country_id)
        data.append('password', formdata?.password)
        data.append('password_confirmation', formdata?.password_confirmation)
        try {
            const result = await signUpService(data);
            if (result?.data?.status) {
                handleOTPVerificationModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Sign Up | AFA Coaching</title>
        </Helmet>
        <Container fluid>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" width='106' />
                    </Link>
                    <Row className="justify-content-center flex-grow-1 w-100 mt-5 student-register">
                        <Col xs={10} sm={6} lg={7} className='mb-4'>
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fs-md-20 fw-bold title'>Create AFA Account</span>
                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>Username*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete='off'
                                        placeholder="Enter Username"
                                        {...register("username", {
                                            required: "Please provide the username",
                                            pattern: {
                                                value: /^\S*$/,
                                                message: "Invalid username"
                                            }
                                        })}
                                    />
                                    {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        autoComplete='off'
                                        {...register("email", {
                                            required: "Please provide the email",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                                </Form.Group>

                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <Form.Select
                                                {...register("country_id")}
                                            >
                                                {countryList.map((item, index) => {
                                                    return <option value={item?.id} key={index}>{item?.code}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control
                                                className='flex-grow-5'
                                                type="tel"
                                                autoComplete='off'
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Please provide the phone number"
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            autoComplete='off'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Please provide the password",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Confirm Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            autoComplete='nope'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password_confirmation', {
                                                required: "Please provide the confirm password",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                                </Form.Group>
 
                                <Form.Group className="mb-3 mt-4">
                                    {/* <PrimaryButton type="submit">Create Account</PrimaryButton> */}
                                    <Button size='lg' variant="primary" className='text-light w-100' type='submit'>Create Account</Button>
                                </Form.Group>
                            </Form>
                            <hr className='mx-5 my-3' />

                            <div className='text-center mt-4 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Already have an account?
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign In</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <Col xs={12} className='d-none d-lg-block mt-auto'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <span className='fs-14 fw-400 text-secondary'>
                                    © AFA 2023
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='fs-14 fw-400 text-secondary'>
                                        help@AFA.com
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block p-0'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>

        {/* OTP Verification Modal */}
        <OTPVerificationModal show={otpVerificationModal} handleClose={handleOTPVerificationModal} phone={getValues("phone")} signup={true} />
    </HelmetProvider>
};

export default SignUp;