import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allProgramsService } from '../../service/guestHomeService';

// Redux-Functions
import { selectProgrammeList, setProgrammeList } from "../../redux/slices/guestHomeSlice"

import ReactStars from 'react-rating-star-with-type'

const Programmes = () => {
    const dataPerRow = 8;
    const dispatch = useDispatch()
    const programmeList = useSelector(selectProgrammeList);
    const [next, setNext] = useState(dataPerRow);

    /**
         * @function fetchCustomerProgramList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerProgramList = async () => {
        try {
            const result = await allProgramsService();
            if (result?.data?.status) {
                dispatch(setProgrammeList(result?.data?.data?.program_list))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerProgramList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkPrice = (item) => {
        const priceList = []
        if (item?.frequencies?.length > 0) {
            item?.frequencies.forEach((item) => {
                if (item.price) {
                    // priceList.push(Number(item.price?.toFixed()))
                    priceList.push(Number(item.price))
                }
            })
        }
        const maxPrice = Math.max(...priceList)
        let showData = ''
        showData = maxPrice
        return (showData)
    }

    const handleMoreImage = () => {
        setNext(next + dataPerRow);
    };

    return <div>
        <div className='boxSection mt-5'>
            <Container>
                <Row className='guest box-programme gx-5'>
                    {/* className='text-sm-center text-xs-center' */}
                    <Col xl={12} className='pt-2 mb-4 d-flex flex-row align-items-center justify-content-between'>
                        <div className=''><h1 className='fw-bold'>Popular Programs</h1></div>
                        <div className=''><Link to='/all/programs'><Button siz='sm' variant='primary' className='text-light fs-600'>View All</Button></Link></div>
                    </Col>

                    <Row className='d-none d-xl-flex'>
                        {programmeList?.length > 0 ? programmeList?.filter(item => item?.is_popular)?.slice(0, 8)?.map(item => (
                            <Col xl={3} p-3 col-lg-3 className='mb-5 customBox'>
                                <Link to={`/program/detail/${item?.id}`} className='text-decoration-none'>
                                    <Card className='h-100 session-box border-2 border-light'>
                                        {/* <Card style={{ width: '280px' }} className='mb-5 session-box border-2 border-light'> */}

                                        <div className="program-card-image">
                                            <Card.Img variant="top" src={item?.banners[0].banner} />
                                        </div>
                                        <Card.Body className='d-flex flex-column'>
                                            {/* <div className="main-price">
                                                <div className="price-cirle-color course-price">
                                                    <p className='mb-0'><span className='fs-14'>{`RM`}2</span></p>
                                                    <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                                </div>
                                            </div> */}
                                            <div className="main-price">
                                                <div className="course-price d-flex flex-column justify-content-center">
                                                    <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                    <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                                </div>
                                            </div>
                                            <Card.Text className='mb-0'>
                                                <div className='wrap_after_two_line description-common-height pt-3'>
                                                    {item?.name ? item?.name?.substr(0, 60) : ''}
                                                </div>
                                            </Card.Text>
                                            <Card.Text className='mb-4'>
                                                <p className='d-flex align-items-center'>
                                                    <div className="div">
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                    <div className="div mt-1 ms-1"><span className='fs-12'>{parseFloat(item?.average_rating).toFixed(1)}</span></div>
                                                </p>
                                            </Card.Text>

                                            <div className='d-flex flex-row justify-content-between align-items-center mt-auto'>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )) : ''}
                    </Row>

                    <Row className='d-none d-lg-flex d-xl-none'>
                        {programmeList?.length > 0 ? programmeList?.slice(0, 8)?.map(item => (
                            <Col lg={3} p-3 className='mb-5 customBox'>
                                <Link to={`/program/detail/${item?.id}`} className='text-decoration-none'>
                                    <Card className='h-100 session-box border-2 border-light'>
                                        {/* <Card style={{ width: '280px' }} className='mb-5 session-box border-2 border-light'> */}

                                        <div className="program-card-image">
                                            <Card.Img variant="top" src={item?.banners[0].banner} />
                                        </div>
                                        <Card.Body className='d-flex flex-column'>
                                            {/* <div className="main-price">
                                                <div className="price-cirle-color course-price">
                                                    <p className='mb-0'><span className='fs-14'>{`RM`}2</span></p>
                                                    <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                                </div>
                                            </div> */}
                                            <div className="main-price">
                                                <div className="course-price d-flex flex-column justify-content-center">
                                                    <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                    <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                                </div>
                                            </div>
                                            <Card.Text className='mb-0'>
                                                <div className='wrap_after_two_line description-common-height pt-3'>
                                                    {item?.name ? item?.name?.substr(0, 60) : ''}
                                                </div>
                                            </Card.Text>
                                            <Card.Text className='mb-4'>
                                                <p className='d-flex align-items-center'>
                                                    <div className="div">
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                    <div className="div mt-1 ms-1"><span className='fs-12'>{parseFloat(item?.average_rating).toFixed(1)}</span></div>
                                                </p>
                                            </Card.Text>

                                            <div className='d-flex flex-row justify-content-between align-items-center mt-auto'>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )) : ''}
                    </Row>

                    <Row className='d-none d-sm-flex d-lg-none'>
                        {programmeList?.length > 0 ? programmeList?.slice(0, 4)?.map(item => (
                            <Col md={6} p-3 className='mb-5 customBox'>
                                <Link to={`/program/detail/${item?.id}`} className='text-decoration-none'>
                                    <Card className='h-100 session-box border-2 border-light'>
                                        {/* <Card style={{ width: '280px' }} className='mb-5 session-box border-2 border-light'> */}

                                        <div className="program-card-image">
                                            <Card.Img variant="top" src={item?.banners[0].banner} />
                                        </div>
                                        <Card.Body className='d-flex flex-column'>
                                            {/* <div className="main-price">
                                                <div className="price-cirle-color course-price">
                                                    <p className='mb-0'><span className='fs-14'>{`RM`}2</span></p>
                                                    <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                                </div>
                                            </div> */}
                                            <div className="main-price">
                                                <div className="course-price d-flex flex-column justify-content-center">
                                                    <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                    <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                                </div>
                                            </div>
                                            <Card.Text className='mb-0'>
                                                <div className='wrap_after_two_line description-common-height pt-3'>
                                                    {item?.name ? item?.name?.substr(0, 60) : ''}
                                                </div>
                                            </Card.Text>
                                            <Card.Text className='mb-4'>
                                                <p className='d-flex align-items-center'>
                                                    <div className="div">
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                    <div className="div mt-1 ms-1"><span className='fs-12'>{parseFloat(item?.average_rating).toFixed(1)}</span></div>
                                                </p>
                                            </Card.Text>

                                            <div className='d-flex flex-row justify-content-between align-items-center mt-auto'>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )) : ''}
                    </Row>

                    <Row className='d-block d-sm-none'>
                        {programmeList?.length > 0 ? programmeList?.slice(0, 4)?.map(item => (
                            <Col sm={12} p-3 col-12 className='mb-5 customBox'>
                                <Link to={`/program/detail/${item?.id}`} className='text-decoration-none'>
                                    <Card className='h-100 session-box border-2 border-light'>
                                        {/* <Card style={{ width: '280px' }} className='mb-5 session-box border-2 border-light'> */}

                                        <div className="program-card-image">
                                            <Card.Img variant="top" src={item?.banners[0].banner} />
                                        </div>
                                        <Card.Body className='d-flex flex-column'>
                                            {/* <div className="main-price">
                                                <div className="price-cirle-color course-price">
                                                    <p className='mb-0'><span className='fs-14'>{`RM`}2</span></p>
                                                    <p className='mb-0'><span className='fs-14'>{`${checkPrice(item)?.toFixed(2)}`}</span></p>
                                                </div>
                                            </div> */}
                                            <div className="main-price">
                                                <div className="course-price d-flex flex-column justify-content-center">
                                                    <span className='fs-14 text-light line-height-20'>{`RM`}</span>
                                                    <span className='fs-14 text-light line-height-20'>{`${checkPrice(item)?.toFixed(2)}`}</span>
                                                </div>
                                            </div>
                                            <Card.Text className='mb-0'>
                                                <div className='wrap_after_two_line description-common-height pt-3'>
                                                    {item?.name ? item?.name?.substr(0, 60) : ''}
                                                </div>
                                            </Card.Text>
                                            <Card.Text className='mb-4'>
                                                <p className='d-flex align-items-center'>
                                                    <div className="div">
                                                        <ReactStars
                                                            disabled
                                                            value={item?.average_rating}
                                                            edit={true}
                                                            activeColors={["#FDB022"]}
                                                        />
                                                    </div>
                                                    <div className="div mt-1 ms-1"><span className='fs-12'>{parseFloat(item?.average_rating).toFixed(1)}</span></div>
                                                </p>
                                            </Card.Text>

                                            <div className='d-flex flex-row justify-content-between align-items-center mt-auto'>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionBook} alt='' width='17' /> {item?.sessions?.length > 0 ? item?.sessions?.length : '0'} <span className='fw-bold'>Classes</span></p></div>
                                                <div><p className='mb-0 fs-12 fw-bold'> <img src={sessionUser} alt='' width='17' /> {item?.students?.length > 0 ? item?.students?.length : '0'} <span className='fw-bold'>Students</span></p></div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )) : ''}
                    </Row>
                </Row>

                {/* <Row>
                    <Col lg='12' className='text-center'>
                        {next < programmeList?.length && (
                            <Button
                                className="mt-4 text-light"
                                variant='primary'
                                onClick={handleMoreImage}
                            >
                                Load more
                            </Button>
                        )}
                    </Col>
                </Row> */}
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default Programmes;