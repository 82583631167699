import { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { studentRatingService } from '../../service/studentProgram';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"
import ReactStars from 'react-rating-star-with-type'
import StarRatings from 'react-star-ratings';

const ReviewRatingModal = ({ rowData }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [giveRating, setGiveRating] = useState(3.5)
    const {
        // register, reset, handleSubmit, formState: { errors }
        register, control, watch, setValue, reset, handleSubmit, formState: { errors }
    } = useForm({
        defaultValues: {
            name: ""
        },
        mode: "onBlur"
    });
 
    const handleClose = () => {
        setShow(!show)
        reset()
        setGiveRating()
    }
    const changeRating = (newRating, name) => {
        setGiveRating(newRating)
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to add bank account for organizer
         */
    const onSubmit = async (formdata) => {
        if (user?.id && giveRating && rowData?.id) {
            const data = new FormData()
            data.append('customer_id', user?.id)
            data.append('rating', giveRating)
            data.append('program_id', rowData?.id)
            data.append('comment', formdata?.description)
            try {
                const result = await studentRatingService({ id: rowData?.id, data });
                if (result?.data?.status) {
                    reset()
                    handleClose()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        }
    }

    return <>
        <div className="give-rating">
            <div className='custom-white-button'>
                <Button className='text-light fs-16' color='light' onClick={()=> setShow(true)}>Give Ratings and Reviews</Button>
            </div>

            <Modal show={show} onHide={() => handleClose()} centered size='md' className='p-3'>
                <Modal.Header className=' border-0' closeButton>
                    
                </Modal.Header>
                <Modal.Body className='d-flex flex-column pt-0 align-items-center' style={{ color: '#344054' }}>
                    
                    <div className='w-100'>
                    <p className='fs-24 fw-600 text-left mb-2'>Your Ratings and Review</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="my-4">
                                <h3 className='fs-20'>Give Ratings</h3>
                                {/* <Form.Label className='fs-14 fw-500'>Give Ratings</Form.Label> */}
                                <div className="rating pt-2">
                                    {/* <ReactStars
                                value={giveRating}
                                edit={true}
                                activeColors={["#FDB022"]}
                            /> */}
                                    <StarRatings
                                        rating={giveRating}
                                        starRatedColor="#FDB022"
                                        changeRating={changeRating}
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="30px"
                                        starSpacing="8px"
                                    />
                                </div>
                                <hr />
                            </Form.Group>

                            <Form.Group className="my-2">
                                <h3 className='fs-20 fw-600'>Write Reviews</h3>
                                <Form.Label className='fs-14 fw-500 fw-600'>Reviews</Form.Label>
                                <Form.Control
                                    type="textarea"
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter here"
                                    {...register("description", {
                                        required: "Please write your review"
                                    })}
                                />
                                {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                            </Form.Group>

                            <div className='d-flex justify-content-end align-items-end mt-4'>
                                <div>
                                    <PrimaryButton className='fs-14 fw-500 px-5' type="submit">Proceed</PrimaryButton>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>


};

export default ReviewRatingModal;