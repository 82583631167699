import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Row, Col, Tab, Tabs, Image, Spinner } from 'react-bootstrap';
import ShowMoreText from "react-show-more-text";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { singleProgramService } from '../../../../service/coachProgram';
 
// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { selectFreshData } from "../../../../redux/coachProgrammeSlice"
import Overview from './Overview';
import Students from './Students';

const ProgrammeDetail = () => {
    const { id } = useParams()
    const [isLoading, setLoading] = useState(false)
    const [key, setKey] = useState('overview');
    const [programmeDetail, setProgramList] = useState([])
    const user = useSelector(selectUser);
    const freshData = useSelector(selectFreshData);

    /**
        * @function fetchSingleProgram
        * @params
        * @description fetch the details of a single program
    */
    const fetchSingleProgram = async () => {
        try {
            console.log('fetchSingleProgram 6')

            const result = await singleProgramService({ id });
            
            if (result?.data?.status) {
                setLoading(false)
                setProgramList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (freshData === true) {
            console.log('fetchSingleProgram freshData')
            setLoading(true)
            fetchSingleProgram()
        }
        // eslint-disable-next-line
    }, [freshData])

    useEffect(() => {
        if (user?.type === 'coach') {
            setLoading(true)
            fetchSingleProgram()
        }
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return <HelmetProvider>
            <Helmet>
                <title>AFA Coaching</title>
            </Helmet>
            <div className='parent'>
                <div className='myHeight text-center'>
                    <Spinner variant='primary' />
                </div>
            </div>
        </HelmetProvider>
    } 

    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching || Coach Programme Detail</title>
        </Helmet>
        <div>
            <div className='DetailSection'>
                <div className='DetailBanner' style={{ background: '#FFEEE6' }}>
                    <Row>
                        <Col xl={7} className=''>
                            <div className='pt-5'>
                                <div className='programme-name mb-4'>
                                    <h1 className=''>{programmeDetail?.name}</h1>
                                </div>
                                    
                                <div className='mb-5'>
                                    <ShowMoreText
                                        className='showMoreText'
                                        lines={3}
                                        more="Show more"
                                        less="Show less"
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        {programmeDetail?.description}
                                    </ShowMoreText>
                                </div>
                            </div>
                        </Col>
                        <Col xl={5} className=''>
                            <div className='text-center programme-image pt-4'>
                                <Image fluid src={programmeDetail?.banners && programmeDetail?.banners.length > 0 ? programmeDetail?.banners[0].banner : ''} alt='Banner' width='' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='TabSection mt-2'>
                <Row>
                    <Col xl={12} lg={12} xs={12} className='single-programe'>
                        <div className='tab-list custom-tab student-tabs'>
                            <Tabs
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                // defaultActiveKey="overview"
                                id="uncontrolled-tab-example"
                                className="mb-3 flex-xs-column"
                            >
                                <Tab eventKey="overview" title="Overview">
                                    <Overview singleData={programmeDetail} />
                                </Tab>
                                <Tab eventKey="student" title="Students">
                                    <Students singleData={programmeDetail} />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='clearfix'></div>
        </div>
    </HelmetProvider>

};

export default ProgrammeDetail;