import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, InputGroup, Image } from 'react-bootstrap';
import { Button } from 'reactstrap'
import { useForm, useFieldArray } from "react-hook-form";
// third party component
import toast from 'react-hot-toast'
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../TextField/ErrorText'
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';

// Images
import { uploadImage } from '../../../assets/images/index';

// API Service
import { updateAccessService, accessListService } from '../../../service/accessAcademy.service';

import { setDataList, setTotalEntry } from "../../../redux/accessAcademySlice";

const UpdateCoach = ({ localData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [profile_Image, setProfile_Image] = useState();
    const [showImage, setProfileImage] = useState();
    const countPerPage = 10
    const page = 1

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            password: '',
            password_confirmation: ''
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    // for setting default value while editing address
    useEffect(() => {
        if (localData?.id) {
            const defaultValues = {};
            defaultValues.name = localData?.name
            defaultValues.phone = localData?.phone
            defaultValues.email = localData?.email
            setProfileImage(localData?.profile_image)
            reset({ ...defaultValues })

        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localData])

    const selectImage = async (event) => {
        const checkSize = event.target.files[0]
        const myData = event.target.files[0]
        // Check if any file is selected.
        if (checkSize?.size) {
            const fileSize = checkSize.size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                toast.danger('File too Big, please select a file less than 2mb')
            } else {
                // Proceed further
                setProfile_Image(myData)
            }

        }
    }

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
        * @function fetchAcademyCoachList
        * @params
        * @description fetch the list of organizer banner
    */
    const fetchAcademyCoachList = async () => {
        try {
            const result = await accessListService({ page, countPerPage });
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setDataList(result?.data?.data.access_manager_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to update coach
    */
    const onSubmit = async (formdata) => {
        try {
            const data = new FormData()
            if (profile_Image) {
                data.append('profile_pic', profile_Image)
            }
            data.append('name', formdata?.name)
            data.append('email', formdata?.email)
            data.append('phone', formdata?.phone)
            data.append('password', formdata?.password)
            data.append('password_confirmation', formdata?.password_confirmation)
            data.append('_method', 'PUT')

            const result = await updateAccessService({ id: localData?.id }, data);
            if (result?.data?.status) {
                handleCloseModal()
                SuccessAlert('Access manager updated successfully')
                fetchAcademyCoachList(1)
                reset()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleCloseModal = () => {
        props.handleClose()
        setProfile_Image()
        reset()
    }

    return <Modal show={props.show} onHide={() => handleCloseModal()} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0 px-5 pt-3' closeButton >
            Update Access Manager
        </Modal.Header>
        <Modal.Body className='px-5'>
            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="col-12">
                    <div className='mb-2 product'>
                        <label htmlFor="upload-button" className="drop-container" role='button'>
                            {typeof profile_Image === 'object' ? <Image className="img mb-3" src={URL.createObjectURL(profile_Image)} roundedCircle={true} width='100' height='100' alt='profile' /> : localData?.profile_image ? <Image className="img mb-3" src={localData?.profile_image} roundedCircle={true} width='100' alt='profile' /> : <Image className="img mb-3" src={uploadImage} roundedCircle={true} width='100' alt='profile' />}
                            <br />
                            <span className="drop-title text-primary fs-14">Change Profile Image</span>
                        </label>
                        <input id="upload-button" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={selectImage}
                            style={{ display: 'none' }} />
                    </div>
                </Form.Group>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Access Manager Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name here"
                        autoComplete='nope'
                        {...register("name", {
                            required: "Please provide the name",
                        })}
                    />
                    {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter phone number here"
                        autoComplete='nope'
                        {...register("phone", {
                            required: "Please provide the phone number",
                        })}
                    />
                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email here"
                        {...register("email", {
                            required: "Please provide the email address",
                        })}
                    />
                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                </Form.Group>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password', {
                                required: "Please provide the password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                </Form.Group>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Confirm Password</Form.Label>
                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password_confirmation', {
                                required: "Please provide the confirm password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                </Form.Group>
                
                <div className="div" id="coach-button-web">
                    <div className='d-flex justify-content-end align-items-center pt-4'>
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5' color='secondary' >Cancel</Button>
                        </div>
                        <div className='ms-3'>
                            <Button className='fs-14 fw-600 px-5 text-light' color='primary' type="submit">Save</Button>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
};

export default UpdateCoach;