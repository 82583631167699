import { Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { CiLocationOn, CiCalendar } from "react-icons/ci";

// Images
import { Logo } from "../../assets/images/svg";

export const TournamentCard = (props) => { 
    return <Col xs={12} sm={6} md={4} lg={3} className='mt-3'>
        <Link to={`/tournament/${props?.item?.id}`} className='text-dark text-decoration-none'>
            <Card className='rounded-5'>
                <div className='position-relative'>
                    <Card.Img variant="top" src={props?.item?.banner_image} />
                    <div className='position-absolute top-50 end-0 bg-light rounded-circle p-3 mt-4'>
                        <Logo />
                    </div>
                </div>
                <Card.Body className='mt-3'>
                    <Card.Title className='fs-18 fw-600 text-blue text-capitalize'>{props?.item?.name}</Card.Title>
                    <div>
                        <CiLocationOn className='text-primary me-2' size={20} />
                        <span className='fs-12 fw-400 text-secondary'>{props?.item?.address}</span>
                    </div>
                    <div>
                        <CiCalendar className='text-primary me-2' size={20} />
                        <span className='fs-12 fw-500 text-secondary'>{props?.item?.registration_start_date} to {props?.item?.registration_end_date}</span>
                    </div>
                </Card.Body>
            </Card>
        </Link>
    </Col>
}

export const BankAccountCard = (props) => {
    return <div className='d-flex align-items-center w-100'>
        <img className='img-fluid bank-image me-3' src={props?.item?.bank?.image} alt={props?.item?.bank?.name} />
        <div className='d-flex flex-column align-items-start flex-grow-1'>
            <span className='fs-18 fw-600 text-secondary mb-2 text-capitalize'>{props?.item?.bank?.name}</span>
            <span className='fs-14 text-secondary'>Holder name : {props?.item?.holder_name}</span>
            <span className='fs-14 text-secondary'>Account no. : {props?.item?.account_number}</span>
        </div>
    </div>
}
