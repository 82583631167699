import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import DataTable from 'react-data-table-component'
import ImageUploading from 'react-images-uploading';
import Lightbox from 'react-18-image-lightbox';
// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
import { Edit3, Calendar } from 'react-feather';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { Image, UploadIcon, Users, Men, Women } from '../../../../assets/images/svg';

// API Service
import { allStudentProgramProgressService, singleStudentProgramProgressService, addProgressService, updateProgressService } from '../../../../service/coachProgram';
import { selectUser } from "../../../../redux/userSlice"
import UpdateSection from './UpdateSection'
import { BiCategoryAlt } from 'react-icons/bi';

const AddProgress = ({ tableRecord }) => {
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const [updateProgressModal, setUpdateProgressModal] = useState(false)
    const [addForm, setAddForm] = useState(false);
    const [show, setShow] = useState(false);
    const [progressListing, setProgressListing] = useState([]);
    const [addData, setAddData] = useState();
    const [newData, setNewData] = useState(false);
    const [formValue, setFormValue] = useState(null);
    const [progressImages, setFile] = useState();
    const [startDate, setStartDate] = useState(new Date())
    const [images, setImages] = useState([]);
    const [photos, setPhotos] = useState();
    const [photoIndex, setPhotoIndex] = useState(1);
    const [photoModalShow, setPhotoModalShow] = useState(false);
    const maxNumber = 5;

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    })

    /**
     * @function fetchSingleProgramProgress
     * @params
     * @description fetch the list of customer banner
     */
    const fetchSingleProgramProgress = async (row) => {
        try {
            const result = await singleStudentProgramProgressService({ id: row.id ? row.id : '0' });
            if (result?.data?.status) {
                setLoading(false)
                setFormValue(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
     * @function fetchAllProgramProgress
     * @params
     * @description fetch the list of customer banner
     */
    const fetchAllProgramProgress = async () => {
        try {
            const result = await allStudentProgramProgressService({ program_id: tableRecord.programs?.length > 0 ? tableRecord.programs[0].id : '0', student_id: tableRecord?.id });
            if (result?.data?.status) {
                setLoading(false)
                setProgressListing(result?.data?.data?.progress_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (updateProgressModal && user?.type === 'coach') {
            setLoading(true)
            fetchAllProgramProgress()
        }
        // eslint-disable-next-line
    }, [updateProgressModal, newData])

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    /**
       * @function handleUpdateProgressModal
       * @params
       * @description set the visibility of add bank account modal
       */
    const handleUpdateProgressModal = () => {
        setUpdateProgressModal(!updateProgressModal)
    }

    /**
       * @function handleImageShowModal
       * @params
       * @description show the images in modal
       */
    const handleImageShowModal = () => {
    }

    const handleClose = () => {
        setUpdateProgressModal(false)
        setPhotoIndex(1)
        setPhotoModalShow(false)
        setPhotos([])
        reset()
        setFile()
        setAddForm(false)
    };

    /**
         * @function onSubmit
         * @params formdata
         * @description used to update progress
         */
    const onSubmit = async (formdata) => {

        if (images?.length > 0) {
            if (formdata?.description && tableRecord?.id) {
                const date = moment(startDate[0]).format('YYYY-MM-DD')
                const time = moment(startDate[0]).format('HH:mm')
                try {

                    const data = new FormData()
                    data.append('session_id', tableRecord?.timeslots.length > 0 ? tableRecord?.timeslots[0]?.session_id : '')
                    data.append('program_id', tableRecord?.programs.length > 0 ? tableRecord?.programs[0]?.id : '')
                    data.append('student_id', tableRecord?.id)
                    data.append('description', formdata?.description)
                    data.append('date', date)
                    data.append('time', '03:57')
                    // data.append('progress_images[0]', progressImages[0])
                    // data.append(`progress_images[${0}]`, progressImages[0])
                    images?.forEach((item, index) => {
                        data.append(`progress_images[${index}]`, item?.file)
                    })
                    // if (progressImages?.length > 0) {
                    //     progressImages.map((element, index) => (
                    //         data.append(`progress_images[${index}]`, element)
                    //     ))
                    // }
                    const result = await addProgressService(data);
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        reset()
                        handleClose()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } catch (error) {
                    ErrorAlert(error)
                }
            } else {
                ErrorAlert('Something going to wrong')
            }
        } else {
            ErrorAlert('Please select program images')
        }

    }

    const handleAddItem = () => {
        setAddForm(true)
        setShow(false)
    }
    const handleEdit = (row) => {
        setShow(!show)
        setAddForm(false)
        setFormValue(row)
    }

    const handleUploadImages = (e) => {
        const file = e?.target?.files[0];
        setFile(file)
    }

    const columns = [{
        name: 'Sr. No.',
        cell: (row, index) => `${index + 1}`,
        // cell: (row, index) => pageCount > 1 ? ((pageCount - 1) * 10) + index + 1 : index + 1,
        width: "80px",
    },

    // {
    //     name: 'Date and Time',
    //     selector: (row) => row?.date ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
    //     >{moment(row?.date).format('DD MMM YYYY, h:mm A')}</span> : '--',
    //     className: 'onlyCapitalized',
    //     minWidth: '180px'
    // },
    {
        name: 'images',
        selector: (row) => row?.progress_images.length > 0 ? <>
            <div className="d-flex align-content-center">
                {row?.progress_images?.slice(0, 2)?.map((item, index) => {
                    return (
                        <div className='me-1 position-relative' key={`${index}`}>
                            <img className='rounded' src={item?.path} alt={item?.name} width='60' />
                            {row?.progress_images?.length > 2 && index === 1 && <>
                                <div className='d-flex flex-column align-items-center justify-content-center bg-secondary opacity-50 w-100 h-100 position-absolute top-0 left-0 p-2 rounded'></div>
                                <div role='button' className='z-1 d-flex flex-column align-items-center justify-content-center w-100 h-100 position-absolute top-0 left-0 p-2 rounded' onClick={() => {
                                    setPhotoIndex(1)
                                    setPhotos(row?.progress_images?.map(item => item?.path))
                                    setPhotoModalShow(true)
                                }}><p className='text-light mb-0'>{row?.progress_images?.length - 2}</p> <p className='text-light mb-0'> more</p></div>
                            </>}
                        </div>
                    );
                })}
            </div>
        </> : '--',
        minWidth: '200px'
    },
    {
        name: 'Description',
        selector: (row) => row?.description ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
        >{row.description}</span> : '--',
        className: 'onlyCapitalized',
        width: '350px'
    },

    {
        name: 'Actions',
        allowOverflow: true,
        selector: (row) => <Edit3 size='17' className='custom-hover' onClick={() => handleEdit(row)} />,
    },]

    return <Fragment>
        <>
            <div className="upgrade">
                <Button size="sm" variant="primary" className='text-light' onClick={handleUpdateProgressModal}>Update</Button>
            </div>
            <div className="modal-update">
                <Modal show={updateProgressModal} onHide={handleClose} size='lg' backdrop="static" centered>
                    <Modal.Header className='fs-22 fw-bold border-0 px-5 mt-3' closeButton >
                        Student Progress
                    </Modal.Header>
                    <Modal.Body className='px-5'>
                        <div className="progress-list mb-4">
                            <Row className='box-programme gx-2'>
                                {tableRecord?.id ? <Fragment>
                                    <Col lg={12} md={12} sm={12} className=''>
                                        <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                            <DataTable
                                                // noHeader
                                                columns={columns}
                                                // customStyles={students && students.length <= 2 ? customStyles : ''}
                                                className='react-dataTable'
                                                // sortIcon={<ChevronDown size={10} />}
                                                data={progressListing}
                                            // pagination
                                            // paginationServer
                                            // paginationTotalRows={10}
                                            // paginationPerPage={10}
                                            // paginationComponentOptions={{ noRowsPerPage: true }}
                                            // paginationComponent={CustomPagination}
                                            // paginationDefaultPage={props?.currentPage + 1}
                                            />
                                        </div>

                                    </Col>
                                </Fragment> : ""}
                            </Row>
                        </div>

                        <div className="progress-form">
                            {addForm === false && show === false ? <>
                                <Row className='mb-2'>
                                    <Col md={12}>
                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                            <div className="add-content fs-14 fw-500" role='button'>
                                                <Button size='sm' variant='outline-dark' onClick={handleAddItem}>Add Progress</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> </> : ''}
                            {addForm ? <><Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                                <div className="frequency-content mb-4 rounded p-3">
                                    <div className="inputs-fields mb-4">
                                        <Row>
                                            <Col md={12} className='mb-2'>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="add-title text-dark fs-14 fw-600"> Add Progress</div>
                                                    {/* <div className="cancel float-end text-primary cursor-pointer"><X size={22} onClick={(e) => handleRemoveItem(i)} /> </div> */}
                                                </div>
                                            </Col>

                                            <ImageUploading
                                                multiple
                                                acceptType={['jpg', 'jpeg', 'png']}
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper d-flex flex-wrap">

                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item position-relative">
                                                                <img className='me-3 mt-2' role='button' src={image['data_url']} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                                                <AiOutlineClose role='button' className='position-absolute top-0 end-0 text-danger bg-light rounded-circle me-3 mt-2' size={15} onClick={() => onImageRemove(index)} />
                                                            </div>
                                                        ))}
                                                        {imageList?.length < 5 && <button className='border-0 p-0'
                                                            style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            <label className='' htmlFor="progressPhotoUpload">
                                                                <div className='d-flex flex-column justify-content-center align-items-center custom-hover p-3 upload-icon' style={{ background: '#FFF8F5' }}>
                                                                    <UploadIcon className='mb-2' />
                                                                    <span className='fs-14 fw-600 text-primary'>Add Image</span>
                                                                </div>
                                                            </label>
                                                        </button>}
                                                        {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                                    </div>

                                                )}
                                            </ImageUploading>

                                            <Col md={12}>
                                                <div className="custom-dropdown">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className='fs-14 fw-500'>Date</Form.Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className='form-control'
                                                                value={startDate}
                                                                id='timepicker'
                                                                options={{
                                                                    static: true,
                                                                    altInput: true,
                                                                    allowInput: true,
                                                                    enableTime: false,
                                                                    noCalendar: false,
                                                                    dateFormat: "YYYY/MM/DD",
                                                                    altFormat: "d-m-Y",
                                                                    // minDate: "today"
                                                                }}
                                                                onChange={date => setStartDate(date)}
                                                            />
                                                            <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div className="custom-price">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className='fs-14 fw-500'>Description</Form.Label>
                                                        <Form.Control
                                                            type="textarea"
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Write description here"
                                                            required="description is required"
                                                            name='description'
                                                            {...register("description", {
                                                                required: "Please provide description",
                                                            })}
                                                        />
                                                        {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>

                                <div className='d-flex justify-content-end align-items-end mt-4'>
                                    <div>
                                        <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Save</PrimaryButton>
                                    </div>
                                </div>
                            </Form></> : ''}
                            {show ? <UpdateSection tableRecord={formValue} setNewData={setNewData} newData={newData} /> : ''}
                        </div>
                    </Modal.Body>
                    {photoModalShow && (
                        <Lightbox
                            mainSrc={photos[photoIndex]}
                            nextSrc={photos[(photoIndex + 1) % photos.length]}
                            prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                            onCloseRequest={() => setPhotoModalShow(false)}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
                            onMoveNextRequest={() => {
                                setPhotoIndex((photoIndex + 1) % photos.length)
                            }}
                        />
                    )}
                </Modal>
            </div>
            <div>
            </div>
        </>
    </Fragment>
};

export default AddProgress;