import axios from "axios"

/**
    * @function allProgramsService
    * @param
    * @description This function is used to get
*/
export const allProgramsService = async() =>{
    try {
        const result = await axios.get(`/coach/program/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function onGoingProgramsService
    * @param
    * @description This function is used to get
*/
export const onGoingProgramsService = async() =>{
    try {
        const result = await axios.get(`/coach/program/ongoing`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramService
    * @param
    * @description This function is used to get
*/
export const singleProgramService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/program/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCategoryService
    * @param
    * @description This function is used to get account
*/
export const allCategoryService = async() =>{
    try {
        const result = await axios.get(`/academy/sport/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}
 
/**
    * @function singleSessionService
    * @param
    * @description This function is used to get
*/
export const singleSessionService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/session/program/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramSessionService
    * @param
    * @description This function is used to get
*/
export const singleProgramSessionService = async({id, session_id}) =>{
    try {
        const result = await axios.get(`/coach/session/program/single/${id}/${session_id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleStudentSessionService
    * @param
    * @description This function is used to get student session
*/
export const singleStudentSessionService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/program/student/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addSessionService
    * @param data
    * @description This function is used to add bank account
*/
export const addSessionService = async(data) =>{
    try {
        const result = await axios.post(`/coach/session/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateSessionService
    * @param { id, data }
    * @description This function is used to update bank account
*/
export const updateSessionService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/coach/session/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function changeSessionService
    * @param { id, data }
    * @description This function is used to update session
*/
export const changeSessionService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/coach/session/student/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramStudentService
    * @param
    * @description This function is used to get
*/
export const singleProgramStudentService = async({id, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/coach/program/student/list/${id}?paginate=${1}&page=${page}&perPage=${countPerPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterStudentService
    * @param
    * @description This function is used to get
*/
export const filterStudentService = async({id, start_date, end_date}) =>{
    try {
        const result = await axios.get(`/coach/program/student/list/${id}?paginate=${false}&start_date=${start_date}&end_date=${end_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterStudentService
    * @param
    * @description This function is used to get all student
*/
export const DownloadCSVStudentService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/program/student/list/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramFilterStudentService
    * @param
    * @description This function is used to get
*/
export const singleProgramFilterStudentService = async({id, page, filter, countPerPage}) =>{
    try {
        const result = await axios.get(`/coach/program/student/list/${id}?paginate=${1}&page=${page}&perPage=${countPerPage}&session_id=${filter}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function ProgramSessionStudentService
    * @param
    * @description This function is used to get
*/
export const ProgramSessionStudentService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/session/program/${id}?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleProgramProgressService
    * @param
    * @description This function is used to get ̵
*/
export const singleProgramProgressService = async({id, session_id}) =>{
    try {
        const result = await axios.get(`/coach/session/program/single/${id}/${session_id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allStudentProgramProgressService
    * @param
    * @description This function is used to get single progress
*/
export const allStudentProgramProgressService = async({program_id, student_id}) =>{
    try {
        const result = await axios.get(`/coach/progress/list?program_id=${program_id}&student_id=${student_id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleStudentProgramProgressService
    * @param
    * @description This function is used to get single progress
*/
export const singleStudentProgramProgressService = async({id}) =>{
    try {
        const result = await axios.get(`/coach/progress/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addProgressService
    * @param data
    * @description This function is used to add bank account
*/
export const addProgressService = async(data) =>{
    try {
        const result = await axios.post(`/coach/progress/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProgressService
    * @param { id, data }
    * @description This function is used to update progress
*/
export const updateProgressService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/coach/progress/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteProgressImageService
    * @param id
    * @description This function is used to delete progress images
*/
export const deleteProgressImageService = async(name) =>{
    try {
        const result = await axios.delete(`/coach/progress/remove-image/1?image_name=${name}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteProgramService
    * @param id
    * @description This function is used to delete progress program
*/
export const deleteProgramService = async(id) =>{
    try {
        const result = await axios.delete(`/academy/program/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function checkStudentAttendance
    * @param data
    * @description This function is used to checkStudentAttendance
*/
export const checkStudentAttendance = async(data) =>{
    try {
        const result = await axios.post(`/coach/program/student/attendence`, data)
        return result;
    } catch (error) {
        return error;
    }
}