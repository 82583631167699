import { useState, useEffect, Fragment } from 'react';
import { Carousel, Form, Container, Row, Col, Card, Button, Badge, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { } from 'react-router-dom'
import Select from 'react-select'
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import { selectThemeColors } from '../../../../utils/Utils'

// Custom Component ../Alert/ErrorAlert
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { XSquare, Mail, Link, Calendar } from 'react-feather'
// Images
// import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../../../assets/images/index";

// API Service
import { allStudentSessionService, allStudentEarningService, singleProgramFilterStudentEarningService, singleProgramFilterStudentEarningwithMonthService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { setStudentEarningList, setSessionList, selectSessionList, selectStudentEarningList, setTotalEntry, selectTotalEntry } from "../../../../redux/academyProgrammeSlice"

import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import Flatpickr from 'react-flatpickr'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import NoWallet from '../../../../components/Common/NoEarned'
import FilterEarning from './FilterEarning'
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import { copyLinkGenerateService } from '../../../../service/academyCopyShareLink';
import EmailConfirmationModal from '../../../../components/Modal/EmailConfirmationModal';

const Students = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [star, setStar] = useState(5);
    const user = useSelector(selectUser);
    const studentsList = useSelector(selectStudentEarningList);
    const sessionList = useSelector(selectSessionList);
    const [studentCount, setStudentCount] = useState(0);
    const [checked, setChecked] = useState(0)
    const [rowData, setRowData] = useState(0)
    const [emailShareModal, setEmailShareModal] = useState(false)
    const totalEntry = useSelector(selectTotalEntry)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const countPerPage = 10

    const [show, setShow] = useState(false)
    const [selectedSession, setSelectedSession] = useState();
    const allSession = [{ label: 'abc', value: '1' }]

    console.log('sessionList', sessionList)
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

        },
        mode: "onBlur"
    })

    const watchDate = watch('startDate')
    if (watchDate?.length > 0) {
        console.log('watchDate', watchDate, moment(watchDate[0])?.format('DD-MM-YYYY'))

    }

    /**
         * @function handleEmailShareLinkModal
         * @params
         * @description generate link for copy
    */
    const handleEmailShareLinkModal = async (rowData) => {
        console.log('rowData', rowData, singleData, rowData?.pivot?.payment_status)
        setEmailShareModal(!emailShareModal)
        setRowData(rowData)
    }

    /**
         * @function handleCopyGenerateLink
         * @params
         * @description generate link for copy
    */
    const handleCopyGenerateLink = async (rowData) => {
        console.log('rowData', rowData, singleData, rowData?.pivot?.payment_status)

        // if (rowData?.pivot?.payment_status === 'COMPLETED' || !rowData?.pivot?.payment_status === 'COMPLETED') {
        if (true) {
            try {
                const data = new FormData()

                data.append('amount', rowData?.pivot?.amount)
                data.append('program_id', rowData?.programs[0]?.id)
                data.append('payment_method', rowData?.pivot?.payment_type)
                data.append('student_ids[0]', rowData?.id)
                data.append('frequency_type', rowData?.pivot?.frequency_type)
                data.append('customer_id', rowData?.customer_id)

                for (const value of data.values()) {
                    console.log('rowData data', value);
                };

                const result = await copyLinkGenerateService(data);
                if (result?.data?.status) {
                    navigator.clipboard.writeText(result?.data?.data);
                    console.log('result?.data', result?.data?.data)
                    SuccessAlert('Link copied successfully')
                } else {
                    console.log('result?.data', result?.response?.data?.message)
                    ErrorAlert(result?.response?.data?.message)
                }

            } catch (error) {

            }
        } else {
            ErrorAlert('Payment is not completed.')
        }
    };

    /**
         * @function fetchAcademySessionList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchAcademySessionList = async () => {
        try {
            if (singleData?.id) {
                const result = await allStudentSessionService({ id: singleData?.id });
                console.log('sessionList result', result)
                if (result?.data?.status) {
                    setLoading(false)
                    const resultSession = result?.data?.data?.map((item) => ({ value: item.id, label: item.date }))
                    const allOptions = [{ value: '', label: 'All' }]
                    const finalDrop = allOptions.concat(resultSession)
                    dispatch(setSessionList(finalDrop))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchAcademyStudentEarningList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchAcademyStudentEarningList = async (page) => {
        try {
            if (singleData?.id) {
                const result = await allStudentEarningService({ id: singleData?.id, page, countPerPage, });
                console.log('fetchAcademyStudentEarningList', result)
                if (result?.data?.status) {
                    setLoading(false)
                    dispatch(setStudentEarningList(result?.data?.data.student_lists))
                    dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                    setStudentCount(result?.data?.data?.pagination.total)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
     * @function filterSingleProgramProgress
     * @params 
     * @description fetch the list of customer banner
     */
    const filterSingleProgramProgress = async (page, filter) => {
        try {
            const result = await singleProgramFilterStudentEarningService({ id: singleData.id, page, filter: filter ? filter : selectedSession?.value, countPerPage });
            // const result = await singleProgramFilterStudentEarningService({ id: singleData.id, page, filter: filter ? filter : selectedSession?.value, countPerPage });
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setStudentEarningList(result?.data?.data?.student_lists))
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
                setStudentCount(result?.data?.data?.pagination?.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
     * @function filterSingleProgramProgressWithMonth
     * @params 
     * @description fetch the list of student earning with month filter
     */
    const filterSingleProgramProgressWithMonth = async (page) => {
        try {
            const result = await singleProgramFilterStudentEarningwithMonthService({ id: singleData.id, page, countPerPage, start_date: moment(watchDate[0])?.format('YYYY-MM-DD') });
            console.log('singleProgramFilterStudentEarningwithMonthService', result)
            if (result?.data?.status) {
                setLoading(false)
                dispatch(setStudentEarningList(result?.data?.data?.student_lists))
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
                setStudentCount(result?.data?.data?.pagination?.total)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    };

    useEffect(() => {
        if (watchDate?.length > 0) {
            filterSingleProgramProgressWithMonth(currentPage + 1)
        }
        setLoading(true)
        fetchAcademySessionList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData, watchDate])

    useEffect(() => {
        if (selectedSession?.value) {
            filterSingleProgramProgress(currentPage + 1, selectedSession?.value)
        } else {
            fetchAcademyStudentEarningList(currentPage + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData, currentPage, selectedSession]);

    const handleCheck = (e) => {
        const value = e.currentTarget.checked
        setChecked(value)

    }

    const columns = [{
        name: 'Date & Time',
        selector: (row) => row?.pivot?.created_at ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px' }}
        >{moment(row?.pivot?.created_at).format('DD MMM YYYY, dddd, h:mm A')}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '250px'
    },
    {
        name: 'User Name',
        selector: (row) => row?.customer?.username ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.customer?.username}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Email',
        selector: (row) => row?.customer?.email ? `${row?.customer?.email}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Student Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Amount',
        selector: (row) => row?.pivot?.amount ? `RM ${row?.pivot?.amount}` : '--',
        minWidth: '120px'
    },
    {
        name: 'Type of Payment',
        selector: (row) => row?.pivot?.frequency_type ? row?.pivot?.frequency_type : '--',
        minWidth: '120px'
    },
    {
        name: 'Time Slots of Session',
        selector: (row) => row.phone ? <Fragment>
            <p>
                <div className="d-flex justify-content-center align-content-center">
                    {row?.timeslots?.length > 0 ? <span className='text-primary'>{moment(row?.timeslots[0]?.session?.date).format('DD-MM-YYYY')}, {row?.timeslots[0]?.start_time} - {row?.timeslots[0]?.end_time}</span> : ''}
                </div>
            </p>
        </Fragment> : '--',
        minWidth: '280px'
    },
    {
        name: 'Status',
        minWidth: '130px',
        selector: (row) => {
            let showData = ''
            if (row.pivot?.payment_status === 'COMPLETED') {
                showData = <Badge pill bg='success'>Full Paid</Badge>
            } else {
                showData = <Badge pill bg="danger">No Payment</Badge>
            }
            // showData = <Badge pill bg="success">Full Paid</Badge>
            return (showData)
        },
    },

    {
        name: 'Copy or share link',
        selector: (row) => row?.pivot?.payment_status ? <><div className="d-flex align-content-center justify-content-start"><div className="div"><Link className={classNames('', { 'text-muted pe-none': row?.pivot?.payment_status === 'COMPLETED' })} role='button' size='17' onClick={() => handleCopyGenerateLink(row)} /></div><div className="ms-3 text-primary"><Mail className={classNames('', { 'text-muted pe-none': row?.pivot?.payment_status === 'COMPLETED' })} role='button' size='17' onClick={() => handleEmailShareLinkModal(row)} /></div></div></> : '--',
        minWidth: '160px',

    },]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
        */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    const handleFilterSession = (e) => {
        const value = e?.value
        if (value) {
            setSelectedSession(e)
            setCurrentPage(0)
            // filterSingleProgramProgress(pageCount, value)
        } else {
            // fetchAcademyStudentEarningList(pageCount)
            setCurrentPage(0)
            setSelectedSession('')
        }

    }

    /**
         * @function CustomPagination
         * @params
         * @description used for Custom Pagination
         */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <>
        <div>
            <div className='boxSection mt-2'>
                <div>
                    <div className="box-cover">
                        <Row className='mb-4'>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                    <div className='mx-2'><p className='fs-18 fw-bold mb-0'>Users</p> </div>
                                    <div>
                                        {studentCount > 0 ? <span className='student-count'>{`${studentCount} `} </span> : '0'}
                                    </div>

                                    <div className='ms-5 d-flex justify-content-start align-items-center'>
                                        {/* <span className=''>June 2023</span>
                                        <div className='custom-calendar ms-4'>
                                            <Calendar size={14} />
                                        </div> */}

                                        <Form>
                                            <Form.Group className="my-2 d-flex align-items-center earningFlatPikr">
                                                <Controller
                                                    id='startDate'
                                                    name='startDate'
                                                    defaultValue={new Date()}
                                                    // // onChange={date => setStartDate(date)}
                                                    control={control}
                                                    render={({ field }) => <Flatpickr className='border-0 py-1' autoComplete='off' id='default-picker' {...field} placeholder='Date'
                                                        options={{
                                                            static: true,
                                                            allowInput: true,
                                                            enableTime: false,
                                                            noCalendar: false,
                                                            plugins: [new monthSelectPlugin({
                                                                shorthand: true, //defaults to false
                                                                dateFormat: "F Y", //defaults to "F Y"
                                                            })]
                                                        }}
                                                    />
                                                    }
                                                />
                                                <div className='border border-dark px-2 py-1 z-1 rounded'><Calendar size='15' /></div>

                                            </Form.Group>
                                        </Form>

                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={12} xs={12} className='pt-3 mb-1'>
                                <div className='d-flex justify-content-end align-items-center flex-lg-row'>
                                    <div className='w-50'>
                                        <Select
                                            // closeMenuOnSelect={false}
                                            theme={selectThemeColors}
                                            className='react-select'
                                            classNamePrefix='select'
                                            defaultValue={selectedSession}
                                            name='clear'
                                            options={sessionList}
                                            placeholder='Session'
                                            onChange={(e) => handleFilterSession(e)}
                                        />
                                    </div>
                                    <div className='ms-4 mx-2'>
                                        <FilterEarning singleData={singleData} />
                                        {/* <div><Button size="md" variant="outline-primary" className='export-button fw-bold'>Export CSV</Button></div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="div">
                            <Row className='box-programme height-80vh gx-2'>
                                <Fragment>
                                    <Col lg={12} md={12} sm={12} className=''>
                                        <div className='react-dataTable react-dataTable-selectable-rows student-list-table'>
                                            <DataTable
                                                // noHeader
                                                columns={columns}
                                                // customStyles={students && students.length <= 2 ? customStyles : ''}
                                                className='react-dataTable'
                                                // sortIcon={<ChevronDown size={10} />}
                                                data={studentsList}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={10}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                paginationComponent={CustomPagination}
                                                paginationDefaultPage={currentPage + 1}
                                            />
                                        </div>

                                    </Col>
                                </Fragment>
                            </Row>
                            <Row>
                                <Col className='' lg={12}>
                                    <div className='pt-3 pb-3 student-pagination'>
                                        <ReactPaginate
                                            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                <span className='fs-14 fw-600 me-2'>Next</span>
                                                <AiOutlineArrowRight className='text-secondary' size={20} />
                                            </div>}
                                            breakLabel='...'
                                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                                <span className='fs-14 fw-600'>Previous</span>
                                            </div>}
                                            pageRangeDisplayed={3}
                                            forcePage={currentPage}
                                            marginPagesDisplayed={3}
                                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                                            breakClassName='page-item me-3 d-flex align-items-center'
                                            nextClassName='page-item next-item flex-grow-1 text-end'
                                            previousClassName='page-item prev-item flex-grow-1 me-3'
                                            disabledLinkClassName='btn disabled p-0 border-0'
                                            pageCount={totalEntry || 1}
                                            onPageChange={page => handlePagination(page)}
                                            containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <div className="div" id='box-coach-mobile-only'>
                <div className='boxSection mt-2'>
                    <div>
                        <Row id='box-coach-mobile-only' className='box-programme gx-2'>
                            {studentsList && studentsList?.length > 0 ? studentsList.map(item => (
                                <Col sm={12} className=''>
                                    <>
                                        <div className='coach-view-box'>
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div className="">
                                                    <p className='fs-14 fw-600 mb-1'>{item?.name}</p>
                                                    <p className='fs-14 fw-600 mb-0'><Badge pill bg="success">Full Paid</Badge></p>
                                                </div>
                                                <div className=''>
                                                    <p className='fs-14 fw-600 mb-0'>RM{item?.pivot?.amount ? item?.pivot?.amount : '0.00'}</p>
                                                    {/* <XSquare size={15} tableRecord={item} /> */}
                                                </div>
                                            </div>
                                            <div className='detail my-2'>
                                                <div className="my-2">
                                                    {/* <p className='mb-0'><span>{item?.email}</span> &nbsp; <span>{item?.email}</span></p> */}
                                                    <p className='mb-0'><span>{item?.email}</span></p>
                                                </div>
                                                <div className="my-2">
                                                    <p className='mb-0'> <span>+60 {item?.phone}</span></p>
                                                </div>

                                            </div>
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div className="">
                                                    <p className='fs-14 fw-600 mb-0'>{moment(item?.pivot?.created_at).format('DD MMM YYYY, dddd, h:mm A')}</p>
                                                </div>
                                                <div className=''>
                                                    <p className='fs-14 fw-600 mb-0'>{item?.id ? <><div className="d-flex align-content-center justify-content-start"><div className="div"><Link size='17' /></div><div className="ms-3 text-primary"><Mail size='17' /></div></div></> : '--'}</p>
                                                    {/* <XSquare size={15} tableRecord={item} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Col>
                            )) : ''}
                        </Row>
                    </div>
                </div>
            </div>
            <div className='clearfix'></div>
        </div>
        <EmailConfirmationModal show={emailShareModal} handleClose={handleEmailShareLinkModal} rowData={rowData} />
    </>
};

export default Students;