import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { logo, authentication } from '../../../../assets/images';

// API Service
import { resetPasswordService } from '../../../../service/auth.service';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibilty
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function handleShowConfirmPassword
        * @params
        * @description toggle the confirm password input field visibilty
    */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
        * @function onSubmit
        * @params formdata
        * @description used to reset password
    */
    const onSubmit = async (formdata) => { 
        try {
            const data = new FormData()
            data.append('phone', state?.phone)
            data.append('reset_otp_token', state?.reset_otp_token)
            data.append('password', formdata?.password)
            data.append('password_confirmation', formdata?.password)
            const result = await resetPasswordService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                navigate('/login')
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }
    
    return <HelmetProvider>
        <Helmet>
            <title>Reset Password | AFA Coaching</title>
        </Helmet>
        <Container fluid>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" width={106} />
                    </Link>
                    <Row className="justify-content-center flex-grow-1 w-100 mt-5">
                        <Col xs={10} sm={6} lg={7} className='mb-4 student-register' >
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fw-bold'>Reset Password</span>
                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>New Password**</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Confirm New Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password_confirmation', {
                                                required: "Confirm Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                                </Form.Group>
                                
                                <Form.Group className="mb-3 mt-4">
                                    <Button size='md' variant="primary" className='text-light w-100' type='submit'>Reset Password</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xs={12} className='d-none d-lg-block mt-auto'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <span className='fs-14 fw-400 text-secondary'>
                                    © AFA 2023
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='fs-14 fw-400 text-secondary'>
                                        help@AFA.com
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default ResetPassword;