import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button, Spinner, Image } from 'react-bootstrap';

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classnames from "classnames";
import moment from 'moment';
import Flatpickr from 'react-flatpickr'
import { PlusCircle, X, Edit3, Calendar, Download } from 'react-feather';

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import DataTable from 'react-data-table-component'
// API Service
import { downloadCSVCoachService, filterCoachService } from '../../../../service/coach.service';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { CSVLink } from 'react-csv';

const defaultValues = {
    startDate: null,
    endDate: null
}

const FilterDateRange = ({ singleData }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [data, setData] = useState(null)
    const [coachList, setCoaches] = useState([])
    const [count, setCount] = useState([])
    const [downloadable, setDownloadable] = useState(null)
    const [csvData, setCSVData] = useState([]);

    let fetchingData = false
    let fileName = ''
    let finalStart = ''
    let finalEnd = ''

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

        },
        mode: "onBlur"
    })

    const setFileName = () => {
        return `order-${startDate}-to-${endDate}`
    }

    const columns = [{
        name: 'Profile',
        selector: (row) => row.profile_image ? <Image src={row?.profile_image} alt='profile' width='35' roundedCircle={true} /> : '--',
        minWidth: '60px'
    },
    {
        name: 'Type',
        selector: (row) => row.type === 'MAIN_COACH' ? <span className='text-success'>MAIN COACH</span> : 'SUB COACH',
        minWidth: '150px'
    },
    {
        // {
        //     name: 'Sr. No.',
        //     cell: (row, index) => pageCount > 1 ? ((pageCount - 1) * 10) + index + 1 : index + 1,
        //     width: "100px",
        // },


        name: 'Name',
        selector: (row) => row?.name ? <span style={{ whiteSpace: 'break-spaces', marginTop: '6px', marginBlock: '6px', fontWeight: '800' }}
        >{row.name}</span> : '--',
        className: 'onlyCapitalized',
        minWidth: '180px'
    },
    {
        name: 'Email',
        selector: (row) => row.email ? `${row.email}` : '--',
        minWidth: '180px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone ? `+60 ${row.phone}` : '--',
        minWidth: '180px'
    },

    {
        name: 'Gender',
        selector: (row) => row.gender ? `${row.gender}` : '--',
        minWidth: '150px'
    },
    {
        name: 'Date of Birth',
        selector: (row) => row.dob ? moment(row.dob).format("DD/MM/YYYY") : '--',
        minWidth: '150px'
    }]

    const fields = ['Sr. No.',
        'Type',
        'Name',
        'Email',
        'Phone Number',
        'Gender',
        'Date of Birth',]

    const processData = (item, index) => {
        return `${index + 1},${item.type ? item.name === 'MAIN_COACH' ? 'Main Coach' : 'Sub Coach' : '-'},${item.name ? item.name : '-'},${item.email ? item.email : '-'},${item.phone ? `+60 ${item?.phone}` : '-'},${item.gender ? item?.gender : '-'},${item.dob ? `${moment(item.dob).format("DD/MM/YYYY") }` : '-'}`
    }

    const exportToCSV = () => {
        if (coachList?.length > 0) {
            const tableData = coachList
            const csvContent = [fields.join(','),
            ...tableData.map((item, index) => processData(item, index))]
                .join('\n')
                .replace(/(^\[)|(\]$)/gm, '')

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const downloadURL = URL.createObjectURL(blob)
            setDownloadable(downloadURL)
            fileName = setFileName()
            fetchingData = false
        } else {
            fetchingData = false
            setCount(0)
        }
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const result = await downloadCSVCoachService();
            console.log('result', result)
            if (result?.data?.status) {
                setLoading(false)
                const get_all = result?.data?.data.coach_list
                setCoaches(get_all)
                setCount(get_all?.length)
                if (get_all?.length > 0) {
                    setCSVData(get_all?.map(item => {
                        return {
                            CoachType: item?.type === 'MAIN_COACH' ? 'Main Coach' : 'Sub Coach',
                            name: item?.name || '-',
                            email: item?.email || '-',
                            contact: `"${item?.phone}"` || '-',
                            gender: item?.gender || '-',
                            DOB: item?.dob ? `=""${moment(item?.dob).format("DD/MM/YYYY")}""` : '-',
                        }
                    }))
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const csvHeaders = [{ label: 'Coach Type', key: 'CoachType' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone number', key: 'contact' },
    { label: 'Gender', key: 'gender' },
    { label: 'Date of Birth', key: 'DOB' }];
    
    useEffect(() => {
        if (coachList?.length > 0) {
            exportToCSV()
        }
        // eslint-disable-next-line
    }, [coachList])

    const onSubmit = data => {
        setData(data)
        if (data.startDate && data.endDate) {
            if (data.startDate !== null && data.endDate !== null) {
                finalStart = moment(data.startDate[0]).format('YYYY-MM-DD')
                finalEnd = moment(data.endDate[0]).format('YYYY-MM-DD')
                // fetchData(data)
            }
        } else {
            ErrorAlert('Please provide start and end date')
        }
    }

    const handleCloseModal = () => {
        setShow(false)
        setStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setEndDate(moment(new Date()).format("YYYY-MM-DD"))
        setData([])
        setCoaches([])
        setCount(0)
        setDownloadable(null)
        reset()
    }

    const displayCondition = () => {
        // count > -1 && !fetchingData
        if (count > -1 && !fetchingData) {
            return <div>
                <span className="text-muted">
                    Result:
                </span>
                <span className="text-primary font-weight-bold">
                    {` ${count} `}
                </span>
                <span>
                    {`record${count > 1 ? 's' : ''}`}
                </span>
            </div>
        }
    }
    const displayConditionDownload = () => {
        if (downloadable) {
            return <div className='d-flex align-md-items-center align-items-start justify-content-end'>
                {/* <div className=''>
                    <Input
                        className='dataTable-filter mb-50'
                        type='text'
                        bsSize='sm'
                        disabled
                        id='search-input'
                        defaultValue={fileName}
                    />
                    download={`${fileName}.csv`}
                </div> */}
                <div className='ms-2'>
                    <Button
                        className=''
                        variant='primary'
                        tag="a"
                        size='sm'
                        href={downloadable}
                        download={`coaches.csv`}
                    >
                        <Download size={15} className='text-light'/>
                        <span className='align-middle ms-50 fs-12 text-light'> Download CSV</span>
                    </Button>
                </div>

            </div>
        }
    }


    return <>
        <div className='me-2'>
            {/* <div>
                <div><Button size="sm" variant="outline-primary" className='export-button fw-bold' onClick={() => setShow(true)}>Export CSV</Button></div>
            </div> */}

            <CSVLink className="text-decoration-none" filename={"Coach.csv"} data={csvData} headers={csvHeaders}>
                <span role='button' className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded d-none d-lg-block'>Export CSV</span>
            </CSVLink>
        </div>

        <Modal show={show} onHide={handleCloseModal} size='lg' centered>
            <Modal.Header className='fs-18 fw-bold border-0' closeButton >
                Coaches filter by date range
            </Modal.Header>
            <Modal.Body className=''>

                <div className="date-piker">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='justify-content-end mx-0 mt-2'>
                            <Col md='6' sm='12' className='mb-1'>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Start Date</Form.Label>
                                    <Controller
                                        id='startDate'
                                        name='startDate'
                                        // defaultValue={startDate}
                                        // // onChange={date => setStartDate(date)}
                                        control={control}
                                        render={({ field }) => <Flatpickr autoComplete='off' id='default-picker' {...field} placeholder='Start Date' options={{ allowInput: true }}
                                            className={classnames('form-control', {
                                                'is-invalid': data !== null && data.startDate === null
                                            })} />
                                        }
                                    />
                                </Form.Group>

                            </Col>

                            <Col md='6' sm='12' className='mb-1'>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>End Date</Form.Label>

                                    <Controller
                                        id='endDate'
                                        name='endDate'
                                        control={control}
                                        // defaultValue={endDate}
                                        render={({ field }) => <Flatpickr
                                            // onChange={date => setEndDate(date)}
                                            id='default-picker'
                                            //  disabled={(startDate.length < 1)}
                                            autoComplete='off'
                                            // onChange={checkData}
                                            {...field} placeholder='End Date' options={{ allowInput: true }}
                                            className={classnames('form-control', {
                                                'is-invalid': data !== null && data.startDate === null
                                            })} />
                                        }
                                    />
                                    {/* {errors.endDate && <FormFeedback>{errors.endDate.message}</FormFeedback>} */}

                                </Form.Group>
                            </Col>
                            <Col sm='12'>
                                <div className='d-flex'>
                                    <Button size="sm" className='me-1 text-light' variant='success' type='submit'>
                                        Filter
                                        {/* {isLoading ? <Spinner color='light' size='sm' /> : 'Create'} */}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Row className='justify-content-end mx-0'>
                        <Col className='mt-1' md='6' sm='6'>
                            {displayCondition()}
                        </Col>
                        <Col className='mt-1' md='6' sm='6'>
                            {displayConditionDownload()}
                        </Col>
                    </Row>
                </div>

                <div className="student-list">
                    {isLoading ? <>
                        <Fragment>
                            <>
                                <div className='parent'>
                                    <div className='myHeight text-center'>
                                        <Spinner variant='primary' />
                                    </div>
                                </div>
                            </>
                        </Fragment>
                    </> : ''}
                    {coachList?.length > 0 ? <div className='export-table react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={coachList}
                            sticky-header="500px"
                            key={coachList?.id}
                        />
                    </div> : ''}
                </div>

            </Modal.Body>

        </Modal>
    </>

};

export default FilterDateRange;