import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

// Custom Component

const ConfirmationModal = (props) => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    const onSubmit = async (formdata) => {
        props?.setConfirmation(true)
        props.handleClose()

    };

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            Are you sure you want to logout?
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <div className='w-100'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='text-center'>
                    </div>

                    <div className='d-flex justify-content-end mt-3'>
                        <Button className='fs-14 fw-500 me-2 opacity-50 text-light' role='button' onClick={() => props.handleClose()}>cancel</Button>
                        <Button className='fs-14 fw-500 text-light' type="submit">Yes</Button>
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
};

export default ConfirmationModal;