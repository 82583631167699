import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button, Image } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import { PlusCircle, X, Edit3, Calendar } from 'react-feather';

import { selectThemeColors } from '../../../..//utils/Utils'

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker'
import DatePickerWithYearAndMonth from '../../../../components/DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { allStudentServiceDrop, addStudentService, allStudentService, allStudentEarningService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice"
import { setStudentList, selectStudentList, setStudentEarningList, setTotalEntry } from "../../../../redux/academyProgrammeSlice"
import { BiCategoryAlt } from 'react-icons/bi';
import { uploadImage } from '../../../../assets/images';
import JoinSessionNewStudentModal from '../../../../components/Modal/JoinSessionNewStudentModal';

const CreateStudent = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const [newStudentSessionData, setNewStudentSessionData] = useState()
    const sessionList = useSelector(selectStudentList);
    const [tournamentImage, setTournamentImage] = useState(null);
    const [startDate, setStartDate] = useState(new Date())
    const [category, setCategory] = useState(null);
    const [mainCoach, setMainCoach] = useState(null);
    const [subCoach, setSubCoach] = useState([]);
    const [selectedDays, setSelectedDays] = useState('');
    const [just_add, setJustAdd] = useState(false);
    const [studentList, setStudent] = useState([]);
    const [allStudentList, setAllStudentList] = useState([]);
    const [singleStudentDetail, setSingleStudentDetail] = useState();
    const [studentCount, setStudentCount] = useState(0);
    const [selectedStudents, setSelectedStudent] = useState();
    const [selectedGender, setSelectedGender] = useState('MALE');
    const [selectedSession, setSelectedSession] = useState();
    const allGender = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
    const [allSession, setAllSession] = useState([]);

    const countPerPage = 10
    console.log('newStudentSessionData', newStudentSessionData)
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    })

    /**
         * @function fetchAcademyStudentEarningList
         * @params
         * @description fetch the list of organizer banner
     */
    const fetchAcademyStudentEarningList = async (page) => {
        try {
            if (singleData?.id) {
                const result = await allStudentEarningService({ id: singleData?.id, page, countPerPage, });
                if (result?.data?.status) {
                    setLoading(false)
                    dispatch(setStudentEarningList(result?.data?.data.student_lists))
                    dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                    setStudentCount(result?.data?.data?.pagination.total)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    };

    /**
        * @function fetchAcademyStudentList
        * @params
        * @description fetch the list of organizer banner
        */
    const fetchAcademyStudentList = async () => {
        try {
            const result = await allStudentServiceDrop({ id: singleData?.id });
            if (result?.data?.status) {
                setLoading(false)
                setAllStudentList(result?.data?.data.customers)
                const resultStudent = result?.data?.data?.customers?.map((item) => ({ value: item.id, label: item?.username }))

                // const resultStudent = result?.data?.data?.customers?.map((item) => ({ value: item.id, label: <div><Image src={item?.profile_pic || uploadImage} alt='' roundedCircle={true} width='18' /><span className='ms-2'>{item?.username}</span></div> }))
                const allOptions = [{ value: '', label: <span className='text-primary fs-12 custom-hover' onClick={() => setJustAdd()}> <PlusCircle size='16' className='text-primary' /> Add new student</span> }]
                const finalDrop = allOptions.concat(resultStudent)
                setStudent(finalDrop)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        setAllSession(singleData?.frequencies?.map((item) => {
            return {
                label: item?.type,
                value: item?.price,
            }
        }))

        if (singleData?.id && user?.type === 'academy') {
            fetchAcademyStudentList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleData, user])

    const directAddStudent = async () => {
        try {
            if (selectedStudents) {
                const data = new FormData()
                data.append('customer_id', selectedStudents)
                const result = await addStudentService({ id: singleData?.id, data });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    props?.fetchAcademyStudentList(1)
                    fetchAcademyStudentEarningList(1)
                    setJustAdd(false)
                    handleCloseModal()
                    // props?.fetchAcademyStudentList(1)
                    // reset()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                ErrorAlert('Please select student')
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleSelect
         * @params e
         * @description used to handle selection
    */
    const handleSelect = (e) => {
        console.log('handleSelect', e, studentList, allStudentList)
        console.log('allStudentList', e, allStudentList?.find(item => item?.id === e?.value))
        setSingleStudentDetail(allStudentList?.find(item => item?.id === e?.value))
        const object = e
        if (object?.value) {
            setSelectedStudent(object?.value)
            setJustAdd(true)
        } else {
            setJustAdd(true)
        }
    };

    /**
         * @function onSubmit
         * @params formdata
         * @description used to add student in program
         */
    const onSubmit = async (formdata) => {

        const object = {
            name: formdata?.studentName,
            email: formdata?.studentEmail,
            phone: formdata?.studentPhone,
            gender: selectedGender?.value,
            dob: moment(startDate[0]).format('YYYY-MM-DD')
        }
        const students = [object]
        if (selectedStudents?.value) {
            try {

                const data = new FormData()
                data.append('customer_id', selectedStudents)
                const result = await addStudentService({ id: singleData?.id, data });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    props?.fetchAcademyStudentList(1)
                    fetchAcademyStudentEarningList(1)
                    setJustAdd(false)
                    handleCloseModal()
                    // props?.fetchAcademyStudentList(1)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            if (selectedGender?.value) {
                try {
                    const data = new FormData()

                    if (singleStudentDetail) {
                        data.append('customer_id', singleStudentDetail?.id)
                    } else {
                        data.append('username', formdata?.username)
                        data.append('phone', formdata?.phone)
                        data.append('email', formdata?.email)
                    }

                    if (students?.length > 0) {
                        students.forEach((element, index) => {
                            data.append(`students[${index}][name]`, element.name)
                            data.append(`students[${index}][email]`, element.email)
                            data.append(`students[${index}][phone]`, element.phone)
                            data.append(`students[${index}][gender]`, element.gender)
                            data.append(`students[${index}][dob]`, element.dob)
                        })
                    }

                    if (selectedSession?.value) {
                        data?.append('frequency_type', selectedSession?.label)
                        data?.append('amount', selectedSession?.value)
                    }

                    if (newStudentSessionData?.length > 0) {
                        data?.append('timeslot_ids[0]', newStudentSessionData[0]?.id)
                    }

                    data?.append('payment_method', 'ONLINE')

                    const result = await addStudentService({ id: singleData?.id, data });
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        props?.fetchAcademyStudentList(1)
                        fetchAcademyStudentEarningList(1)
                        setJustAdd(false)
                        reset()
                        handleCloseModal()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } catch (error) {
                    ErrorAlert(error)
                }
            } else {
                ErrorAlert('Please select gender')
            }
        }
    }

    const handleCloseModal = () => {
        props.handleClose()
        setJustAdd(false)
        setSelectedStudent('')
        setSelectedGender('')
        setSelectedSession()
        setNewStudentSessionData()
        reset()
    }

    useEffect(() => {
        const defaultValues = {}

        defaultValues.username = singleStudentDetail?.username
        defaultValues.phone = singleStudentDetail?.phone
        defaultValues.email = singleStudentDetail?.email

        reset({
            ...defaultValues
        })
        // eslint-disable-next-line
    }, [singleStudentDetail]);


    return <Modal show={props.show} onHide={handleCloseModal} size='md' centered>
        <Modal.Header className='fs-22 fw-bold border-0' closeButton >
            Add Student
        </Modal.Header>
        <Modal.Body className=''>
            <div className='text-left'>
                {/* <div>
                    <h5>Search <span><Button size='sm' className='text-light' variant="primary" onClick={() => setJustAdd(true)}>Add Student</Button></span></h5>
                </div> */}

                {just_add === true ? "" : <div className=''>
                    <Form.Label className='fs-14 fw-600'>Search / Add Student</Form.Label>
                    <Select
                        formatOptionLabel={function (data) {
                            return (
                                <div>
                                    <Image src={allStudentList?.find(item => parseInt(item?.id) === parseInt(data?.value))?.profile_pic || uploadImage} alt='' roundedCircle={true} width='18' /><span className='ms-2'>{data?.label}</span>
                                </div>
                            );
                        }}
                        closeMenuOnSelect={true}
                        isSearchable
                        theme={selectThemeColors}
                        className='react-select'
                        classNamePrefix='select'
                        defaultValue={selectedStudents}
                        name='clear'
                        options={studentList}
                        placeholder='Students'
                        onChange={(e) => handleSelect(e)}
                    />
                </div>}

            </div>
            {just_add === true ? <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <h5 className='pt-3 fw-600'>User Detail</h5>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>User Name</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={singleStudentDetail?.username}
                        placeholder="Enter username here"
                        {...register("username", {
                            required: "Please provide the username",
                        })}
                    />
                    {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="number"
                        disabled={singleStudentDetail?.phone}
                        placeholder="Enter phone number here"
                        {...register("phone", {
                            required: "Please provide the phone number",
                        })}
                    />
                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        disabled={singleStudentDetail?.email}
                        type="email"
                        placeholder="Enter email here"
                        {...register("email", {
                            required: "Please provide the email address",
                        })}
                    />
                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                </Form.Group>

                <h5 className='pt-3 fw-600'>Student Detail</h5>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Student Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name here"
                        {...register("studentName", {
                            required: "Please provide the name ",
                        })}
                    />
                    {errors.studentName && <ErrorText>{errors.studentName.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter phone here"
                        {...register("studentPhone", {
                            required: "Please provide the phone number",
                        })}
                    />
                    {errors.studentPhone && <ErrorText>{errors.studentPhone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email here"
                        {...register("studentEmail", {
                            required: "Please provide the email address",
                        })}
                    />
                    {errors.studentEmail && <ErrorText>{errors.studentEmail.message}</ErrorText>}
                </Form.Group>

                <Row>
                    <Form.Group className="col-6">
                        <Form.Label className='fs-14 fw-600'>Gender</Form.Label>
                        <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedGender}
                            name='clear'
                            options={allGender}
                            placeholder='Gender'
                            onChange={setSelectedGender}
                        />
                    </Form.Group>
                    <Form.Group className="col-6 update-student">
                        <Form.Label className='fs-14 fw-600'>Date of Birth</Form.Label>
                        <InputGroup>
                            <Flatpickr
                                className='form-control'
                                value={startDate}
                                required
                                id='timepicker'
                                options={{
                                    static: true,
                                    altInput: true,
                                    allowInput: true,
                                    enableTime: false,
                                    noCalendar: false,
                                    dateFormat: "Y-m-d",
                                    maxDate: "today",
                                    defaultDate: new Date(),
                                }}
                                onChange={date => setStartDate(date)}
                            />
                            <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Row>

                <Row className='mt-3'>
                    <Form.Group className="">
                        <Form.Label className='fs-14 fw-600'>Frequency</Form.Label>
                        <Select
                            // closeMenuOnSelect={false}
                            theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedSession}
                            name='frequency'
                            options={allSession}
                            placeholder='Select frequency'
                            onChange={setSelectedSession}
                        />
                    </Form.Group>
                </Row>

                <div className="join-session-button mt-3">
                    <div className="joined">
                        <JoinSessionNewStudentModal setNewStudentSessionData={setNewStudentSessionData} newStudentSessionData={newStudentSessionData} rowData={singleData?.id} />
                    </div>
                    {newStudentSessionData?.length > 0 && <div className='mt-2'>
                        <Button variant='' className={classNames('border pe-none')}> {`${newStudentSessionData[0]?.start_time}`} {`- ${newStudentSessionData[0]?.end_time}`} </Button>
                    </div>}
                </div>
                <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Add</PrimaryButton>
                    </div>
                </div>
            </Form> : <div className='d-flex justify-content-end align-items-end mt-4'>
                <div>
                    <PrimaryButton className='fs-14 fw-600 px-5' onClick={() => directAddStudent()}>Add</PrimaryButton>
                </div>
            </div>}
        </Modal.Body>
    </Modal>
};

export default CreateStudent;