import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import DataTable from 'react-data-table-component'
import ImageUploading from 'react-images-uploading';

// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker'
import DatePickerWithYearAndMonth from '../../../../components/DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import { PlusCircle, X, Edit3, Calendar } from 'react-feather';
// Images
import { badminton } from '../../../../assets/images';
import { Image, UploadIcon, Users, Men, Women } from '../../../../assets/images/svg';

// API Service
import { allStudentProgramProgressService, singleStudentProgramProgressService, addProgressService, updateProgressService } from '../../../../service/coachProgram';
import { selectUser } from "../../../../redux/userSlice"

import { BiCategoryAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

const UpdateProgress = ({ tableRecord, ...props }) => {
    const [isLoading, setLoading] = useState(false)
    const user = useSelector(selectUser);
    const [edit, setEdit] = useState(true);
    const [showImages, setShowImages] = useState([]);
    const [formValue, setFormValue] = useState('');
    const [progressImages, setFile] = useState([]);
    const [startDate, setStartDate] = useState([moment()])
    const [images, setImages] = useState([]);
    const maxNumber = 5;

    const progress_date = useRef(null);
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: { description: '', progress_date: '' },
        mode: "onBlur",
    })

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    useEffect(() => {
        console.log('formValue', formValue, new Date("2024-01-30"))
        if (formValue?.id) {
            const defaultValues = {};
            const date = moment(formValue?.date).format('Y-m-d')
            // defaultValues.progress_date = date
            defaultValues.progress_date = new Date(formValue?.date)
            defaultValues.description = formValue?.description
            if (formValue?.progress_images?.length > 0) {
                formValue?.progress_images.map((element, index) => (
                    setShowImages(formValue?.progress_images)
                ))
                setImages(formValue?.progress_images?.map(item => {
                    return {
                        data_url: item?.path
                    }
                }))
            }
            reset({ ...defaultValues })
        }
        // eslint-disable-next-line
    }, [formValue])

    /**
     * @function fetchSingleProgramProgress
     * @params
     * @description fetch the list of customer banner
     */
    const fetchSingleProgramProgress = async () => {
        try {
            const result = await singleStudentProgramProgressService({ id: tableRecord.id ? tableRecord.id : '0' });
            if (result?.data?.status) {
                setLoading(false)
                setFormValue(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleClose = () => {
        reset()
        setFile()
        setEdit(false)
    };

    useEffect(() => {
        console.log('fetchSingleProgramProgress', tableRecord)
        if (tableRecord?.id) {
            fetchSingleProgramProgress()
        }
        // eslint-disable-next-line
    }, [edit, tableRecord])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to update progress
    */
    const onSubmit = async (formdata) => {
        if (formValue?.program_id) {
            if (formdata?.description) {
                const date = moment(formdata?.progress_date).format('YYYY-MM-DD')
                const time = moment(formdata?.progress_date).format('HH:mm')
                try {
                    const data = new FormData()
                    data.append('session_id', formValue?.session_id)
                    data.append('program_id', formValue?.program_id)
                    data.append('student_id', formValue?.student_id)
                    data.append('description', formdata?.description)
                    data.append('date', date)
                    data.append('time', time)
                    data.append('_method', 'PUT')
                    // if (progressImages?.length > 0) {
                    //     progressImages?.map((element, index) => (
                    //         data.append(`progress_images[${index}]`, element)
                    //     ))
                    // }

                    if (images?.length > 0) {
                        images?.filter(item => item?.file instanceof File).map((element, index) => (
                            data.append(`progress_images[${index}]`, element?.file)
                        ))
                    }
                    const result = await updateProgressService({ id: tableRecord.id, data });
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        reset()
                        setEdit(!edit)
                        props?.setNewData(!props?.newData)
                        handleClose()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                } catch (error) {
                    ErrorAlert(error)
                }
            } else {
                ErrorAlert('Something going to wrong')
            }
        } else {
            // ErrorAlert('Please select program images')
        }

    }

    const handleUploadImages = (e) => {
        const file = e?.target?.files;
        setFile(file)
    }

    return <Fragment>
        <>
            <div className="modal-update">
                <div className="progress-form">
                    {edit ? <><Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                        <div className="progressList-list mb-4">
                            <div className="frequency-content">
                                <Fragment>
                                    <div className="inputs-fields mb-4">
                                        <Row>
                                            <Col md={12} className='mb-4'>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="add-title text-dark fs-14 fw-600"> <span>Update Progress</span></div>
                                                </div>
                                            </Col>
                                            
                                            {/* {progressImages?.length > 0 ? progressImages?.map((item, i) => <Fragment>
                                                <Col md={2} xs={12} className='mb-2'>
                                                    <div className="preview">
                                                        <img role='button' className='img-fluid' src={URL.createObjectURL(item)} alt={'progressImage'} />
                                                    </div>
                                                </Col>
                                            </Fragment>) : <>
                                            </>} */}

                                            {/* {showImages?.length > 0 ? showImages?.map((item, i) => <Fragment>
                                                <Col md={2} xs={12} className='mb-2'>
                                                    <div className="preview">
                                                        <a href={item?.path} alt={item?.name} target='_blank' rel="noreferrer"><img role='button' className='img-fluid' src={item?.path} alt={'progressImage'} /></a>
                                                    </div>
                                                </Col>
                                            </Fragment>) : <>
                                            </>} */}

                                            <ImageUploading
                                                multiple
                                                acceptType={['jpg', 'jpeg', 'png']}
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper d-flex flex-wrap">
                                                    
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item position-relative">
                                                                <img className='me-3 mt-2' role='button' src={image['data_url']} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                                                {/* <AiOutlineClose role='button' className='position-absolute top-0 end-0 text-danger bg-light rounded-circle me-3 mt-2' size={15} onClick={() => onImageRemove(index)} /> */}
                                                            </div>
                                                        ))}
                                                        {imageList?.length < 5 && <button type='button' className='border-0 p-0'
                                                            style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            <label className='' htmlFor="progressPhotoUpload">
                                                                <div className='d-flex flex-column justify-content-center align-items-center custom-hover p-3 upload-icon' style={{ background: '#FFF8F5' }}>
                                                                    <UploadIcon className='mb-2' />
                                                                    <span className='fs-14 fw-600 text-primary'>Add Image</span>
                                                                </div>
                                                            </label>
                                                        </button>}
                                                        {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                                    </div>
                                                    
                                                )}
                                            </ImageUploading>

                                            {/* <Col md={3}>
                                                <input className='d-none' name='images' type='file' id="progressPhotoUpload" accept="image/*" multiple onChange={(e) => handleUploadImages(e)} />
                                                <label className='' htmlFor="progressPhotoUpload">
                                                    <div role='button' className='d-flex flex-column justify-content-center align-items-center p-3 upload-icon' style={{ background: '#FFF8F5' }}>
                                                        <UploadIcon className='mb-2' />
                                                        <span className='fs-14 fw-600 text-primary'>Add Image</span>
                                                    </div>
                                                </label>
                                            </Col> */}

                                            <Col md={12} className='pt-2'>
                                                <Form.Group>
                                                    <Form.Label className='text-fs-14 fw-500'>Date</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`progress_date`}
                                                        render={({ field }) => (
                                                            <DatePickerWithYearAndMonth
                                                                selected={field?.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                customInput={<CustomDatePicker inputRef={progress_date} />}
                                                            />
                                                        )}
                                                        rules={{ required: 'Please provide the date' }}
                                                    />
                                                    {errors.progress_date && <ErrorText>{errors.progress_date.message}</ErrorText>}
                                                </Form.Group>
                                            </Col>

                                            <Col md={12}>
                                                <div className="custom-price">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className='fs-14 fw-500'>Description</Form.Label>
                                                        {/* <Form.Control
                                                            type="textarea"
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Enter description here"
                                                            // required="description is required"
                                                            name='description'
                                                            {...register("description", {
                                                                required: "Please provide description",
                                                            })}
                                                        /> */}
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            placeholder="Enter description here"
                                                            {...register("description", {
                                                                required: "Please provide the description",
                                                            })}
                                                        />
                                                        {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </Fragment>
                            </div>

                        </div>


                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Save</PrimaryButton>
                            </div>
                        </div>
                    </Form></> : ''}
                </div>
            </div>

        </>
    </Fragment>


};

export default UpdateProgress;