import axios from "axios"
 
/**
    * @function getProfileDetails
    * @param data
    * @description This function is used to change password for player
*/
export const getProfileDetails = async () => {
    try {
        const result = await axios.get('/academy/auth/profile')
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function changePasswordService
    * @param data
    * @description This function is used to change password for player
*/
export const changePasswordService = async (data) => {
    try {
        const result = await axios.post('/academy/auth/change-password', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProfileImageService
    * @param data
    * @description This function is used to update profile image for player
*/
export const updateProfileImageService = async (data) => {
    try {
        const result = await axios.post('/academy/auth/profile-picture', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProfileService
    * @param data
    * @description This function is used to update profile data for player
*/
export const updateProfileService = async (data) => {
    try {
        const result = await axios.post('/academy/auth/update-profile', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateAcademyProfileImageService
    * @param data
    * @description This function is used to update profile image for academy
*/
export const updateAcademyProfileImageService = async (data) => {
    try {
        const result = await axios.post('/academy/auth/profile-picture', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateAcademyProfileService
    * @param data
    * @description This function is used to update profile data for academy
*/
export const updateAcademyProfileService = async (data) => {
    try {
        const result = await axios.post('/academy/auth/update-profile', data)
        return result;
    } catch (error) {
        return error;
    }
}