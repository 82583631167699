import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Custom Component
import ErrorAlert from '../components/Alert/ErrorAlert';

// Utility Service

// API Service
// import { getCustomerProfileService } from '../../service/profile.service';

// Redux-Functions
import { setToken, setUser } from '../redux/userSlice';
import { getCustomerProfileDetails } from '../service/auth.service';
import { setUserSession, updateUserSession } from '../utils/AuthService';

const SSO = () => {
    const dispatch = useDispatch();
    
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    /**
        * @function fetchUserInfo
        * @params
        * @description fetch the info of user
    */
    const fetchUserInfo = async () => {
        try {
            const result = await getCustomerProfileDetails();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))

                // setUserSession(searchParams?.get("token"), {
                //     ...result.data?.data,
                //     type: 'customer'
                // })
                updateUserSession({
                    ...result.data?.data,
                    type: 'customer'
                })
                navigate('/student/home');
                // navigate('/')
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    };

    useEffect(() => {
        if (searchParams?.get("result") === "success" && searchParams?.get("token")) {
            dispatch(setToken(searchParams?.get("token")))
            localStorage.setItem('token', searchParams?.get("token"));
            fetchUserInfo()
        } else {
            ErrorAlert('Something went wrong')
        }
        // eslint-disable-next-line
    }, [searchParams])
    
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>SSO | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
    </HelmetProvider>
};

export default SSO;