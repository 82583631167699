import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { Label } from 'reactstrap';
import { Calendar, Edit3 } from 'react-feather';
import { useForm } from "react-hook-form";
import Flatpickr from 'react-flatpickr'

// Custom Component
import { PrimaryButton } from '../../../../components/Button';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { changeSessionService, singleProgramSessionService, singleStudentSessionService } from '../../../../service/coachProgram';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";
import { selectFreshData, setFreshData } from "../../../../redux/coachProgrammeSlice";
import classNames from 'classnames';
import moment from 'moment';

const ChangeSessionModal = ({ tableRecord, rowData, ...props }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const user = useSelector(selectUser);
    const freshData = useSelector(selectFreshData);
    const [startDate, setStartDate] = useState()
    const [frequency, setFrequency] = useState([]);
    const [formValue, setFormValue] = useState({});
    const [sessionformValue, setSessionFormValue] = useState({});
    
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

        },
        mode: "onBlur"
    })

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    /**
        * @function fetchSingleProgramSession
        * @params
        * @description fetch the list of customer banner
    */
    const fetchSingleProgramSession = async () => {
        console.log('fetchSingleProgramSession', tableRecord, rowData)
        if (tableRecord) {
            try {
                const result = await singleStudentSessionService({ id: tableRecord.id});
                console.log('resultsss', result?.data?.data)
                if (result?.data?.status) {
                    setLoading(false)
                    setFormValue(result?.data?.data)
                    // setFormValue(result?.data?.data?.map(item => {
                    //     return {
                    //         ...item,
                    //         timeslots: item?.timeslots?.map(itemTimeslot => {
                    //             return {
                    //                 ...itemTimeslot,
                    //                 isSelected: false
                    //             }
                    //         })
                    //     }
                    // }))
                    // setFormValue({
                    //     ...result?.data?.data,
                    //     timeslots: result?.data?.data?.timeslots?.map(item => {
                    //         return {
                    //             ...item,
                    //             isSelected: false
                    //         }
                    //     })
                    // })
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('Time slots are not available')
            setLoading(false)
        }
        
    }

        /**
        * @function fetchSingleProgramSessionAndSlot
        * @params
        * @description fetch the list of session's timeslots
    */
    const fetchSingleProgramSessionAndSlot = async () => {
        console.log('fetchSingleProgramSession', tableRecord, rowData)
        if (tableRecord) {
            try {
                const result = await singleProgramSessionService({ id: rowData.id, session_id: tableRecord?.timeslots[0]?.session_id });
                console.log('setSessionFormValue', result?.data?.data)
                if (result?.data?.status) {
                    setLoading(false)
                    // setFormValue(result?.data?.data)
                    setSessionFormValue(
                         {
                            ...result?.data?.data,
                            timeslots: result?.data?.data?.timeslots?.map(itemTimeslot => {
                                return {
                                    ...itemTimeslot,
                                    isSelected: false
                                }
                            })
                        }
                    )
                    // setFormValue({
                    //     ...result?.data?.data,
                    //     timeslots: result?.data?.data?.timeslots?.map(item => {
                    //         return {
                    //             ...item,
                    //             isSelected: false
                    //         }
                    //     })
                    // })
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('Time slots are not available')
            setLoading(false)
        }
        
    }

    useEffect(() => {
        console.log('props?.show', props?.show, user?.type)
        if (props?.show && user?.type === 'coach') {
            setLoading(true)
            fetchSingleProgramSession()
            fetchSingleProgramSessionAndSlot()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.show, user])

    useEffect(() => {
        if (sessionformValue?.date) {
            setStartDate(sessionformValue?.date)
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionformValue])

    /**
         * @function onSubmit
         * @params formdata
         * @description used to change session
         */
    const onSubmit = async (formdata) => {
        console.log('formdata', formdata, tableRecord, rowData, sessionformValue)
        if (sessionformValue?.timeslots?.some(item => item?.isSelected)) {
            try {
                const data = new FormData()
                data.append('_method', 'PUT')
                data.append('session_id', sessionformValue?.id)
                data.append('timeslot_id', sessionformValue?.timeslots?.find(item => item?.isSelected)?.id)
                data.append('student_id', tableRecord?.id)

                // data.append('sessions[0][timeslots][0][max_pax]', formValue?.timeslots?.find(item => item?.isSelected)?.max_pax)
                // data.append('sessions[0][timeslots][0][start_time]', moment(formValue?.timeslots?.find(item => item?.isSelected)?.start_time, 'hh:mm A').format('HH:mm'))
                // data.append('sessions[0][timeslots][0][end_time]', moment(formValue?.timeslots?.find(item => item?.isSelected)?.end_time, 'hh:mm A').format('HH:mm'))
            
                for (const value of data.values()) {
                    console.log('data', value);
                };

                const result = await changeSessionService({ id: rowData?.id, data });
                
                if (result?.data?.status) {
                    dispatch(setFreshData(!freshData))
                    props?.handleUpdateProgressClose()
                    SuccessAlert(result?.data?.message)
                    reset()
                    props?.handleClose()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('Select Session first')
        } 
    }

    const handleSelect = (item, index) => {
        console.log('item', item)
        setSessionFormValue({
            ...sessionformValue,
            timeslots: sessionformValue?.timeslots?.map(itemTimeSlot => {
                return {
                    ...itemTimeSlot,
                    isSelected: itemTimeSlot?.id === item?.id ? !item?.isSelected : false
                }
            })
        })
    };

    return <>
        <Fragment>
            <div><Edit3 className='custom-hover' size={17} onClick={props?.handleClose} /></div>
            <Modal show={props?.show} onHide={props?.handleClose} className='modal-dialog-centered modal-lg'>
                <Modal.Header className='fs-22 fw-bold border-0 px-5  mt-3' closeButton >
                    Change Session
                </Modal.Header>
                <Modal.Body className='px-5 d-flex flex-column align-items-center'>
                    <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xl={12} className='pt-3 mb-4'>
                                <p>Joined Session</p>
                                <div className=''>
                                    {formValue?.timeslots && formValue?.timeslots.length > 0 ? <Button className='ms- text-light' variant='primary' >{`${formValue?.timeslots[0]?.start_time} - ${formValue?.timeslots[0]?.end_time}`} </Button> : ''}
                                </div>
                            </Col>
                        </Row>

                        <div className="frequency-list mb-2">
                            <Row>
                                <Col xl={12} className='pt-3 mb-4'>
                                    <Label>Date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className='form-control'
                                            value={startDate}
                                            id='timepicker'
                                            options={{
                                                static: true,
                                                enableTime: false,
                                                altInput: true,
                                                allowInput: false,
                                                clickOpens: false,
                                                noCalendar: false,
                                                altFormat: "d/m/Y, l",
                                                // dateFormat: "d/m/Y",
                                            }}
                                            onChange={date => setStartDate(date)}
                                        />

                                        <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <div className='d-flex flex-wrap align-items-start'>
                                    {
                                        formValue?.timeslots && sessionformValue?.timeslots?.length > 0 && sessionformValue?.timeslots.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        (item?.start_time === formValue?.timeslots[0]?.start_time) && <div key={index} className='mb-3 me-3'>
                                                            <Button className=' text-light' variant='primary' disabled onClick={() => handleSelect(item, index)}>{`${item?.start_time} - ${item?.end_time}`}
                                                            </Button>
                                                        </div>
                                                    }
                                                    {
                                                        (item?.start_time !== formValue?.timeslots[0]?.start_time) && !item?.is_open && <div key={index} className='mb-3 me-3'>
                                                            <Button className=' text-dark' variant='light' disabled onClick={() => handleSelect(item, index)}>{`${item?.start_time} - ${item?.end_time}`}
                                                            </Button>
                                                        </div>
                                                    }

                                                    {
                                                        (item?.start_time !== formValue?.timeslots[0]?.start_time) && item?.is_open && <div key={index} className='mb-3 me-3'>
                                                            <Button className={classNames('border coach-select-session', {'text-dark' : !item?.isSelected, 'text-light' : item?.isSelected})} variant={item?.isSelected ? 'primary' : 'light'} onClick={() => handleSelect(item, index)}>{`${item?.start_time} - ${item?.end_time}`}
                                                            </Button>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                        )}
                                </div>
                                
                            </Row>
                        </div>

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' type="submit">Change</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    </>

};

export default ChangeSessionModal;