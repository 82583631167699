import axios from "axios"

/**
    * @function coachListService
    * @param
    * @description This function is used to get all coach
*/
export const coachListService = async({page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/coach/list?paginate=${true}&page=${page}&perPage=${countPerPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function filterCoachService
    * @param
    * @description This function is used to filter coach
*/
export const filterCoachService = async({start_date, end_date}) =>{
    try {
        const result = await axios.get(`/academy/coach/list?paginate=${false}&start_date=${start_date}&end_date=${end_date}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function downloadCSVCoachService
    * @param
    * @description This function is used to get coach list
*/
export const downloadCSVCoachService = async() =>{
    try {
        const result = await axios.get(`/academy/coach/list?paginate=${false}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function coachSearchListService
    * @param
    * @description This function is used to get search coach
*/
export const coachSearchListService = async({searchValue, page, countPerPage}) =>{
    try {
        const result = await axios.get(`/academy/coach/list?paginate=${true}&page=${page}&perPage=${countPerPage}&keyword=${searchValue}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function coachSingleService
    * @param
    * @description This function is used to get single coach
*/
export const coachSingleService = async({id}) =>{
    try {
        const result = await axios.get(`/academy/coach/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addCoachService
    * @param data
    * @description This function is used to update profile data for player
*/
export const addCoachService = async (data) => {
    try {
        const result = await axios.post(`/academy/coach/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateCoachService
    * @param data
    * @description This function is used to update profile data for player
*/
export const updateCoachService = async ({id}, data) => {
    try {
        const result = await axios.post(`/academy/coach/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteCoachService
    * @param data
    * @description This function is used to delete
*/
export const deleteCoachService = async ({id}) => {
    try {
        const result = await axios.delete(`/academy/coach/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function changePasswordService
    * @param data
    * @description This function is used to change password
*/
export const changePasswordService = async (data) => {
    try {
        const result = await axios.post('/customer/auth/change-password', data)
        return result;
    } catch (error) {
        return error;
    }
}
