import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom'
import classNames from "classnames";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import Select, { components, MultiValueRemoveProps } from 'react-select'
import Flatpickr from 'react-flatpickr'
// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button';

import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { addStudentService } from '../../service/studentProgram';
import { selectPaymentType, setPaymentType } from "../../redux/slices/studentProgramme"
import { PlusCircle, X, Search, Calendar } from 'react-feather';
import { selectThemeColors } from '../..//utils/Utils'
const CreateSessionModal = ({ id, addedStudent, ...props }) => {
    const dispatch = useDispatch()
    const paymentType = useSelector(selectPaymentType)
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [selectedGender, setSelectedGender] = useState('MALE');
    const [studentList, setStudent] = useState([{ name: '', email: '', phone: '', dob: moment().format('YYYY-MM-DD'), gender: '' }]);
    const allGender = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
    const todayDate = moment().date()
    const navigate = useNavigate()
    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            studentList: [],
            startDate: moment().format('YYYY-MM-DD')
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    /**
         * @function onSubmit
         * @params formdata
         * @description used to join session
         */
    const onSubmit = async (formdata) => {
        console.log('studentList', studentList)
        try {
            if (id) {
                const data = new FormData()
                if (studentList?.length > 0) {
                    studentList.forEach((element, index) => {
                        data.append(`students[${index}][name]`, element?.name)
                        data.append(`students[${index}][phone]`, element?.phone)
                        data.append(`students[${index}][email]`, element?.email)
                        data.append(`students[${index}][dob]`, element?.dob)
                        data.append(`students[${index}][gender]`, element?.gender)
                    })
                }
                const result = await addStudentService({ data, id });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    setStudent([])
                    reset()
                    props.handleClose()
                    dispatch(setPaymentType(paymentType))
                    navigate(`/student/checkout/${id}`)
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleAddSession = () => {
        setStudent([...studentList, { date: '', location: '', latitude: '', longitude: '' }])
    }
    const handleRemoveSession = (index1) => {
        const temp = [...studentList]
        const deleteVal = [...temp]

        setStudent(deleteVal => {
            return deleteVal.filter((_, i) => i !== index1)
        })
    }

    const handleStartDateChange = (date, index1) => {
        const startDate = date.length > 0 ? moment(date[0]).format('YYYY-MM-DD') : ''
        const temp = [...studentList]
        const name = 'dob'
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = startDate
            setStudent(temp)
        } else {

        }
    }
    const handleName = (e, index1) => {
        const { name, value } = e?.target
        const temp = [...studentList]
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = value
            setStudent(temp)
        }
    }

    const handlePhone = (e, index1) => {
        const { name, value } = e?.target
        const temp = [...studentList]
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = value
            setStudent(temp)
        }
    }

    const handleEmail = (e, index1) => {
        const { name, value } = e?.target
        const temp = [...studentList]
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = value
            setStudent(temp)
        }
    }
    const handleSelectGender = (e, index1) => {
        const { value } = e
        const name = 'gender'
        const temp = [...studentList]
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = value
            setStudent(temp)
        }
    }

    // useEffect(() => {
    //     if (addedStudent?.length > 0) {
    //         setStudent(addedStudent)
    //     }
    // }, [])

    const modalClose = () => {
        props.handleClose()
        setStudent([{ name: '', email: '', phone: '', date: '', gender: '' }])
    }

    return <Modal role="dialog" data-bs-focus="false" className='p-2' show={props.show} onHide={() => modalClose()} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0' closeButton >
            Join Program
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xl={12} className='pt-3 mb-4'>
                        <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-row'>
                            <div><h1 className='fs-20 '>Students</h1></div>
                            <div><Button size="sm" variant="primary" className='fw-600 fs-14 export-button' onClick={handleAddSession}><PlusCircle size={15} /> <span>Add Student</span></Button></div>
                        </div>
                    </Col>
                </Row>

                <div className="add-student">
                    {studentList?.map((item, index1) => {
                        return (
                            <Fragment>
                                <div className="frequency-list mb-2">
                                    <div className="mb-3" key={`${index1}`}>
                                        <div className="first">
                                            <div className='d-flex justify-content-between align-items-center align-items-start flex-row'>
                                                <div><h1 className='fs-16 fw-600'>Student - <span className='fw-bold'>{`${index1 + 1}`}</span></h1></div>
                                                <div className="cancel float-end text-primary cursor-pointer"><X size={22} onClick={(e) => handleRemoveSession(index1, item)} /> </div>
                                            </div>
                                            <div className="details">
                                                <Form.Group className="my-4">
                                                    <Form.Label className='fs-14 fw-600'>Student Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete='nope'
                                                        placeholder="Enter name here"
                                                        required="Name is required"
                                                        name='name'
                                                        onChange={(e) => handleName(e, index1)}
                                                    />
                                                    {/* {errors.name && <ErrorText>{errors.name.message}</ErrorText>} */}
                                                </Form.Group>
                                                <Form.Group className="my-4">
                                                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoComplete='nope'
                                                        placeholder="Enter number here"
                                                        required="Number is required"
                                                        name='phone'
                                                        // maxlength="12"
                                                        onChange={(e) => handlePhone(e, index1)}
                                                    />
                                                    {/* {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>} */}
                                                </Form.Group>
                                                <Form.Group className="my-4">
                                                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email address here"
                                                        required="Email is required"
                                                        name='email'
                                                        onChange={(e) => handleEmail(e, index1)}

                                                    />
                                                    {/* {errors.email && <ErrorText>{errors.email.message}</ErrorText>} */}
                                                </Form.Group>

                                                <Row>
                                                    <Form.Group className="col-6">
                                                        <Form.Label className='fs-14 fw-600'>Gender</Form.Label>
                                                        <Select
                                                            // closeMenuOnSelect={false}
                                                            theme={selectThemeColors}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            defaultValue={selectedGender}
                                                            name='gender'
                                                            options={allGender}
                                                            placeholder='Gender'
                                                            onChange={(e) => handleSelectGender(e, index1)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-6 join-student">
                                                        <Form.Label className='fs-14 fw-600'>Date of birth</Form.Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className=''
                                                                required
                                                                value={startDate}
                                                                id='timepicker'
                                                                name='date'
                                                                options={{
                                                                    altInput: true,
                                                                    allowInput: true,
                                                                    static: true,
                                                                    enableTime: false,
                                                                    noCalendar: false,
                                                                    altFormat: "M j, Y",
                                                                    dateFormat: "Y-m-d",
                                                                    maxDate: "today",
                                                                    // disable: ['2024', '2025']
                                                                }}
                                                                onChange={(date) => handleStartDateChange(date, index1)}
                                                            />
                                                            <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>

                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )

                    })}

                </div>

                <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Submit</PrimaryButton>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
};

export default CreateSessionModal;