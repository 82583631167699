import React from "react";
// import "./one.css";

function VTcontent(props) {
  const data = props.data.expData;

  return (
    <div
      key={props.index}
      className="section__Jobs-styledContent"
      style={ props.activeTabId === props.index ? { display: "block" } : { display: "none" }
      }
    > <h5>{data.content}</h5>
    </div>
  );
}

export default VTcontent;
