import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ShowMoreText from "react-show-more-text";
import { Row, Col, Tab, Tabs, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import Overview from './Overview';
import Students from './Students';
import Earnings from './Earnings';
import UpdateProgramModal from './UpdateProgram';

// Images
import { post1Image, sessionBook, sessionUser } from "../../../../assets/images/index";

// API Service
import { singleProgramService, programmeStatusChange, allStudentService, allStudentSessionDropService } from '../../../../service/academyProgramme';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";
import { setFreshData, selectFreshData, setStudentList, setSessionDropList, setTotalEntry } from "../../../../redux/academyProgrammeSlice"

const ProgrammeDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [programmeDetail, setProgramList] = useState([])
    const freshData = useSelector(selectFreshData);
    const user = useSelector(selectUser);

    /**
             * @function fetchAcademySessionList
             * @params
             * @description fetch the list of session
             */
    const fetchAcademySessionList = async () => {
        try {
            if (id) {
                const result = await allStudentSessionDropService({ id });
                if (result?.data?.status) {
                    const resultSession = result?.data?.data?.map((item) => ({ value: item.id, label: item.date }))
                    const allOptions = [{ value: '', label: 'All' }]
                    const finalDrop = allOptions.concat(resultSession)
                    dispatch(setSessionDropList(finalDrop))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchAcademyStudentList
         * @params
         * @description fetch the list of organizer banner
         */
    const fetchAcademyStudentList = async (page) => {
        try {
            if (id) {
                const result = await allStudentService({ id: id, page, countPerPage: 1, });
                if (result?.data?.status) {
                    dispatch(setStudentList(result?.data?.data.student_list))
                    dispatch(setTotalEntry(result?.data?.data?.pagination.total_pages))
                    dispatch(setFreshData(false))
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }

        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchSingleProgram
         * @params
         * @description fetch the list of customer banner
         */
    const fetchSingleProgram = async () => {
        try {
            console.log('fetchSingleProgram 4')

            const result = await singleProgramService({ id });
            if (result?.data?.status) {
                fetchAcademyStudentList()
                fetchAcademySessionList()
                setProgramList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const statusUpdate = async () => {
        try {
            const result = await programmeStatusChange({ id });
            if (result?.data?.status) {
                fetchSingleProgram()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (freshData) {
            fetchSingleProgram()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freshData])

    useEffect(() => {
        if (user?.type === 'academy') {
            fetchSingleProgram()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const programmeDetailP = {
        id: 1,
        image: post1Image,
        contents: 'Lorem ipsum dolor sit amet consectetur. Suspendisse.',
        session: 4,
        students: 21,
        price: 32,
        iconBook: sessionBook,
        iconUser: sessionUser
    }

    return <HelmetProvider>
        <Helmet>
            <title>AFA Coaching</title>
        </Helmet>
        <div>
            <div className='DetailSection'>
                <div className='DetailBanner' style={{ background: '#FFEEE6' }}>
                    <Row>
                        <Col xl={7} className=''>
                            <div>
                                <div className='programme-name mb-4'>
                                    <h1 className=''>{programmeDetail?.name}</h1>
                                </div>
                                <div className='mb-5'>
                                    {/* <p>{programmeDetail?.description ? programmeDetail?.description.substr(0, 200) : ''}</p> */}
                                    <ShowMoreText
                                        className='showMoreText'
                                        lines={3}
                                        more="Show more"
                                        less="Show less"
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        {programmeDetail?.description}
                                    </ShowMoreText>
                                </div>
                                <div className='programme-button'>
                                    <div className='d-flex justify-content-start align-items-center flex-lg-row'>
                                        <div>
                                            <UpdateProgramModal localData={programmeDetail} />
                                        </div>
                                        {programmeDetail?.status ? <div role='button' className='bg-danger bg-opacity-20 rounded py-1 ms-2 publish-unpublish-btn' onClick={statusUpdate}>
                                            <span className='text-danger fw-bold px-3 me-2'>Program Unpublished</span>
                                        </div> : <div role='button' className='bg-success bg-opacity-20 rounded py-1 ms-2 publish-unpublish-btn' onClick={statusUpdate}>
                                            <span className='text-success fw-bold px-3 me-2'>Program Published</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={5} className=''>
                            <div className='text-center programme-image pt-4'>
                                <Image fluid src={programmeDetail?.banners && programmeDetail?.banners.length > 0 ? programmeDetail?.banners[0].banner : ''} alt='Banner' width='' />
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
            <div className='TabSection mt-2'>
                <Row>
                    <Col xl={12} lg={12} xs={12} className='single-programe'>
                        <div className='tab-list custom-tab student-tabs'>
                            <Tabs
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example"
                                className="mb-3 flex-xs-column"
                            >
                                <Tab eventKey="overview" title="Overview">
                                    <Overview singleData={programmeDetail} />
                                </Tab>
                                <Tab eventKey="student" title="Students">
                                    <Students singleData={programmeDetail} />
                                </Tab>
                                <Tab eventKey="earning" title="Earnings">
                                    <Earnings singleData={programmeDetail} />
                                </Tab>
                            </Tabs>

                        </div>

                    </Col>
                </Row>

            </div>
            <div className='clearfix'></div>
        </div>
    </HelmetProvider>

};

export default ProgrammeDetail;