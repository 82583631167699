import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

// Custom Component
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { getCustomerTermsAndConditionsService, getOrganizerPrivacyPolicyService } from '../../../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../../../redux/userSlice";

const TermsAndCondition = () => {
    const user = useSelector(selectUser)
   
    const [termsAndConditions, setTermsAndConditions] = useState()

    /**
        * @function fetchCustomerTermsAndConditions
        * @params
        * @description fetch the content of terms and conditions for customer
    */
    const fetchCustomerTermsAndConditions = async () => {
        try {
            const result = await getCustomerTermsAndConditionsService();
            if (result?.data?.status) {
                setTermsAndConditions(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerTermsAndConditions
        * @params
        * @description fetch the content of terms and conditions for organizer
    */
    const fetchOrganizerTermsAndConditions = async () => {
        try {
            const result = await getOrganizerPrivacyPolicyService();
            if (result?.data?.status) {
                setTermsAndConditions(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerTermsAndConditions()
        } else {
            fetchCustomerTermsAndConditions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
    return <HelmetProvider>
        <Helmet>
            <title>Terms And Conditions | AFA Coaching</title>
        </Helmet>
        {termsAndConditions && parse(termsAndConditions)}
    </HelmetProvider>
};

export default TermsAndCondition;