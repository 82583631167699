import { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';
import { ArrowDown, ArrowRight, Search, } from 'react-feather'
// Images
import { post1Image, post2Image, post3Image, post4Image, sessionBook, sessionUser } from "../../assets/images/index";

// API Service
import { allFaqService } from '../../service/guestHomeService';

// Redux-Functions
import { selectFaqList, setFaqList } from "../../redux/slices/guestHomeSlice"

import ReactStars from 'react-rating-star-with-type'
import { Link } from 'react-router-dom';

const TopCoach = () => {
    const dispatch = useDispatch()
    const faqList = useSelector(selectFaqList);

    /**
         * @function fetchCustomerFaqList
         * @params
         * @description fetch the list of customer banner
         */
    const fetchCustomerFaqList = async () => {
        try {
            const result = await allFaqService();
            if (result?.data?.status) {
                dispatch(setFaqList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerFaqList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        <div className='faqSection mt-5'>
            <Container>
                <Row className='faq-list'>

                    <Col lg={{ span: 8, offset: 2 }} className='pt-2 mb-5'>
                        <div>
                            <div className='faq-title text-center'>
                                <h1 className='fw-bold'>Frequently asked questions</h1>
                                <p>Everything you need to know about the AFA</p>
                            </div>
                        </div>
                        <div className=''>
                            {faqList.length > 0 ? faqList?.filter(item => item?.status)?.map((item, index) => (
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header><span className='fw-600'>{item?.question}</span></Accordion.Header>
                                        <Accordion.Body>
                                            {item?.answer}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            )) : ''}
                        </div>
                    </Col>
                    <hr />
                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>

    </div>

};

export default TopCoach;