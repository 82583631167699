import { useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import CurrentBalance from '../Common/CurrentBalance';
import { BankAccountCard } from '../Card';
import NoBank from '../Common/NoBank';
import ErrorText from '../TextField/ErrorText'
import SuccessAlert from '../Alert/SuccessAlert'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { withdrawWalletService } from '../../service/walletAcademy.service';
import { allBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList, selectBankAccountList } from "../../redux/bankAccountSlice"

const WithdrawWalletModal = (props) => {
    const dispatch = useDispatch();
    const bankAccountList = useSelector(selectBankAccountList)
    // onBlur
    const { 
        // register, control, reset, handleSubmit, formState: { errors } }
        register, control, watch, setValue, reset, handleSubmit, formState: { errors } }
         = useForm({ 
        defaultValues: { amount: ''},
        mode: "onBlur" });

    /**
         * @function fetchBankAccountList
         * @params
         * @description fetch the list of bank account
         */
    const fetchBankAccountList = async () => {
        try {
            const result = await allBankAccountService();
            if (result?.data?.status) {
                dispatch(setBankAccountList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
         * @function onSubmit
         * @params formdata
         * @description used to wihdraw wallet for organizer
         */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('amount', formdata?.amount)
        try {
            const result = await withdrawWalletService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                reset()
                props?.fetchTransactionHistoryList()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            } 
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleCloseModal = ()=> {
        reset({ amount: ''})
        props.handleClose()
    }
    
    return <Modal show={props.show} onHide={handleCloseModal} centered>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            Withdraw
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <div className='w-100'>
                <CurrentBalance modal={true} />

                <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Withdrawal Amount</Form.Label>
                        <Form.Control 
                            type="number"
                            placeholder="Enter amount here"
                            {...register("amount", {
                                required: "Please provide the amount"
                            })}
                        />
                        {errors.amount && <ErrorText>{errors.amount.message}</ErrorText>}
                    </Form.Group>

                    {bankAccountList?.length > 0 ? bankAccountList?.map((item, index) => {
                            return <BankAccountCard item={item} key={index} />
                        }) : <NoBank handleAddBankAccountModal={props.handleAddBankAccountModal} />
                    }

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5' type="submit" disabled={bankAccountList?.length === 0}>Withdraw</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
};

export default WithdrawWalletModal;