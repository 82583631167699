import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Edit3 } from 'react-feather';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from "../../../../components/Button";
import ErrorText from '../../../../components/TextField/ErrorText'
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { Avatar } from '../../../../assets/images/svg'

// API Service
import { allCountryService } from '../../../../service/country.service';
import { getProfileDetails, updateProfileImageService, updateAcademyProfileImageService, updateAcademyProfileService } from '../../../../service/academyProfile';

// Redux-Functions
import { setUser, selectToken, selectUser } from "../../../../redux/userSlice"

const Profile = () => {
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false)
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const [academyProfile, setProfile] = useState('')
    const [countryList, setCountryList] = useState([])
    const [profileImage, setProfileImage] = useState(null);

    const { register, control, setValue, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });


    /**
         * @function fetchProfile
         * @params
         * @description fetch the list of country
         */
    const fetchProfile = async () => {
        try {
            const result = await getProfileDetails();
            if (result?.data?.status) {
                setProfile(result?.data?.data)
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'academy'
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }
    /**
         * @function fetchCountryList
         * @params
         * @description fetch the list of country
         */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
        fetchProfile()
        // eslint-disable-next-line
    }, [])

    // for setting default value while editing address
    useEffect(() => {
        if (user && countryList) {
            const defaultValues = {};
            if (user?.type === 'academy') {
                defaultValues.name = academyProfile?.name
                defaultValues.address = academyProfile?.address
                defaultValues.latitude = academyProfile?.latitude
                defaultValues.longitude = academyProfile?.longitude
                setProfileImage(academyProfile?.profile_image)
            }
            defaultValues.country_id = academyProfile?.country_id
            defaultValues.phone = academyProfile?.phone
            defaultValues.email = academyProfile?.email
            // defaultValues.gender = academyProfile?.gender
            if (academyProfile?.gender === 'MALE') {
                defaultValues.gender = { label: 'Male', value: 'MALE' }
            } else if (academyProfile?.gender === 'FEMALE') {
                defaultValues.gender = { label: 'Female', value: 'FEMALE' }
            }
            reset({ ...defaultValues })
        } else {
            reset()
            setProfileImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, academyProfile, countryList])

    /**
         * @function removePhoto
         * @params
         * @description used for remove profile photo
         */
    const removePhoto = async () => {
        setProfileImage()
    }

    /**
         * @function handleSelect
         * @params address
         * @description used for selecting the address
         */
    const handleSelect = async (address) => {
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            setValue('address', address);
            setValue('latitude', latAndLang.lat);
            setValue('longitude', latAndLang.lng);
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
         * @function onSubmit
         * @params formdata
         * @description used to update profile
         */
    const onSubmit = async (formdata) => {
        if (isEdit) {
            try {
                let result;
                if (user?.type === 'academy') {
                    const data = new FormData()
                    data.append('name', formdata?.name)
                    data.append('email', formdata?.email)
                    data.append('phone', formdata?.phone)
                    data.append('country_id', formdata?.country_id)
                    data.append('gender', formdata?.gender)
                    if (formdata?.address && formdata?.latitude && formdata?.longitude) {
                        data.append('address', formdata?.address)
                        data.append('latitude', formdata?.latitude)
                        data.append('longitude', formdata?.longitude)
                    }
                    if (profileImage instanceof File) {
                        const imageData = new FormData()
                        imageData.append('profile_pic', profileImage)
                        await updateAcademyProfileImageService(imageData)
                    } else if (!profileImage) {
                        await updateProfileImageService({})
                    }
                    result = await updateAcademyProfileService(data);
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        setEdit(!isEdit)
                        fetchProfile()
                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }
                }
            } catch (error) {
                ErrorAlert(error)
            }
        } else {
            ErrorAlert('Please click on edit account before submit')
        }
    }
    const handleEdit = () => {
        setEdit(!isEdit)
    }

    return <HelmetProvider>
        <Helmet>
            <title>Academy Profile | AFA Coaching</title>
        </Helmet>
        <Row className='mb-2'>

            <Col className='' lg={{ span: 6, offset: 1 }} >
                <p className='fs-25 fw-600 mt-4 px-3 p-0'>Personal information</p>
            </Col>

            <Col className='profile-background' lg={{ span: 6, offset: 1 }} >
                <Form className='mt-4 px-3' onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='fs-18 fw-600'>&nbsp;</span>
                        <div>
                            {isEdit ? <PrimaryButton type='submit'>Save Changes</PrimaryButton> : <span onClick={handleEdit} className='fs-14 custom-hover'><Edit3 size={17} /> Edit Account</span>}
                        </div>
                    </div>

                    <div className='d-flex flex-column'>
                        {profileImage instanceof File ? <img className='profile-photo rounded-circle' src={URL.createObjectURL(profileImage)} alt={user?.username || user?.name} /> : profileImage ? <img className='profile-photo rounded-circle' src={profileImage} alt={user?.username || user?.name} /> : <Avatar />}

                        {isEdit ? <div className='d-flex align-items-center mt-3'>
                            <input className='d-none' type='file' id="profilePhotoUpload" accept="image/*" onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setProfileImage(e.target.files[0])
                                }
                            }} onClick={(e) => e.target.value} />
                            <label htmlFor="profilePhotoUpload">
                                <span role='button' className='fs-14 fw-500 text-light bg-primary py-2 px-3 rounded'>Change Photo</span>
                            </label>
                            <div className='ms-3'>
                                <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={removePhoto}>Remove Photo</PrimaryOutlineButton>
                            </div>
                        </div> : ''}
                    </div>

                    {user?.type === 'academy' &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Full Name*</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={isEdit === false}
                                placeholder="Enter full name"
                                {...register("name", {
                                    required: "Please provide the full name"
                                })}
                            />
                            {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                        </Form.Group>
                    }

                    {countryList.length > 0 &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                            <InputGroup className='rounded'>
                                <Form.Select
                                    {...register("country_id")}
                                    disabled={isEdit === false}
                                >
                                    {countryList.map((item, index) => {
                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                    })}
                                </Form.Select>
                                <Form.Control
                                    className='user-profile-edit-phone'
                                    type="tel"
                                    disabled={isEdit === false}
                                    placeholder="Enter Phone number"
                                    {...register("phone", {
                                        required: "Please provide the phone"
                                    })}
                                />
                            </InputGroup>
                            {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                        </Form.Group>
                    }

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            disabled={isEdit === false}
                            {...register("email", {
                                required: "Please provide the email",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                    </Form.Group>

                    {/* <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                            <Form.Select
                                disabled={isEdit === false}
                                {...register("gender")}
                            >
                                <option value='MALE'>Male</option>
                                <option value='FEMALE'>Female</option>
                            </Form.Select>
                        </Form.Group> */}

                    {user?.type === 'academy' &&
                        <Controller
                            control={control}
                            name={`address`}
                            render={({ field }) => (
                                <PlacesAutocomplete
                                    value={field.value}
                                    onChange={field.onChange}
                                    onSelect={handleSelect}
                                    debounce={300}
                                    className='form-control border-1'
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <Form.Group className="my-4">
                                            <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                                            <Form.Control
                                                
                                                {...getInputProps({
                                                    placeholder: 'Enter location here',
                                                    className: 'rounded-3'
                                                })}
                                                disabled={isEdit === false}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div className='w-100 text-center mt-3'>
                                                    <Spinner animation="border" />
                                                </div>
                                                }
                                                {suggestions.map(suggestion => {
                                                    return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                                        <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                    </div>
                                                })}
                                            </div>
                                        </Form.Group>
                                    )}
                                </PlacesAutocomplete>
                            )}
                        />
                    }
                </Form>
            </Col>
        </Row>
    </HelmetProvider>
};

export default Profile;