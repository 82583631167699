import React, { useState } from 'react'
import PaymentFailureModal from '../../../../components/Modal/PaymentErrorModal'

const PaymentFailure = () => {
    const [paymentFailureModalShow, setPaymentFailureModalShow] = useState(true);

    /**
      * @function handleFailureModalShow
      * @params ()
      * @description use To show modal
    */
    const handleFailureModalShow = () => {
        setPaymentFailureModalShow(!paymentFailureModalShow)
    };

    return (
        <>
            <PaymentFailureModal show={paymentFailureModalShow} handleClose={handleFailureModalShow} />
        </>
    )
};

export default PaymentFailure