import { useState, useEffect } from 'react';
//react-bootstrap
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
//third party
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import classNames from "classnames";
import { Calendar } from 'react-feather';
import DatePicker from "react-datepicker";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import GanttChart from './ganttChart';
import SchedulerWithCalendar from './SchedulerWithCalendar';
import SchedulerWithCalendar2 from './SchedulerWithCalendar2';

const CalendarWithGanttChart = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [weekDay, setWeekDay] = useState(moment().format('dddd'))
    const [day, setDay] = useState(moment().format('DD'))

    /**
        * @function handleDateSelect
        * @params
        * @description to handle date selection
    */
    const handleDateSelect = () => {
        console.log('handleDateSelect')
    }

    /**
        * @function handleDateChange
        * @params
        * @description to handle date change
    */
    const handleDateChange = (date) => {
        setStartDate(date)
        console.log('handleDateChange', date, moment(date).format('dddd'), moment(date).format('DD'))
        setWeekDay(moment(date).format('dddd'))
        setDay(moment(date).format('DD'))
    }

    return (
        <>
            <h5 className='mt-4 fw-bold'>Calendar</h5>
            <div className="my-3 py-3 bg-ganttChartFlatpickr">
                <Form.Group className="">
                    <InputGroup className='ganttChartFlatpickr justify-content-center'>
                        <Flatpickr
                            className='form-control bg-transparent border-0 fs-18'
                            required
                            value={startDate}
                            placeholder='Select date'
                            id='timepicker'
                            name='date'
                            options={{
                                altInput: true,
                                allowInput: true,
                                static: true,
                                enableTime: false,
                                noCalendar: false,
                                altFormat: "F Y",
                                // altFormat: "F j, Y", //show date with month and year format
                                dateFormat: "Y-m-d",
                            }}
                            onChange={(date) => (handleDateChange(date[0]))}
                        />
                        {/* <DatePicker
                            selected={new Date()}
                            onSelect={handleDateSelect} //when day is clicked
                            onChange={handleDateChange} //only when value has changed
                        /> */}
                        <InputGroup.Text className='rounded-2' id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <hr />
                <div className='d-flex justify-content-center align-items-center'>
                    <Button className='bg-light py-0 px-2 me-3 fs-12 border-secondary' onClick={() => {
                        setStartDate(moment(startDate).subtract(1, 'days').toDate())
                        setWeekDay(moment(startDate).subtract(1, 'days').format('dddd'))
                        setDay(moment(startDate).subtract(1, 'days').format('DD'))
                    }}><IoIosArrowRoundBack className='me-1' />Previous</Button>
                    <div className='d-flex'>
                        <p className='mb-0 me-1'>{weekDay}</p>
                        <p className='mb-0'>{day}</p>
                    </div>
                    <Button className='bg-light py-0 px-2 ms-3 fs-12 border-secondary' onClick={() => {
                        setStartDate(moment(startDate).add(1, 'days').toDate())
                        setWeekDay(moment(startDate).add(1, 'days').format('dddd'))
                        setDay(moment(startDate).add(1, 'days').format('DD'))
                    }}>Next <IoIosArrowRoundForward className='ms-1' /></Button>
                </div>
            </div>
            <GanttChart date={startDate} />
            {/* <SchedulerWithCalendar date={startDate} />
            <SchedulerWithCalendar2 /> */}
        </>
    )
};

export default CalendarWithGanttChart