import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { MoreVertical, Edit, Eye, Trash, Edit2, Edit3 } from 'react-feather'
// import UpdateProduct from './updateModule'

// ** Reactstrap Imports
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    toast,
    Alert
} from 'reactstrap'

import ErrorAlert from '../../../../components/Alert/ErrorAlert';
import SuccessAlert from '../../../../components/Alert/SuccessAlert';

// Utility-Service
import { getToken } from '../../../../utils/AuthService'

// API-Service
import { coachSingleService, deleteCoachService, coachListService } from '../../../../service/coach.service';

// Redux-Settings
import {
    setCoachList,
    setTotalEntry,
    setSingleData
} from '../../../../redux/coachSlice'
import ViewDetail from './ViewCoach'
import UpdateCoach from './UpdateCoach'

const DeleteUpdate = ({ tableRecord }) => {
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [showCoachViewModal, setCoachViewModal] = useState(false)
    const [showCoachEditModal, setCoachEditModal] = useState(false)
    const [localData, setData] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = getToken()

    let resData  = {}
    /**
        * @function fetchSingleData
        * @params
        * @description fetch the list of customer banner
        */
    const fetchSingleData = async () => {
        try {
            const result = await coachSingleService({ id: tableRecord.id });
            if (result?.data?.status) {
                resData = result?.data?.data
                setData(result?.data?.data)
                dispatch(setSingleData(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function handleViewCoachModal
         * @params
         * @description set the visibility of add bank account modal
         */
    const handleViewCoachModal = () => {
        setCoachViewModal(!showCoachViewModal)
    }

    const handleEditCoachModal = () => {
        setCoachEditModal(!showCoachEditModal)
    }

    useEffect(() => {
        if (showCoachEditModal || showCoachViewModal) {
            fetchSingleData()
        }
        // eslint-disable-next-line
    }, [showCoachEditModal, showCoachViewModal])

    const handleDeleteModal = () => {
        setIsDeleteModal(true)
    }

    const handleCancel = () => {
        setIsDeleteModal(false)
    }

    const handleDelete = async () => {
        const result = await deleteCoachService({
            id: tableRecord?.id,
            token
        })
        if (result?.data?.status) {
            // toast.success(result.data.message)
            SuccessAlert('Coach Deleted Successfully')
            setIsDeleteModal(false)
            const resultAll = await coachListService({ page: 1, countPerPage: 10, token });
            if (resultAll?.data?.status) {
                dispatch(setCoachList(resultAll?.data?.data?.coach_list))
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total_pages))
                // SuccessToast(resultAll.data.message)
            } else {
                // toast.success(resultAll.data.message)
            }
        }
    }

    return (
        <>
            <div className='d-flex align-items-center justify-content-between flex-row'>
                {localData?.id ? <Fragment>
                    <ViewDetail tableRecord={tableRecord} show={showCoachViewModal} handleClose={handleViewCoachModal} size='lg' />
                    <UpdateCoach localData={localData} show={showCoachEditModal} handleEditCoachModal={handleEditCoachModal} size='lg' />
                </Fragment> : ''}
                <div>
                    <Eye className='me-50 text-primary ms-42 custom-hover' size={20} onClick={handleViewCoachModal} />
                </div>
                <div>
                    <Edit className='me-50 text-primary ms-2 custom-hover' size={20} onClick={handleEditCoachModal} />
                </div>
                <div>
                    <Trash className='me-50 text-primary ms-2 custom-hover' size={20} onClick={handleDeleteModal} />
                    </div>
            </div>

            <Modal isOpen={isDeleteModal} toggle={() => setIsDeleteModal(!isDeleteModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setIsDeleteModal(!isDeleteModal)}>Delete Coach</ModalHeader>
                <ModalBody>
                    <h6>Are you sure, you want to delete this coach?</h6>
                </ModalBody>
                <ModalFooter>
                    <Button className='ms-2 text-light' color='dark' onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button color='primary text-light' onClick={() => handleDelete(!isDeleteModal)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default DeleteUpdate
