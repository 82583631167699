import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';

// Images
import { feedback } from "../../assets/images/index";

// API Service
import { getFeedbackService, getStudentRatingService } from '../../service/guestHomeService';

// Redux-Functions
import { selectRatReviewsList, setClientList } from "../../redux/slices/guestHomeSlice"
import ReactStars from 'react-rating-star-with-type'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'
import "swiper/css/effect-fade";

const UserSlides = () => {
    const dispatch = useDispatch()
    const clientList = useSelector(selectRatReviewsList);
    const swiper = useSwiper()
    const swiperSlide = useSwiperSlide();

    // const clientLists = [{
    //     profile_image: feedback,
    //     name: 'Azad Akhtar',
    //     position: 'Frontend Developer, New Delhi',
    //     rating: 4.3,
    //     message: 'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.'
    // },
    // {

    //     profile_image: feedback,
    //     name: 'Jacob Duffy',
    //     position: 'Backend Developer, Germany',
    //     rating: 4.3,
    //     message: 'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.'
    // },
    // {

    //     profile_image: feedback,
    //     name: 'Doug Hagerty',
    //     position: 'Business Developer, London',
    //     rating: 4.3,
    //     message: 'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.'
    // }]

    /**
         * @function fetchCustomerFeedbackList
         * @params
         * @description fetch the list of customer rating
         */
    const fetchCustomerFeedbackList = async () => {
        try {
            const result = await getFeedbackService();
            console.log('getFeedbackService', result)
            if (result?.data?.status) {
                dispatch(setClientList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            console.log('getFeedbackService', error)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerFeedbackList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        <div className='user-section mt-4'>
            <Container>
                <Row className='slides-list'>

                    <Col lg={12} className='pt-2 mb-5'>
                        <div>
                            <div className='faq-title mb-5'>
                                <h1 className='fs-lg-45 fs-24 fw-bold'>Users-love-us Don’t take it from us.</h1>
                                <p>Your valuable ratings and reviews matter to us.</p>
                            </div>
                        </div>
                        <div className='client-feedback'>
                            <div className="list-feedback">
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    dots={Pagination}
                                    arrows={Navigation}
                                    Autoplay
                                    pagination={{ clickable: true }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                >
                                    {clientList?.length > 0 ? clientList.map((item, index) => (
                                        <>
                                            <SwiperSlide key={index}>
                                                <div className="item-feedback">
                                                    <Row className='item-feedback-both p-0 m-0'>
                                                        <Col lg={8} md={12} sm={12} className='p-0'>
                                                            <div className="h-100">
                                                                <div className="contents h-100">
                                                                    <p className=''>
                                                                        <div className='customer-rating'>
                                                                            <ReactStars
                                                                                disabled
                                                                                value={item?.rate}
                                                                                edit={false}
                                                                                activeColors={["#FDB022"]}
                                                                            />
                                                                        </div>
                                                                    </p>
                                                                    <p className='message pb-2'> {item?.description}</p>
                                                                    <p className='name d-flex'><p className='fw-bold fs-20 mb-0'>&ndash;&nbsp;</p> {item?.name}</p>
                                                                    <p className='position fs-12'>{item?.title}</p>
                                                                    {/* <p className='slider-dots mb-0'><span className=''>.</span><span className='ms-2'>.</span><span className='ms-2'>.</span></p> */}
                                                                    {/* <p className='slider-dots mb-0'><span className='' onClick={() => swiper.slideNext()} >.</span><span className='ms-2' onClick={() => swiper.slideNext()} >.</span><span className='ms-2' onClick={() => swiper.slideNext()}>.</span></p> */}
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={4} md={12} sm={12} className='p-0'>
                                                            <div className="profile-image h-100" style={{ maxWidth: '100%' }}>
                                                                <Image src={item?.image} alt={item?.name} className="" width={'100%'} style={{ maxWidth: '100%' }} />
                                                            </div>
                                                            {/* <img src={item?.image} alt={item?.name}  /> */}
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </SwiperSlide>

                                        </>

                                    )) : ''}
                                </Swiper>

                            </div>


                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
        <div className='clearfix'></div>
    </div>

};

export default UserSlides;