import { useEffect } from 'react'
import { Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from 'moment';

// Custom Component
import NoTransaction from "./NoTransaction";

// Images
import { Earned, Withdrawn } from "./../../../assets/images/svg";

const MyTransaction = (props) => {
    useEffect(() => {
        props?.fetchTransactionHistoryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.currentPage, props?.searchKeyword])

    const columns = [{
        name: '',
        selector: (row) => row.transaction_type === 'DEPOSIT' ? <div className='d-flex align-items-center'>
            <Earned className='me-2' />
            <span className='fs-14 fw-600'>Earned</span>
        </div> : <div className='d-flex align-items-center'>
            <Withdrawn className='me-2' />
            <span className='fs-14 fw-600'>Withdrawn</span>
        </div>
    },
    {
        name: '',
        selector: (row) => row?.customer?.username ? <span className='fs-14 fw-600'>{row?.customer?.username}</span> : '-',
    },
    {
        name: '',
        selector: (row) => row?.updated_amount || row?.amount ? <span className='fs-14 fw-600 text-secondary'>RM{row?.updated_amount || row?.amount}</span> : '-',
    },
    {
        name: '',
        selector: (row) => row?.created_at ? moment(row?.created_at).format("MMM DD YYYY, hh:mm A") : '-',
    },
    {
        name: '',
        selector: (row) => row.transaction_type !== 'DEPOSIT' && row?.is_withdrawn === 0 ? <div className='bg-warning bg-opacity-50 text-warning rounded-pill px-2 py-1'>
            <span>Processing</span>
        </div> : row.transaction_type !== 'DEPOSIT' ? <div className='bg-success bg-opacity-50 text-success rounded-pill px-2 py-1'>
            <span>Processed</span>
        </div> : '-'
    }]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        props?.setCurrentPage(page.selected)
    }

    return <Row className='my-3 px-3'>
        <span className='fs-24 fw-600 mb-3'>Wallet Transaction</span>
        {props?.transactionHistoryList.length > 0 ? <>
            <DataTable
                className='d-none d-lg-block wallet-transaction'
                noHeader={true}
                columns={columns}
                data={props?.transactionHistoryList}
            />
            <div>
                {props?.transactionHistoryList?.map((item, index) => {
                    return <div className='d-flex d-lg-none flex-column align-items-start border rounded p-2 mb-3' key={index}>
                        <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
                            {item?.transaction_type === 'DEPOSIT' ? <div className='d-flex align-items-center'>
                                <Earned className='me-2' />
                                <span className='fs-14 fw-600'>Earned</span>
                            </div> : <div className='d-flex align-items-center'>
                                <Withdrawn className='me-2' />
                                <span className='fs-14 fw-600'>Withdrawn</span>
                            </div>
                            }
                            {item?.transaction_type !== 'DEPOSIT' && item?.is_withdrawn === 0 ? <div className='bg-warning bg-opacity-50 text-warning rounded-pill px-2 py-1'>
                                <span>Processing</span>
                            </div> : item?.step === 'Processing' ? <div className='bg-success bg-opacity-50 text-success rounded-pill px-2 py-1'>
                                <span>Processed</span>
                            </div> : '-'
                            }
                        </div>
                        <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
                            <span className='fs-14 fw-600'>{item?.customer?.username}</span>
                            <span className='fs-14 fw-600 text-secondary'>RM{item?.updated_amount || item?.amount}</span>
                        </div>
                        <span className='fs-12 text-secondary'>{moment(item?.time).format("MMM DD YYYY, hh:mm A")}</span>
                    </div>
                })}
                <ReactPaginate
                    nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                        <span className='fs-14 fw-600 me-2'>Next</span>
                        <AiOutlineArrowRight className='text-secondary' size={20} />
                    </div>}
                    breakLabel='...'
                    previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                        <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                        <span className='fs-14 fw-600'>Previous</span>
                    </div>}
                    pageRangeDisplayed={3}
                    forcePage={props?.currentPage}
                    marginPagesDisplayed={3}
                    activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                    pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                    breakClassName='page-item me-3 d-flex align-items-center'
                    nextClassName='page-item next-item flex-grow-1 text-end'
                    previousClassName='page-item prev-item flex-grow-1 me-3'
                    disabledLinkClassName='btn disabled p-0 border-0'
                    pageCount={props?.totalEntry || 1}
                    onPageChange={page => handlePagination(page)}
                    containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                />
            </div>
        </> : <NoTransaction />
        }
    </Row>
};

export default MyTransaction;