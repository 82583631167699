import { Modal, Row, Col } from 'react-bootstrap';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from '../Button';

const PaymentConfirmModal = (props) => {
    /**
        * @function handleSubmit
        * @params
        * @description to submit
    */
    const handleSubmit = () => {
        props?.setConfirmation(!props?.confirmation)
        props?.handleClose()
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='border-0' closeButton />
        <Modal.Body className='d-flex flex-column align-items-center'>
            <span className='fs-24 fw-600 text-primary mt-1 mb-2'>Important note!</span>
            <span className='fs-16 fw-600 text-dark text-center'>Payment Made are Not Refundable. Please make sure all info are correct.</span>
            <Row className='mb-4 w-100 mt-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <PrimaryOutlineButton className='fs-14 fw-500 text-dark border-secondary' onClick={props.handleClose}>Edit</PrimaryOutlineButton>
                </Col>
                <Col xs={6} md={4}>
                    <PrimaryButton className='fs-14 fw-500' onClick={() => handleSubmit()}>Confirm</PrimaryButton>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
};

export default PaymentConfirmModal;