import { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button, InputGroup, Image, Spinner, Input } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import classNames from "classnames";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import Select, { components, MultiValueRemoveProps } from 'react-select'
import Flatpickr from 'react-flatpickr'
// Custom Component
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';

import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// API Service
import { addSessionService, singleProgramService } from '../../../../service/coachProgram';
import { selectFreshData, setFreshData } from "../../../../redux/coachProgrammeSlice"
import { PlusCircle, X, Search, Clock, Calendar } from 'react-feather';

const CreateSessionModal = ({ singleData, ...props }) => {
    const dispatch = useDispatch()
    const freshData = useSelector(selectFreshData);
    const [startDate, setStartDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [sessionList, setSession] = useState([{ time_slots: [], date: '', location: '', latitude: '', longitude: '', }]);
    // const [frequency, setFrequency] = useState([]);
    const [address, setAddress] = useState();
    // const [latitude, setLatitude] = useState();
    // const [longitude, setLongitude] = useState();

    console.log('sessionList', sessionList)

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: {
            name: '',
            description: '',
            topic: '',
            requirement: '',
            selectedCategory: '',
            selectedMainCoach: '',
            selectedSubCoach: '',
            selectedType: '',
            checked: false,
            sessionList: [],
            startDate: ""
        },
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    /**
        * @function fetchSingleProgram
        * @params
        * @description fetch the list of customer banner
        */
    const fetchSingleProgram = async () => {
        try {
            console.log('fetchSingleProgram 5')

            const result = await singleProgramService({ id: singleData?.id });
            if (result?.data?.status) {
                // setLoading(false)
                // setProgramList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to create session
         */
    const onSubmit = async (formdata) => {
        try {
            const data = new FormData()
            data.append('program_id', singleData.id)
            if (sessionList?.length > 0) {
                sessionList.forEach((element, index) => {
                    data.append(`sessions[${index}][date]`, element.date)
                    data.append(`sessions[${index}][location]`, element.location)
                    data.append(`sessions[${index}][latitude]`, element.latitude)
                    data.append(`sessions[${index}][longitude]`, element.longitude)
                    element.time_slots.forEach((element2, index2) => {
                        data.append(`sessions[${index}][timeslots][${index2}][max_pax]`, element2.max)
                        data.append(`sessions[${index}][timeslots][${index2}][start_time]`, element2.startTime)
                        data.append(`sessions[${index}][timeslots][${index2}][end_time]`, element2.endTime)
                    })
                })
            }
            const result = await addSessionService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                dispatch(setFreshData(!freshData))
                setSession([])
                reset()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleAddSession = () => {
        setSession([...sessionList, { date: '', location: '', latitude: '', longitude: '', time_slots: [{ max: '', startTime: '', endTime: '' }] }])
    }
    const handleRemoveSession = (index1) => {
        const temp = [...sessionList]
        const deleteVal = [...temp]
        deleteVal.splice(index1, 1)
        setSession(deleteVal)
    }

    const handleAddItem = (index1) => {
        const temp = [...sessionList]

        if (temp[index1].time_slots?.length > 0) {
            temp[index1].time_slots = [...temp[index1].time_slots, { max: '', startTime: '', endTime: '' }]
        } else {
            temp[index1].time_slots = [{ max: '', startTime: '', endTime: '' }]
        }
        setSession(temp)
    }

    const handleRemoveItem = (index1, index2) => {
        const temp = [...sessionList]
        if (temp[index1].time_slots?.length > 0) {
            const deleteVal = [...temp[index1].time_slots]
            deleteVal.splice(index2, 1)
            temp[index1].time_slots = deleteVal
            setSession(temp)
        } else {

        }
    }

    const handleStartDateChange = (date, index1) => {
        const startDate = date.length > 0 ? moment(date[0]).format('YYYY-MM-DD') : ''
        const temp = [...sessionList]
        const name = 'date'
        if (temp?.length > 0 && temp[index1]) {
            temp[index1][name] = startDate
            setSession(temp)
        } else {

        }
    }

    /**
         * @function handleSelectLocation
         * @params address
         * @description used for selecting the location / address
         */

    const handleSelectLocation = async (index1, address) => {
        const temp = [...sessionList]
        try {
            const result = await geocodeByAddress(address);
            // const latAndLang = await getLatLng(result.length > 0 ? result[0] : '')
            const latAndLang = await getLatLng(result[0])
            const name = 'location'
            const latitude = 'latitude'
            const longitude = 'longitude'
            if (temp?.length > 0 && temp[index1]) {
                temp[index1][name] = address
                temp[index1][latitude] = latAndLang?.lat
                temp[index1][longitude] = latAndLang?.lng
                setSession(temp)
            } else {
                setSession(temp)
            }

        } catch (error) {
            console.log(error);
        }
    };

    /**
    @function handleSelect
    @params address
    @description used for selecting the address
    */
    const handleSelect = async (address) => {
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            setValue('address', address);
            setValue('latitude', latAndLang.lat);
            setValue('longitude', latAndLang.lng);
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
         * @function handleAddressChange
         * @params address
         * @description used for search the address
         */

    const handleAddressChange = async (index1, address) => {
        const temp = [...sessionList]
        try {
            const name = 'location'
            if (temp?.length > 0 && temp[index1]) {
                temp[index1][name] = address
                setSession(temp)
            } else {
                setSession(temp)
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleMaxChange = (e, index1, index2) => {
        const temp = [...sessionList]
        const { name, value } = e?.target
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = value
            setSession(temp)
        } else {

        }
    }
    const handleStartTimeChange = (date, startDate, index1, index2) => {
        const startTime = date.length > 0 ? moment(date[0]).format('HH:mm') : moment(startDate[0]).format('HH:mm')
        const temp = [...sessionList]
        const name = 'startTime'
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = startTime
            setSession(temp)
        } else {

        }
    }

    const handleEndTimeChange = (date, endtime, index1, index2) => {
        const endTime = date.length > 0 ? moment(date[0]).format('HH:mm') : moment(startDate[0]).format('HH:mm')
        const temp = [...sessionList]
        const name = 'endTime'
        if (temp[index1].time_slots?.length > 0 && temp[index1].time_slots[index2]) {
            temp[index1].time_slots[index2][name] = endTime
            setSession(temp)
        }
    }

    const handleCloseModal = () => {
        props.handleClose()
        setSession([{ time_slots: [], date: '', location: '', latitude: '', longitude: '', }])
        setAddress('')
    }

    return <Modal show={props.show} onHide={handleCloseModal} size={props.size} centered>
        <Modal.Header className='fs-20 fw-bold border-0 pt-4 px-5' closeButton >
            Create Program Session
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center px-5 pb-3'>
            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xl={12} className='mb-2'>
                        <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-lg-row flex-column'>
                            <div><h1 className='fs-18 text-secondary'>Frequency</h1></div>
                            <div><h1 className='fs-18 text-secondary'>Monthly 4 Sessions</h1></div>
                        </div>
                    </Col>
                    <Col xl={12} className='pt-3 mb-2'>
                        <div className='d-flex justify-content-between align-items-lg-center align-items-start flex-row add-session-button'>
                            <div><h1 className='fs-18 fw-600'>Sessions</h1></div>
                            <div>
                                {props?.currentSessionListing?.length + sessionList?.length === 4 ? '' : <Button size="sm" variant="primary" className='text-primary' onClick={handleAddSession}>
                                    <PlusCircle size={15} /> <span className='text-primary'>{`${props?.currentSessionListing?.length + sessionList?.length > 1 ? 'Add More Session' : 'Add Session'}`}</span></Button>}
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="mb-2">
                    {sessionList?.map((item, index1) => {
                        return (
                            <Fragment>
                                <div className="frequency-list  mb-3" key={`${index1}`}>
                                    <div className="first">
                                        <div className='d-flex justify-content-between align-items-center align-items-start flex-row'>
                                            <div><h1 className='fs-16 fw-600'>{`Session ${props?.currentSessionListing?.length + index1 + 1}`}</h1></div>
                                            <div className="cancel float-end text-primary cursor-pointer"><X size={22} onClick={(e) => handleRemoveSession(index1)} /> </div>
                                        </div>
                                        <div className="session">
                                            <Form.Group className="my-2">
                                                <Form.Label className='fs-14 fw-600'>Date</Form.Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className='form-control'
                                                        required
                                                        value={startDate}
                                                        placeholder='Select date'
                                                        id='timepicker'
                                                        name='date'
                                                        options={{
                                                            static: true,
                                                            dateFormat: 'Y-m-d',
                                                            allowInput: true,
                                                            enableTime: false,
                                                            minDate: moment().add(1, 'days').format("YYYY-MM-DD")
                                                        }}
                                                        onChange={(date) => handleStartDateChange(date, index1)}
                                                    />
                                                    <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>

                                            <PlacesAutocomplete
                                                value={item?.location}
                                                // defaultValue={item?.location}
                                                // onChange={field.onChange}
                                                onChange={(address) => handleAddressChange(index1, address)}
                                                onSelect={(address) => handleSelectLocation(index1, address)}
                                                // onSelect={handleSelectLocation}
                                                // debounce={300}
                                                className='form-control border-1'
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <Form.Group className="my-4">
                                                        <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                                                        <Form.Control
                                                            name='location'
                                                            required
                                                            {...getInputProps({
                                                                placeholder: 'Enter location here',
                                                                className: 'location-search-input'
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div className='w-100 text-center mt-3'>
                                                                <Spinner animation="border" />
                                                            </div>
                                                            }
                                                            {suggestions.map(suggestion => {
                                                                return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                                                    <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </Form.Group>
                                                )}
                                            </PlacesAutocomplete>

                                            {/* <Controller
                                                control={control}
                                                name={`address`}
                                                render={({ field }) => (
                                                    <PlacesAutocomplete
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        onSelect={handleSelect}
                                                        debounce={300}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <Form.Group className="my-4">
                                                                <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                                                                <Form.Control
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Address',
                                                                        className: 'location-search-input'
                                                                    })}
                                                                />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div className='w-100 text-center mt-3'>
                                                                        <Spinner animation="border" />
                                                                    </div>
                                                                    }
                                                                    {suggestions.map(suggestion => {
                                                                        return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                                                            <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </PlacesAutocomplete>
                                                )}
                                            /> */}
                                        </div>
                                    </div>

                                    <div className="child">
                                        <div className="frequency-content pt-4">
                                            <Row className='mb-2'>
                                                <Col md={12}>
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <div className="add-title text-dark fs-14 fw-600"> Time Slot</div>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <div className=''>
                                                {/* copy and paste here original script */}
                                                {item?.time_slots?.map((object, index2) => <Fragment>
                                                    <div className="inputs-fields" key={`child${index1}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="cancel-mobile">
                                                                    <div className="cancel float-end text-primary cursor-pointer">
                                                                        <X size={22} onClick={(e) => handleRemoveItem(index1, index2)} />
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col md='3' sm='12' className=''>
                                                                <div className="custom-number">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Max Pax</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Enter number"
                                                                            required="number is required"
                                                                            name='max'
                                                                            onChange={(e) => handleMaxChange(e, index1, index2)}

                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>

                                                            <Col md='4' sm='12' className=''>
                                                                <div className="custom-number time-selection">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Starting time</Form.Label>
                                                                        <InputGroup>
                                                                            <Flatpickr
                                                                                className='form-control'
                                                                                required
                                                                                value={startTime}
                                                                                placeholder='Select time'
                                                                                id='timepicker'
                                                                                name='startTime'
                                                                                options={{
                                                                                    static: true,
                                                                                    altInput: true,
                                                                                    allowInput: true,
                                                                                    enableTime: true,
                                                                                    noCalendar: true,
                                                                                    dateFormat: "H:i",
                                                                                    maxTime: sessionList[index1]?.time_slots[index2]?.endTime ? sessionList[index1]?.time_slots[index2]?.endTime : null,
                                                                                }}
                                                                                onChange={(date) => handleStartTimeChange(date, startTime, index1, index2)}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend"><Clock size='15' /></InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md='4' sm='12' className=''>
                                                                <div className="custom-price time-selection">
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label className='fs-14 fw-600'>Ending time</Form.Label>
                                                                        <InputGroup>
                                                                            <Flatpickr
                                                                                className='form-control'
                                                                                required
                                                                                value={endTime}
                                                                                placeholder='Select time'
                                                                                id='timepicker'
                                                                                name='endTime'
                                                                                options={{
                                                                                    static: true,
                                                                                    altInput: true,
                                                                                    allowInput: true,
                                                                                    enableTime: true,
                                                                                    noCalendar: true,
                                                                                    dateFormat: "H:i",
                                                                                    minTime: sessionList[index1]?.time_slots[index2]?.startTime ? sessionList[index1]?.time_slots[index2]?.startTime : null,
                                                                                }}
                                                                                onChange={(date) => handleEndTimeChange(date, endTime, index1, index2)}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend"><Clock size='15' /></InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md='1'>
                                                                <div className="cancel-web">
                                                                    <div className="cancel float-end text-primary cursor-pointer">
                                                                        <X size={22} onClick={(e) => handleRemoveItem(index1, index2)} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Row className='pt-2 mb-2'>
                                        <Col md={12}>
                                            <div className="d-flex flex-row justify-content-start align-items-center">
                                                <div className="add-title text-dark fs-14 fw-600"></div>
                                                <div className="add-content text-primary fs-14 fw-600 cursor-pointer">
                                                    <p className={`${index1}`} onClick={() => handleAddItem(index1)}><span><PlusCircle className='text-primary' size={15} /></span> <span className='fw-600 fs-12 text-primary'>{item?.time_slots?.length > 0 ? "Add more time slot" : "Add time slot "}</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Fragment>
                        )

                    })}

                </div>

                <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5 custom-hover' type="submit">Create Session</PrimaryButton>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
};

export default CreateSessionModal;