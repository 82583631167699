import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, InputGroup, Image } from 'react-bootstrap';
import { useForm, useFieldArray, Controller } from "react-hook-form";
// third party component
import classNames from "classnames";
import moment from 'moment';
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import toast from 'react-hot-toast'
import { LuEye, LuEyeOff } from "react-icons/lu";
// Custom Component
import { Calendar } from 'react-feather';
import ErrorText from '../../../../components/TextField/ErrorText'
import { PrimaryButton } from '../../../../components/Button';
import { selectThemeColors } from '../../../..//utils/Utils'
// import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker'
import SuccessAlert from '../../../../components/Alert/SuccessAlert';
import ErrorAlert from '../../../../components/Alert/ErrorAlert';

// Images
import { uploadImage } from '../../../../assets/images/index'

// API Service
import { addCoachService, coachListService } from '../../../../service/coach.service';
import { selectUser } from "../../../../redux/userSlice"

const CreateCoach = (props) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const user = useSelector(selectUser);
    const [startDate, setStartDate] = useState(new Date())
    const [coachImage, setFile] = useState(null);
    const [coachType, setCoachType] = useState(null);
    const allCoach = [{ label: 'Main Coach', value: 'MAIN_COACH' }, { label: 'Sub Coach', value: 'SUB_COACH' }]
    const [selectedGender, setSelectedGender] = useState('MALE');
    const allGender = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
    const [show, setShow] = useState(false)
    // const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        const { register, reset, handleSubmit, formState: { errors } } = useForm({
        // defaultValues: {
        //     name: '',
        //     phone: '',
        //     email: '',
        //     description: '',
        //     password: '',
        //     password_confirmation: ''
        // },
        // mode: "onBlur"
        mode: 'onSubmit'
    })

    const selectImages = async (event) => {
        const checkSize = event.target.files[0]
        const myData = event.target.files[0]
        // Check if any file is selected.
        if (checkSize?.size) {
            const fileSize = checkSize.size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                toast.danger('File too Big, please select a file less than 2mb')
                // $(file).val(''); //for clearing with Jquery
            } else {
                // Proceed further
                setFile(myData)
            }

        }
    }

    /**
         * @function onSubmit
         * @params formdata
         * @description used to update program
         */
    const onSubmit = async (formdata) => {
        // e.preventDefault();
        try {
            if (selectedGender?.value) {
                if (coachType?.value) {
                    // if (!coachImage) {
                    //     ErrorAlert('Please upload the profile image')
                    //     return
                    // }
                    const data = new FormData()
                    if (coachImage) {
                        data.append('profile_pic', coachImage)
                    }
                    const dob = moment(startDate[0]).format('YYYY-MM-DD')
                    data.append('name', formdata?.name)
                    data.append('email', formdata?.email)
                    data.append('phone', formdata?.phone)
                    data.append('academy_id', user?.id)
                    data.append('type', coachType?.value)
                    data.append('gender', selectedGender?.value)
                    data.append('password', formdata?.password)
                    data.append('password_confirmation', formdata?.password_confirmation)
                    data.append('description', formdata?.description)
                    data.append('dob', dob)

                    const result = await addCoachService(data);
                    if (result?.data?.status) {
                        SuccessAlert(result?.data?.message)
                        handleClosePopup()
                        props?.handleAddCoachModal()
                        props?.fetchAcademyCoachList(1)

                    } else {
                        ErrorAlert(result?.response?.data?.message)
                    }

                } else {
                    ErrorAlert('Please select the coach type')
                }
            } else {
                ErrorAlert('Please select the gender')
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const handleClosePopup = () => {
        reset()
        setCoachType('')
        setFile('')
        setSelectedGender()
        setStartDate()

    };

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return <Modal show={props.show} onHide={props.handleAddCoachModal} size={props.size} centered>
        <Modal.Header className='fs-22 fw-bold border-0 px-5 pt-3' closeButton >
            Add New Coach
        </Modal.Header>
        <Modal.Body className='px-5'>

            <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="col-12">
                    {/* <Form.Label className='fs-14 fw-500'>Coach Type</Form.Label> */}
                    <div className='mb-4 product'>
                        <label htmlFor="upload-button" class="drop-container" role='button' >
                            {coachImage ? <Image className="img mb-3" src={URL.createObjectURL(coachImage)} roundedCircle width='100' height='100' alt='profile' /> : <img className="img mb-3" src={uploadImage} width='100' height='100' alt='profile' />}

                            <br />
                            <span class="drop-title text-primary fs-14">Add Profile Image</span>
                        </label>
                        <input id="upload-button" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={selectImages}
                            style={{ display: 'none' }} />
                    </div>
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label className='fs-14 fw-600'>Coach Type</Form.Label>
                    <Select
                        // closeMenuOnSelect={false}
                        theme={selectThemeColors}
                        className='react-select'
                        classNamePrefix='select'
                        defaultValue={coachType}
                        name='clear'
                        options={allCoach}
                        placeholder='Coach Type'
                        onChange={setCoachType}
                    />
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Coach Name</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete='nope'
                        placeholder="Enter name here"
                        {...register("name", {
                            required: "Please provide coach name",
                        })}
                    />
                    {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Phone Number</Form.Label>
                    <Form.Control
                        type="tel"
                        autoComplete='nope'
                        placeholder="Enter phone number here"
                        {...register("phone", {
                            required: "Please provide phone number",
                        })}
                    />
                    {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        autoComplete='nope'
                        placeholder="Enter email here"
                        {...register("email", {
                            required: "Please provide email address",
                        })}
                    />
                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                </Form.Group>
                <Row>
                    <Form.Group className="col-6">
                        <Form.Label className='fs-14 fw-600'>Gender</Form.Label>
                        <Select
                            // closeMenuOnSelect={false}
                            // theme={selectThemeColors}
                            className='react-select'
                            classNamePrefix='select'
                            defaultValue={selectedGender}
                            name='clear'
                            options={allGender}
                            placeholder='Gender'
                            onChange={setSelectedGender}
                        />
                    </Form.Group>
                    <Form.Group className="col-6 academy-date">
                        <Form.Label className='fs-14 fw-600'>Date Of Birth</Form.Label>
                        <InputGroup>
                        <Flatpickr
                            className='form-control'
                            value={startDate}
                            required
                            id='timepicker'
                            options={{
                                // altInput: true,
                                static: true,
                                allowInput: true,
                                enableTime: false,
                                noCalendar: false,
                                dateFormat: "d/m/Y",
                            }}
                            onChange={date => setStartDate(date)}
                        />
                        <InputGroup.Text id="inputGroupPrepend"><Calendar size='15' /></InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                </Row>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        {...register("description", {
                            required: "Please provide description",
                        })}
                    />
                    {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Password</Form.Label>
                    {/* <Form.Control
                        type="password"
                        autoComplete='nope'
                        placeholder="Enter password here here"
                        {...register("password", {
                            required: "Password address is required",
                        })}
                    /> */}
                    {/* {errors.password && <ErrorText>{errors.password.message}</ErrorText>} */}

                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password', {
                                required: "Please provide the password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                </Form.Group>


                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-600'>Confirm Password</Form.Label>
                    {/* <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        autoComplete='nope'
                        placeholder="Enter confirm password here here"
                        {...register("password_confirmation", {
                            required: "COnfirm Password address is required",
                        })}
                    />
                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>} */}

                    <InputGroup className='rounded'>
                        <Form.Control
                            className='border-end-0'
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete='nope'
                            placeholder='Enter password'
                            {...register('password_confirmation', {
                                required: "Please provide the confirm password",
                            })}
                        />
                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                    </InputGroup>
                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                </Form.Group>

                <div className='d-flex justify-content-end align-items-end mt-4'>
                    <div>
                        <PrimaryButton className='fs-14 fw-600 px-5' type="submit">Add</PrimaryButton>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
};

export default CreateCoach;