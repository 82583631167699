import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trash, Edit3 } from 'react-feather'
 
// ** Reactstrap Imports
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import ErrorAlert from '../../Alert/ErrorAlert';
import SuccessAlert from '../../Alert/SuccessAlert';

// Utility-Service
import { getToken } from '../../../utils/AuthService'

// API-Service
import { accessSingleService, deleteAccessService, accessListService } from '../../../service/accessAcademy.service';

// Redux-Settings
import { setDataList, setTotalEntry, setSingleData } from '../../../redux/accessAcademySlice';

import UpdateCoach from './UpdateCoach'

const DeleteUpdate = ({ tableRecord, ...props }) => {
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [showCoachViewModal, setCoachViewModal] = useState(false)
    const [showCoachEditModal, setCoachEditModal] = useState(false)
    const [localData, setData] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = getToken()

    let resData = {}
    
    /**
        * @function fetchSingleData
        * @params
        * @description fetch the list of customer banner
    */
    const fetchSingleData = async () => {
        try {
            const result = await accessSingleService({ id: tableRecord.id });
            if (result?.data?.status) {
                resData = result?.data?.data
                setData(result?.data?.data)
                dispatch(setSingleData(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleViewCoachModal
        * @params
        * @description set the visibility of add bank account modal
    */
    const handleViewCoachModal = () => {
        setCoachViewModal(!showCoachViewModal)
    }

    const handleEditCoachModal = () => {
        setCoachEditModal(!showCoachEditModal)
    }

    useEffect(() => {
        if (showCoachEditModal || showCoachViewModal) {
            fetchSingleData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCoachEditModal, showCoachViewModal])

    const handleDeleteModal = () => {
        setIsDeleteModal(true)
    }

    const handleCancel = () => {
        setIsDeleteModal(false)
    }

    const handleDelete = async () => {
        const result = await deleteAccessService({
            id: tableRecord?.id,
            token
        })
        if (result?.data?.status) {
            SuccessAlert('Access Manager has been deleted')
            setIsDeleteModal(false)
            props?.setRefetch(props?.refetch)
            const resultAll = await accessListService({ page: 1, countPerPage: 10, token });
            if (resultAll?.data?.status) {
                dispatch(setDataList(resultAll?.data?.data?.access_manager_list))
                dispatch(setTotalEntry(resultAll?.data?.data?.pagination?.total_pages))
                props?.setCoachCount(resultAll?.data?.data?.pagination.total)
            }
        }
    }

    return <>
        <div className='d-flex align-items-center justify-content-between flex-row'>
            {localData?.id ? <Fragment>
                <UpdateCoach localData={localData} show={showCoachEditModal} handleClose={handleEditCoachModal} size='md' />
            </Fragment> : ''}
            <div>
                <Edit3 className='me-50 ms-2 custom-hover text-primary' size={20} onClick={handleEditCoachModal} />
            </div>
            <div>
                <Trash className='me-50 ms-2 custom-hover text-primary' size={15} onClick={handleDeleteModal} />
            </div>
        </div>

        <Modal isOpen={isDeleteModal} toggle={() => setIsDeleteModal(!isDeleteModal)} className='modal-dialog-centered'>
            <ModalHeader toggle={() => setIsDeleteModal(!isDeleteModal)}>Delete Access Manager</ModalHeader>
            <ModalBody>
                <h5>Are you sure, you want to delete this access manager?</h5>
            </ModalBody>
            <ModalFooter>
                <Button className='ms-2 text-light' color='dark' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color='primary text-light' onClick={() => handleDelete(!isDeleteModal)}>
                    Yes
                </Button>
            </ModalFooter>
        </Modal>

    </>
}

export default DeleteUpdate
